.board {
    padding: 20px;
}

.ant-table.ant-table-small {
    font-size: 12px;
}

.ant-table-tbody td.ant-table-cell,
.ant-table-thead td.ant-table-cell {
    text-align: center;
    position: relative;
}

.ant-table-tbody > tr.ant-table-row > td {
    cursor: pointer;
}

.ant-table-tbody > tr.ant-table-row:hover > td.schedule-item,
.ant-table-tbody > tr > td.ant-table-cell-row-hover.schedule-item {
    background: #fff;
}

.ant-table-tbody td.ant-table-cell.schedule-item:not(.not-allowed):not(.ant-table-cell-fix-left):hover {
    /*color: #8d8d8d;*/
    background: #f7f7f7;
}

.ant-table-tbody > tr.ant-table-row > td.ant-table-cell.schedule-item.not-allowed,
.ant-table-tbody > tr > td.ant-table-cell.schedule-item.not-allowed,
.ant-table-tbody > tr.ant-table-row:hover > td.ant-table-cell.schedule-item.not-allowed,
.ant-table-tbody > tr > td.ant-table-cell-row-hover.ant-table-cell.schedule-item.not-allowed {
    cursor: not-allowed;
    background: rgb(216, 120, 120);
}

.ant-table-tbody > tr.ant-table-row > td.ant-table-cell.schedule-item.disabled,
.ant-table-tbody > tr > td.ant-table-cell.schedule-item.disabled,
.ant-table-tbody > tr.ant-table-row:hover > td.ant-table-cell.schedule-item.disabled,
.ant-table-tbody > tr > td.ant-table-cell-row-hover.ant-table-cell.schedule-item.disabled {
    cursor: not-allowed;
    background: rgb(246, 246, 246);
}

.ant-table-tbody > tr.ant-table-row > td.ant-table-cell.schedule-item.focused,
.ant-table-tbody > tr > td.ant-table-cell.schedule-item.focused,
.ant-table-tbody > tr.ant-table-row:hover > td.ant-table-cell.schedule-item.focused,
.ant-table-tbody > tr > td.ant-table-cell-row-hover.ant-table-cell.schedule-item.focused {
    background: rgb(223, 223, 223)
}

.ant-table-tbody td.ant-table-cell.schedule-item.has-status:not(.not-allowed)::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    z-index: 3;
}

td.ant-table-cell.ant-table-cell-fix-left {
    text-align: left;
    z-index: 4;
}

td.ant-table-cell span {
    display: block;
}

.detail-switcher {
    position: absolute;
    top: 0;
    left: 0;
}

div[id*="board-schedule-table-"] {
    width: 100%;
    position: relative;
}

.ap-switcher-wrapper {
    z-index: 10;
    position: absolute;
    right: 0px;
    top: 0;
    width: 40px;
    height: 40px;
    top: 117px;
    cursor: pointer;
    background: #ffffff;
    color: #272828;
    font-size: 20px;
    border-radius: 5px;
    border: 1px solid #c1c1c1;
    border-right: none;
    font-size: 18px;
    border-radius: 5px 0 0 5px;
    text-align: center;
    line-height: 40px;
}