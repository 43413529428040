.AbbeyBeforeBg:before,
.AbbeyAfterBg:after,
.AbbeyBg {
    background-color: #4c4f56
}

.AbbeyColor {
    color: #4c4f56
}

.AbsoluteZeroBeforeBg:before,
.AbsoluteZeroAfterBg:after,
.AbsoluteZeroBg {
    background-color: #0048ba
}

.AbsoluteZeroColor {
    color: #0048ba
}

.AcadiaBeforeBg:before,
.AcadiaAfterBg:after,
.AcadiaBg {
    background-color: #1b1404
}

.AcadiaColor {
    color: #1b1404
}

.AcapulcoBeforeBg:before,
.AcapulcoAfterBg:after,
.AcapulcoBg {
    background-color: #7cb0a1
}

.AcapulcoColor {
    color: #7cb0a1
}

.AcidGreenBeforeBg:before,
.AcidGreenAfterBg:after,
.AcidGreenBg {
    background-color: #b0bf1a
}

.AcidGreenColor {
    color: #b0bf1a
}

.AeroBeforeBg:before,
.AeroAfterBg:after,
.AeroBg {
    background-color: #7cb9e8
}

.AeroColor {
    color: #7cb9e8
}

.AeroBlueBeforeBg:before,
.AeroBlueAfterBg:after,
.AeroBlueBg {
    background-color: #c9ffe5
}

.AeroBlueColor {
    color: #c9ffe5
}

.AffairBeforeBg:before,
.AffairAfterBg:after,
.AffairBg {
    background-color: #714693
}

.AffairColor {
    color: #714693
}

.AfricanVioletBeforeBg:before,
.AfricanVioletAfterBg:after,
.AfricanVioletBg {
    background-color: #b284be
}

.AfricanVioletColor {
    color: #b284be
}

.AirForceBlueBeforeBg:before,
.AirForceBlueAfterBg:after,
.AirForceBlueBg {
    background-color: #00308f
}

.AirForceBlueColor {
    color: #00308f
}

.AirSuperiorityBlueBeforeBg:before,
.AirSuperiorityBlueAfterBg:after,
.AirSuperiorityBlueBg {
    background-color: #72a0c1
}

.AirSuperiorityBlueColor {
    color: #72a0c1
}

.AkaroaBeforeBg:before,
.AkaroaAfterBg:after,
.AkaroaBg {
    background-color: #d4c4a8
}

.AkaroaColor {
    color: #d4c4a8
}

.AlabamaCrimsonBeforeBg:before,
.AlabamaCrimsonAfterBg:after,
.AlabamaCrimsonBg {
    background-color: #af002a
}

.AlabamaCrimsonColor {
    color: #af002a
}

.AlabasterBeforeBg:before,
.AlabasterAfterBg:after,
.AlabasterBg {
    background-color: #fafafa
}

.AlabasterColor {
    color: #fafafa
}

.AlbescentWhiteBeforeBg:before,
.AlbescentWhiteAfterBg:after,
.AlbescentWhiteBg {
    background-color: #f5e9d3
}

.AlbescentWhiteColor {
    color: #f5e9d3
}

.AlgaeGreenBeforeBg:before,
.AlgaeGreenAfterBg:after,
.AlgaeGreenBg {
    background-color: #93dfb8
}

.AlgaeGreenColor {
    color: #93dfb8
}

.AliceBlueBeforeBg:before,
.AliceBlueAfterBg:after,
.AliceBlueBg {
    background-color: #f0f8ff
}

.AliceBlueColor {
    color: #f0f8ff
}

.AlienArmpitBeforeBg:before,
.AlienArmpitAfterBg:after,
.AlienArmpitBg {
    background-color: #84de02
}

.AlienArmpitColor {
    color: #84de02
}

.AlizarinCrimsonBeforeBg:before,
.AlizarinCrimsonAfterBg:after,
.AlizarinCrimsonBg {
    background-color: #e32636
}

.AlizarinCrimsonColor {
    color: #e32636
}

.AlloyOrangeBeforeBg:before,
.AlloyOrangeAfterBg:after,
.AlloyOrangeBg {
    background-color: #c46210
}

.AlloyOrangeColor {
    color: #c46210
}

.AllportsBeforeBg:before,
.AllportsAfterBg:after,
.AllportsBg {
    background-color: #0076a3
}

.AllportsColor {
    color: #0076a3
}

.AlmondBeforeBg:before,
.AlmondAfterBg:after,
.AlmondBg {
    background-color: #efdecd
}

.AlmondColor {
    color: #efdecd
}

.AlmondFrostBeforeBg:before,
.AlmondFrostAfterBg:after,
.AlmondFrostBg {
    background-color: #907b71
}

.AlmondFrostColor {
    color: #907b71
}

.AlpineBeforeBg:before,
.AlpineAfterBg:after,
.AlpineBg {
    background-color: #af8f2c
}

.AlpineColor {
    color: #af8f2c
}

.AltoBeforeBg:before,
.AltoAfterBg:after,
.AltoBg {
    background-color: #dbdbdb
}

.AltoColor {
    color: #dbdbdb
}

.AluminiumBeforeBg:before,
.AluminiumAfterBg:after,
.AluminiumBg {
    background-color: #a9acb6
}

.AluminiumColor {
    color: #a9acb6
}

.AmaranthBeforeBg:before,
.AmaranthAfterBg:after,
.AmaranthBg {
    background-color: #e52b50
}

.AmaranthColor {
    color: #e52b50
}

.AmaranthPinkBeforeBg:before,
.AmaranthPinkAfterBg:after,
.AmaranthPinkBg {
    background-color: #f19cbb
}

.AmaranthPinkColor {
    color: #f19cbb
}

.AmaranthPurpleBeforeBg:before,
.AmaranthPurpleAfterBg:after,
.AmaranthPurpleBg {
    background-color: #ab274f
}

.AmaranthPurpleColor {
    color: #ab274f
}

.AmaranthRedBeforeBg:before,
.AmaranthRedAfterBg:after,
.AmaranthRedBg {
    background-color: #d3212d
}

.AmaranthRedColor {
    color: #d3212d
}

.AmazonBeforeBg:before,
.AmazonAfterBg:after,
.AmazonBg {
    background-color: #3b7a57
}

.AmazonColor {
    color: #3b7a57
}

.AmberBeforeBg:before,
.AmberAfterBg:after,
.AmberBg {
    background-color: #ffbf00
}

.AmberColor {
    color: #ffbf00
}

.AmericanRoseBeforeBg:before,
.AmericanRoseAfterBg:after,
.AmericanRoseBg {
    background-color: #ff033e
}

.AmericanRoseColor {
    color: #ff033e
}

.AmericanoBeforeBg:before,
.AmericanoAfterBg:after,
.AmericanoBg {
    background-color: #87756e
}

.AmericanoColor {
    color: #87756e
}

.AmethystBeforeBg:before,
.AmethystAfterBg:after,
.AmethystBg {
    background-color: #9966cc
}

.AmethystColor {
    color: #9966cc
}

.AmethystSmokeBeforeBg:before,
.AmethystSmokeAfterBg:after,
.AmethystSmokeBg {
    background-color: #a397b4
}

.AmethystSmokeColor {
    color: #a397b4
}

.AmourBeforeBg:before,
.AmourAfterBg:after,
.AmourBg {
    background-color: #f9eaf3
}

.AmourColor {
    color: #f9eaf3
}

.AmuletBeforeBg:before,
.AmuletAfterBg:after,
.AmuletBg {
    background-color: #7b9f80
}

.AmuletColor {
    color: #7b9f80
}

.AnakiwaBeforeBg:before,
.AnakiwaAfterBg:after,
.AnakiwaBg {
    background-color: #9de5ff
}

.AnakiwaColor {
    color: #9de5ff
}

.AndroidGreenBeforeBg:before,
.AndroidGreenAfterBg:after,
.AndroidGreenBg {
    background-color: #a4c639
}

.AndroidGreenColor {
    color: #a4c639
}

.AntiFlashWhiteBeforeBg:before,
.AntiFlashWhiteAfterBg:after,
.AntiFlashWhiteBg {
    background-color: #f2f3f4
}

.AntiFlashWhiteColor {
    color: #f2f3f4
}

.AntiqueBrassBeforeBg:before,
.AntiqueBrassAfterBg:after,
.AntiqueBrassBg {
    background-color: #cd9575
}

.AntiqueBrassColor {
    color: #cd9575
}

.AntiqueBronzeBeforeBg:before,
.AntiqueBronzeAfterBg:after,
.AntiqueBronzeBg {
    background-color: #665d1e
}

.AntiqueBronzeColor {
    color: #665d1e
}

.AntiqueFuchsiaBeforeBg:before,
.AntiqueFuchsiaAfterBg:after,
.AntiqueFuchsiaBg {
    background-color: #915c83
}

.AntiqueFuchsiaColor {
    color: #915c83
}

.AntiqueRubyBeforeBg:before,
.AntiqueRubyAfterBg:after,
.AntiqueRubyBg {
    background-color: #841b2d
}

.AntiqueRubyColor {
    color: #841b2d
}

.AntiqueWhiteBeforeBg:before,
.AntiqueWhiteAfterBg:after,
.AntiqueWhiteBg {
    background-color: #faebd7
}

.AntiqueWhiteColor {
    color: #faebd7
}

.AnzacBeforeBg:before,
.AnzacAfterBg:after,
.AnzacBg {
    background-color: #e0b646
}

.AnzacColor {
    color: #e0b646
}

.AoBeforeBg:before,
.AoAfterBg:after,
.AoBg {
    background-color: #008000
}

.AoColor {
    color: #008000
}

.ApacheBeforeBg:before,
.ApacheAfterBg:after,
.ApacheBg {
    background-color: #dfbe6f
}

.ApacheColor {
    color: #dfbe6f
}

.AppleBeforeBg:before,
.AppleAfterBg:after,
.AppleBg {
    background-color: #4fa83d
}

.AppleColor {
    color: #4fa83d
}

.AppleBlossomBeforeBg:before,
.AppleBlossomAfterBg:after,
.AppleBlossomBg {
    background-color: #af4d43
}

.AppleBlossomColor {
    color: #af4d43
}

.AppleGreenBeforeBg:before,
.AppleGreenAfterBg:after,
.AppleGreenBg {
    background-color: #8db600
}

.AppleGreenColor {
    color: #8db600
}

.ApricotBeforeBg:before,
.ApricotAfterBg:after,
.ApricotBg {
    background-color: #fbceb1
}

.ApricotColor {
    color: #fbceb1
}

.ApricotWhiteBeforeBg:before,
.ApricotWhiteAfterBg:after,
.ApricotWhiteBg {
    background-color: #fffeec
}

.ApricotWhiteColor {
    color: #fffeec
}

.AquaDeepBeforeBg:before,
.AquaDeepAfterBg:after,
.AquaDeepBg {
    background-color: #014b43
}

.AquaDeepColor {
    color: #014b43
}

.AquaForestBeforeBg:before,
.AquaForestAfterBg:after,
.AquaForestBg {
    background-color: #5fa777
}

.AquaForestColor {
    color: #5fa777
}

.AquaHazeBeforeBg:before,
.AquaHazeAfterBg:after,
.AquaHazeBg {
    background-color: #edf5f5
}

.AquaHazeColor {
    color: #edf5f5
}

.AquaIslandBeforeBg:before,
.AquaIslandAfterBg:after,
.AquaIslandBg {
    background-color: #a1dad7
}

.AquaIslandColor {
    color: #a1dad7
}

.AquaSpringBeforeBg:before,
.AquaSpringAfterBg:after,
.AquaSpringBg {
    background-color: #eaf9f5
}

.AquaSpringColor {
    color: #eaf9f5
}

.AquaSqueezeBeforeBg:before,
.AquaSqueezeAfterBg:after,
.AquaSqueezeBg {
    background-color: #e8f5f2
}

.AquaSqueezeColor {
    color: #e8f5f2
}

.AquamarineBeforeBg:before,
.AquamarineAfterBg:after,
.AquamarineBg {
    background-color: #7fffd4
}

.AquamarineColor {
    color: #7fffd4
}

.AquamarineBlueBeforeBg:before,
.AquamarineBlueAfterBg:after,
.AquamarineBlueBg {
    background-color: #71d9e2
}

.AquamarineBlueColor {
    color: #71d9e2
}

.ArapawaBeforeBg:before,
.ArapawaAfterBg:after,
.ArapawaBg {
    background-color: #110c6c
}

.ArapawaColor {
    color: #110c6c
}

.ArcticLimeBeforeBg:before,
.ArcticLimeAfterBg:after,
.ArcticLimeBg {
    background-color: #d0ff14
}

.ArcticLimeColor {
    color: #d0ff14
}

.ArmadilloBeforeBg:before,
.ArmadilloAfterBg:after,
.ArmadilloBg {
    background-color: #433e37
}

.ArmadilloColor {
    color: #433e37
}

.ArmyGreenBeforeBg:before,
.ArmyGreenAfterBg:after,
.ArmyGreenBg {
    background-color: #4b5320
}

.ArmyGreenColor {
    color: #4b5320
}

.ArrowtownBeforeBg:before,
.ArrowtownAfterBg:after,
.ArrowtownBg {
    background-color: #948771
}

.ArrowtownColor {
    color: #948771
}

.ArsenicBeforeBg:before,
.ArsenicAfterBg:after,
.ArsenicBg {
    background-color: #3b444b
}

.ArsenicColor {
    color: #3b444b
}

.ArtichokeBeforeBg:before,
.ArtichokeAfterBg:after,
.ArtichokeBg {
    background-color: #8f9779
}

.ArtichokeColor {
    color: #8f9779
}

.ArylideYellowBeforeBg:before,
.ArylideYellowAfterBg:after,
.ArylideYellowBg {
    background-color: #e9d66b
}

.ArylideYellowColor {
    color: #e9d66b
}

.AshBeforeBg:before,
.AshAfterBg:after,
.AshBg {
    background-color: #c6c3b5
}

.AshColor {
    color: #c6c3b5
}

.AshGreyBeforeBg:before,
.AshGreyAfterBg:after,
.AshGreyBg {
    background-color: #b2beb5
}

.AshGreyColor {
    color: #b2beb5
}

.AsparagusBeforeBg:before,
.AsparagusAfterBg:after,
.AsparagusBg {
    background-color: #87a96b
}

.AsparagusColor {
    color: #87a96b
}

.AsphaltBeforeBg:before,
.AsphaltAfterBg:after,
.AsphaltBg {
    background-color: #130a06
}

.AsphaltColor {
    color: #130a06
}

.AstraBeforeBg:before,
.AstraAfterBg:after,
.AstraBg {
    background-color: #faeab9
}

.AstraColor {
    color: #faeab9
}

.AstralBeforeBg:before,
.AstralAfterBg:after,
.AstralBg {
    background-color: #327da0
}

.AstralColor {
    color: #327da0
}

.AstronautBeforeBg:before,
.AstronautAfterBg:after,
.AstronautBg {
    background-color: #283a77
}

.AstronautColor {
    color: #283a77
}

.AstronautBlueBeforeBg:before,
.AstronautBlueAfterBg:after,
.AstronautBlueBg {
    background-color: #013e62
}

.AstronautBlueColor {
    color: #013e62
}

.AthensGrayBeforeBg:before,
.AthensGrayAfterBg:after,
.AthensGrayBg {
    background-color: #eef0f3
}

.AthensGrayColor {
    color: #eef0f3
}

.AthsSpecialBeforeBg:before,
.AthsSpecialAfterBg:after,
.AthsSpecialBg {
    background-color: #ecebce
}

.AthsSpecialColor {
    color: #ecebce
}

.AtlantisBeforeBg:before,
.AtlantisAfterBg:after,
.AtlantisBg {
    background-color: #97cd2d
}

.AtlantisColor {
    color: #97cd2d
}

.AtollBeforeBg:before,
.AtollAfterBg:after,
.AtollBg {
    background-color: #0a6f75
}

.AtollColor {
    color: #0a6f75
}

.AuChicoBeforeBg:before,
.AuChicoAfterBg:after,
.AuChicoBg {
    background-color: #97605d
}

.AuChicoColor {
    color: #97605d
}

.AubergineBeforeBg:before,
.AubergineAfterBg:after,
.AubergineBg {
    background-color: #3b0910
}

.AubergineColor {
    color: #3b0910
}

.AuburnBeforeBg:before,
.AuburnAfterBg:after,
.AuburnBg {
    background-color: #a52a2a
}

.AuburnColor {
    color: #a52a2a
}

.AureolinBeforeBg:before,
.AureolinAfterBg:after,
.AureolinBg {
    background-color: #fdee00
}

.AureolinColor {
    color: #fdee00
}

.AuroMetalSaurusBeforeBg:before,
.AuroMetalSaurusAfterBg:after,
.AuroMetalSaurusBg {
    background-color: #6e7f80
}

.AuroMetalSaurusColor {
    color: #6e7f80
}

.AustralianMintBeforeBg:before,
.AustralianMintAfterBg:after,
.AustralianMintBg {
    background-color: #f5ffbe
}

.AustralianMintColor {
    color: #f5ffbe
}

.AvocadoBeforeBg:before,
.AvocadoAfterBg:after,
.AvocadoBg {
    background-color: #568203
}

.AvocadoColor {
    color: #568203
}

.AxolotlBeforeBg:before,
.AxolotlAfterBg:after,
.AxolotlBg {
    background-color: #4e6649
}

.AxolotlColor {
    color: #4e6649
}

.AzaleaBeforeBg:before,
.AzaleaAfterBg:after,
.AzaleaBg {
    background-color: #f7c8da
}

.AzaleaColor {
    color: #f7c8da
}

.AztecBeforeBg:before,
.AztecAfterBg:after,
.AztecBg {
    background-color: #0d1c19
}

.AztecColor {
    color: #0d1c19
}

.AztecGoldBeforeBg:before,
.AztecGoldAfterBg:after,
.AztecGoldBg {
    background-color: #c39953
}

.AztecGoldColor {
    color: #c39953
}

.AzureBeforeBg:before,
.AzureAfterBg:after,
.AzureBg {
    background-color: #007fff
}

.AzureColor {
    color: #007fff
}

.AzureMistBeforeBg:before,
.AzureMistAfterBg:after,
.AzureMistBg {
    background-color: #f0ffff
}

.AzureMistColor {
    color: #f0ffff
}

.AzureishWhiteBeforeBg:before,
.AzureishWhiteAfterBg:after,
.AzureishWhiteBg {
    background-color: #dbe9f4
}

.AzureishWhiteColor {
    color: #dbe9f4
}

.BabyBlueBeforeBg:before,
.BabyBlueAfterBg:after,
.BabyBlueBg {
    background-color: #89cff0
}

.BabyBlueColor {
    color: #89cff0
}

.BabyBlueEyesBeforeBg:before,
.BabyBlueEyesAfterBg:after,
.BabyBlueEyesBg {
    background-color: #a1caf1
}

.BabyBlueEyesColor {
    color: #a1caf1
}

.BabyPowderBeforeBg:before,
.BabyPowderAfterBg:after,
.BabyPowderBg {
    background-color: #fefefa
}

.BabyPowderColor {
    color: #fefefa
}

.BahamaBlueBeforeBg:before,
.BahamaBlueAfterBg:after,
.BahamaBlueBg {
    background-color: #026395
}

.BahamaBlueColor {
    color: #026395
}

.BahiaBeforeBg:before,
.BahiaAfterBg:after,
.BahiaBg {
    background-color: #a5cb0c
}

.BahiaColor {
    color: #a5cb0c
}

.BajaWhiteBeforeBg:before,
.BajaWhiteAfterBg:after,
.BajaWhiteBg {
    background-color: #fff8d1
}

.BajaWhiteColor {
    color: #fff8d1
}

.BakerMillerPinkBeforeBg:before,
.BakerMillerPinkAfterBg:after,
.BakerMillerPinkBg {
    background-color: #ff91af
}

.BakerMillerPinkColor {
    color: #ff91af
}

.BaliHaiBeforeBg:before,
.BaliHaiAfterBg:after,
.BaliHaiBg {
    background-color: #859faf
}

.BaliHaiColor {
    color: #859faf
}

.BallBlueBeforeBg:before,
.BallBlueAfterBg:after,
.BallBlueBg {
    background-color: #21abcd
}

.BallBlueColor {
    color: #21abcd
}

.BalticSeaBeforeBg:before,
.BalticSeaAfterBg:after,
.BalticSeaBg {
    background-color: #2a2630
}

.BalticSeaColor {
    color: #2a2630
}

.BambooBeforeBg:before,
.BambooAfterBg:after,
.BambooBg {
    background-color: #da6304
}

.BambooColor {
    color: #da6304
}

.BananaManiaBeforeBg:before,
.BananaManiaAfterBg:after,
.BananaManiaBg {
    background-color: #fae7b5
}

.BananaManiaColor {
    color: #fae7b5
}

.BananaYellowBeforeBg:before,
.BananaYellowAfterBg:after,
.BananaYellowBg {
    background-color: #ffe135
}

.BananaYellowColor {
    color: #ffe135
}

.BandicootBeforeBg:before,
.BandicootAfterBg:after,
.BandicootBg {
    background-color: #858470
}

.BandicootColor {
    color: #858470
}

.BarberryBeforeBg:before,
.BarberryAfterBg:after,
.BarberryBg {
    background-color: #ded717
}

.BarberryColor {
    color: #ded717
}

.BarbiePinkBeforeBg:before,
.BarbiePinkAfterBg:after,
.BarbiePinkBg {
    background-color: #e0218a
}

.BarbiePinkColor {
    color: #e0218a
}

.BarleyCornBeforeBg:before,
.BarleyCornAfterBg:after,
.BarleyCornBg {
    background-color: #a68b5b
}

.BarleyCornColor {
    color: #a68b5b
}

.BarleyWhiteBeforeBg:before,
.BarleyWhiteAfterBg:after,
.BarleyWhiteBg {
    background-color: #fff4ce
}

.BarleyWhiteColor {
    color: #fff4ce
}

.BarnRedBeforeBg:before,
.BarnRedAfterBg:after,
.BarnRedBg {
    background-color: #7c0a02
}

.BarnRedColor {
    color: #7c0a02
}

.BarossaBeforeBg:before,
.BarossaAfterBg:after,
.BarossaBg {
    background-color: #44012d
}

.BarossaColor {
    color: #44012d
}

.BastilleBeforeBg:before,
.BastilleAfterBg:after,
.BastilleBg {
    background-color: #292130
}

.BastilleColor {
    color: #292130
}

.BattleshipGrayBeforeBg:before,
.BattleshipGrayAfterBg:after,
.BattleshipGrayBg {
    background-color: #828f72
}

.BattleshipGrayColor {
    color: #828f72
}

.BayLeafBeforeBg:before,
.BayLeafAfterBg:after,
.BayLeafBg {
    background-color: #7da98d
}

.BayLeafColor {
    color: #7da98d
}

.BayofManyBeforeBg:before,
.BayofManyAfterBg:after,
.BayofManyBg {
    background-color: #273a81
}

.BayofManyColor {
    color: #273a81
}

.BazaarBeforeBg:before,
.BazaarAfterBg:after,
.BazaarBg {
    background-color: #98777b
}

.BazaarColor {
    color: #98777b
}

.BdazzledBlueBeforeBg:before,
.BdazzledBlueAfterBg:after,
.BdazzledBlueBg {
    background-color: #2e5894
}

.BdazzledBlueColor {
    color: #2e5894
}

.BeauBlueBeforeBg:before,
.BeauBlueAfterBg:after,
.BeauBlueBg {
    background-color: #bcd4e6
}

.BeauBlueColor {
    color: #bcd4e6
}

.BeautyBushBeforeBg:before,
.BeautyBushAfterBg:after,
.BeautyBushBg {
    background-color: #eec1be
}

.BeautyBushColor {
    color: #eec1be
}

.BeaverBeforeBg:before,
.BeaverAfterBg:after,
.BeaverBg {
    background-color: #9f8170
}

.BeaverColor {
    color: #9f8170
}

.BeeswaxBeforeBg:before,
.BeeswaxAfterBg:after,
.BeeswaxBg {
    background-color: #fef2c7
}

.BeeswaxColor {
    color: #fef2c7
}

.BeigeBeforeBg:before,
.BeigeAfterBg:after,
.BeigeBg {
    background-color: #f5f5dc
}

.BeigeColor {
    color: #f5f5dc
}

.BelgionBeforeBg:before,
.BelgionAfterBg:after,
.BelgionBg {
    background-color: #add8ff
}

.BelgionColor {
    color: #add8ff
}

.BermudaBeforeBg:before,
.BermudaAfterBg:after,
.BermudaBg {
    background-color: #7dd8c6
}

.BermudaColor {
    color: #7dd8c6
}

.BermudaGrayBeforeBg:before,
.BermudaGrayAfterBg:after,
.BermudaGrayBg {
    background-color: #6b8ba2
}

.BermudaGrayColor {
    color: #6b8ba2
}

.BerylGreenBeforeBg:before,
.BerylGreenAfterBg:after,
.BerylGreenBg {
    background-color: #dee5c0
}

.BerylGreenColor {
    color: #dee5c0
}

.BiancaBeforeBg:before,
.BiancaAfterBg:after,
.BiancaBg {
    background-color: #fcfbf3
}

.BiancaColor {
    color: #fcfbf3
}

.BigDipOrubyBeforeBg:before,
.BigDipOrubyAfterBg:after,
.BigDipOrubyBg {
    background-color: #9c2542
}

.BigDipOrubyColor {
    color: #9c2542
}

.BigFootFeetBeforeBg:before,
.BigFootFeetAfterBg:after,
.BigFootFeetBg {
    background-color: #e88e5a
}

.BigFootFeetColor {
    color: #e88e5a
}

.BigStoneBeforeBg:before,
.BigStoneAfterBg:after,
.BigStoneBg {
    background-color: #162a40
}

.BigStoneColor {
    color: #162a40
}

.BilbaoBeforeBg:before,
.BilbaoAfterBg:after,
.BilbaoBg {
    background-color: #327c14
}

.BilbaoColor {
    color: #327c14
}

.BilobaFlowerBeforeBg:before,
.BilobaFlowerAfterBg:after,
.BilobaFlowerBg {
    background-color: #b2a1ea
}

.BilobaFlowerColor {
    color: #b2a1ea
}

.BirchBeforeBg:before,
.BirchAfterBg:after,
.BirchBg {
    background-color: #373021
}

.BirchColor {
    color: #373021
}

.BirdFlowerBeforeBg:before,
.BirdFlowerAfterBg:after,
.BirdFlowerBg {
    background-color: #d4cd16
}

.BirdFlowerColor {
    color: #d4cd16
}

.BiscayBeforeBg:before,
.BiscayAfterBg:after,
.BiscayBg {
    background-color: #1b3162
}

.BiscayColor {
    color: #1b3162
}

.BismarkBeforeBg:before,
.BismarkAfterBg:after,
.BismarkBg {
    background-color: #497183
}

.BismarkColor {
    color: #497183
}

.BisonHideBeforeBg:before,
.BisonHideAfterBg:after,
.BisonHideBg {
    background-color: #c1b7a4
}

.BisonHideColor {
    color: #c1b7a4
}

.BisqueBeforeBg:before,
.BisqueAfterBg:after,
.BisqueBg {
    background-color: #ffe4c4
}

.BisqueColor {
    color: #ffe4c4
}

.BistreBeforeBg:before,
.BistreAfterBg:after,
.BistreBg {
    background-color: #3d2b1f
}

.BistreColor {
    color: #3d2b1f
}

.BitterBeforeBg:before,
.BitterAfterBg:after,
.BitterBg {
    background-color: #868974
}

.BitterColor {
    color: #868974
}

.BitterLemonBeforeBg:before,
.BitterLemonAfterBg:after,
.BitterLemonBg {
    background-color: #cae00d
}

.BitterLemonColor {
    color: #cae00d
}

.BittersweetBeforeBg:before,
.BittersweetAfterBg:after,
.BittersweetBg {
    background-color: #fe6f5e
}

.BittersweetColor {
    color: #fe6f5e
}

.BittersweetShimmerBeforeBg:before,
.BittersweetShimmerAfterBg:after,
.BittersweetShimmerBg {
    background-color: #bf4f51
}

.BittersweetShimmerColor {
    color: #bf4f51
}

.BizarreBeforeBg:before,
.BizarreAfterBg:after,
.BizarreBg {
    background-color: #eededa
}

.BizarreColor {
    color: #eededa
}

.BlackBeforeBg:before,
.BlackAfterBg:after,
.BlackBg {
    background-color: #000000
}

.BlackColor {
    color: #000000
}

.BlackBeanBeforeBg:before,
.BlackBeanAfterBg:after,
.BlackBeanBg {
    background-color: #3d0c02
}

.BlackBeanColor {
    color: #3d0c02
}

.BlackCoralBeforeBg:before,
.BlackCoralAfterBg:after,
.BlackCoralBg {
    background-color: #54626f
}

.BlackCoralColor {
    color: #54626f
}

.BlackForestBeforeBg:before,
.BlackForestAfterBg:after,
.BlackForestBg {
    background-color: #0b1304
}

.BlackForestColor {
    color: #0b1304
}

.BlackHazeBeforeBg:before,
.BlackHazeAfterBg:after,
.BlackHazeBg {
    background-color: #f6f7f7
}

.BlackHazeColor {
    color: #f6f7f7
}

.BlackLeatherJacketBeforeBg:before,
.BlackLeatherJacketAfterBg:after,
.BlackLeatherJacketBg {
    background-color: #253529
}

.BlackLeatherJacketColor {
    color: #253529
}

.BlackMarlinBeforeBg:before,
.BlackMarlinAfterBg:after,
.BlackMarlinBg {
    background-color: #3e2c1c
}

.BlackMarlinColor {
    color: #3e2c1c
}

.BlackOliveBeforeBg:before,
.BlackOliveAfterBg:after,
.BlackOliveBg {
    background-color: #3b3c36
}

.BlackOliveColor {
    color: #3b3c36
}

.BlackPearlBeforeBg:before,
.BlackPearlAfterBg:after,
.BlackPearlBg {
    background-color: #041322
}

.BlackPearlColor {
    color: #041322
}

.BlackRockBeforeBg:before,
.BlackRockAfterBg:after,
.BlackRockBg {
    background-color: #0d0332
}

.BlackRockColor {
    color: #0d0332
}

.BlackRoseBeforeBg:before,
.BlackRoseAfterBg:after,
.BlackRoseBg {
    background-color: #67032d
}

.BlackRoseColor {
    color: #67032d
}

.BlackRussianBeforeBg:before,
.BlackRussianAfterBg:after,
.BlackRussianBg {
    background-color: #0a001c
}

.BlackRussianColor {
    color: #0a001c
}

.BlackShadowsBeforeBg:before,
.BlackShadowsAfterBg:after,
.BlackShadowsBg {
    background-color: #bfafb2
}

.BlackShadowsColor {
    color: #bfafb2
}

.BlackSqueezeBeforeBg:before,
.BlackSqueezeAfterBg:after,
.BlackSqueezeBg {
    background-color: #f2fafa
}

.BlackSqueezeColor {
    color: #f2fafa
}

.BlackWhiteBeforeBg:before,
.BlackWhiteAfterBg:after,
.BlackWhiteBg {
    background-color: #fffef6
}

.BlackWhiteColor {
    color: #fffef6
}

.BlackberryBeforeBg:before,
.BlackberryAfterBg:after,
.BlackberryBg {
    background-color: #4d0135
}

.BlackberryColor {
    color: #4d0135
}

.BlackcurrantBeforeBg:before,
.BlackcurrantAfterBg:after,
.BlackcurrantBg {
    background-color: #32293a
}

.BlackcurrantColor {
    color: #32293a
}

.BlanchedAlmondBeforeBg:before,
.BlanchedAlmondAfterBg:after,
.BlanchedAlmondBg {
    background-color: #ffebcd
}

.BlanchedAlmondColor {
    color: #ffebcd
}

.BlastOffBronzeBeforeBg:before,
.BlastOffBronzeAfterBg:after,
.BlastOffBronzeBg {
    background-color: #a57164
}

.BlastOffBronzeColor {
    color: #a57164
}

.BlazeOrangeBeforeBg:before,
.BlazeOrangeAfterBg:after,
.BlazeOrangeBg {
    background-color: #ff6700
}

.BlazeOrangeColor {
    color: #ff6700
}

.BleachWhiteBeforeBg:before,
.BleachWhiteAfterBg:after,
.BleachWhiteBg {
    background-color: #fef3d8
}

.BleachWhiteColor {
    color: #fef3d8
}

.BleachedCedarBeforeBg:before,
.BleachedCedarAfterBg:after,
.BleachedCedarBg {
    background-color: #2c2133
}

.BleachedCedarColor {
    color: #2c2133
}

.BleuDeFranceBeforeBg:before,
.BleuDeFranceAfterBg:after,
.BleuDeFranceBg {
    background-color: #318ce7
}

.BleuDeFranceColor {
    color: #318ce7
}

.BlizzardBlueBeforeBg:before,
.BlizzardBlueAfterBg:after,
.BlizzardBlueBg {
    background-color: #a3e3ed
}

.BlizzardBlueColor {
    color: #a3e3ed
}

.BlondBeforeBg:before,
.BlondAfterBg:after,
.BlondBg {
    background-color: #faf0be
}

.BlondColor {
    color: #faf0be
}

.BlossomBeforeBg:before,
.BlossomAfterBg:after,
.BlossomBg {
    background-color: #dcb4bc
}

.BlossomColor {
    color: #dcb4bc
}

.BlueBeforeBg:before,
.BlueAfterBg:after,
.BlueBg {
    background-color: #0000ff
}

.BlueColor {
    color: #0000ff
}

.BlueBayouxBeforeBg:before,
.BlueBayouxAfterBg:after,
.BlueBayouxBg {
    background-color: #496679
}

.BlueBayouxColor {
    color: #496679
}

.BlueBellBeforeBg:before,
.BlueBellAfterBg:after,
.BlueBellBg {
    background-color: #a2a2d0
}

.BlueBellColor {
    color: #a2a2d0
}

.BlueChalkBeforeBg:before,
.BlueChalkAfterBg:after,
.BlueChalkBg {
    background-color: #f1e9ff
}

.BlueChalkColor {
    color: #f1e9ff
}

.BlueCharcoalBeforeBg:before,
.BlueCharcoalAfterBg:after,
.BlueCharcoalBg {
    background-color: #010d1a
}

.BlueCharcoalColor {
    color: #010d1a
}

.BlueChillBeforeBg:before,
.BlueChillAfterBg:after,
.BlueChillBg {
    background-color: #0c8990
}

.BlueChillColor {
    color: #0c8990
}

.BlueDiamondBeforeBg:before,
.BlueDiamondAfterBg:after,
.BlueDiamondBg {
    background-color: #380474
}

.BlueDiamondColor {
    color: #380474
}

.BlueDianneBeforeBg:before,
.BlueDianneAfterBg:after,
.BlueDianneBg {
    background-color: #204852
}

.BlueDianneColor {
    color: #204852
}

.BlueGemBeforeBg:before,
.BlueGemAfterBg:after,
.BlueGemBg {
    background-color: #2c0e8c
}

.BlueGemColor {
    color: #2c0e8c
}

.BlueGrayBeforeBg:before,
.BlueGrayAfterBg:after,
.BlueGrayBg {
    background-color: #6699cc
}

.BlueGrayColor {
    color: #6699cc
}

.BlueGreenBeforeBg:before,
.BlueGreenAfterBg:after,
.BlueGreenBg {
    background-color: #0d98ba
}

.BlueGreenColor {
    color: #0d98ba
}

.BlueHazeBeforeBg:before,
.BlueHazeAfterBg:after,
.BlueHazeBg {
    background-color: #bfbed8
}

.BlueHazeColor {
    color: #bfbed8
}

.BlueJeansBeforeBg:before,
.BlueJeansAfterBg:after,
.BlueJeansBg {
    background-color: #5dadec
}

.BlueJeansColor {
    color: #5dadec
}

.BlueLagoonBeforeBg:before,
.BlueLagoonAfterBg:after,
.BlueLagoonBg {
    background-color: #ace5ee
}

.BlueLagoonColor {
    color: #ace5ee
}

.BlueMagentaVioletBeforeBg:before,
.BlueMagentaVioletAfterBg:after,
.BlueMagentaVioletBg {
    background-color: #553592
}

.BlueMagentaVioletColor {
    color: #553592
}

.BlueMargueriteBeforeBg:before,
.BlueMargueriteAfterBg:after,
.BlueMargueriteBg {
    background-color: #7666c6
}

.BlueMargueriteColor {
    color: #7666c6
}

.BlueRibbonBeforeBg:before,
.BlueRibbonAfterBg:after,
.BlueRibbonBg {
    background-color: #0066ff
}

.BlueRibbonColor {
    color: #0066ff
}

.BlueRomanceBeforeBg:before,
.BlueRomanceAfterBg:after,
.BlueRomanceBg {
    background-color: #d2f6de
}

.BlueRomanceColor {
    color: #d2f6de
}

.BlueSapphireBeforeBg:before,
.BlueSapphireAfterBg:after,
.BlueSapphireBg {
    background-color: #126180
}

.BlueSapphireColor {
    color: #126180
}

.BlueSmokeBeforeBg:before,
.BlueSmokeAfterBg:after,
.BlueSmokeBg {
    background-color: #748881
}

.BlueSmokeColor {
    color: #748881
}

.BlueStoneBeforeBg:before,
.BlueStoneAfterBg:after,
.BlueStoneBg {
    background-color: #016162
}

.BlueStoneColor {
    color: #016162
}

.BlueVioletBeforeBg:before,
.BlueVioletAfterBg:after,
.BlueVioletBg {
    background-color: #8a2be2
}

.BlueVioletColor {
    color: #8a2be2
}

.BlueWhaleBeforeBg:before,
.BlueWhaleAfterBg:after,
.BlueWhaleBg {
    background-color: #042e4c
}

.BlueWhaleColor {
    color: #042e4c
}

.BlueYonderBeforeBg:before,
.BlueYonderAfterBg:after,
.BlueYonderBg {
    background-color: #5072a7
}

.BlueYonderColor {
    color: #5072a7
}

.BlueZodiacBeforeBg:before,
.BlueZodiacAfterBg:after,
.BlueZodiacBg {
    background-color: #13264d
}

.BlueZodiacColor {
    color: #13264d
}

.BlueberryBeforeBg:before,
.BlueberryAfterBg:after,
.BlueberryBg {
    background-color: #4f86f7
}

.BlueberryColor {
    color: #4f86f7
}

.BluebonnetBeforeBg:before,
.BluebonnetAfterBg:after,
.BluebonnetBg {
    background-color: #1c1cf0
}

.BluebonnetColor {
    color: #1c1cf0
}

.BlumineBeforeBg:before,
.BlumineAfterBg:after,
.BlumineBg {
    background-color: #18587a
}

.BlumineColor {
    color: #18587a
}

.BlushBeforeBg:before,
.BlushAfterBg:after,
.BlushBg {
    background-color: #de5d83
}

.BlushColor {
    color: #de5d83
}

.BoleBeforeBg:before,
.BoleAfterBg:after,
.BoleBg {
    background-color: #79443b
}

.BoleColor {
    color: #79443b
}

.BombayBeforeBg:before,
.BombayAfterBg:after,
.BombayBg {
    background-color: #afb1b8
}

.BombayColor {
    color: #afb1b8
}

.BonJourBeforeBg:before,
.BonJourAfterBg:after,
.BonJourBg {
    background-color: #e5e0e1
}

.BonJourColor {
    color: #e5e0e1
}

.BondiBlueBeforeBg:before,
.BondiBlueAfterBg:after,
.BondiBlueBg {
    background-color: #0095b6
}

.BondiBlueColor {
    color: #0095b6
}

.BoneBeforeBg:before,
.BoneAfterBg:after,
.BoneBg {
    background-color: #e3dac9
}

.BoneColor {
    color: #e3dac9
}

.BoogerBusterBeforeBg:before,
.BoogerBusterAfterBg:after,
.BoogerBusterBg {
    background-color: #dde26a
}

.BoogerBusterColor {
    color: #dde26a
}

.BordeauxBeforeBg:before,
.BordeauxAfterBg:after,
.BordeauxBg {
    background-color: #5c0120
}

.BordeauxColor {
    color: #5c0120
}

.BossanovaBeforeBg:before,
.BossanovaAfterBg:after,
.BossanovaBg {
    background-color: #4e2a5a
}

.BossanovaColor {
    color: #4e2a5a
}

.BostonBlueBeforeBg:before,
.BostonBlueAfterBg:after,
.BostonBlueBg {
    background-color: #3b91b4
}

.BostonBlueColor {
    color: #3b91b4
}

.BostonUniversityRedBeforeBg:before,
.BostonUniversityRedAfterBg:after,
.BostonUniversityRedBg {
    background-color: #cc0000
}

.BostonUniversityRedColor {
    color: #cc0000
}

.BotticelliBeforeBg:before,
.BotticelliAfterBg:after,
.BotticelliBg {
    background-color: #c7dde5
}

.BotticelliColor {
    color: #c7dde5
}

.BottleGreenBeforeBg:before,
.BottleGreenAfterBg:after,
.BottleGreenBg {
    background-color: #006a4e
}

.BottleGreenColor {
    color: #006a4e
}

.BoulderBeforeBg:before,
.BoulderAfterBg:after,
.BoulderBg {
    background-color: #7a7a7a
}

.BoulderColor {
    color: #7a7a7a
}

.BouquetBeforeBg:before,
.BouquetAfterBg:after,
.BouquetBg {
    background-color: #ae809e
}

.BouquetColor {
    color: #ae809e
}

.BourbonBeforeBg:before,
.BourbonAfterBg:after,
.BourbonBg {
    background-color: #ba6f1e
}

.BourbonColor {
    color: #ba6f1e
}

.BoysenberryBeforeBg:before,
.BoysenberryAfterBg:after,
.BoysenberryBg {
    background-color: #873260
}

.BoysenberryColor {
    color: #873260
}

.BrackenBeforeBg:before,
.BrackenAfterBg:after,
.BrackenBg {
    background-color: #4a2a04
}

.BrackenColor {
    color: #4a2a04
}

.BrandeisBlueBeforeBg:before,
.BrandeisBlueAfterBg:after,
.BrandeisBlueBg {
    background-color: #0070ff
}

.BrandeisBlueColor {
    color: #0070ff
}

.BrandyBeforeBg:before,
.BrandyAfterBg:after,
.BrandyBg {
    background-color: #dec196
}

.BrandyColor {
    color: #dec196
}

.BrandyPunchBeforeBg:before,
.BrandyPunchAfterBg:after,
.BrandyPunchBg {
    background-color: #cd8429
}

.BrandyPunchColor {
    color: #cd8429
}

.BrandyRoseBeforeBg:before,
.BrandyRoseAfterBg:after,
.BrandyRoseBg {
    background-color: #bb8983
}

.BrandyRoseColor {
    color: #bb8983
}

.BrassBeforeBg:before,
.BrassAfterBg:after,
.BrassBg {
    background-color: #b5a642
}

.BrassColor {
    color: #b5a642
}

.BreakerBayBeforeBg:before,
.BreakerBayAfterBg:after,
.BreakerBayBg {
    background-color: #5da19f
}

.BreakerBayColor {
    color: #5da19f
}

.BrickRedBeforeBg:before,
.BrickRedAfterBg:after,
.BrickRedBg {
    background-color: #cb4154
}

.BrickRedColor {
    color: #cb4154
}

.BridalHeathBeforeBg:before,
.BridalHeathAfterBg:after,
.BridalHeathBg {
    background-color: #fffaf4
}

.BridalHeathColor {
    color: #fffaf4
}

.BridesmaidBeforeBg:before,
.BridesmaidAfterBg:after,
.BridesmaidBg {
    background-color: #fef0ec
}

.BridesmaidColor {
    color: #fef0ec
}

.BrightCeruleanBeforeBg:before,
.BrightCeruleanAfterBg:after,
.BrightCeruleanBg {
    background-color: #1dacd6
}

.BrightCeruleanColor {
    color: #1dacd6
}

.BrightGrayBeforeBg:before,
.BrightGrayAfterBg:after,
.BrightGrayBg {
    background-color: #3c4151
}

.BrightGrayColor {
    color: #3c4151
}

.BrightGreenBeforeBg:before,
.BrightGreenAfterBg:after,
.BrightGreenBg {
    background-color: #66ff00
}

.BrightGreenColor {
    color: #66ff00
}

.BrightLavenderBeforeBg:before,
.BrightLavenderAfterBg:after,
.BrightLavenderBg {
    background-color: #bf94e4
}

.BrightLavenderColor {
    color: #bf94e4
}

.BrightLilacBeforeBg:before,
.BrightLilacAfterBg:after,
.BrightLilacBg {
    background-color: #d891ef
}

.BrightLilacColor {
    color: #d891ef
}

.BrightMaroonBeforeBg:before,
.BrightMaroonAfterBg:after,
.BrightMaroonBg {
    background-color: #c32148
}

.BrightMaroonColor {
    color: #c32148
}

.BrightNavyBlueBeforeBg:before,
.BrightNavyBlueAfterBg:after,
.BrightNavyBlueBg {
    background-color: #1974d2
}

.BrightNavyBlueColor {
    color: #1974d2
}

.BrightRedBeforeBg:before,
.BrightRedAfterBg:after,
.BrightRedBg {
    background-color: #b10000
}

.BrightRedColor {
    color: #b10000
}

.BrightSunBeforeBg:before,
.BrightSunAfterBg:after,
.BrightSunBg {
    background-color: #fed33c
}

.BrightSunColor {
    color: #fed33c
}

.BrightTurquoiseBeforeBg:before,
.BrightTurquoiseAfterBg:after,
.BrightTurquoiseBg {
    background-color: #08e8de
}

.BrightTurquoiseColor {
    color: #08e8de
}

.BrightUbeBeforeBg:before,
.BrightUbeAfterBg:after,
.BrightUbeBg {
    background-color: #d19fe8
}

.BrightUbeColor {
    color: #d19fe8
}

.BrightYellowBeforeBg:before,
.BrightYellowAfterBg:after,
.BrightYellowBg {
    background-color: #ffaa1d
}

.BrightYellowColor {
    color: #ffaa1d
}

.BrilliantAzureBeforeBg:before,
.BrilliantAzureAfterBg:after,
.BrilliantAzureBg {
    background-color: #3399ff
}

.BrilliantAzureColor {
    color: #3399ff
}

.BrilliantLavenderBeforeBg:before,
.BrilliantLavenderAfterBg:after,
.BrilliantLavenderBg {
    background-color: #f4bbff
}

.BrilliantLavenderColor {
    color: #f4bbff
}

.BrilliantRoseBeforeBg:before,
.BrilliantRoseAfterBg:after,
.BrilliantRoseBg {
    background-color: #ff55a3
}

.BrilliantRoseColor {
    color: #ff55a3
}

.BrinkPinkBeforeBg:before,
.BrinkPinkAfterBg:after,
.BrinkPinkBg {
    background-color: #fb607f
}

.BrinkPinkColor {
    color: #fb607f
}

.BritishRacingGreenBeforeBg:before,
.BritishRacingGreenAfterBg:after,
.BritishRacingGreenBg {
    background-color: #004225
}

.BritishRacingGreenColor {
    color: #004225
}

.BroncoBeforeBg:before,
.BroncoAfterBg:after,
.BroncoBg {
    background-color: #aba196
}

.BroncoColor {
    color: #aba196
}

.BronzeBeforeBg:before,
.BronzeAfterBg:after,
.BronzeBg {
    background-color: #cd7f32
}

.BronzeColor {
    color: #cd7f32
}

.BronzeOliveBeforeBg:before,
.BronzeOliveAfterBg:after,
.BronzeOliveBg {
    background-color: #4e420c
}

.BronzeOliveColor {
    color: #4e420c
}

.BronzeYellowBeforeBg:before,
.BronzeYellowAfterBg:after,
.BronzeYellowBg {
    background-color: #737000
}

.BronzeYellowColor {
    color: #737000
}

.BronzetoneBeforeBg:before,
.BronzetoneAfterBg:after,
.BronzetoneBg {
    background-color: #4d400f
}

.BronzetoneColor {
    color: #4d400f
}

.BroomBeforeBg:before,
.BroomAfterBg:after,
.BroomBg {
    background-color: #ffec13
}

.BroomColor {
    color: #ffec13
}

.BrownBeforeBg:before,
.BrownAfterBg:after,
.BrownBg {
    background-color: #964b00
}

.BrownColor {
    color: #964b00
}

.BrownBrambleBeforeBg:before,
.BrownBrambleAfterBg:after,
.BrownBrambleBg {
    background-color: #592804
}

.BrownBrambleColor {
    color: #592804
}

.BrownDerbyBeforeBg:before,
.BrownDerbyAfterBg:after,
.BrownDerbyBg {
    background-color: #492615
}

.BrownDerbyColor {
    color: #492615
}

.BrownPodBeforeBg:before,
.BrownPodAfterBg:after,
.BrownPodBg {
    background-color: #401801
}

.BrownPodColor {
    color: #401801
}

.BrownRustBeforeBg:before,
.BrownRustAfterBg:after,
.BrownRustBg {
    background-color: #af593e
}

.BrownRustColor {
    color: #af593e
}

.BrownSugarBeforeBg:before,
.BrownSugarAfterBg:after,
.BrownSugarBg {
    background-color: #af6e4d
}

.BrownSugarColor {
    color: #af6e4d
}

.BrownTumbleweedBeforeBg:before,
.BrownTumbleweedAfterBg:after,
.BrownTumbleweedBg {
    background-color: #37290e
}

.BrownTumbleweedColor {
    color: #37290e
}

.BrownYellowBeforeBg:before,
.BrownYellowAfterBg:after,
.BrownYellowBg {
    background-color: #cc9966
}

.BrownYellowColor {
    color: #cc9966
}

.BrunswickGreenBeforeBg:before,
.BrunswickGreenAfterBg:after,
.BrunswickGreenBg {
    background-color: #1b4d3e
}

.BrunswickGreenColor {
    color: #1b4d3e
}

.BubbleGumBeforeBg:before,
.BubbleGumAfterBg:after,
.BubbleGumBg {
    background-color: #ffc1cc
}

.BubbleGumColor {
    color: #ffc1cc
}

.BubblesBeforeBg:before,
.BubblesAfterBg:after,
.BubblesBg {
    background-color: #e7feff
}

.BubblesColor {
    color: #e7feff
}

.BuccaneerBeforeBg:before,
.BuccaneerAfterBg:after,
.BuccaneerBg {
    background-color: #622f30
}

.BuccaneerColor {
    color: #622f30
}

.BudBeforeBg:before,
.BudAfterBg:after,
.BudBg {
    background-color: #a8ae9c
}

.BudColor {
    color: #a8ae9c
}

.BudGreenBeforeBg:before,
.BudGreenAfterBg:after,
.BudGreenBg {
    background-color: #7bb661
}

.BudGreenColor {
    color: #7bb661
}

.BuddhaGoldBeforeBg:before,
.BuddhaGoldAfterBg:after,
.BuddhaGoldBg {
    background-color: #c1a004
}

.BuddhaGoldColor {
    color: #c1a004
}

.BuffBeforeBg:before,
.BuffAfterBg:after,
.BuffBg {
    background-color: #f0dc82
}

.BuffColor {
    color: #f0dc82
}

.BulgarianRoseBeforeBg:before,
.BulgarianRoseAfterBg:after,
.BulgarianRoseBg {
    background-color: #480607
}

.BulgarianRoseColor {
    color: #480607
}

.BullShotBeforeBg:before,
.BullShotAfterBg:after,
.BullShotBg {
    background-color: #864d1e
}

.BullShotColor {
    color: #864d1e
}

.BunkerBeforeBg:before,
.BunkerAfterBg:after,
.BunkerBg {
    background-color: #0d1117
}

.BunkerColor {
    color: #0d1117
}

.BuntingBeforeBg:before,
.BuntingAfterBg:after,
.BuntingBg {
    background-color: #151f4c
}

.BuntingColor {
    color: #151f4c
}

.BurgundyBeforeBg:before,
.BurgundyAfterBg:after,
.BurgundyBg {
    background-color: #800020
}

.BurgundyColor {
    color: #800020
}

.BurlywoodBeforeBg:before,
.BurlywoodAfterBg:after,
.BurlywoodBg {
    background-color: #deb887
}

.BurlywoodColor {
    color: #deb887
}

.BurnhamBeforeBg:before,
.BurnhamAfterBg:after,
.BurnhamBg {
    background-color: #002e20
}

.BurnhamColor {
    color: #002e20
}

.BurningOrangeBeforeBg:before,
.BurningOrangeAfterBg:after,
.BurningOrangeBg {
    background-color: #ff7034
}

.BurningOrangeColor {
    color: #ff7034
}

.BurningSandBeforeBg:before,
.BurningSandAfterBg:after,
.BurningSandBg {
    background-color: #d99376
}

.BurningSandColor {
    color: #d99376
}

.BurnishedBrownBeforeBg:before,
.BurnishedBrownAfterBg:after,
.BurnishedBrownBg {
    background-color: #a17a74
}

.BurnishedBrownColor {
    color: #a17a74
}

.BurntMaroonBeforeBg:before,
.BurntMaroonAfterBg:after,
.BurntMaroonBg {
    background-color: #420303
}

.BurntMaroonColor {
    color: #420303
}

.BurntOrangeBeforeBg:before,
.BurntOrangeAfterBg:after,
.BurntOrangeBg {
    background-color: #cc5500
}

.BurntOrangeColor {
    color: #cc5500
}

.BurntSiennaBeforeBg:before,
.BurntSiennaAfterBg:after,
.BurntSiennaBg {
    background-color: #e97451
}

.BurntSiennaColor {
    color: #e97451
}

.BurntUmberBeforeBg:before,
.BurntUmberAfterBg:after,
.BurntUmberBg {
    background-color: #8a3324
}

.BurntUmberColor {
    color: #8a3324
}

.BushBeforeBg:before,
.BushAfterBg:after,
.BushBg {
    background-color: #0d2e1c
}

.BushColor {
    color: #0d2e1c
}

.ButtercupBeforeBg:before,
.ButtercupAfterBg:after,
.ButtercupBg {
    background-color: #f3ad16
}

.ButtercupColor {
    color: #f3ad16
}

.ButteredRumBeforeBg:before,
.ButteredRumAfterBg:after,
.ButteredRumBg {
    background-color: #a1750d
}

.ButteredRumColor {
    color: #a1750d
}

.ButterflyBushBeforeBg:before,
.ButterflyBushAfterBg:after,
.ButterflyBushBg {
    background-color: #624e9a
}

.ButterflyBushColor {
    color: #624e9a
}

.ButtermilkBeforeBg:before,
.ButtermilkAfterBg:after,
.ButtermilkBg {
    background-color: #fff1b5
}

.ButtermilkColor {
    color: #fff1b5
}

.ButteryWhiteBeforeBg:before,
.ButteryWhiteAfterBg:after,
.ButteryWhiteBg {
    background-color: #fffcea
}

.ButteryWhiteColor {
    color: #fffcea
}

.ByzantineBeforeBg:before,
.ByzantineAfterBg:after,
.ByzantineBg {
    background-color: #bd33a4
}

.ByzantineColor {
    color: #bd33a4
}

.ByzantiumBeforeBg:before,
.ByzantiumAfterBg:after,
.ByzantiumBg {
    background-color: #702963
}

.ByzantiumColor {
    color: #702963
}

.CGBlueBeforeBg:before,
.CGBlueAfterBg:after,
.CGBlueBg {
    background-color: #007aa5
}

.CGBlueColor {
    color: #007aa5
}

.CGRedBeforeBg:before,
.CGRedAfterBg:after,
.CGRedBg {
    background-color: #e03c31
}

.CGRedColor {
    color: #e03c31
}

.CabSavBeforeBg:before,
.CabSavAfterBg:after,
.CabSavBg {
    background-color: #4d0a18
}

.CabSavColor {
    color: #4d0a18
}

.CabaretBeforeBg:before,
.CabaretAfterBg:after,
.CabaretBg {
    background-color: #d94972
}

.CabaretColor {
    color: #d94972
}

.CabbagePontBeforeBg:before,
.CabbagePontAfterBg:after,
.CabbagePontBg {
    background-color: #3f4c3a
}

.CabbagePontColor {
    color: #3f4c3a
}

.CactusBeforeBg:before,
.CactusAfterBg:after,
.CactusBg {
    background-color: #587156
}

.CactusColor {
    color: #587156
}

.CadetBeforeBg:before,
.CadetAfterBg:after,
.CadetBg {
    background-color: #536872
}

.CadetColor {
    color: #536872
}

.CadetBlueBeforeBg:before,
.CadetBlueAfterBg:after,
.CadetBlueBg {
    background-color: #5f9ea0
}

.CadetBlueColor {
    color: #5f9ea0
}

.CadetGreyBeforeBg:before,
.CadetGreyAfterBg:after,
.CadetGreyBg {
    background-color: #91a3b0
}

.CadetGreyColor {
    color: #91a3b0
}

.CadillacBeforeBg:before,
.CadillacAfterBg:after,
.CadillacBg {
    background-color: #b04c6a
}

.CadillacColor {
    color: #b04c6a
}

.CadmiumGreenBeforeBg:before,
.CadmiumGreenAfterBg:after,
.CadmiumGreenBg {
    background-color: #006b3c
}

.CadmiumGreenColor {
    color: #006b3c
}

.CadmiumOrangeBeforeBg:before,
.CadmiumOrangeAfterBg:after,
.CadmiumOrangeBg {
    background-color: #ed872d
}

.CadmiumOrangeColor {
    color: #ed872d
}

.CadmiumRedBeforeBg:before,
.CadmiumRedAfterBg:after,
.CadmiumRedBg {
    background-color: #e30022
}

.CadmiumRedColor {
    color: #e30022
}

.CadmiumYellowBeforeBg:before,
.CadmiumYellowAfterBg:after,
.CadmiumYellowBg {
    background-color: #fff600
}

.CadmiumYellowColor {
    color: #fff600
}

.CafeNoirBeforeBg:before,
.CafeNoirAfterBg:after,
.CafeNoirBg {
    background-color: #4b3621
}

.CafeNoirColor {
    color: #4b3621
}

.CafeRoyaleBeforeBg:before,
.CafeRoyaleAfterBg:after,
.CafeRoyaleBg {
    background-color: #6f440c
}

.CafeRoyaleColor {
    color: #6f440c
}

.CalPolyGreenBeforeBg:before,
.CalPolyGreenAfterBg:after,
.CalPolyGreenBg {
    background-color: #1e4d2b
}

.CalPolyGreenColor {
    color: #1e4d2b
}

.CalicoBeforeBg:before,
.CalicoAfterBg:after,
.CalicoBg {
    background-color: #e0c095
}

.CalicoColor {
    color: #e0c095
}

.CaliforniaBeforeBg:before,
.CaliforniaAfterBg:after,
.CaliforniaBg {
    background-color: #fe9d04
}

.CaliforniaColor {
    color: #fe9d04
}

.CalypsoBeforeBg:before,
.CalypsoAfterBg:after,
.CalypsoBg {
    background-color: #31728d
}

.CalypsoColor {
    color: #31728d
}

.CamaroneBeforeBg:before,
.CamaroneAfterBg:after,
.CamaroneBg {
    background-color: #00581a
}

.CamaroneColor {
    color: #00581a
}

.CambridgeBlueBeforeBg:before,
.CambridgeBlueAfterBg:after,
.CambridgeBlueBg {
    background-color: #a3c1ad
}

.CambridgeBlueColor {
    color: #a3c1ad
}

.CamelotBeforeBg:before,
.CamelotAfterBg:after,
.CamelotBg {
    background-color: #893456
}

.CamelotColor {
    color: #893456
}

.CameoBeforeBg:before,
.CameoAfterBg:after,
.CameoBg {
    background-color: #d9b99b
}

.CameoColor {
    color: #d9b99b
}

.CameoPinkBeforeBg:before,
.CameoPinkAfterBg:after,
.CameoPinkBg {
    background-color: #efbbcc
}

.CameoPinkColor {
    color: #efbbcc
}

.CamouflageBeforeBg:before,
.CamouflageAfterBg:after,
.CamouflageBg {
    background-color: #3c3910
}

.CamouflageColor {
    color: #3c3910
}

.CamouflageGreenBeforeBg:before,
.CamouflageGreenAfterBg:after,
.CamouflageGreenBg {
    background-color: #78866b
}

.CamouflageGreenColor {
    color: #78866b
}

.CanCanBeforeBg:before,
.CanCanAfterBg:after,
.CanCanBg {
    background-color: #d591a4
}

.CanCanColor {
    color: #d591a4
}

.CanaryBeforeBg:before,
.CanaryAfterBg:after,
.CanaryBg {
    background-color: #f3fb62
}

.CanaryColor {
    color: #f3fb62
}

.CanaryYellowBeforeBg:before,
.CanaryYellowAfterBg:after,
.CanaryYellowBg {
    background-color: #ffef00
}

.CanaryYellowColor {
    color: #ffef00
}

.CandlelightBeforeBg:before,
.CandlelightAfterBg:after,
.CandlelightBg {
    background-color: #fcd917
}

.CandlelightColor {
    color: #fcd917
}

.CandyAppleRedBeforeBg:before,
.CandyAppleRedAfterBg:after,
.CandyAppleRedBg {
    background-color: #ff0800
}

.CandyAppleRedColor {
    color: #ff0800
}

.CannonBlackBeforeBg:before,
.CannonBlackAfterBg:after,
.CannonBlackBg {
    background-color: #251706
}

.CannonBlackColor {
    color: #251706
}

.CannonPinkBeforeBg:before,
.CannonPinkAfterBg:after,
.CannonPinkBg {
    background-color: #894367
}

.CannonPinkColor {
    color: #894367
}

.CapeCodBeforeBg:before,
.CapeCodAfterBg:after,
.CapeCodBg {
    background-color: #3c4443
}

.CapeCodColor {
    color: #3c4443
}

.CapeHoneyBeforeBg:before,
.CapeHoneyAfterBg:after,
.CapeHoneyBg {
    background-color: #fee5ac
}

.CapeHoneyColor {
    color: #fee5ac
}

.CapePalliserBeforeBg:before,
.CapePalliserAfterBg:after,
.CapePalliserBg {
    background-color: #a26645
}

.CapePalliserColor {
    color: #a26645
}

.CaperBeforeBg:before,
.CaperAfterBg:after,
.CaperBg {
    background-color: #dcedb4
}

.CaperColor {
    color: #dcedb4
}

.CapriBeforeBg:before,
.CapriAfterBg:after,
.CapriBg {
    background-color: #00bfff
}

.CapriColor {
    color: #00bfff
}

.CaputMortuumBeforeBg:before,
.CaputMortuumAfterBg:after,
.CaputMortuumBg {
    background-color: #592720
}

.CaputMortuumColor {
    color: #592720
}

.CaramelBeforeBg:before,
.CaramelAfterBg:after,
.CaramelBg {
    background-color: #ffddaf
}

.CaramelColor {
    color: #ffddaf
}

.CararraBeforeBg:before,
.CararraAfterBg:after,
.CararraBg {
    background-color: #eeeee8
}

.CararraColor {
    color: #eeeee8
}

.CardinGreenBeforeBg:before,
.CardinGreenAfterBg:after,
.CardinGreenBg {
    background-color: #01361c
}

.CardinGreenColor {
    color: #01361c
}

.CardinalBeforeBg:before,
.CardinalAfterBg:after,
.CardinalBg {
    background-color: #c41e3a
}

.CardinalColor {
    color: #c41e3a
}

.CardinalPinkBeforeBg:before,
.CardinalPinkAfterBg:after,
.CardinalPinkBg {
    background-color: #8c055e
}

.CardinalPinkColor {
    color: #8c055e
}

.CareysPinkBeforeBg:before,
.CareysPinkAfterBg:after,
.CareysPinkBg {
    background-color: #d29eaa
}

.CareysPinkColor {
    color: #d29eaa
}

.CaribbeanGreenBeforeBg:before,
.CaribbeanGreenAfterBg:after,
.CaribbeanGreenBg {
    background-color: #00cc99
}

.CaribbeanGreenColor {
    color: #00cc99
}

.CarissmaBeforeBg:before,
.CarissmaAfterBg:after,
.CarissmaBg {
    background-color: #ea88a8
}

.CarissmaColor {
    color: #ea88a8
}

.CarlaBeforeBg:before,
.CarlaAfterBg:after,
.CarlaBg {
    background-color: #f3ffd8
}

.CarlaColor {
    color: #f3ffd8
}

.CarmineBeforeBg:before,
.CarmineAfterBg:after,
.CarmineBg {
    background-color: #960018
}

.CarmineColor {
    color: #960018
}

.CarminePinkBeforeBg:before,
.CarminePinkAfterBg:after,
.CarminePinkBg {
    background-color: #eb4c42
}

.CarminePinkColor {
    color: #eb4c42
}

.CarmineRedBeforeBg:before,
.CarmineRedAfterBg:after,
.CarmineRedBg {
    background-color: #ff0038
}

.CarmineRedColor {
    color: #ff0038
}

.CarnabyTanBeforeBg:before,
.CarnabyTanAfterBg:after,
.CarnabyTanBg {
    background-color: #5c2e01
}

.CarnabyTanColor {
    color: #5c2e01
}

.CarnationBeforeBg:before,
.CarnationAfterBg:after,
.CarnationBg {
    background-color: #f95a61
}

.CarnationColor {
    color: #f95a61
}

.CarnationPinkBeforeBg:before,
.CarnationPinkAfterBg:after,
.CarnationPinkBg {
    background-color: #ffa6c9
}

.CarnationPinkColor {
    color: #ffa6c9
}

.CarnelianBeforeBg:before,
.CarnelianAfterBg:after,
.CarnelianBg {
    background-color: #b31b1b
}

.CarnelianColor {
    color: #b31b1b
}

.CarolinaBlueBeforeBg:before,
.CarolinaBlueAfterBg:after,
.CarolinaBlueBg {
    background-color: #56a0d3
}

.CarolinaBlueColor {
    color: #56a0d3
}

.CarouselPinkBeforeBg:before,
.CarouselPinkAfterBg:after,
.CarouselPinkBg {
    background-color: #f9e0ed
}

.CarouselPinkColor {
    color: #f9e0ed
}

.CarrotOrangeBeforeBg:before,
.CarrotOrangeAfterBg:after,
.CarrotOrangeBg {
    background-color: #ed9121
}

.CarrotOrangeColor {
    color: #ed9121
}

.CasablancaBeforeBg:before,
.CasablancaAfterBg:after,
.CasablancaBg {
    background-color: #f8b853
}

.CasablancaColor {
    color: #f8b853
}

.CasalBeforeBg:before,
.CasalAfterBg:after,
.CasalBg {
    background-color: #2f6168
}

.CasalColor {
    color: #2f6168
}

.CascadeBeforeBg:before,
.CascadeAfterBg:after,
.CascadeBg {
    background-color: #8ba9a5
}

.CascadeColor {
    color: #8ba9a5
}

.CashmereBeforeBg:before,
.CashmereAfterBg:after,
.CashmereBg {
    background-color: #e6bea5
}

.CashmereColor {
    color: #e6bea5
}

.CasperBeforeBg:before,
.CasperAfterBg:after,
.CasperBg {
    background-color: #adbed1
}

.CasperColor {
    color: #adbed1
}

.CastletonGreenBeforeBg:before,
.CastletonGreenAfterBg:after,
.CastletonGreenBg {
    background-color: #00563b
}

.CastletonGreenColor {
    color: #00563b
}

.CastroBeforeBg:before,
.CastroAfterBg:after,
.CastroBg {
    background-color: #52001f
}

.CastroColor {
    color: #52001f
}

.CatalinaBlueBeforeBg:before,
.CatalinaBlueAfterBg:after,
.CatalinaBlueBg {
    background-color: #062a78
}

.CatalinaBlueColor {
    color: #062a78
}

.CatawbaBeforeBg:before,
.CatawbaAfterBg:after,
.CatawbaBg {
    background-color: #703642
}

.CatawbaColor {
    color: #703642
}

.CatskillWhiteBeforeBg:before,
.CatskillWhiteAfterBg:after,
.CatskillWhiteBg {
    background-color: #eef6f7
}

.CatskillWhiteColor {
    color: #eef6f7
}

.CavernPinkBeforeBg:before,
.CavernPinkAfterBg:after,
.CavernPinkBg {
    background-color: #e3bebe
}

.CavernPinkColor {
    color: #e3bebe
}

.CedarBeforeBg:before,
.CedarAfterBg:after,
.CedarBg {
    background-color: #3e1c14
}

.CedarColor {
    color: #3e1c14
}

.CedarChestBeforeBg:before,
.CedarChestAfterBg:after,
.CedarChestBg {
    background-color: #c95a49
}

.CedarChestColor {
    color: #c95a49
}

.CedarWoodFinishBeforeBg:before,
.CedarWoodFinishAfterBg:after,
.CedarWoodFinishBg {
    background-color: #711a00
}

.CedarWoodFinishColor {
    color: #711a00
}

.CeilBeforeBg:before,
.CeilAfterBg:after,
.CeilBg {
    background-color: #92a1cf
}

.CeilColor {
    color: #92a1cf
}

.CeladonBeforeBg:before,
.CeladonAfterBg:after,
.CeladonBg {
    background-color: #ace1af
}

.CeladonColor {
    color: #ace1af
}

.CeladonGreenBeforeBg:before,
.CeladonGreenAfterBg:after,
.CeladonGreenBg {
    background-color: #2f847c
}

.CeladonGreenColor {
    color: #2f847c
}

.CeleryBeforeBg:before,
.CeleryAfterBg:after,
.CeleryBg {
    background-color: #b8c25d
}

.CeleryColor {
    color: #b8c25d
}

.CelesteBeforeBg:before,
.CelesteAfterBg:after,
.CelesteBg {
    background-color: #b2ffff
}

.CelesteColor {
    color: #b2ffff
}

.CelestialBlueBeforeBg:before,
.CelestialBlueAfterBg:after,
.CelestialBlueBg {
    background-color: #4997d0
}

.CelestialBlueColor {
    color: #4997d0
}

.CelloBeforeBg:before,
.CelloAfterBg:after,
.CelloBg {
    background-color: #1e385b
}

.CelloColor {
    color: #1e385b
}

.CelticBeforeBg:before,
.CelticAfterBg:after,
.CelticBg {
    background-color: #163222
}

.CelticColor {
    color: #163222
}

.CementBeforeBg:before,
.CementAfterBg:after,
.CementBg {
    background-color: #8d7662
}

.CementColor {
    color: #8d7662
}

.CeramicBeforeBg:before,
.CeramicAfterBg:after,
.CeramicBg {
    background-color: #fcfff9
}

.CeramicColor {
    color: #fcfff9
}

.CeriseBeforeBg:before,
.CeriseAfterBg:after,
.CeriseBg {
    background-color: #de3163
}

.CeriseColor {
    color: #de3163
}

.CerisePinkBeforeBg:before,
.CerisePinkAfterBg:after,
.CerisePinkBg {
    background-color: #ec3b83
}

.CerisePinkColor {
    color: #ec3b83
}

.CeruleanBeforeBg:before,
.CeruleanAfterBg:after,
.CeruleanBg {
    background-color: #007ba7
}

.CeruleanColor {
    color: #007ba7
}

.CeruleanBlueBeforeBg:before,
.CeruleanBlueAfterBg:after,
.CeruleanBlueBg {
    background-color: #2a52be
}

.CeruleanBlueColor {
    color: #2a52be
}

.CeruleanFrostBeforeBg:before,
.CeruleanFrostAfterBg:after,
.CeruleanFrostBg {
    background-color: #6d9bc3
}

.CeruleanFrostColor {
    color: #6d9bc3
}

.ChablisBeforeBg:before,
.ChablisAfterBg:after,
.ChablisBg {
    background-color: #fff4f3
}

.ChablisColor {
    color: #fff4f3
}

.ChaletGreenBeforeBg:before,
.ChaletGreenAfterBg:after,
.ChaletGreenBg {
    background-color: #516e3d
}

.ChaletGreenColor {
    color: #516e3d
}

.ChalkyBeforeBg:before,
.ChalkyAfterBg:after,
.ChalkyBg {
    background-color: #eed794
}

.ChalkyColor {
    color: #eed794
}

.ChambrayBeforeBg:before,
.ChambrayAfterBg:after,
.ChambrayBg {
    background-color: #354e8c
}

.ChambrayColor {
    color: #354e8c
}

.ChamoisBeforeBg:before,
.ChamoisAfterBg:after,
.ChamoisBg {
    background-color: #eddcb1
}

.ChamoisColor {
    color: #eddcb1
}

.ChamoiseeBeforeBg:before,
.ChamoiseeAfterBg:after,
.ChamoiseeBg {
    background-color: #a0785a
}

.ChamoiseeColor {
    color: #a0785a
}

.ChampagneBeforeBg:before,
.ChampagneAfterBg:after,
.ChampagneBg {
    background-color: #f7e7ce
}

.ChampagneColor {
    color: #f7e7ce
}

.ChantillyBeforeBg:before,
.ChantillyAfterBg:after,
.ChantillyBg {
    background-color: #f8c3df
}

.ChantillyColor {
    color: #f8c3df
}

.CharadeBeforeBg:before,
.CharadeAfterBg:after,
.CharadeBg {
    background-color: #292937
}

.CharadeColor {
    color: #292937
}

.CharcoalBeforeBg:before,
.CharcoalAfterBg:after,
.CharcoalBg {
    background-color: #36454f
}

.CharcoalColor {
    color: #36454f
}

.ChardonBeforeBg:before,
.ChardonAfterBg:after,
.ChardonBg {
    background-color: #fff3f1
}

.ChardonColor {
    color: #fff3f1
}

.ChardonnayBeforeBg:before,
.ChardonnayAfterBg:after,
.ChardonnayBg {
    background-color: #ffcd8c
}

.ChardonnayColor {
    color: #ffcd8c
}

.CharlestonGreenBeforeBg:before,
.CharlestonGreenAfterBg:after,
.CharlestonGreenBg {
    background-color: #232b2b
}

.CharlestonGreenColor {
    color: #232b2b
}

.CharlotteBeforeBg:before,
.CharlotteAfterBg:after,
.CharlotteBg {
    background-color: #baeef9
}

.CharlotteColor {
    color: #baeef9
}

.CharmBeforeBg:before,
.CharmAfterBg:after,
.CharmBg {
    background-color: #d47494
}

.CharmColor {
    color: #d47494
}

.CharmPinkBeforeBg:before,
.CharmPinkAfterBg:after,
.CharmPinkBg {
    background-color: #e68fac
}

.CharmPinkColor {
    color: #e68fac
}

.ChartreuseBeforeBg:before,
.ChartreuseAfterBg:after,
.ChartreuseBg {
    background-color: #dfff00
}

.ChartreuseColor {
    color: #dfff00
}

.ChateauGreenBeforeBg:before,
.ChateauGreenAfterBg:after,
.ChateauGreenBg {
    background-color: #40a860
}

.ChateauGreenColor {
    color: #40a860
}

.ChatelleBeforeBg:before,
.ChatelleAfterBg:after,
.ChatelleBg {
    background-color: #bdb3c7
}

.ChatelleColor {
    color: #bdb3c7
}

.ChathamsBlueBeforeBg:before,
.ChathamsBlueAfterBg:after,
.ChathamsBlueBg {
    background-color: #175579
}

.ChathamsBlueColor {
    color: #175579
}

.ChelseaCucumberBeforeBg:before,
.ChelseaCucumberAfterBg:after,
.ChelseaCucumberBg {
    background-color: #83aa5d
}

.ChelseaCucumberColor {
    color: #83aa5d
}

.ChelseaGemBeforeBg:before,
.ChelseaGemAfterBg:after,
.ChelseaGemBg {
    background-color: #9e5302
}

.ChelseaGemColor {
    color: #9e5302
}

.CheninBeforeBg:before,
.CheninAfterBg:after,
.CheninBg {
    background-color: #dfcd6f
}

.CheninColor {
    color: #dfcd6f
}

.CherokeeBeforeBg:before,
.CherokeeAfterBg:after,
.CherokeeBg {
    background-color: #fcda98
}

.CherokeeColor {
    color: #fcda98
}

.CherryBlossomPinkBeforeBg:before,
.CherryBlossomPinkAfterBg:after,
.CherryBlossomPinkBg {
    background-color: #ffb7c5
}

.CherryBlossomPinkColor {
    color: #ffb7c5
}

.CherryPieBeforeBg:before,
.CherryPieAfterBg:after,
.CherryPieBg {
    background-color: #2a0359
}

.CherryPieColor {
    color: #2a0359
}

.CherrywoodBeforeBg:before,
.CherrywoodAfterBg:after,
.CherrywoodBg {
    background-color: #651a14
}

.CherrywoodColor {
    color: #651a14
}

.CherubBeforeBg:before,
.CherubAfterBg:after,
.CherubBg {
    background-color: #f8d9e9
}

.CherubColor {
    color: #f8d9e9
}

.ChestnutBeforeBg:before,
.ChestnutAfterBg:after,
.ChestnutBg {
    background-color: #954535
}

.ChestnutColor {
    color: #954535
}

.ChetwodeBlueBeforeBg:before,
.ChetwodeBlueAfterBg:after,
.ChetwodeBlueBg {
    background-color: #8581d9
}

.ChetwodeBlueColor {
    color: #8581d9
}

.ChicagoBeforeBg:before,
.ChicagoAfterBg:after,
.ChicagoBg {
    background-color: #5d5c58
}

.ChicagoColor {
    color: #5d5c58
}

.ChiffonBeforeBg:before,
.ChiffonAfterBg:after,
.ChiffonBg {
    background-color: #f1ffc8
}

.ChiffonColor {
    color: #f1ffc8
}

.ChileanFireBeforeBg:before,
.ChileanFireAfterBg:after,
.ChileanFireBg {
    background-color: #f77703
}

.ChileanFireColor {
    color: #f77703
}

.ChileanHeathBeforeBg:before,
.ChileanHeathAfterBg:after,
.ChileanHeathBg {
    background-color: #fffde6
}

.ChileanHeathColor {
    color: #fffde6
}

.ChinaIvoryBeforeBg:before,
.ChinaIvoryAfterBg:after,
.ChinaIvoryBg {
    background-color: #fcffe7
}

.ChinaIvoryColor {
    color: #fcffe7
}

.ChinaRoseBeforeBg:before,
.ChinaRoseAfterBg:after,
.ChinaRoseBg {
    background-color: #a8516e
}

.ChinaRoseColor {
    color: #a8516e
}

.ChineseRedBeforeBg:before,
.ChineseRedAfterBg:after,
.ChineseRedBg {
    background-color: #aa381e
}

.ChineseRedColor {
    color: #aa381e
}

.ChineseVioletBeforeBg:before,
.ChineseVioletAfterBg:after,
.ChineseVioletBg {
    background-color: #856088
}

.ChineseVioletColor {
    color: #856088
}

.ChinoBeforeBg:before,
.ChinoAfterBg:after,
.ChinoBg {
    background-color: #cec7a7
}

.ChinoColor {
    color: #cec7a7
}

.ChinookBeforeBg:before,
.ChinookAfterBg:after,
.ChinookBg {
    background-color: #a8e3bd
}

.ChinookColor {
    color: #a8e3bd
}

.ChlorophyllGreenBeforeBg:before,
.ChlorophyllGreenAfterBg:after,
.ChlorophyllGreenBg {
    background-color: #4aff00
}

.ChlorophyllGreenColor {
    color: #4aff00
}

.ChocolateBeforeBg:before,
.ChocolateAfterBg:after,
.ChocolateBg {
    background-color: #7b3f00
}

.ChocolateColor {
    color: #7b3f00
}

.ChristalleBeforeBg:before,
.ChristalleAfterBg:after,
.ChristalleBg {
    background-color: #33036b
}

.ChristalleColor {
    color: #33036b
}

.ChristiBeforeBg:before,
.ChristiAfterBg:after,
.ChristiBg {
    background-color: #67a712
}

.ChristiColor {
    color: #67a712
}

.ChristineBeforeBg:before,
.ChristineAfterBg:after,
.ChristineBg {
    background-color: #e7730a
}

.ChristineColor {
    color: #e7730a
}

.ChromeWhiteBeforeBg:before,
.ChromeWhiteAfterBg:after,
.ChromeWhiteBg {
    background-color: #e8f1d4
}

.ChromeWhiteColor {
    color: #e8f1d4
}

.ChromeYellowBeforeBg:before,
.ChromeYellowAfterBg:after,
.ChromeYellowBg {
    background-color: #ffa700
}

.ChromeYellowColor {
    color: #ffa700
}

.CinderBeforeBg:before,
.CinderAfterBg:after,
.CinderBg {
    background-color: #0e0e18
}

.CinderColor {
    color: #0e0e18
}

.CinderellaBeforeBg:before,
.CinderellaAfterBg:after,
.CinderellaBg {
    background-color: #fde1dc
}

.CinderellaColor {
    color: #fde1dc
}

.CinereousBeforeBg:before,
.CinereousAfterBg:after,
.CinereousBg {
    background-color: #98817b
}

.CinereousColor {
    color: #98817b
}

.CinnabarBeforeBg:before,
.CinnabarAfterBg:after,
.CinnabarBg {
    background-color: #e34234
}

.CinnabarColor {
    color: #e34234
}

.CinnamonSatinBeforeBg:before,
.CinnamonSatinAfterBg:after,
.CinnamonSatinBg {
    background-color: #cd607e
}

.CinnamonSatinColor {
    color: #cd607e
}

.CioccolatoBeforeBg:before,
.CioccolatoAfterBg:after,
.CioccolatoBg {
    background-color: #55280c
}

.CioccolatoColor {
    color: #55280c
}

.CitrineBeforeBg:before,
.CitrineAfterBg:after,
.CitrineBg {
    background-color: #e4d00a
}

.CitrineColor {
    color: #e4d00a
}

.CitrineWhiteBeforeBg:before,
.CitrineWhiteAfterBg:after,
.CitrineWhiteBg {
    background-color: #faf7d6
}

.CitrineWhiteColor {
    color: #faf7d6
}

.CitronBeforeBg:before,
.CitronAfterBg:after,
.CitronBg {
    background-color: #9fa91f
}

.CitronColor {
    color: #9fa91f
}

.CitrusBeforeBg:before,
.CitrusAfterBg:after,
.CitrusBg {
    background-color: #a1c50a
}

.CitrusColor {
    color: #a1c50a
}

.ClairvoyantBeforeBg:before,
.ClairvoyantAfterBg:after,
.ClairvoyantBg {
    background-color: #480656
}

.ClairvoyantColor {
    color: #480656
}

.ClamShellBeforeBg:before,
.ClamShellAfterBg:after,
.ClamShellBg {
    background-color: #d4b6af
}

.ClamShellColor {
    color: #d4b6af
}

.ClaretBeforeBg:before,
.ClaretAfterBg:after,
.ClaretBg {
    background-color: #7f1734
}

.ClaretColor {
    color: #7f1734
}

.ClassicRoseBeforeBg:before,
.ClassicRoseAfterBg:after,
.ClassicRoseBg {
    background-color: #fbcce7
}

.ClassicRoseColor {
    color: #fbcce7
}

.ClayAshBeforeBg:before,
.ClayAshAfterBg:after,
.ClayAshBg {
    background-color: #bdc8b3
}

.ClayAshColor {
    color: #bdc8b3
}

.ClayCreekBeforeBg:before,
.ClayCreekAfterBg:after,
.ClayCreekBg {
    background-color: #8a8360
}

.ClayCreekColor {
    color: #8a8360
}

.ClearDayBeforeBg:before,
.ClearDayAfterBg:after,
.ClearDayBg {
    background-color: #e9fffd
}

.ClearDayColor {
    color: #e9fffd
}

.ClementineBeforeBg:before,
.ClementineAfterBg:after,
.ClementineBg {
    background-color: #e96e00
}

.ClementineColor {
    color: #e96e00
}

.ClinkerBeforeBg:before,
.ClinkerAfterBg:after,
.ClinkerBg {
    background-color: #371d09
}

.ClinkerColor {
    color: #371d09
}

.CloudBeforeBg:before,
.CloudAfterBg:after,
.CloudBg {
    background-color: #c7c4bf
}

.CloudColor {
    color: #c7c4bf
}

.CloudBurstBeforeBg:before,
.CloudBurstAfterBg:after,
.CloudBurstBg {
    background-color: #202e54
}

.CloudBurstColor {
    color: #202e54
}

.CloudyBeforeBg:before,
.CloudyAfterBg:after,
.CloudyBg {
    background-color: #aca59f
}

.CloudyColor {
    color: #aca59f
}

.CloverBeforeBg:before,
.CloverAfterBg:after,
.CloverBg {
    background-color: #384910
}

.CloverColor {
    color: #384910
}

.CobaltBlueBeforeBg:before,
.CobaltBlueAfterBg:after,
.CobaltBlueBg {
    background-color: #0047ab
}

.CobaltBlueColor {
    color: #0047ab
}

.CocoaBeanBeforeBg:before,
.CocoaBeanAfterBg:after,
.CocoaBeanBg {
    background-color: #481c1c
}

.CocoaBeanColor {
    color: #481c1c
}

.CocoaBrownBeforeBg:before,
.CocoaBrownAfterBg:after,
.CocoaBrownBg {
    background-color: #d2691e
}

.CocoaBrownColor {
    color: #d2691e
}

.CoconutBeforeBg:before,
.CoconutAfterBg:after,
.CoconutBg {
    background-color: #965a3e
}

.CoconutColor {
    color: #965a3e
}

.CoconutCreamBeforeBg:before,
.CoconutCreamAfterBg:after,
.CoconutCreamBg {
    background-color: #f8f7dc
}

.CoconutCreamColor {
    color: #f8f7dc
}

.CodGrayBeforeBg:before,
.CodGrayAfterBg:after,
.CodGrayBg {
    background-color: #0b0b0b
}

.CodGrayColor {
    color: #0b0b0b
}

.CoffeeBeforeBg:before,
.CoffeeAfterBg:after,
.CoffeeBg {
    background-color: #6f4e37
}

.CoffeeColor {
    color: #6f4e37
}

.CoffeeBeanBeforeBg:before,
.CoffeeBeanAfterBg:after,
.CoffeeBeanBg {
    background-color: #2a140e
}

.CoffeeBeanColor {
    color: #2a140e
}

.CognacBeforeBg:before,
.CognacAfterBg:after,
.CognacBg {
    background-color: #9f381d
}

.CognacColor {
    color: #9f381d
}

.ColaBeforeBg:before,
.ColaAfterBg:after,
.ColaBg {
    background-color: #3f2500
}

.ColaColor {
    color: #3f2500
}

.ColdPurpleBeforeBg:before,
.ColdPurpleAfterBg:after,
.ColdPurpleBg {
    background-color: #aba0d9
}

.ColdPurpleColor {
    color: #aba0d9
}

.ColdTurkeyBeforeBg:before,
.ColdTurkeyAfterBg:after,
.ColdTurkeyBg {
    background-color: #cebaba
}

.ColdTurkeyColor {
    color: #cebaba
}

.ColonialWhiteBeforeBg:before,
.ColonialWhiteAfterBg:after,
.ColonialWhiteBg {
    background-color: #ffedbc
}

.ColonialWhiteColor {
    color: #ffedbc
}

.ColumbiaBlueBeforeBg:before,
.ColumbiaBlueAfterBg:after,
.ColumbiaBlueBg {
    background-color: #c4d8e2
}

.ColumbiaBlueColor {
    color: #c4d8e2
}

.CometBeforeBg:before,
.CometAfterBg:after,
.CometBg {
    background-color: #5c5d75
}

.CometColor {
    color: #5c5d75
}

.ComoBeforeBg:before,
.ComoAfterBg:after,
.ComoBg {
    background-color: #517c66
}

.ComoColor {
    color: #517c66
}

.ConchBeforeBg:before,
.ConchAfterBg:after,
.ConchBg {
    background-color: #c9d9d2
}

.ConchColor {
    color: #c9d9d2
}

.ConcordBeforeBg:before,
.ConcordAfterBg:after,
.ConcordBg {
    background-color: #7c7b7a
}

.ConcordColor {
    color: #7c7b7a
}

.ConcreteBeforeBg:before,
.ConcreteAfterBg:after,
.ConcreteBg {
    background-color: #f2f2f2
}

.ConcreteColor {
    color: #f2f2f2
}

.ConfettiBeforeBg:before,
.ConfettiAfterBg:after,
.ConfettiBg {
    background-color: #e9d75a
}

.ConfettiColor {
    color: #e9d75a
}

.CongoBrownBeforeBg:before,
.CongoBrownAfterBg:after,
.CongoBrownBg {
    background-color: #593737
}

.CongoBrownColor {
    color: #593737
}

.CongoPinkBeforeBg:before,
.CongoPinkAfterBg:after,
.CongoPinkBg {
    background-color: #f88379
}

.CongoPinkColor {
    color: #f88379
}

.CongressBlueBeforeBg:before,
.CongressBlueAfterBg:after,
.CongressBlueBg {
    background-color: #02478e
}

.CongressBlueColor {
    color: #02478e
}

.ConiferBeforeBg:before,
.ConiferAfterBg:after,
.ConiferBg {
    background-color: #acdd4d
}

.ConiferColor {
    color: #acdd4d
}

.ContessaBeforeBg:before,
.ContessaAfterBg:after,
.ContessaBg {
    background-color: #c6726b
}

.ContessaColor {
    color: #c6726b
}

.CoolBlackBeforeBg:before,
.CoolBlackAfterBg:after,
.CoolBlackBg {
    background-color: #002e63
}

.CoolBlackColor {
    color: #002e63
}

.CoolGreyBeforeBg:before,
.CoolGreyAfterBg:after,
.CoolGreyBg {
    background-color: #8c92ac
}

.CoolGreyColor {
    color: #8c92ac
}

.CopperBeforeBg:before,
.CopperAfterBg:after,
.CopperBg {
    background-color: #b87333
}

.CopperColor {
    color: #b87333
}

.CopperCanyonBeforeBg:before,
.CopperCanyonAfterBg:after,
.CopperCanyonBg {
    background-color: #7e3a15
}

.CopperCanyonColor {
    color: #7e3a15
}

.CopperPennyBeforeBg:before,
.CopperPennyAfterBg:after,
.CopperPennyBg {
    background-color: #ad6f69
}

.CopperPennyColor {
    color: #ad6f69
}

.CopperRedBeforeBg:before,
.CopperRedAfterBg:after,
.CopperRedBg {
    background-color: #cb6d51
}

.CopperRedColor {
    color: #cb6d51
}

.CopperRoseBeforeBg:before,
.CopperRoseAfterBg:after,
.CopperRoseBg {
    background-color: #996666
}

.CopperRoseColor {
    color: #996666
}

.CopperRustBeforeBg:before,
.CopperRustAfterBg:after,
.CopperRustBg {
    background-color: #944747
}

.CopperRustColor {
    color: #944747
}

.CoquelicotBeforeBg:before,
.CoquelicotAfterBg:after,
.CoquelicotBg {
    background-color: #ff3800
}

.CoquelicotColor {
    color: #ff3800
}

.CoralBeforeBg:before,
.CoralAfterBg:after,
.CoralBg {
    background-color: #ff7f50
}

.CoralColor {
    color: #ff7f50
}

.CoralRedBeforeBg:before,
.CoralRedAfterBg:after,
.CoralRedBg {
    background-color: #ff4040
}

.CoralRedColor {
    color: #ff4040
}

.CoralReefBeforeBg:before,
.CoralReefAfterBg:after,
.CoralReefBg {
    background-color: #c7bca2
}

.CoralReefColor {
    color: #c7bca2
}

.CoralTreeBeforeBg:before,
.CoralTreeAfterBg:after,
.CoralTreeBg {
    background-color: #a86b6b
}

.CoralTreeColor {
    color: #a86b6b
}

.CordovanBeforeBg:before,
.CordovanAfterBg:after,
.CordovanBg {
    background-color: #893f45
}

.CordovanColor {
    color: #893f45
}

.CorduroyBeforeBg:before,
.CorduroyAfterBg:after,
.CorduroyBg {
    background-color: #606e68
}

.CorduroyColor {
    color: #606e68
}

.CorianderBeforeBg:before,
.CorianderAfterBg:after,
.CorianderBg {
    background-color: #c4d0b0
}

.CorianderColor {
    color: #c4d0b0
}

.CorkBeforeBg:before,
.CorkAfterBg:after,
.CorkBg {
    background-color: #40291d
}

.CorkColor {
    color: #40291d
}

.CornBeforeBg:before,
.CornAfterBg:after,
.CornBg {
    background-color: #e7bf05
}

.CornColor {
    color: #e7bf05
}

.CornFieldBeforeBg:before,
.CornFieldAfterBg:after,
.CornFieldBg {
    background-color: #f8facd
}

.CornFieldColor {
    color: #f8facd
}

.CornHarvestBeforeBg:before,
.CornHarvestAfterBg:after,
.CornHarvestBg {
    background-color: #8b6b0b
}

.CornHarvestColor {
    color: #8b6b0b
}

.CornflowerBlueBeforeBg:before,
.CornflowerBlueAfterBg:after,
.CornflowerBlueBg {
    background-color: #6495ed
}

.CornflowerBlueColor {
    color: #6495ed
}

.CornflowerLilacBeforeBg:before,
.CornflowerLilacAfterBg:after,
.CornflowerLilacBg {
    background-color: #ffb0ac
}

.CornflowerLilacColor {
    color: #ffb0ac
}

.CornsilkBeforeBg:before,
.CornsilkAfterBg:after,
.CornsilkBg {
    background-color: #fff8dc
}

.CornsilkColor {
    color: #fff8dc
}

.CorvetteBeforeBg:before,
.CorvetteAfterBg:after,
.CorvetteBg {
    background-color: #fad3a2
}

.CorvetteColor {
    color: #fad3a2
}

.CosmicBeforeBg:before,
.CosmicAfterBg:after,
.CosmicBg {
    background-color: #76395d
}

.CosmicColor {
    color: #76395d
}

.CosmicCobaltBeforeBg:before,
.CosmicCobaltAfterBg:after,
.CosmicCobaltBg {
    background-color: #2e2d88
}

.CosmicCobaltColor {
    color: #2e2d88
}

.CosmicLatteBeforeBg:before,
.CosmicLatteAfterBg:after,
.CosmicLatteBg {
    background-color: #fff8e7
}

.CosmicLatteColor {
    color: #fff8e7
}

.CosmosBeforeBg:before,
.CosmosAfterBg:after,
.CosmosBg {
    background-color: #ffd8d9
}

.CosmosColor {
    color: #ffd8d9
}

.CostaDelSolBeforeBg:before,
.CostaDelSolAfterBg:after,
.CostaDelSolBg {
    background-color: #615d30
}

.CostaDelSolColor {
    color: #615d30
}

.CottonCandyBeforeBg:before,
.CottonCandyAfterBg:after,
.CottonCandyBg {
    background-color: #ffbcd9
}

.CottonCandyColor {
    color: #ffbcd9
}

.CottonSeedBeforeBg:before,
.CottonSeedAfterBg:after,
.CottonSeedBg {
    background-color: #c2bdb6
}

.CottonSeedColor {
    color: #c2bdb6
}

.CountyGreenBeforeBg:before,
.CountyGreenAfterBg:after,
.CountyGreenBg {
    background-color: #01371a
}

.CountyGreenColor {
    color: #01371a
}

.CowboyBeforeBg:before,
.CowboyAfterBg:after,
.CowboyBg {
    background-color: #4d282d
}

.CowboyColor {
    color: #4d282d
}

.CoyoteBrownBeforeBg:before,
.CoyoteBrownAfterBg:after,
.CoyoteBrownBg {
    background-color: #81613e
}

.CoyoteBrownColor {
    color: #81613e
}

.CrailBeforeBg:before,
.CrailAfterBg:after,
.CrailBg {
    background-color: #b95140
}

.CrailColor {
    color: #b95140
}

.CranberryBeforeBg:before,
.CranberryAfterBg:after,
.CranberryBg {
    background-color: #db5079
}

.CranberryColor {
    color: #db5079
}

.CraterBrownBeforeBg:before,
.CraterBrownAfterBg:after,
.CraterBrownBg {
    background-color: #462425
}

.CraterBrownColor {
    color: #462425
}

.CrayolaBlueBeforeBg:before,
.CrayolaBlueAfterBg:after,
.CrayolaBlueBg {
    background-color: #1f75fe
}

.CrayolaBlueColor {
    color: #1f75fe
}

.CrayolaGreenBeforeBg:before,
.CrayolaGreenAfterBg:after,
.CrayolaGreenBg {
    background-color: #1cac78
}

.CrayolaGreenColor {
    color: #1cac78
}

.CrayolaOrangeBeforeBg:before,
.CrayolaOrangeAfterBg:after,
.CrayolaOrangeBg {
    background-color: #ff7538
}

.CrayolaOrangeColor {
    color: #ff7538
}

.CrayolaRedBeforeBg:before,
.CrayolaRedAfterBg:after,
.CrayolaRedBg {
    background-color: #ee204d
}

.CrayolaRedColor {
    color: #ee204d
}

.CrayolaYellowBeforeBg:before,
.CrayolaYellowAfterBg:after,
.CrayolaYellowBg {
    background-color: #fce883
}

.CrayolaYellowColor {
    color: #fce883
}

.CreamBeforeBg:before,
.CreamAfterBg:after,
.CreamBg {
    background-color: #fffdd0
}

.CreamColor {
    color: #fffdd0
}

.CreamBruleeBeforeBg:before,
.CreamBruleeAfterBg:after,
.CreamBruleeBg {
    background-color: #ffe5a0
}

.CreamBruleeColor {
    color: #ffe5a0
}

.CreamCanBeforeBg:before,
.CreamCanAfterBg:after,
.CreamCanBg {
    background-color: #f5c85c
}

.CreamCanColor {
    color: #f5c85c
}

.CreoleBeforeBg:before,
.CreoleAfterBg:after,
.CreoleBg {
    background-color: #1e0f04
}

.CreoleColor {
    color: #1e0f04
}

.CreteBeforeBg:before,
.CreteAfterBg:after,
.CreteBg {
    background-color: #737829
}

.CreteColor {
    color: #737829
}

.CrimsonBeforeBg:before,
.CrimsonAfterBg:after,
.CrimsonBg {
    background-color: #dc143c
}

.CrimsonColor {
    color: #dc143c
}

.CrimsonGloryBeforeBg:before,
.CrimsonGloryAfterBg:after,
.CrimsonGloryBg {
    background-color: #be0032
}

.CrimsonGloryColor {
    color: #be0032
}

.CrimsonRedBeforeBg:before,
.CrimsonRedAfterBg:after,
.CrimsonRedBg {
    background-color: #990000
}

.CrimsonRedColor {
    color: #990000
}

.CrocodileBeforeBg:before,
.CrocodileAfterBg:after,
.CrocodileBg {
    background-color: #736d58
}

.CrocodileColor {
    color: #736d58
}

.CrownofThornsBeforeBg:before,
.CrownofThornsAfterBg:after,
.CrownofThornsBg {
    background-color: #771f1f
}

.CrownofThornsColor {
    color: #771f1f
}

.CrowsheadBeforeBg:before,
.CrowsheadAfterBg:after,
.CrowsheadBg {
    background-color: #1c1208
}

.CrowsheadColor {
    color: #1c1208
}

.CruiseBeforeBg:before,
.CruiseAfterBg:after,
.CruiseBg {
    background-color: #b5ecdf
}

.CruiseColor {
    color: #b5ecdf
}

.CrusoeBeforeBg:before,
.CrusoeAfterBg:after,
.CrusoeBg {
    background-color: #004816
}

.CrusoeColor {
    color: #004816
}

.CrustaBeforeBg:before,
.CrustaAfterBg:after,
.CrustaBg {
    background-color: #fd7b33
}

.CrustaColor {
    color: #fd7b33
}

.CuminBeforeBg:before,
.CuminAfterBg:after,
.CuminBg {
    background-color: #924321
}

.CuminColor {
    color: #924321
}

.CumulusBeforeBg:before,
.CumulusAfterBg:after,
.CumulusBg {
    background-color: #fdffd5
}

.CumulusColor {
    color: #fdffd5
}

.CupidBeforeBg:before,
.CupidAfterBg:after,
.CupidBg {
    background-color: #fbbeda
}

.CupidColor {
    color: #fbbeda
}

.CuriousBlueBeforeBg:before,
.CuriousBlueAfterBg:after,
.CuriousBlueBg {
    background-color: #2596d1
}

.CuriousBlueColor {
    color: #2596d1
}

.CuttySarkBeforeBg:before,
.CuttySarkAfterBg:after,
.CuttySarkBg {
    background-color: #507672
}

.CuttySarkColor {
    color: #507672
}

.CyanBeforeBg:before,
.CyanAfterBg:after,
.CyanBg {
    background-color: #00ffff
}

.CyanColor {
    color: #00ffff
}

.CyanAzureBeforeBg:before,
.CyanAzureAfterBg:after,
.CyanAzureBg {
    background-color: #4e82b4
}

.CyanAzureColor {
    color: #4e82b4
}

.CyanBlueAzureBeforeBg:before,
.CyanBlueAzureAfterBg:after,
.CyanBlueAzureBg {
    background-color: #4682bf
}

.CyanBlueAzureColor {
    color: #4682bf
}

.CyanCobaltBlueBeforeBg:before,
.CyanCobaltBlueAfterBg:after,
.CyanCobaltBlueBg {
    background-color: #28589c
}

.CyanCobaltBlueColor {
    color: #28589c
}

.CyanCornflowerBlueBeforeBg:before,
.CyanCornflowerBlueAfterBg:after,
.CyanCornflowerBlueBg {
    background-color: #188bc2
}

.CyanCornflowerBlueColor {
    color: #188bc2
}

.CyberGrapeBeforeBg:before,
.CyberGrapeAfterBg:after,
.CyberGrapeBg {
    background-color: #58427c
}

.CyberGrapeColor {
    color: #58427c
}

.CyberYellowBeforeBg:before,
.CyberYellowAfterBg:after,
.CyberYellowBg {
    background-color: #ffd300
}

.CyberYellowColor {
    color: #ffd300
}

.CyclamenBeforeBg:before,
.CyclamenAfterBg:after,
.CyclamenBg {
    background-color: #f56fa1
}

.CyclamenColor {
    color: #f56fa1
}

.CyprusBeforeBg:before,
.CyprusAfterBg:after,
.CyprusBg {
    background-color: #003e40
}

.CyprusColor {
    color: #003e40
}

.DaffodilBeforeBg:before,
.DaffodilAfterBg:after,
.DaffodilBg {
    background-color: #ffff31
}

.DaffodilColor {
    color: #ffff31
}

.DaintreeBeforeBg:before,
.DaintreeAfterBg:after,
.DaintreeBg {
    background-color: #012731
}

.DaintreeColor {
    color: #012731
}

.DairyCreamBeforeBg:before,
.DairyCreamAfterBg:after,
.DairyCreamBg {
    background-color: #f9e4bc
}

.DairyCreamColor {
    color: #f9e4bc
}

.DaisyBushBeforeBg:before,
.DaisyBushAfterBg:after,
.DaisyBushBg {
    background-color: #4f2398
}

.DaisyBushColor {
    color: #4f2398
}

.DallasBeforeBg:before,
.DallasAfterBg:after,
.DallasBg {
    background-color: #6e4b26
}

.DallasColor {
    color: #6e4b26
}

.DandelionBeforeBg:before,
.DandelionAfterBg:after,
.DandelionBg {
    background-color: #f0e130
}

.DandelionColor {
    color: #f0e130
}

.DanubeBeforeBg:before,
.DanubeAfterBg:after,
.DanubeBg {
    background-color: #6093d1
}

.DanubeColor {
    color: #6093d1
}

.DarkBlueBeforeBg:before,
.DarkBlueAfterBg:after,
.DarkBlueBg {
    background-color: #00008b
}

.DarkBlueColor {
    color: #00008b
}

.DarkBlueGrayBeforeBg:before,
.DarkBlueGrayAfterBg:after,
.DarkBlueGrayBg {
    background-color: #666699
}

.DarkBlueGrayColor {
    color: #666699
}

.DarkBrownBeforeBg:before,
.DarkBrownAfterBg:after,
.DarkBrownBg {
    background-color: #654321
}

.DarkBrownColor {
    color: #654321
}

.DarkBrownTangeloBeforeBg:before,
.DarkBrownTangeloAfterBg:after,
.DarkBrownTangeloBg {
    background-color: #88654e
}

.DarkBrownTangeloColor {
    color: #88654e
}

.DarkBurgundyBeforeBg:before,
.DarkBurgundyAfterBg:after,
.DarkBurgundyBg {
    background-color: #770f05
}

.DarkBurgundyColor {
    color: #770f05
}

.DarkByzantiumBeforeBg:before,
.DarkByzantiumAfterBg:after,
.DarkByzantiumBg {
    background-color: #5d3954
}

.DarkByzantiumColor {
    color: #5d3954
}

.DarkCandyAppleRedBeforeBg:before,
.DarkCandyAppleRedAfterBg:after,
.DarkCandyAppleRedBg {
    background-color: #a40000
}

.DarkCandyAppleRedColor {
    color: #a40000
}

.DarkCeruleanBeforeBg:before,
.DarkCeruleanAfterBg:after,
.DarkCeruleanBg {
    background-color: #08457e
}

.DarkCeruleanColor {
    color: #08457e
}

.DarkChestnutBeforeBg:before,
.DarkChestnutAfterBg:after,
.DarkChestnutBg {
    background-color: #986960
}

.DarkChestnutColor {
    color: #986960
}

.DarkCoralBeforeBg:before,
.DarkCoralAfterBg:after,
.DarkCoralBg {
    background-color: #cd5b45
}

.DarkCoralColor {
    color: #cd5b45
}

.DarkCyanBeforeBg:before,
.DarkCyanAfterBg:after,
.DarkCyanBg {
    background-color: #008b8b
}

.DarkCyanColor {
    color: #008b8b
}

.DarkEbonyBeforeBg:before,
.DarkEbonyAfterBg:after,
.DarkEbonyBg {
    background-color: #3c2005
}

.DarkEbonyColor {
    color: #3c2005
}

.DarkFernBeforeBg:before,
.DarkFernAfterBg:after,
.DarkFernBg {
    background-color: #0a480d
}

.DarkFernColor {
    color: #0a480d
}

.DarkGoldenrodBeforeBg:before,
.DarkGoldenrodAfterBg:after,
.DarkGoldenrodBg {
    background-color: #b8860b
}

.DarkGoldenrodColor {
    color: #b8860b
}

.DarkGreenBeforeBg:before,
.DarkGreenAfterBg:after,
.DarkGreenBg {
    background-color: #013220
}

.DarkGreenColor {
    color: #013220
}

.DarkGunmetalBeforeBg:before,
.DarkGunmetalAfterBg:after,
.DarkGunmetalBg {
    background-color: #1f262a
}

.DarkGunmetalColor {
    color: #1f262a
}

.DarkImperialBlueBeforeBg:before,
.DarkImperialBlueAfterBg:after,
.DarkImperialBlueBg {
    background-color: #6e6ef9
}

.DarkImperialBlueColor {
    color: #6e6ef9
}

.DarkJungleGreenBeforeBg:before,
.DarkJungleGreenAfterBg:after,
.DarkJungleGreenBg {
    background-color: #1a2421
}

.DarkJungleGreenColor {
    color: #1a2421
}

.DarkKhakiBeforeBg:before,
.DarkKhakiAfterBg:after,
.DarkKhakiBg {
    background-color: #bdb76b
}

.DarkKhakiColor {
    color: #bdb76b
}

.DarkLavenderBeforeBg:before,
.DarkLavenderAfterBg:after,
.DarkLavenderBg {
    background-color: #734f96
}

.DarkLavenderColor {
    color: #734f96
}

.DarkLiverBeforeBg:before,
.DarkLiverAfterBg:after,
.DarkLiverBg {
    background-color: #534b4f
}

.DarkLiverColor {
    color: #534b4f
}

.DarkMagentaBeforeBg:before,
.DarkMagentaAfterBg:after,
.DarkMagentaBg {
    background-color: #8b008b
}

.DarkMagentaColor {
    color: #8b008b
}

.DarkMediumGrayBeforeBg:before,
.DarkMediumGrayAfterBg:after,
.DarkMediumGrayBg {
    background-color: #a9a9a9
}

.DarkMediumGrayColor {
    color: #a9a9a9
}

.DarkMidnightBlueBeforeBg:before,
.DarkMidnightBlueAfterBg:after,
.DarkMidnightBlueBg {
    background-color: #003366
}

.DarkMidnightBlueColor {
    color: #003366
}

.DarkMossGreenBeforeBg:before,
.DarkMossGreenAfterBg:after,
.DarkMossGreenBg {
    background-color: #4a5d23
}

.DarkMossGreenColor {
    color: #4a5d23
}

.DarkOliveGreenBeforeBg:before,
.DarkOliveGreenAfterBg:after,
.DarkOliveGreenBg {
    background-color: #556b2f
}

.DarkOliveGreenColor {
    color: #556b2f
}

.DarkOrangeBeforeBg:before,
.DarkOrangeAfterBg:after,
.DarkOrangeBg {
    background-color: #ff8c00
}

.DarkOrangeColor {
    color: #ff8c00
}

.DarkOrchidBeforeBg:before,
.DarkOrchidAfterBg:after,
.DarkOrchidBg {
    background-color: #9932cc
}

.DarkOrchidColor {
    color: #9932cc
}

.DarkPastelBlueBeforeBg:before,
.DarkPastelBlueAfterBg:after,
.DarkPastelBlueBg {
    background-color: #779ecb
}

.DarkPastelBlueColor {
    color: #779ecb
}

.DarkPastelGreenBeforeBg:before,
.DarkPastelGreenAfterBg:after,
.DarkPastelGreenBg {
    background-color: #03c03c
}

.DarkPastelGreenColor {
    color: #03c03c
}

.DarkPastelPurpleBeforeBg:before,
.DarkPastelPurpleAfterBg:after,
.DarkPastelPurpleBg {
    background-color: #966fd6
}

.DarkPastelPurpleColor {
    color: #966fd6
}

.DarkPastelRedBeforeBg:before,
.DarkPastelRedAfterBg:after,
.DarkPastelRedBg {
    background-color: #c23b22
}

.DarkPastelRedColor {
    color: #c23b22
}

.DarkPinkBeforeBg:before,
.DarkPinkAfterBg:after,
.DarkPinkBg {
    background-color: #e75480
}

.DarkPinkColor {
    color: #e75480
}

.DarkPuceBeforeBg:before,
.DarkPuceAfterBg:after,
.DarkPuceBg {
    background-color: #4f3a3c
}

.DarkPuceColor {
    color: #4f3a3c
}

.DarkPurpleBeforeBg:before,
.DarkPurpleAfterBg:after,
.DarkPurpleBg {
    background-color: #301934
}

.DarkPurpleColor {
    color: #301934
}

.DarkRaspberryBeforeBg:before,
.DarkRaspberryAfterBg:after,
.DarkRaspberryBg {
    background-color: #872657
}

.DarkRaspberryColor {
    color: #872657
}

.DarkRedBeforeBg:before,
.DarkRedAfterBg:after,
.DarkRedBg {
    background-color: #8b0000
}

.DarkRedColor {
    color: #8b0000
}

.DarkSalmonBeforeBg:before,
.DarkSalmonAfterBg:after,
.DarkSalmonBg {
    background-color: #e9967a
}

.DarkSalmonColor {
    color: #e9967a
}

.DarkScarletBeforeBg:before,
.DarkScarletAfterBg:after,
.DarkScarletBg {
    background-color: #560319
}

.DarkScarletColor {
    color: #560319
}

.DarkSeaGreenBeforeBg:before,
.DarkSeaGreenAfterBg:after,
.DarkSeaGreenBg {
    background-color: #8fbc8f
}

.DarkSeaGreenColor {
    color: #8fbc8f
}

.DarkSiennaBeforeBg:before,
.DarkSiennaAfterBg:after,
.DarkSiennaBg {
    background-color: #3c1414
}

.DarkSiennaColor {
    color: #3c1414
}

.DarkSkyBlueBeforeBg:before,
.DarkSkyBlueAfterBg:after,
.DarkSkyBlueBg {
    background-color: #8cbed6
}

.DarkSkyBlueColor {
    color: #8cbed6
}

.DarkSlateBlueBeforeBg:before,
.DarkSlateBlueAfterBg:after,
.DarkSlateBlueBg {
    background-color: #483d8b
}

.DarkSlateBlueColor {
    color: #483d8b
}

.DarkSlateGrayBeforeBg:before,
.DarkSlateGrayAfterBg:after,
.DarkSlateGrayBg {
    background-color: #2f4f4f
}

.DarkSlateGrayColor {
    color: #2f4f4f
}

.DarkSpringGreenBeforeBg:before,
.DarkSpringGreenAfterBg:after,
.DarkSpringGreenBg {
    background-color: #177245
}

.DarkSpringGreenColor {
    color: #177245
}

.DarkTanBeforeBg:before,
.DarkTanAfterBg:after,
.DarkTanBg {
    background-color: #918151
}

.DarkTanColor {
    color: #918151
}

.DarkTangerineBeforeBg:before,
.DarkTangerineAfterBg:after,
.DarkTangerineBg {
    background-color: #ffa812
}

.DarkTangerineColor {
    color: #ffa812
}

.DarkTerraCottaBeforeBg:before,
.DarkTerraCottaAfterBg:after,
.DarkTerraCottaBg {
    background-color: #cc4e5c
}

.DarkTerraCottaColor {
    color: #cc4e5c
}

.DarkTurquoiseBeforeBg:before,
.DarkTurquoiseAfterBg:after,
.DarkTurquoiseBg {
    background-color: #00ced1
}

.DarkTurquoiseColor {
    color: #00ced1
}

.DarkVanillaBeforeBg:before,
.DarkVanillaAfterBg:after,
.DarkVanillaBg {
    background-color: #d1bea8
}

.DarkVanillaColor {
    color: #d1bea8
}

.DarkVioletBeforeBg:before,
.DarkVioletAfterBg:after,
.DarkVioletBg {
    background-color: #9400d3
}

.DarkVioletColor {
    color: #9400d3
}

.DarkYellowBeforeBg:before,
.DarkYellowAfterBg:after,
.DarkYellowBg {
    background-color: #9b870c
}

.DarkYellowColor {
    color: #9b870c
}

.DartmouthGreenBeforeBg:before,
.DartmouthGreenAfterBg:after,
.DartmouthGreenBg {
    background-color: #00703c
}

.DartmouthGreenColor {
    color: #00703c
}

.DavysGreyBeforeBg:before,
.DavysGreyAfterBg:after,
.DavysGreyBg {
    background-color: #555555
}

.DavysGreyColor {
    color: #555555
}

.DawnBeforeBg:before,
.DawnAfterBg:after,
.DawnBg {
    background-color: #a6a29a
}

.DawnColor {
    color: #a6a29a
}

.DawnPinkBeforeBg:before,
.DawnPinkAfterBg:after,
.DawnPinkBg {
    background-color: #f3e9e5
}

.DawnPinkColor {
    color: #f3e9e5
}

.DeYorkBeforeBg:before,
.DeYorkAfterBg:after,
.DeYorkBg {
    background-color: #7ac488
}

.DeYorkColor {
    color: #7ac488
}

.DebianRedBeforeBg:before,
.DebianRedAfterBg:after,
.DebianRedBg {
    background-color: #d70a53
}

.DebianRedColor {
    color: #d70a53
}

.DecoBeforeBg:before,
.DecoAfterBg:after,
.DecoBg {
    background-color: #d2da97
}

.DecoColor {
    color: #d2da97
}

.DeepBlueBeforeBg:before,
.DeepBlueAfterBg:after,
.DeepBlueBg {
    background-color: #220878
}

.DeepBlueColor {
    color: #220878
}

.DeepBlushBeforeBg:before,
.DeepBlushAfterBg:after,
.DeepBlushBg {
    background-color: #e47698
}

.DeepBlushColor {
    color: #e47698
}

.DeepBronzeBeforeBg:before,
.DeepBronzeAfterBg:after,
.DeepBronzeBg {
    background-color: #4a3004
}

.DeepBronzeColor {
    color: #4a3004
}

.DeepCarmineBeforeBg:before,
.DeepCarmineAfterBg:after,
.DeepCarmineBg {
    background-color: #a9203e
}

.DeepCarmineColor {
    color: #a9203e
}

.DeepCarminePinkBeforeBg:before,
.DeepCarminePinkAfterBg:after,
.DeepCarminePinkBg {
    background-color: #ef3038
}

.DeepCarminePinkColor {
    color: #ef3038
}

.DeepCarrotOrangeBeforeBg:before,
.DeepCarrotOrangeAfterBg:after,
.DeepCarrotOrangeBg {
    background-color: #e9692c
}

.DeepCarrotOrangeColor {
    color: #e9692c
}

.DeepCeriseBeforeBg:before,
.DeepCeriseAfterBg:after,
.DeepCeriseBg {
    background-color: #da3287
}

.DeepCeriseColor {
    color: #da3287
}

.DeepChestnutBeforeBg:before,
.DeepChestnutAfterBg:after,
.DeepChestnutBg {
    background-color: #b94e48
}

.DeepChestnutColor {
    color: #b94e48
}

.DeepCoveBeforeBg:before,
.DeepCoveAfterBg:after,
.DeepCoveBg {
    background-color: #051040
}

.DeepCoveColor {
    color: #051040
}

.DeepFirBeforeBg:before,
.DeepFirAfterBg:after,
.DeepFirBg {
    background-color: #002900
}

.DeepFirColor {
    color: #002900
}

.DeepForestGreenBeforeBg:before,
.DeepForestGreenAfterBg:after,
.DeepForestGreenBg {
    background-color: #182d09
}

.DeepForestGreenColor {
    color: #182d09
}

.DeepFuchsiaBeforeBg:before,
.DeepFuchsiaAfterBg:after,
.DeepFuchsiaBg {
    background-color: #c154c1
}

.DeepFuchsiaColor {
    color: #c154c1
}

.DeepGreenBeforeBg:before,
.DeepGreenAfterBg:after,
.DeepGreenBg {
    background-color: #056608
}

.DeepGreenColor {
    color: #056608
}

.DeepGreenCyanTurquoiseBeforeBg:before,
.DeepGreenCyanTurquoiseAfterBg:after,
.DeepGreenCyanTurquoiseBg {
    background-color: #0e7c61
}

.DeepGreenCyanTurquoiseColor {
    color: #0e7c61
}

.DeepJungleGreenBeforeBg:before,
.DeepJungleGreenAfterBg:after,
.DeepJungleGreenBg {
    background-color: #004b49
}

.DeepJungleGreenColor {
    color: #004b49
}

.DeepKoamaruBeforeBg:before,
.DeepKoamaruAfterBg:after,
.DeepKoamaruBg {
    background-color: #333366
}

.DeepKoamaruColor {
    color: #333366
}

.DeepLemonBeforeBg:before,
.DeepLemonAfterBg:after,
.DeepLemonBg {
    background-color: #f5c71a
}

.DeepLemonColor {
    color: #f5c71a
}

.DeepLilacBeforeBg:before,
.DeepLilacAfterBg:after,
.DeepLilacBg {
    background-color: #9955bb
}

.DeepLilacColor {
    color: #9955bb
}

.DeepMagentaBeforeBg:before,
.DeepMagentaAfterBg:after,
.DeepMagentaBg {
    background-color: #cc00cc
}

.DeepMagentaColor {
    color: #cc00cc
}

.DeepMaroonBeforeBg:before,
.DeepMaroonAfterBg:after,
.DeepMaroonBg {
    background-color: #820000
}

.DeepMaroonColor {
    color: #820000
}

.DeepOakBeforeBg:before,
.DeepOakAfterBg:after,
.DeepOakBg {
    background-color: #412010
}

.DeepOakColor {
    color: #412010
}

.DeepPinkBeforeBg:before,
.DeepPinkAfterBg:after,
.DeepPinkBg {
    background-color: #ff1493
}

.DeepPinkColor {
    color: #ff1493
}

.DeepPuceBeforeBg:before,
.DeepPuceAfterBg:after,
.DeepPuceBg {
    background-color: #a95c68
}

.DeepPuceColor {
    color: #a95c68
}

.DeepRedBeforeBg:before,
.DeepRedAfterBg:after,
.DeepRedBg {
    background-color: #850101
}

.DeepRedColor {
    color: #850101
}

.DeepRubyBeforeBg:before,
.DeepRubyAfterBg:after,
.DeepRubyBg {
    background-color: #843f5b
}

.DeepRubyColor {
    color: #843f5b
}

.DeepSaffronBeforeBg:before,
.DeepSaffronAfterBg:after,
.DeepSaffronBg {
    background-color: #ff9933
}

.DeepSaffronColor {
    color: #ff9933
}

.DeepSapphireBeforeBg:before,
.DeepSapphireAfterBg:after,
.DeepSapphireBg {
    background-color: #082567
}

.DeepSapphireColor {
    color: #082567
}

.DeepSeaBeforeBg:before,
.DeepSeaAfterBg:after,
.DeepSeaBg {
    background-color: #01826b
}

.DeepSeaColor {
    color: #01826b
}

.DeepSeaGreenBeforeBg:before,
.DeepSeaGreenAfterBg:after,
.DeepSeaGreenBg {
    background-color: #095859
}

.DeepSeaGreenColor {
    color: #095859
}

.DeepSpaceSparkleBeforeBg:before,
.DeepSpaceSparkleAfterBg:after,
.DeepSpaceSparkleBg {
    background-color: #4a646c
}

.DeepSpaceSparkleColor {
    color: #4a646c
}

.DeepTaupeBeforeBg:before,
.DeepTaupeAfterBg:after,
.DeepTaupeBg {
    background-color: #7e5e60
}

.DeepTaupeColor {
    color: #7e5e60
}

.DeepTealBeforeBg:before,
.DeepTealAfterBg:after,
.DeepTealBg {
    background-color: #003532
}

.DeepTealColor {
    color: #003532
}

.DeepTuscanRedBeforeBg:before,
.DeepTuscanRedAfterBg:after,
.DeepTuscanRedBg {
    background-color: #66424d
}

.DeepTuscanRedColor {
    color: #66424d
}

.DeepVioletBeforeBg:before,
.DeepVioletAfterBg:after,
.DeepVioletBg {
    background-color: #330066
}

.DeepVioletColor {
    color: #330066
}

.DeerBeforeBg:before,
.DeerAfterBg:after,
.DeerBg {
    background-color: #ba8759
}

.DeerColor {
    color: #ba8759
}

.DelRioBeforeBg:before,
.DelRioAfterBg:after,
.DelRioBg {
    background-color: #b09a95
}

.DelRioColor {
    color: #b09a95
}

.DellBeforeBg:before,
.DellAfterBg:after,
.DellBg {
    background-color: #396413
}

.DellColor {
    color: #396413
}

.DeltaBeforeBg:before,
.DeltaAfterBg:after,
.DeltaBg {
    background-color: #a4a49d
}

.DeltaColor {
    color: #a4a49d
}

.DelugeBeforeBg:before,
.DelugeAfterBg:after,
.DelugeBg {
    background-color: #7563a8
}

.DelugeColor {
    color: #7563a8
}

.DenimBeforeBg:before,
.DenimAfterBg:after,
.DenimBg {
    background-color: #1560bd
}

.DenimColor {
    color: #1560bd
}

.DenimBlueBeforeBg:before,
.DenimBlueAfterBg:after,
.DenimBlueBg {
    background-color: #2243b6
}

.DenimBlueColor {
    color: #2243b6
}

.DerbyBeforeBg:before,
.DerbyAfterBg:after,
.DerbyBg {
    background-color: #ffeed8
}

.DerbyColor {
    color: #ffeed8
}

.DesaturatedCyanBeforeBg:before,
.DesaturatedCyanAfterBg:after,
.DesaturatedCyanBg {
    background-color: #669999
}

.DesaturatedCyanColor {
    color: #669999
}

.DesertBeforeBg:before,
.DesertAfterBg:after,
.DesertBg {
    background-color: #ae6020
}

.DesertColor {
    color: #ae6020
}

.DesertSandBeforeBg:before,
.DesertSandAfterBg:after,
.DesertSandBg {
    background-color: #edc9af
}

.DesertSandColor {
    color: #edc9af
}

.DesertStormBeforeBg:before,
.DesertStormAfterBg:after,
.DesertStormBg {
    background-color: #f8f8f7
}

.DesertStormColor {
    color: #f8f8f7
}

.DesireBeforeBg:before,
.DesireAfterBg:after,
.DesireBg {
    background-color: #ea3c53
}

.DesireColor {
    color: #ea3c53
}

.DewBeforeBg:before,
.DewAfterBg:after,
.DewBg {
    background-color: #eafffe
}

.DewColor {
    color: #eafffe
}

.DiSerriaBeforeBg:before,
.DiSerriaAfterBg:after,
.DiSerriaBg {
    background-color: #db995e
}

.DiSerriaColor {
    color: #db995e
}

.DiamondBeforeBg:before,
.DiamondAfterBg:after,
.DiamondBg {
    background-color: #b9f2ff
}

.DiamondColor {
    color: #b9f2ff
}

.DieselBeforeBg:before,
.DieselAfterBg:after,
.DieselBg {
    background-color: #130000
}

.DieselColor {
    color: #130000
}

.DimGrayBeforeBg:before,
.DimGrayAfterBg:after,
.DimGrayBg {
    background-color: #696969
}

.DimGrayColor {
    color: #696969
}

.DingleyBeforeBg:before,
.DingleyAfterBg:after,
.DingleyBg {
    background-color: #5d7747
}

.DingleyColor {
    color: #5d7747
}

.DingyDungeonBeforeBg:before,
.DingyDungeonAfterBg:after,
.DingyDungeonBg {
    background-color: #c53151
}

.DingyDungeonColor {
    color: #c53151
}

.DirtBeforeBg:before,
.DirtAfterBg:after,
.DirtBg {
    background-color: #9b7653
}

.DirtColor {
    color: #9b7653
}

.DiscoBeforeBg:before,
.DiscoAfterBg:after,
.DiscoBg {
    background-color: #871550
}

.DiscoColor {
    color: #871550
}

.DixieBeforeBg:before,
.DixieAfterBg:after,
.DixieBg {
    background-color: #e29418
}

.DixieColor {
    color: #e29418
}

.DodgerBlueBeforeBg:before,
.DodgerBlueAfterBg:after,
.DodgerBlueBg {
    background-color: #1e90ff
}

.DodgerBlueColor {
    color: #1e90ff
}

.DogsBeforeBg:before,
.DogsAfterBg:after,
.DogsBg {
    background-color: #b86d29
}

.DogsColor {
    color: #b86d29
}

.DogwoodRoseBeforeBg:before,
.DogwoodRoseAfterBg:after,
.DogwoodRoseBg {
    background-color: #d71868
}

.DogwoodRoseColor {
    color: #d71868
}

.DollarBillBeforeBg:before,
.DollarBillAfterBg:after,
.DollarBillBg {
    background-color: #85bb65
}

.DollarBillColor {
    color: #85bb65
}

.DollyBeforeBg:before,
.DollyAfterBg:after,
.DollyBg {
    background-color: #f9ff8b
}

.DollyColor {
    color: #f9ff8b
}

.DolphinBeforeBg:before,
.DolphinAfterBg:after,
.DolphinBg {
    background-color: #646077
}

.DolphinColor {
    color: #646077
}

.DominoBeforeBg:before,
.DominoAfterBg:after,
.DominoBg {
    background-color: #8e775e
}

.DominoColor {
    color: #8e775e
}

.DonJuanBeforeBg:before,
.DonJuanAfterBg:after,
.DonJuanBg {
    background-color: #5d4c51
}

.DonJuanColor {
    color: #5d4c51
}

.DonkeyBrownBeforeBg:before,
.DonkeyBrownAfterBg:after,
.DonkeyBrownBg {
    background-color: #664c28
}

.DonkeyBrownColor {
    color: #664c28
}

.DoradoBeforeBg:before,
.DoradoAfterBg:after,
.DoradoBg {
    background-color: #6b5755
}

.DoradoColor {
    color: #6b5755
}

.DoubleColonialWhiteBeforeBg:before,
.DoubleColonialWhiteAfterBg:after,
.DoubleColonialWhiteBg {
    background-color: #eee3ad
}

.DoubleColonialWhiteColor {
    color: #eee3ad
}

.DoublePearlLustaBeforeBg:before,
.DoublePearlLustaAfterBg:after,
.DoublePearlLustaBg {
    background-color: #fcf4d0
}

.DoublePearlLustaColor {
    color: #fcf4d0
}

.DoubleSpanishWhiteBeforeBg:before,
.DoubleSpanishWhiteAfterBg:after,
.DoubleSpanishWhiteBg {
    background-color: #e6d7b9
}

.DoubleSpanishWhiteColor {
    color: #e6d7b9
}

.DoveGrayBeforeBg:before,
.DoveGrayAfterBg:after,
.DoveGrayBg {
    background-color: #6d6c6c
}

.DoveGrayColor {
    color: #6d6c6c
}

.DownriverBeforeBg:before,
.DownriverAfterBg:after,
.DownriverBg {
    background-color: #092256
}

.DownriverColor {
    color: #092256
}

.DownyBeforeBg:before,
.DownyAfterBg:after,
.DownyBg {
    background-color: #6fd0c5
}

.DownyColor {
    color: #6fd0c5
}

.DriftwoodBeforeBg:before,
.DriftwoodAfterBg:after,
.DriftwoodBg {
    background-color: #af8751
}

.DriftwoodColor {
    color: #af8751
}

.DroverBeforeBg:before,
.DroverAfterBg:after,
.DroverBg {
    background-color: #fdf7ad
}

.DroverColor {
    color: #fdf7ad
}

.DukeBlueBeforeBg:before,
.DukeBlueAfterBg:after,
.DukeBlueBg {
    background-color: #00009c
}

.DukeBlueColor {
    color: #00009c
}

.DullLavenderBeforeBg:before,
.DullLavenderAfterBg:after,
.DullLavenderBg {
    background-color: #a899e6
}

.DullLavenderColor {
    color: #a899e6
}

.DuneBeforeBg:before,
.DuneAfterBg:after,
.DuneBg {
    background-color: #383533
}

.DuneColor {
    color: #383533
}

.DustStormBeforeBg:before,
.DustStormAfterBg:after,
.DustStormBg {
    background-color: #e5ccc9
}

.DustStormColor {
    color: #e5ccc9
}

.DustyGrayBeforeBg:before,
.DustyGrayAfterBg:after,
.DustyGrayBg {
    background-color: #a8989b
}

.DustyGrayColor {
    color: #a8989b
}

.DutchWhiteBeforeBg:before,
.DutchWhiteAfterBg:after,
.DutchWhiteBg {
    background-color: #efdfbb
}

.DutchWhiteColor {
    color: #efdfbb
}

.EagleBeforeBg:before,
.EagleAfterBg:after,
.EagleBg {
    background-color: #b6baa4
}

.EagleColor {
    color: #b6baa4
}

.EagleGreenBeforeBg:before,
.EagleGreenAfterBg:after,
.EagleGreenBg {
    background-color: #004953
}

.EagleGreenColor {
    color: #004953
}

.EarlsGreenBeforeBg:before,
.EarlsGreenAfterBg:after,
.EarlsGreenBg {
    background-color: #c9b93b
}

.EarlsGreenColor {
    color: #c9b93b
}

.EarlyDawnBeforeBg:before,
.EarlyDawnAfterBg:after,
.EarlyDawnBg {
    background-color: #fff9e6
}

.EarlyDawnColor {
    color: #fff9e6
}

.EarthYellowBeforeBg:before,
.EarthYellowAfterBg:after,
.EarthYellowBg {
    background-color: #e1a95f
}

.EarthYellowColor {
    color: #e1a95f
}

.EastBayBeforeBg:before,
.EastBayAfterBg:after,
.EastBayBg {
    background-color: #414c7d
}

.EastBayColor {
    color: #414c7d
}

.EastSideBeforeBg:before,
.EastSideAfterBg:after,
.EastSideBg {
    background-color: #ac91ce
}

.EastSideColor {
    color: #ac91ce
}

.EasternBlueBeforeBg:before,
.EasternBlueAfterBg:after,
.EasternBlueBg {
    background-color: #1e9ab0
}

.EasternBlueColor {
    color: #1e9ab0
}

.EbbBeforeBg:before,
.EbbAfterBg:after,
.EbbBg {
    background-color: #e9e3e3
}

.EbbColor {
    color: #e9e3e3
}

.EbonyBeforeBg:before,
.EbonyAfterBg:after,
.EbonyBg {
    background-color: #555d50
}

.EbonyColor {
    color: #555d50
}

.EbonyClayBeforeBg:before,
.EbonyClayAfterBg:after,
.EbonyClayBg {
    background-color: #26283b
}

.EbonyClayColor {
    color: #26283b
}

.EclipseBeforeBg:before,
.EclipseAfterBg:after,
.EclipseBg {
    background-color: #311c17
}

.EclipseColor {
    color: #311c17
}

.EcruBeforeBg:before,
.EcruAfterBg:after,
.EcruBg {
    background-color: #c2b280
}

.EcruColor {
    color: #c2b280
}

.EcruWhiteBeforeBg:before,
.EcruWhiteAfterBg:after,
.EcruWhiteBg {
    background-color: #f5f3e5
}

.EcruWhiteColor {
    color: #f5f3e5
}

.EcstasyBeforeBg:before,
.EcstasyAfterBg:after,
.EcstasyBg {
    background-color: #fa7814
}

.EcstasyColor {
    color: #fa7814
}

.EdenBeforeBg:before,
.EdenAfterBg:after,
.EdenBg {
    background-color: #105852
}

.EdenColor {
    color: #105852
}

.EdgewaterBeforeBg:before,
.EdgewaterAfterBg:after,
.EdgewaterBg {
    background-color: #c8e3d7
}

.EdgewaterColor {
    color: #c8e3d7
}

.EdwardBeforeBg:before,
.EdwardAfterBg:after,
.EdwardBg {
    background-color: #a2aeab
}

.EdwardColor {
    color: #a2aeab
}

.EerieBlackBeforeBg:before,
.EerieBlackAfterBg:after,
.EerieBlackBg {
    background-color: #1b1b1b
}

.EerieBlackColor {
    color: #1b1b1b
}

.EggSourBeforeBg:before,
.EggSourAfterBg:after,
.EggSourBg {
    background-color: #fff4dd
}

.EggSourColor {
    color: #fff4dd
}

.EggWhiteBeforeBg:before,
.EggWhiteAfterBg:after,
.EggWhiteBg {
    background-color: #ffefc1
}

.EggWhiteColor {
    color: #ffefc1
}

.EggplantBeforeBg:before,
.EggplantAfterBg:after,
.EggplantBg {
    background-color: #614051
}

.EggplantColor {
    color: #614051
}

.EggshellBeforeBg:before,
.EggshellAfterBg:after,
.EggshellBg {
    background-color: #f0ead6
}

.EggshellColor {
    color: #f0ead6
}

.EgyptianBlueBeforeBg:before,
.EgyptianBlueAfterBg:after,
.EgyptianBlueBg {
    background-color: #1034a6
}

.EgyptianBlueColor {
    color: #1034a6
}

.ElPasoBeforeBg:before,
.ElPasoAfterBg:after,
.ElPasoBg {
    background-color: #1e1708
}

.ElPasoColor {
    color: #1e1708
}

.ElSalvaBeforeBg:before,
.ElSalvaAfterBg:after,
.ElSalvaBg {
    background-color: #8f3e33
}

.ElSalvaColor {
    color: #8f3e33
}

.ElectricBlueBeforeBg:before,
.ElectricBlueAfterBg:after,
.ElectricBlueBg {
    background-color: #7df9ff
}

.ElectricBlueColor {
    color: #7df9ff
}

.ElectricCrimsonBeforeBg:before,
.ElectricCrimsonAfterBg:after,
.ElectricCrimsonBg {
    background-color: #ff003f
}

.ElectricCrimsonColor {
    color: #ff003f
}

.ElectricIndigoBeforeBg:before,
.ElectricIndigoAfterBg:after,
.ElectricIndigoBg {
    background-color: #6f00ff
}

.ElectricIndigoColor {
    color: #6f00ff
}

.ElectricLimeBeforeBg:before,
.ElectricLimeAfterBg:after,
.ElectricLimeBg {
    background-color: #ccff00
}

.ElectricLimeColor {
    color: #ccff00
}

.ElectricPurpleBeforeBg:before,
.ElectricPurpleAfterBg:after,
.ElectricPurpleBg {
    background-color: #bf00ff
}

.ElectricPurpleColor {
    color: #bf00ff
}

.ElectricVioletBeforeBg:before,
.ElectricVioletAfterBg:after,
.ElectricVioletBg {
    background-color: #8b00ff
}

.ElectricVioletColor {
    color: #8b00ff
}

.ElectricYellowBeforeBg:before,
.ElectricYellowAfterBg:after,
.ElectricYellowBg {
    background-color: #ffff33
}

.ElectricYellowColor {
    color: #ffff33
}

.ElephantBeforeBg:before,
.ElephantAfterBg:after,
.ElephantBg {
    background-color: #123447
}

.ElephantColor {
    color: #123447
}

.ElfGreenBeforeBg:before,
.ElfGreenAfterBg:after,
.ElfGreenBg {
    background-color: #088370
}

.ElfGreenColor {
    color: #088370
}

.ElmBeforeBg:before,
.ElmAfterBg:after,
.ElmBg {
    background-color: #1c7c7d
}

.ElmColor {
    color: #1c7c7d
}

.EmeraldBeforeBg:before,
.EmeraldAfterBg:after,
.EmeraldBg {
    background-color: #50c878
}

.EmeraldColor {
    color: #50c878
}

.EminenceBeforeBg:before,
.EminenceAfterBg:after,
.EminenceBg {
    background-color: #6c3082
}

.EminenceColor {
    color: #6c3082
}

.EmperorBeforeBg:before,
.EmperorAfterBg:after,
.EmperorBg {
    background-color: #514649
}

.EmperorColor {
    color: #514649
}

.EmpressBeforeBg:before,
.EmpressAfterBg:after,
.EmpressBg {
    background-color: #817377
}

.EmpressColor {
    color: #817377
}

.EndeavourBeforeBg:before,
.EndeavourAfterBg:after,
.EndeavourBg {
    background-color: #0056a7
}

.EndeavourColor {
    color: #0056a7
}

.EnergyYellowBeforeBg:before,
.EnergyYellowAfterBg:after,
.EnergyYellowBg {
    background-color: #f8dd5c
}

.EnergyYellowColor {
    color: #f8dd5c
}

.EngineeringInternationalOrangeBeforeBg:before,
.EngineeringInternationalOrangeAfterBg:after,
.EngineeringInternationalOrangeBg {
    background-color: #ba160c
}

.EngineeringInternationalOrangeColor {
    color: #ba160c
}

.EnglishHollyBeforeBg:before,
.EnglishHollyAfterBg:after,
.EnglishHollyBg {
    background-color: #022d15
}

.EnglishHollyColor {
    color: #022d15
}

.EnglishLavenderBeforeBg:before,
.EnglishLavenderAfterBg:after,
.EnglishLavenderBg {
    background-color: #b48395
}

.EnglishLavenderColor {
    color: #b48395
}

.EnglishRedBeforeBg:before,
.EnglishRedAfterBg:after,
.EnglishRedBg {
    background-color: #ab4b52
}

.EnglishRedColor {
    color: #ab4b52
}

.EnglishVermillionBeforeBg:before,
.EnglishVermillionAfterBg:after,
.EnglishVermillionBg {
    background-color: #cc474b
}

.EnglishVermillionColor {
    color: #cc474b
}

.EnglishWalnutBeforeBg:before,
.EnglishWalnutAfterBg:after,
.EnglishWalnutBg {
    background-color: #3e2b23
}

.EnglishWalnutColor {
    color: #3e2b23
}

.EnvyBeforeBg:before,
.EnvyAfterBg:after,
.EnvyBg {
    background-color: #8ba690
}

.EnvyColor {
    color: #8ba690
}

.EquatorBeforeBg:before,
.EquatorAfterBg:after,
.EquatorBg {
    background-color: #e1bc64
}

.EquatorColor {
    color: #e1bc64
}

.EspressoBeforeBg:before,
.EspressoAfterBg:after,
.EspressoBg {
    background-color: #612718
}

.EspressoColor {
    color: #612718
}

.EternityBeforeBg:before,
.EternityAfterBg:after,
.EternityBg {
    background-color: #211a0e
}

.EternityColor {
    color: #211a0e
}

.EtonBlueBeforeBg:before,
.EtonBlueAfterBg:after,
.EtonBlueBg {
    background-color: #96c8a2
}

.EtonBlueColor {
    color: #96c8a2
}

.EucalyptusBeforeBg:before,
.EucalyptusAfterBg:after,
.EucalyptusBg {
    background-color: #44d7a8
}

.EucalyptusColor {
    color: #44d7a8
}

.EunryBeforeBg:before,
.EunryAfterBg:after,
.EunryBg {
    background-color: #cfa39d
}

.EunryColor {
    color: #cfa39d
}

.EveningSeaBeforeBg:before,
.EveningSeaAfterBg:after,
.EveningSeaBg {
    background-color: #024e46
}

.EveningSeaColor {
    color: #024e46
}

.EvergladeBeforeBg:before,
.EvergladeAfterBg:after,
.EvergladeBg {
    background-color: #1c402e
}

.EvergladeColor {
    color: #1c402e
}

.FOGRA29RichBlackBeforeBg:before,
.FOGRA29RichBlackAfterBg:after,
.FOGRA29RichBlackBg {
    background-color: #010b13
}

.FOGRA29RichBlackColor {
    color: #010b13
}

.FOGRA39RichBlackBeforeBg:before,
.FOGRA39RichBlackAfterBg:after,
.FOGRA39RichBlackBg {
    background-color: #010203
}

.FOGRA39RichBlackColor {
    color: #010203
}

.FadedJadeBeforeBg:before,
.FadedJadeAfterBg:after,
.FadedJadeBg {
    background-color: #427977
}

.FadedJadeColor {
    color: #427977
}

.FairPinkBeforeBg:before,
.FairPinkAfterBg:after,
.FairPinkBg {
    background-color: #ffefec
}

.FairPinkColor {
    color: #ffefec
}

.FalconBeforeBg:before,
.FalconAfterBg:after,
.FalconBg {
    background-color: #7f626d
}

.FalconColor {
    color: #7f626d
}

.FallowBeforeBg:before,
.FallowAfterBg:after,
.FallowBg {
    background-color: #c19a6b
}

.FallowColor {
    color: #c19a6b
}

.FaluRedBeforeBg:before,
.FaluRedAfterBg:after,
.FaluRedBg {
    background-color: #801818
}

.FaluRedColor {
    color: #801818
}

.FandangoBeforeBg:before,
.FandangoAfterBg:after,
.FandangoBg {
    background-color: #b53389
}

.FandangoColor {
    color: #b53389
}

.FandangoPinkBeforeBg:before,
.FandangoPinkAfterBg:after,
.FandangoPinkBg {
    background-color: #de5285
}

.FandangoPinkColor {
    color: #de5285
}

.FantasyBeforeBg:before,
.FantasyAfterBg:after,
.FantasyBg {
    background-color: #faf3f0
}

.FantasyColor {
    color: #faf3f0
}

.FashionFuchsiaBeforeBg:before,
.FashionFuchsiaAfterBg:after,
.FashionFuchsiaBg {
    background-color: #f400a1
}

.FashionFuchsiaColor {
    color: #f400a1
}

.FawnBeforeBg:before,
.FawnAfterBg:after,
.FawnBg {
    background-color: #e5aa70
}

.FawnColor {
    color: #e5aa70
}

.FedoraBeforeBg:before,
.FedoraAfterBg:after,
.FedoraBg {
    background-color: #796a78
}

.FedoraColor {
    color: #796a78
}

.FeijoaBeforeBg:before,
.FeijoaAfterBg:after,
.FeijoaBg {
    background-color: #9fdd8c
}

.FeijoaColor {
    color: #9fdd8c
}

.FeldgrauBeforeBg:before,
.FeldgrauAfterBg:after,
.FeldgrauBg {
    background-color: #4d5d53
}

.FeldgrauColor {
    color: #4d5d53
}

.FernBeforeBg:before,
.FernAfterBg:after,
.FernBg {
    background-color: #63b76c
}

.FernColor {
    color: #63b76c
}

.FernFrondBeforeBg:before,
.FernFrondAfterBg:after,
.FernFrondBg {
    background-color: #657220
}

.FernFrondColor {
    color: #657220
}

.FernGreenBeforeBg:before,
.FernGreenAfterBg:after,
.FernGreenBg {
    background-color: #4f7942
}

.FernGreenColor {
    color: #4f7942
}

.FerraBeforeBg:before,
.FerraAfterBg:after,
.FerraBg {
    background-color: #704f50
}

.FerraColor {
    color: #704f50
}

.FerrariRedBeforeBg:before,
.FerrariRedAfterBg:after,
.FerrariRedBg {
    background-color: #ff2800
}

.FerrariRedColor {
    color: #ff2800
}

.FestivalBeforeBg:before,
.FestivalAfterBg:after,
.FestivalBg {
    background-color: #fbe96c
}

.FestivalColor {
    color: #fbe96c
}

.FetaBeforeBg:before,
.FetaAfterBg:after,
.FetaBg {
    background-color: #f0fcea
}

.FetaColor {
    color: #f0fcea
}

.FieldDrabBeforeBg:before,
.FieldDrabAfterBg:after,
.FieldDrabBg {
    background-color: #6c541e
}

.FieldDrabColor {
    color: #6c541e
}

.FieryOrangeBeforeBg:before,
.FieryOrangeAfterBg:after,
.FieryOrangeBg {
    background-color: #b35213
}

.FieryOrangeColor {
    color: #b35213
}

.FieryRoseBeforeBg:before,
.FieryRoseAfterBg:after,
.FieryRoseBg {
    background-color: #ff5470
}

.FieryRoseColor {
    color: #ff5470
}

.FinchBeforeBg:before,
.FinchAfterBg:after,
.FinchBg {
    background-color: #626649
}

.FinchColor {
    color: #626649
}

.FinlandiaBeforeBg:before,
.FinlandiaAfterBg:after,
.FinlandiaBg {
    background-color: #556d56
}

.FinlandiaColor {
    color: #556d56
}

.FinnBeforeBg:before,
.FinnAfterBg:after,
.FinnBg {
    background-color: #692d54
}

.FinnColor {
    color: #692d54
}

.FiordBeforeBg:before,
.FiordAfterBg:after,
.FiordBg {
    background-color: #405169
}

.FiordColor {
    color: #405169
}

.FireBeforeBg:before,
.FireAfterBg:after,
.FireBg {
    background-color: #aa4203
}

.FireColor {
    color: #aa4203
}

.FireBushBeforeBg:before,
.FireBushAfterBg:after,
.FireBushBg {
    background-color: #e89928
}

.FireBushColor {
    color: #e89928
}

.FireEngineRedBeforeBg:before,
.FireEngineRedAfterBg:after,
.FireEngineRedBg {
    background-color: #ce2029
}

.FireEngineRedColor {
    color: #ce2029
}

.FirebrickBeforeBg:before,
.FirebrickAfterBg:after,
.FirebrickBg {
    background-color: #b22222
}

.FirebrickColor {
    color: #b22222
}

.FireflyBeforeBg:before,
.FireflyAfterBg:after,
.FireflyBg {
    background-color: #0e2a30
}

.FireflyColor {
    color: #0e2a30
}

.FlameBeforeBg:before,
.FlameAfterBg:after,
.FlameBg {
    background-color: #e25822
}

.FlameColor {
    color: #e25822
}

.FlamePeaBeforeBg:before,
.FlamePeaAfterBg:after,
.FlamePeaBg {
    background-color: #da5b38
}

.FlamePeaColor {
    color: #da5b38
}

.FlamencoBeforeBg:before,
.FlamencoAfterBg:after,
.FlamencoBg {
    background-color: #ff7d07
}

.FlamencoColor {
    color: #ff7d07
}

.FlamingoBeforeBg:before,
.FlamingoAfterBg:after,
.FlamingoBg {
    background-color: #f2552a
}

.FlamingoColor {
    color: #f2552a
}

.FlamingoPinkBeforeBg:before,
.FlamingoPinkAfterBg:after,
.FlamingoPinkBg {
    background-color: #fc8eac
}

.FlamingoPinkColor {
    color: #fc8eac
}

.FlavescentBeforeBg:before,
.FlavescentAfterBg:after,
.FlavescentBg {
    background-color: #f7e98e
}

.FlavescentColor {
    color: #f7e98e
}

.FlaxBeforeBg:before,
.FlaxAfterBg:after,
.FlaxBg {
    background-color: #eedc82
}

.FlaxColor {
    color: #eedc82
}

.FlaxSmokeBeforeBg:before,
.FlaxSmokeAfterBg:after,
.FlaxSmokeBg {
    background-color: #7b8265
}

.FlaxSmokeColor {
    color: #7b8265
}

.FlintBeforeBg:before,
.FlintAfterBg:after,
.FlintBg {
    background-color: #6f6a61
}

.FlintColor {
    color: #6f6a61
}

.FlirtBeforeBg:before,
.FlirtAfterBg:after,
.FlirtBg {
    background-color: #a2006d
}

.FlirtColor {
    color: #a2006d
}

.FloralWhiteBeforeBg:before,
.FloralWhiteAfterBg:after,
.FloralWhiteBg {
    background-color: #fffaf0
}

.FloralWhiteColor {
    color: #fffaf0
}

.FlushMahoganyBeforeBg:before,
.FlushMahoganyAfterBg:after,
.FlushMahoganyBg {
    background-color: #ca3435
}

.FlushMahoganyColor {
    color: #ca3435
}

.FoamBeforeBg:before,
.FoamAfterBg:after,
.FoamBg {
    background-color: #d8fcfa
}

.FoamColor {
    color: #d8fcfa
}

.FogBeforeBg:before,
.FogAfterBg:after,
.FogBg {
    background-color: #d7d0ff
}

.FogColor {
    color: #d7d0ff
}

.FoggyGrayBeforeBg:before,
.FoggyGrayAfterBg:after,
.FoggyGrayBg {
    background-color: #cbcab6
}

.FoggyGrayColor {
    color: #cbcab6
}

.FollyBeforeBg:before,
.FollyAfterBg:after,
.FollyBg {
    background-color: #ff004f
}

.FollyColor {
    color: #ff004f
}

.ForestGreenBeforeBg:before,
.ForestGreenAfterBg:after,
.ForestGreenBg {
    background-color: #228b22
}

.ForestGreenColor {
    color: #228b22
}

.ForgetMeNotBeforeBg:before,
.ForgetMeNotAfterBg:after,
.ForgetMeNotBg {
    background-color: #fff1ee
}

.ForgetMeNotColor {
    color: #fff1ee
}

.FountainBlueBeforeBg:before,
.FountainBlueAfterBg:after,
.FountainBlueBg {
    background-color: #56b4be
}

.FountainBlueColor {
    color: #56b4be
}

.FrangipaniBeforeBg:before,
.FrangipaniAfterBg:after,
.FrangipaniBg {
    background-color: #ffdeb3
}

.FrangipaniColor {
    color: #ffdeb3
}

.FrenchBistreBeforeBg:before,
.FrenchBistreAfterBg:after,
.FrenchBistreBg {
    background-color: #856d4d
}

.FrenchBistreColor {
    color: #856d4d
}

.FrenchBlueBeforeBg:before,
.FrenchBlueAfterBg:after,
.FrenchBlueBg {
    background-color: #0072bb
}

.FrenchBlueColor {
    color: #0072bb
}

.FrenchFuchsiaBeforeBg:before,
.FrenchFuchsiaAfterBg:after,
.FrenchFuchsiaBg {
    background-color: #fd3f92
}

.FrenchFuchsiaColor {
    color: #fd3f92
}

.FrenchGrayBeforeBg:before,
.FrenchGrayAfterBg:after,
.FrenchGrayBg {
    background-color: #bdbdc6
}

.FrenchGrayColor {
    color: #bdbdc6
}

.FrenchLilacBeforeBg:before,
.FrenchLilacAfterBg:after,
.FrenchLilacBg {
    background-color: #86608e
}

.FrenchLilacColor {
    color: #86608e
}

.FrenchLimeBeforeBg:before,
.FrenchLimeAfterBg:after,
.FrenchLimeBg {
    background-color: #9efd38
}

.FrenchLimeColor {
    color: #9efd38
}

.FrenchMauveBeforeBg:before,
.FrenchMauveAfterBg:after,
.FrenchMauveBg {
    background-color: #d473d4
}

.FrenchMauveColor {
    color: #d473d4
}

.FrenchPassBeforeBg:before,
.FrenchPassAfterBg:after,
.FrenchPassBg {
    background-color: #bdedfd
}

.FrenchPassColor {
    color: #bdedfd
}

.FrenchPinkBeforeBg:before,
.FrenchPinkAfterBg:after,
.FrenchPinkBg {
    background-color: #fd6c9e
}

.FrenchPinkColor {
    color: #fd6c9e
}

.FrenchPlumBeforeBg:before,
.FrenchPlumAfterBg:after,
.FrenchPlumBg {
    background-color: #811453
}

.FrenchPlumColor {
    color: #811453
}

.FrenchPuceBeforeBg:before,
.FrenchPuceAfterBg:after,
.FrenchPuceBg {
    background-color: #4e1609
}

.FrenchPuceColor {
    color: #4e1609
}

.FrenchRaspberryBeforeBg:before,
.FrenchRaspberryAfterBg:after,
.FrenchRaspberryBg {
    background-color: #c72c48
}

.FrenchRaspberryColor {
    color: #c72c48
}

.FrenchRoseBeforeBg:before,
.FrenchRoseAfterBg:after,
.FrenchRoseBg {
    background-color: #f64a8a
}

.FrenchRoseColor {
    color: #f64a8a
}

.FrenchSkyBlueBeforeBg:before,
.FrenchSkyBlueAfterBg:after,
.FrenchSkyBlueBg {
    background-color: #77b5fe
}

.FrenchSkyBlueColor {
    color: #77b5fe
}

.FrenchVioletBeforeBg:before,
.FrenchVioletAfterBg:after,
.FrenchVioletBg {
    background-color: #8806ce
}

.FrenchVioletColor {
    color: #8806ce
}

.FrenchWineBeforeBg:before,
.FrenchWineAfterBg:after,
.FrenchWineBg {
    background-color: #ac1e44
}

.FrenchWineColor {
    color: #ac1e44
}

.FreshAirBeforeBg:before,
.FreshAirAfterBg:after,
.FreshAirBg {
    background-color: #a6e7ff
}

.FreshAirColor {
    color: #a6e7ff
}

.FreshEggplantBeforeBg:before,
.FreshEggplantAfterBg:after,
.FreshEggplantBg {
    background-color: #990066
}

.FreshEggplantColor {
    color: #990066
}

.FriarGrayBeforeBg:before,
.FriarGrayAfterBg:after,
.FriarGrayBg {
    background-color: #807e79
}

.FriarGrayColor {
    color: #807e79
}

.FringyFlowerBeforeBg:before,
.FringyFlowerAfterBg:after,
.FringyFlowerBg {
    background-color: #b1e2c1
}

.FringyFlowerColor {
    color: #b1e2c1
}

.FrolyBeforeBg:before,
.FrolyAfterBg:after,
.FrolyBg {
    background-color: #f57584
}

.FrolyColor {
    color: #f57584
}

.FrostBeforeBg:before,
.FrostAfterBg:after,
.FrostBg {
    background-color: #edf5dd
}

.FrostColor {
    color: #edf5dd
}

.FrostbiteBeforeBg:before,
.FrostbiteAfterBg:after,
.FrostbiteBg {
    background-color: #e936a7
}

.FrostbiteColor {
    color: #e936a7
}

.FrostedMintBeforeBg:before,
.FrostedMintAfterBg:after,
.FrostedMintBg {
    background-color: #dbfff8
}

.FrostedMintColor {
    color: #dbfff8
}

.FrosteeBeforeBg:before,
.FrosteeAfterBg:after,
.FrosteeBg {
    background-color: #e4f6e7
}

.FrosteeColor {
    color: #e4f6e7
}

.FruitSaladBeforeBg:before,
.FruitSaladAfterBg:after,
.FruitSaladBg {
    background-color: #4f9d5d
}

.FruitSaladColor {
    color: #4f9d5d
}

.FuchsiaBeforeBg:before,
.FuchsiaAfterBg:after,
.FuchsiaBg {
    background-color: #ff00ff
}

.FuchsiaColor {
    color: #ff00ff
}

.FuchsiaBlueBeforeBg:before,
.FuchsiaBlueAfterBg:after,
.FuchsiaBlueBg {
    background-color: #7a58c1
}

.FuchsiaBlueColor {
    color: #7a58c1
}

.FuchsiaPinkBeforeBg:before,
.FuchsiaPinkAfterBg:after,
.FuchsiaPinkBg {
    background-color: #ff77ff
}

.FuchsiaPinkColor {
    color: #ff77ff
}

.FuchsiaPurpleBeforeBg:before,
.FuchsiaPurpleAfterBg:after,
.FuchsiaPurpleBg {
    background-color: #cc397b
}

.FuchsiaPurpleColor {
    color: #cc397b
}

.FuchsiaRoseBeforeBg:before,
.FuchsiaRoseAfterBg:after,
.FuchsiaRoseBg {
    background-color: #c74375
}

.FuchsiaRoseColor {
    color: #c74375
}

.FuegoBeforeBg:before,
.FuegoAfterBg:after,
.FuegoBg {
    background-color: #bede0d
}

.FuegoColor {
    color: #bede0d
}

.FuelYellowBeforeBg:before,
.FuelYellowAfterBg:after,
.FuelYellowBg {
    background-color: #eca927
}

.FuelYellowColor {
    color: #eca927
}

.FulvousBeforeBg:before,
.FulvousAfterBg:after,
.FulvousBg {
    background-color: #e48400
}

.FulvousColor {
    color: #e48400
}

.FunBlueBeforeBg:before,
.FunBlueAfterBg:after,
.FunBlueBg {
    background-color: #1959a8
}

.FunBlueColor {
    color: #1959a8
}

.FunGreenBeforeBg:before,
.FunGreenAfterBg:after,
.FunGreenBg {
    background-color: #016d39
}

.FunGreenColor {
    color: #016d39
}

.FuscousGrayBeforeBg:before,
.FuscousGrayAfterBg:after,
.FuscousGrayBg {
    background-color: #54534d
}

.FuscousGrayColor {
    color: #54534d
}

.FuzzyWuzzyBeforeBg:before,
.FuzzyWuzzyAfterBg:after,
.FuzzyWuzzyBg {
    background-color: #cc6666
}

.FuzzyWuzzyColor {
    color: #cc6666
}

.FuzzyWuzzyBrownBeforeBg:before,
.FuzzyWuzzyBrownAfterBg:after,
.FuzzyWuzzyBrownBg {
    background-color: #c45655
}

.FuzzyWuzzyBrownColor {
    color: #c45655
}

.GOGreenBeforeBg:before,
.GOGreenAfterBg:after,
.GOGreenBg {
    background-color: #00ab66
}

.GOGreenColor {
    color: #00ab66
}

.GableGreenBeforeBg:before,
.GableGreenAfterBg:after,
.GableGreenBg {
    background-color: #163531
}

.GableGreenColor {
    color: #163531
}

.GainsboroBeforeBg:before,
.GainsboroAfterBg:after,
.GainsboroBg {
    background-color: #dcdcdc
}

.GainsboroColor {
    color: #dcdcdc
}

.GalleryBeforeBg:before,
.GalleryAfterBg:after,
.GalleryBg {
    background-color: #efefef
}

.GalleryColor {
    color: #efefef
}

.GallianoBeforeBg:before,
.GallianoAfterBg:after,
.GallianoBg {
    background-color: #dcb20c
}

.GallianoColor {
    color: #dcb20c
}

.GambogeBeforeBg:before,
.GambogeAfterBg:after,
.GambogeBg {
    background-color: #e49b0f
}

.GambogeColor {
    color: #e49b0f
}

.GambogeOrangeBeforeBg:before,
.GambogeOrangeAfterBg:after,
.GambogeOrangeBg {
    background-color: #996600
}

.GambogeOrangeColor {
    color: #996600
}

.GargoyleGasBeforeBg:before,
.GargoyleGasAfterBg:after,
.GargoyleGasBg {
    background-color: #ffdf46
}

.GargoyleGasColor {
    color: #ffdf46
}

.GeebungBeforeBg:before,
.GeebungAfterBg:after,
.GeebungBg {
    background-color: #d18f1b
}

.GeebungColor {
    color: #d18f1b
}

.GenericViridianBeforeBg:before,
.GenericViridianAfterBg:after,
.GenericViridianBg {
    background-color: #007f66
}

.GenericViridianColor {
    color: #007f66
}

.GenoaBeforeBg:before,
.GenoaAfterBg:after,
.GenoaBg {
    background-color: #15736b
}

.GenoaColor {
    color: #15736b
}

.GeraldineBeforeBg:before,
.GeraldineAfterBg:after,
.GeraldineBg {
    background-color: #fb8989
}

.GeraldineColor {
    color: #fb8989
}

.GeyserBeforeBg:before,
.GeyserAfterBg:after,
.GeyserBg {
    background-color: #d4dfe2
}

.GeyserColor {
    color: #d4dfe2
}

.GhostBeforeBg:before,
.GhostAfterBg:after,
.GhostBg {
    background-color: #c7c9d5
}

.GhostColor {
    color: #c7c9d5
}

.GhostWhiteBeforeBg:before,
.GhostWhiteAfterBg:after,
.GhostWhiteBg {
    background-color: #f8f8ff
}

.GhostWhiteColor {
    color: #f8f8ff
}

.GiantsClubBeforeBg:before,
.GiantsClubAfterBg:after,
.GiantsClubBg {
    background-color: #b05c52
}

.GiantsClubColor {
    color: #b05c52
}

.GiantsOrangeBeforeBg:before,
.GiantsOrangeAfterBg:after,
.GiantsOrangeBg {
    background-color: #fe5a1d
}

.GiantsOrangeColor {
    color: #fe5a1d
}

.GigasBeforeBg:before,
.GigasAfterBg:after,
.GigasBg {
    background-color: #523c94
}

.GigasColor {
    color: #523c94
}

.GimbletBeforeBg:before,
.GimbletAfterBg:after,
.GimbletBg {
    background-color: #b8b56a
}

.GimbletColor {
    color: #b8b56a
}

.GinBeforeBg:before,
.GinAfterBg:after,
.GinBg {
    background-color: #e8f2eb
}

.GinColor {
    color: #e8f2eb
}

.GinFizzBeforeBg:before,
.GinFizzAfterBg:after,
.GinFizzBg {
    background-color: #fff9e2
}

.GinFizzColor {
    color: #fff9e2
}

.GingerBeforeBg:before,
.GingerAfterBg:after,
.GingerBg {
    background-color: #b06500
}

.GingerColor {
    color: #b06500
}

.GivryBeforeBg:before,
.GivryAfterBg:after,
.GivryBg {
    background-color: #f8e4bf
}

.GivryColor {
    color: #f8e4bf
}

.GlacierBeforeBg:before,
.GlacierAfterBg:after,
.GlacierBg {
    background-color: #80b3c4
}

.GlacierColor {
    color: #80b3c4
}

.GladeGreenBeforeBg:before,
.GladeGreenAfterBg:after,
.GladeGreenBg {
    background-color: #61845f
}

.GladeGreenColor {
    color: #61845f
}

.GlaucousBeforeBg:before,
.GlaucousAfterBg:after,
.GlaucousBg {
    background-color: #6082b6
}

.GlaucousColor {
    color: #6082b6
}

.GlitterBeforeBg:before,
.GlitterAfterBg:after,
.GlitterBg {
    background-color: #e6e8fa
}

.GlitterColor {
    color: #e6e8fa
}

.GlossyGrapeBeforeBg:before,
.GlossyGrapeAfterBg:after,
.GlossyGrapeBg {
    background-color: #ab92b3
}

.GlossyGrapeColor {
    color: #ab92b3
}

.GoBenBeforeBg:before,
.GoBenAfterBg:after,
.GoBenBg {
    background-color: #726d4e
}

.GoBenColor {
    color: #726d4e
}

.GoblinBeforeBg:before,
.GoblinAfterBg:after,
.GoblinBg {
    background-color: #3d7d52
}

.GoblinColor {
    color: #3d7d52
}

.GoldDropBeforeBg:before,
.GoldDropAfterBg:after,
.GoldDropBg {
    background-color: #f18200
}

.GoldDropColor {
    color: #f18200
}

.GoldFusionBeforeBg:before,
.GoldFusionAfterBg:after,
.GoldFusionBg {
    background-color: #85754e
}

.GoldFusionColor {
    color: #85754e
}

.GoldTipsBeforeBg:before,
.GoldTipsAfterBg:after,
.GoldTipsBg {
    background-color: #deba13
}

.GoldTipsColor {
    color: #deba13
}

.GoldenBeforeBg:before,
.GoldenAfterBg:after,
.GoldenBg {
    background-color: #ffd700
}

.GoldenColor {
    color: #ffd700
}

.GoldenBellBeforeBg:before,
.GoldenBellAfterBg:after,
.GoldenBellBg {
    background-color: #e28913
}

.GoldenBellColor {
    color: #e28913
}

.GoldenBrownBeforeBg:before,
.GoldenBrownAfterBg:after,
.GoldenBrownBg {
    background-color: #996515
}

.GoldenBrownColor {
    color: #996515
}

.GoldenDreamBeforeBg:before,
.GoldenDreamAfterBg:after,
.GoldenDreamBg {
    background-color: #f0d52d
}

.GoldenDreamColor {
    color: #f0d52d
}

.GoldenFizzBeforeBg:before,
.GoldenFizzAfterBg:after,
.GoldenFizzBg {
    background-color: #f5fb3d
}

.GoldenFizzColor {
    color: #f5fb3d
}

.GoldenGateBridgeBeforeBg:before,
.GoldenGateBridgeAfterBg:after,
.GoldenGateBridgeBg {
    background-color: #c0362c
}

.GoldenGateBridgeColor {
    color: #c0362c
}

.GoldenGlowBeforeBg:before,
.GoldenGlowAfterBg:after,
.GoldenGlowBg {
    background-color: #fde295
}

.GoldenGlowColor {
    color: #fde295
}

.GoldenPoppyBeforeBg:before,
.GoldenPoppyAfterBg:after,
.GoldenPoppyBg {
    background-color: #fcc200
}

.GoldenPoppyColor {
    color: #fcc200
}

.GoldenSandBeforeBg:before,
.GoldenSandAfterBg:after,
.GoldenSandBg {
    background-color: #f0db7d
}

.GoldenSandColor {
    color: #f0db7d
}

.GoldenTainoiBeforeBg:before,
.GoldenTainoiAfterBg:after,
.GoldenTainoiBg {
    background-color: #ffcc5c
}

.GoldenTainoiColor {
    color: #ffcc5c
}

.GoldenYellowBeforeBg:before,
.GoldenYellowAfterBg:after,
.GoldenYellowBg {
    background-color: #ffdf00
}

.GoldenYellowColor {
    color: #ffdf00
}

.GoldenrodBeforeBg:before,
.GoldenrodAfterBg:after,
.GoldenrodBg {
    background-color: #daa520
}

.GoldenrodColor {
    color: #daa520
}

.GondolaBeforeBg:before,
.GondolaAfterBg:after,
.GondolaBg {
    background-color: #261414
}

.GondolaColor {
    color: #261414
}

.GordonsGreenBeforeBg:before,
.GordonsGreenAfterBg:after,
.GordonsGreenBg {
    background-color: #0b1107
}

.GordonsGreenColor {
    color: #0b1107
}

.GorseBeforeBg:before,
.GorseAfterBg:after,
.GorseBg {
    background-color: #fff14f
}

.GorseColor {
    color: #fff14f
}

.GossamerBeforeBg:before,
.GossamerAfterBg:after,
.GossamerBg {
    background-color: #069b81
}

.GossamerColor {
    color: #069b81
}

.GossipBeforeBg:before,
.GossipAfterBg:after,
.GossipBg {
    background-color: #d2f8b0
}

.GossipColor {
    color: #d2f8b0
}

.GothicBeforeBg:before,
.GothicAfterBg:after,
.GothicBg {
    background-color: #6d92a1
}

.GothicColor {
    color: #6d92a1
}

.GovernorBayBeforeBg:before,
.GovernorBayAfterBg:after,
.GovernorBayBg {
    background-color: #2f3cb3
}

.GovernorBayColor {
    color: #2f3cb3
}

.GrainBrownBeforeBg:before,
.GrainBrownAfterBg:after,
.GrainBrownBg {
    background-color: #e4d5b7
}

.GrainBrownColor {
    color: #e4d5b7
}

.GrandisBeforeBg:before,
.GrandisAfterBg:after,
.GrandisBg {
    background-color: #ffd38c
}

.GrandisColor {
    color: #ffd38c
}

.GraniteGrayBeforeBg:before,
.GraniteGrayAfterBg:after,
.GraniteGrayBg {
    background-color: #676767
}

.GraniteGrayColor {
    color: #676767
}

.GraniteGreenBeforeBg:before,
.GraniteGreenAfterBg:after,
.GraniteGreenBg {
    background-color: #8d8974
}

.GraniteGreenColor {
    color: #8d8974
}

.GrannyAppleBeforeBg:before,
.GrannyAppleAfterBg:after,
.GrannyAppleBg {
    background-color: #d5f6e3
}

.GrannyAppleColor {
    color: #d5f6e3
}

.GrannySmithBeforeBg:before,
.GrannySmithAfterBg:after,
.GrannySmithBg {
    background-color: #84a0a0
}

.GrannySmithColor {
    color: #84a0a0
}

.GrannySmithAppleBeforeBg:before,
.GrannySmithAppleAfterBg:after,
.GrannySmithAppleBg {
    background-color: #a8e4a0
}

.GrannySmithAppleColor {
    color: #a8e4a0
}

.GrapeBeforeBg:before,
.GrapeAfterBg:after,
.GrapeBg {
    background-color: #6f2da8
}

.GrapeColor {
    color: #6f2da8
}

.GraphiteBeforeBg:before,
.GraphiteAfterBg:after,
.GraphiteBg {
    background-color: #251607
}

.GraphiteColor {
    color: #251607
}

.GravelBeforeBg:before,
.GravelAfterBg:after,
.GravelBg {
    background-color: #4a444b
}

.GravelColor {
    color: #4a444b
}

.GrayBeforeBg:before,
.GrayAfterBg:after,
.GrayBg {
    background-color: #808080
}

.GrayColor {
    color: #808080
}

.GrayAsparagusBeforeBg:before,
.GrayAsparagusAfterBg:after,
.GrayAsparagusBg {
    background-color: #465945
}

.GrayAsparagusColor {
    color: #465945
}

.GrayChateauBeforeBg:before,
.GrayChateauAfterBg:after,
.GrayChateauBg {
    background-color: #a2aab3
}

.GrayChateauColor {
    color: #a2aab3
}

.GrayNickelBeforeBg:before,
.GrayNickelAfterBg:after,
.GrayNickelBg {
    background-color: #c3c3bd
}

.GrayNickelColor {
    color: #c3c3bd
}

.GrayNurseBeforeBg:before,
.GrayNurseAfterBg:after,
.GrayNurseBg {
    background-color: #e7ece6
}

.GrayNurseColor {
    color: #e7ece6
}

.GrayOliveBeforeBg:before,
.GrayOliveAfterBg:after,
.GrayOliveBg {
    background-color: #a9a491
}

.GrayOliveColor {
    color: #a9a491
}

.GraySuitBeforeBg:before,
.GraySuitAfterBg:after,
.GraySuitBg {
    background-color: #c1becd
}

.GraySuitColor {
    color: #c1becd
}

.GreenBeforeBg:before,
.GreenAfterBg:after,
.GreenBg {
    background-color: #00ff00
}

.GreenColor {
    color: #00ff00
}

.GreenBlueBeforeBg:before,
.GreenBlueAfterBg:after,
.GreenBlueBg {
    background-color: #1164b4
}

.GreenBlueColor {
    color: #1164b4
}

.GreenCyanBeforeBg:before,
.GreenCyanAfterBg:after,
.GreenCyanBg {
    background-color: #009966
}

.GreenCyanColor {
    color: #009966
}

.GreenHazeBeforeBg:before,
.GreenHazeAfterBg:after,
.GreenHazeBg {
    background-color: #01a368
}

.GreenHazeColor {
    color: #01a368
}

.GreenHouseBeforeBg:before,
.GreenHouseAfterBg:after,
.GreenHouseBg {
    background-color: #24500f
}

.GreenHouseColor {
    color: #24500f
}

.GreenKelpBeforeBg:before,
.GreenKelpAfterBg:after,
.GreenKelpBg {
    background-color: #25311c
}

.GreenKelpColor {
    color: #25311c
}

.GreenLeafBeforeBg:before,
.GreenLeafAfterBg:after,
.GreenLeafBg {
    background-color: #436a0d
}

.GreenLeafColor {
    color: #436a0d
}

.GreenLizardBeforeBg:before,
.GreenLizardAfterBg:after,
.GreenLizardBg {
    background-color: #a7f432
}

.GreenLizardColor {
    color: #a7f432
}

.GreenMistBeforeBg:before,
.GreenMistAfterBg:after,
.GreenMistBg {
    background-color: #cbd3b0
}

.GreenMistColor {
    color: #cbd3b0
}

.GreenPeaBeforeBg:before,
.GreenPeaAfterBg:after,
.GreenPeaBg {
    background-color: #1d6142
}

.GreenPeaColor {
    color: #1d6142
}

.GreenSheenBeforeBg:before,
.GreenSheenAfterBg:after,
.GreenSheenBg {
    background-color: #6eaea1
}

.GreenSheenColor {
    color: #6eaea1
}

.GreenSmokeBeforeBg:before,
.GreenSmokeAfterBg:after,
.GreenSmokeBg {
    background-color: #a4af6e
}

.GreenSmokeColor {
    color: #a4af6e
}

.GreenSpringBeforeBg:before,
.GreenSpringAfterBg:after,
.GreenSpringBg {
    background-color: #b8c1b1
}

.GreenSpringColor {
    color: #b8c1b1
}

.GreenVogueBeforeBg:before,
.GreenVogueAfterBg:after,
.GreenVogueBg {
    background-color: #032b52
}

.GreenVogueColor {
    color: #032b52
}

.GreenWaterlooBeforeBg:before,
.GreenWaterlooAfterBg:after,
.GreenWaterlooBg {
    background-color: #101405
}

.GreenWaterlooColor {
    color: #101405
}

.GreenWhiteBeforeBg:before,
.GreenWhiteAfterBg:after,
.GreenWhiteBg {
    background-color: #e8ebe0
}

.GreenWhiteColor {
    color: #e8ebe0
}

.GreenYellowBeforeBg:before,
.GreenYellowAfterBg:after,
.GreenYellowBg {
    background-color: #adff2f
}

.GreenYellowColor {
    color: #adff2f
}

.GrenadierBeforeBg:before,
.GrenadierAfterBg:after,
.GrenadierBg {
    background-color: #d54600
}

.GrenadierColor {
    color: #d54600
}

.GrizzlyBeforeBg:before,
.GrizzlyAfterBg:after,
.GrizzlyBg {
    background-color: #885818
}

.GrizzlyColor {
    color: #885818
}

.GrulloBeforeBg:before,
.GrulloAfterBg:after,
.GrulloBg {
    background-color: #a99a86
}

.GrulloColor {
    color: #a99a86
}

.GuardsmanRedBeforeBg:before,
.GuardsmanRedAfterBg:after,
.GuardsmanRedBg {
    background-color: #ba0101
}

.GuardsmanRedColor {
    color: #ba0101
}

.GulfBlueBeforeBg:before,
.GulfBlueAfterBg:after,
.GulfBlueBg {
    background-color: #051657
}

.GulfBlueColor {
    color: #051657
}

.GulfStreamBeforeBg:before,
.GulfStreamAfterBg:after,
.GulfStreamBg {
    background-color: #80b3ae
}

.GulfStreamColor {
    color: #80b3ae
}

.GullGrayBeforeBg:before,
.GullGrayAfterBg:after,
.GullGrayBg {
    background-color: #9dacb7
}

.GullGrayColor {
    color: #9dacb7
}

.GumLeafBeforeBg:before,
.GumLeafAfterBg:after,
.GumLeafBg {
    background-color: #b6d3bf
}

.GumLeafColor {
    color: #b6d3bf
}

.GumboBeforeBg:before,
.GumboAfterBg:after,
.GumboBg {
    background-color: #7ca1a6
}

.GumboColor {
    color: #7ca1a6
}

.GunPowderBeforeBg:before,
.GunPowderAfterBg:after,
.GunPowderBg {
    background-color: #414257
}

.GunPowderColor {
    color: #414257
}

.GunmetalBeforeBg:before,
.GunmetalAfterBg:after,
.GunmetalBg {
    background-color: #2a3439
}

.GunmetalColor {
    color: #2a3439
}

.GunsmokeBeforeBg:before,
.GunsmokeAfterBg:after,
.GunsmokeBg {
    background-color: #828685
}

.GunsmokeColor {
    color: #828685
}

.GurkhaBeforeBg:before,
.GurkhaAfterBg:after,
.GurkhaBg {
    background-color: #9a9577
}

.GurkhaColor {
    color: #9a9577
}

.HaciendaBeforeBg:before,
.HaciendaAfterBg:after,
.HaciendaBg {
    background-color: #98811b
}

.HaciendaColor {
    color: #98811b
}

.HairyHeathBeforeBg:before,
.HairyHeathAfterBg:after,
.HairyHeathBg {
    background-color: #6b2a14
}

.HairyHeathColor {
    color: #6b2a14
}

.HaitiBeforeBg:before,
.HaitiAfterBg:after,
.HaitiBg {
    background-color: #1b1035
}

.HaitiColor {
    color: #1b1035
}

.HalayaUbeBeforeBg:before,
.HalayaUbeAfterBg:after,
.HalayaUbeBg {
    background-color: #663854
}

.HalayaUbeColor {
    color: #663854
}

.HalfBakedBeforeBg:before,
.HalfBakedAfterBg:after,
.HalfBakedBg {
    background-color: #85c4cc
}

.HalfBakedColor {
    color: #85c4cc
}

.HalfColonialWhiteBeforeBg:before,
.HalfColonialWhiteAfterBg:after,
.HalfColonialWhiteBg {
    background-color: #fdf6d3
}

.HalfColonialWhiteColor {
    color: #fdf6d3
}

.HalfDutchWhiteBeforeBg:before,
.HalfDutchWhiteAfterBg:after,
.HalfDutchWhiteBg {
    background-color: #fef7de
}

.HalfDutchWhiteColor {
    color: #fef7de
}

.HalfSpanishWhiteBeforeBg:before,
.HalfSpanishWhiteAfterBg:after,
.HalfSpanishWhiteBg {
    background-color: #fef4db
}

.HalfSpanishWhiteColor {
    color: #fef4db
}

.HalfandHalfBeforeBg:before,
.HalfandHalfAfterBg:after,
.HalfandHalfBg {
    background-color: #fffee1
}

.HalfandHalfColor {
    color: #fffee1
}

.HamptonBeforeBg:before,
.HamptonAfterBg:after,
.HamptonBg {
    background-color: #e5d8af
}

.HamptonColor {
    color: #e5d8af
}

.HanBlueBeforeBg:before,
.HanBlueAfterBg:after,
.HanBlueBg {
    background-color: #446ccf
}

.HanBlueColor {
    color: #446ccf
}

.HanPurpleBeforeBg:before,
.HanPurpleAfterBg:after,
.HanPurpleBg {
    background-color: #5218fa
}

.HanPurpleColor {
    color: #5218fa
}

.HarlequinBeforeBg:before,
.HarlequinAfterBg:after,
.HarlequinBg {
    background-color: #3fff00
}

.HarlequinColor {
    color: #3fff00
}

.HarlequinGreenBeforeBg:before,
.HarlequinGreenAfterBg:after,
.HarlequinGreenBg {
    background-color: #46cb18
}

.HarlequinGreenColor {
    color: #46cb18
}

.HarpBeforeBg:before,
.HarpAfterBg:after,
.HarpBg {
    background-color: #e6f2ea
}

.HarpColor {
    color: #e6f2ea
}

.HarvardCrimsonBeforeBg:before,
.HarvardCrimsonAfterBg:after,
.HarvardCrimsonBg {
    background-color: #c90016
}

.HarvardCrimsonColor {
    color: #c90016
}

.HarvestGoldBeforeBg:before,
.HarvestGoldAfterBg:after,
.HarvestGoldBg {
    background-color: #da9100
}

.HarvestGoldColor {
    color: #da9100
}

.HavelockBlueBeforeBg:before,
.HavelockBlueAfterBg:after,
.HavelockBlueBg {
    background-color: #5590d9
}

.HavelockBlueColor {
    color: #5590d9
}

.HawaiianTanBeforeBg:before,
.HawaiianTanAfterBg:after,
.HawaiianTanBg {
    background-color: #9d5616
}

.HawaiianTanColor {
    color: #9d5616
}

.HawkesBlueBeforeBg:before,
.HawkesBlueAfterBg:after,
.HawkesBlueBg {
    background-color: #d4e2fc
}

.HawkesBlueColor {
    color: #d4e2fc
}

.HeatWaveBeforeBg:before,
.HeatWaveAfterBg:after,
.HeatWaveBg {
    background-color: #ff7a00
}

.HeatWaveColor {
    color: #ff7a00
}

.HeathBeforeBg:before,
.HeathAfterBg:after,
.HeathBg {
    background-color: #541012
}

.HeathColor {
    color: #541012
}

.HeatherBeforeBg:before,
.HeatherAfterBg:after,
.HeatherBg {
    background-color: #b7c3d0
}

.HeatherColor {
    color: #b7c3d0
}

.HeatheredGrayBeforeBg:before,
.HeatheredGrayAfterBg:after,
.HeatheredGrayBg {
    background-color: #b6b095
}

.HeatheredGrayColor {
    color: #b6b095
}

.HeavyMetalBeforeBg:before,
.HeavyMetalAfterBg:after,
.HeavyMetalBg {
    background-color: #2b3228
}

.HeavyMetalColor {
    color: #2b3228
}

.HeliotropeBeforeBg:before,
.HeliotropeAfterBg:after,
.HeliotropeBg {
    background-color: #df73ff
}

.HeliotropeColor {
    color: #df73ff
}

.HeliotropeGrayBeforeBg:before,
.HeliotropeGrayAfterBg:after,
.HeliotropeGrayBg {
    background-color: #aa98a9
}

.HeliotropeGrayColor {
    color: #aa98a9
}

.HeliotropeMagentaBeforeBg:before,
.HeliotropeMagentaAfterBg:after,
.HeliotropeMagentaBg {
    background-color: #aa00bb
}

.HeliotropeMagentaColor {
    color: #aa00bb
}

.HemlockBeforeBg:before,
.HemlockAfterBg:after,
.HemlockBg {
    background-color: #5e5d3b
}

.HemlockColor {
    color: #5e5d3b
}

.HempBeforeBg:before,
.HempAfterBg:after,
.HempBg {
    background-color: #907874
}

.HempColor {
    color: #907874
}

.HibiscusBeforeBg:before,
.HibiscusAfterBg:after,
.HibiscusBg {
    background-color: #b6316c
}

.HibiscusColor {
    color: #b6316c
}

.HighlandBeforeBg:before,
.HighlandAfterBg:after,
.HighlandBg {
    background-color: #6f8e63
}

.HighlandColor {
    color: #6f8e63
}

.HillaryBeforeBg:before,
.HillaryAfterBg:after,
.HillaryBg {
    background-color: #aca586
}

.HillaryColor {
    color: #aca586
}

.HimalayaBeforeBg:before,
.HimalayaAfterBg:after,
.HimalayaBg {
    background-color: #6a5d1b
}

.HimalayaColor {
    color: #6a5d1b
}

.HintofGreenBeforeBg:before,
.HintofGreenAfterBg:after,
.HintofGreenBg {
    background-color: #e6ffe9
}

.HintofGreenColor {
    color: #e6ffe9
}

.HintofRedBeforeBg:before,
.HintofRedAfterBg:after,
.HintofRedBg {
    background-color: #fbf9f9
}

.HintofRedColor {
    color: #fbf9f9
}

.HintofYellowBeforeBg:before,
.HintofYellowAfterBg:after,
.HintofYellowBg {
    background-color: #fafde4
}

.HintofYellowColor {
    color: #fafde4
}

.HippieBlueBeforeBg:before,
.HippieBlueAfterBg:after,
.HippieBlueBg {
    background-color: #589aaf
}

.HippieBlueColor {
    color: #589aaf
}

.HippieGreenBeforeBg:before,
.HippieGreenAfterBg:after,
.HippieGreenBg {
    background-color: #53824b
}

.HippieGreenColor {
    color: #53824b
}

.HippiePinkBeforeBg:before,
.HippiePinkAfterBg:after,
.HippiePinkBg {
    background-color: #ae4560
}

.HippiePinkColor {
    color: #ae4560
}

.HitGrayBeforeBg:before,
.HitGrayAfterBg:after,
.HitGrayBg {
    background-color: #a1adb5
}

.HitGrayColor {
    color: #a1adb5
}

.HitPinkBeforeBg:before,
.HitPinkAfterBg:after,
.HitPinkBg {
    background-color: #ffab81
}

.HitPinkColor {
    color: #ffab81
}

.HokeyPokeyBeforeBg:before,
.HokeyPokeyAfterBg:after,
.HokeyPokeyBg {
    background-color: #c8a528
}

.HokeyPokeyColor {
    color: #c8a528
}

.HokiBeforeBg:before,
.HokiAfterBg:after,
.HokiBg {
    background-color: #65869f
}

.HokiColor {
    color: #65869f
}

.HollyBeforeBg:before,
.HollyAfterBg:after,
.HollyBg {
    background-color: #011d13
}

.HollyColor {
    color: #011d13
}

.HoneyFlowerBeforeBg:before,
.HoneyFlowerAfterBg:after,
.HoneyFlowerBg {
    background-color: #4f1c70
}

.HoneyFlowerColor {
    color: #4f1c70
}

.HoneydewBeforeBg:before,
.HoneydewAfterBg:after,
.HoneydewBg {
    background-color: #f0fff0
}

.HoneydewColor {
    color: #f0fff0
}

.HoneysuckleBeforeBg:before,
.HoneysuckleAfterBg:after,
.HoneysuckleBg {
    background-color: #edfc84
}

.HoneysuckleColor {
    color: #edfc84
}

.HonoluluBlueBeforeBg:before,
.HonoluluBlueAfterBg:after,
.HonoluluBlueBg {
    background-color: #006db0
}

.HonoluluBlueColor {
    color: #006db0
}

.HookersGreenBeforeBg:before,
.HookersGreenAfterBg:after,
.HookersGreenBg {
    background-color: #49796b
}

.HookersGreenColor {
    color: #49796b
}

.HopbushBeforeBg:before,
.HopbushAfterBg:after,
.HopbushBg {
    background-color: #d06da1
}

.HopbushColor {
    color: #d06da1
}

.HorizonBeforeBg:before,
.HorizonAfterBg:after,
.HorizonBg {
    background-color: #5a87a0
}

.HorizonColor {
    color: #5a87a0
}

.HorsesBeforeBg:before,
.HorsesAfterBg:after,
.HorsesBg {
    background-color: #543d37
}

.HorsesColor {
    color: #543d37
}

.HorsesNeckBeforeBg:before,
.HorsesNeckAfterBg:after,
.HorsesNeckBg {
    background-color: #604913
}

.HorsesNeckColor {
    color: #604913
}

.HotMagentaBeforeBg:before,
.HotMagentaAfterBg:after,
.HotMagentaBg {
    background-color: #ff1dce
}

.HotMagentaColor {
    color: #ff1dce
}

.HotPinkBeforeBg:before,
.HotPinkAfterBg:after,
.HotPinkBg {
    background-color: #ff69b4
}

.HotPinkColor {
    color: #ff69b4
}

.HotToddyBeforeBg:before,
.HotToddyAfterBg:after,
.HotToddyBg {
    background-color: #b38007
}

.HotToddyColor {
    color: #b38007
}

.HummingBirdBeforeBg:before,
.HummingBirdAfterBg:after,
.HummingBirdBg {
    background-color: #cff9f3
}

.HummingBirdColor {
    color: #cff9f3
}

.HunterGreenBeforeBg:before,
.HunterGreenAfterBg:after,
.HunterGreenBg {
    background-color: #355e3b
}

.HunterGreenColor {
    color: #355e3b
}

.HurricaneBeforeBg:before,
.HurricaneAfterBg:after,
.HurricaneBg {
    background-color: #877c7b
}

.HurricaneColor {
    color: #877c7b
}

.HuskBeforeBg:before,
.HuskAfterBg:after,
.HuskBg {
    background-color: #b7a458
}

.HuskColor {
    color: #b7a458
}

.IceColdBeforeBg:before,
.IceColdAfterBg:after,
.IceColdBg {
    background-color: #b1f4e7
}

.IceColdColor {
    color: #b1f4e7
}

.IcebergBeforeBg:before,
.IcebergAfterBg:after,
.IcebergBg {
    background-color: #71a6d2
}

.IcebergColor {
    color: #71a6d2
}

.IcterineBeforeBg:before,
.IcterineAfterBg:after,
.IcterineBg {
    background-color: #fcf75e
}

.IcterineColor {
    color: #fcf75e
}

.IlluminatingEmeraldBeforeBg:before,
.IlluminatingEmeraldAfterBg:after,
.IlluminatingEmeraldBg {
    background-color: #319177
}

.IlluminatingEmeraldColor {
    color: #319177
}

.IllusionBeforeBg:before,
.IllusionAfterBg:after,
.IllusionBg {
    background-color: #f6a4c9
}

.IllusionColor {
    color: #f6a4c9
}

.ImperialBeforeBg:before,
.ImperialAfterBg:after,
.ImperialBg {
    background-color: #602f6b
}

.ImperialColor {
    color: #602f6b
}

.ImperialBlueBeforeBg:before,
.ImperialBlueAfterBg:after,
.ImperialBlueBg {
    background-color: #002395
}

.ImperialBlueColor {
    color: #002395
}

.ImperialRedBeforeBg:before,
.ImperialRedAfterBg:after,
.ImperialRedBg {
    background-color: #ed2939
}

.ImperialRedColor {
    color: #ed2939
}

.InchWormBeforeBg:before,
.InchWormAfterBg:after,
.InchWormBg {
    background-color: #b0e313
}

.InchWormColor {
    color: #b0e313
}

.InchwormBeforeBg:before,
.InchwormAfterBg:after,
.InchwormBg {
    background-color: #b2ec5d
}

.InchwormColor {
    color: #b2ec5d
}

.IndependenceBeforeBg:before,
.IndependenceAfterBg:after,
.IndependenceBg {
    background-color: #4c516d
}

.IndependenceColor {
    color: #4c516d
}

.IndiaGreenBeforeBg:before,
.IndiaGreenAfterBg:after,
.IndiaGreenBg {
    background-color: #138808
}

.IndiaGreenColor {
    color: #138808
}

.IndianRedBeforeBg:before,
.IndianRedAfterBg:after,
.IndianRedBg {
    background-color: #cd5c5c
}

.IndianRedColor {
    color: #cd5c5c
}

.IndianTanBeforeBg:before,
.IndianTanAfterBg:after,
.IndianTanBg {
    background-color: #4d1e01
}

.IndianTanColor {
    color: #4d1e01
}

.IndianYellowBeforeBg:before,
.IndianYellowAfterBg:after,
.IndianYellowBg {
    background-color: #e3a857
}

.IndianYellowColor {
    color: #e3a857
}

.IndigoBeforeBg:before,
.IndigoAfterBg:after,
.IndigoBg {
    background-color: #4b0082
}

.IndigoColor {
    color: #4b0082
}

.IndigoDyeBeforeBg:before,
.IndigoDyeAfterBg:after,
.IndigoDyeBg {
    background-color: #091f92
}

.IndigoDyeColor {
    color: #091f92
}

.IndochineBeforeBg:before,
.IndochineAfterBg:after,
.IndochineBg {
    background-color: #c26b03
}

.IndochineColor {
    color: #c26b03
}

.InternationalKleinBlueBeforeBg:before,
.InternationalKleinBlueAfterBg:after,
.InternationalKleinBlueBg {
    background-color: #002fa7
}

.InternationalKleinBlueColor {
    color: #002fa7
}

.InternationalOrangeBeforeBg:before,
.InternationalOrangeAfterBg:after,
.InternationalOrangeBg {
    background-color: #ff4f00
}

.InternationalOrangeColor {
    color: #ff4f00
}

.IrisBeforeBg:before,
.IrisAfterBg:after,
.IrisBg {
    background-color: #5a4fcf
}

.IrisColor {
    color: #5a4fcf
}

.IrishCoffeeBeforeBg:before,
.IrishCoffeeAfterBg:after,
.IrishCoffeeBg {
    background-color: #5f3d26
}

.IrishCoffeeColor {
    color: #5f3d26
}

.IrokoBeforeBg:before,
.IrokoAfterBg:after,
.IrokoBg {
    background-color: #433120
}

.IrokoColor {
    color: #433120
}

.IronBeforeBg:before,
.IronAfterBg:after,
.IronBg {
    background-color: #d4d7d9
}

.IronColor {
    color: #d4d7d9
}

.IronsideGrayBeforeBg:before,
.IronsideGrayAfterBg:after,
.IronsideGrayBg {
    background-color: #676662
}

.IronsideGrayColor {
    color: #676662
}

.IronstoneBeforeBg:before,
.IronstoneAfterBg:after,
.IronstoneBg {
    background-color: #86483c
}

.IronstoneColor {
    color: #86483c
}

.IrresistibleBeforeBg:before,
.IrresistibleAfterBg:after,
.IrresistibleBg {
    background-color: #b3446c
}

.IrresistibleColor {
    color: #b3446c
}

.IsabellineBeforeBg:before,
.IsabellineAfterBg:after,
.IsabellineBg {
    background-color: #f4f0ec
}

.IsabellineColor {
    color: #f4f0ec
}

.IslamicGreenBeforeBg:before,
.IslamicGreenAfterBg:after,
.IslamicGreenBg {
    background-color: #009000
}

.IslamicGreenColor {
    color: #009000
}

.IslandSpiceBeforeBg:before,
.IslandSpiceAfterBg:after,
.IslandSpiceBg {
    background-color: #fffcee
}

.IslandSpiceColor {
    color: #fffcee
}

.IvoryBeforeBg:before,
.IvoryAfterBg:after,
.IvoryBg {
    background-color: #fffff0
}

.IvoryColor {
    color: #fffff0
}

.JacarandaBeforeBg:before,
.JacarandaAfterBg:after,
.JacarandaBg {
    background-color: #2e0329
}

.JacarandaColor {
    color: #2e0329
}

.JacartaBeforeBg:before,
.JacartaAfterBg:after,
.JacartaBg {
    background-color: #3a2a6a
}

.JacartaColor {
    color: #3a2a6a
}

.JackoBeanBeforeBg:before,
.JackoBeanAfterBg:after,
.JackoBeanBg {
    background-color: #2e1905
}

.JackoBeanColor {
    color: #2e1905
}

.JacksonsPurpleBeforeBg:before,
.JacksonsPurpleAfterBg:after,
.JacksonsPurpleBg {
    background-color: #20208d
}

.JacksonsPurpleColor {
    color: #20208d
}

.JadeBeforeBg:before,
.JadeAfterBg:after,
.JadeBg {
    background-color: #00a86b
}

.JadeColor {
    color: #00a86b
}

.JaffaBeforeBg:before,
.JaffaAfterBg:after,
.JaffaBg {
    background-color: #ef863f
}

.JaffaColor {
    color: #ef863f
}

.JaggedIceBeforeBg:before,
.JaggedIceAfterBg:after,
.JaggedIceBg {
    background-color: #c2e8e5
}

.JaggedIceColor {
    color: #c2e8e5
}

.JaggerBeforeBg:before,
.JaggerAfterBg:after,
.JaggerBg {
    background-color: #350e57
}

.JaggerColor {
    color: #350e57
}

.JaguarBeforeBg:before,
.JaguarAfterBg:after,
.JaguarBg {
    background-color: #080110
}

.JaguarColor {
    color: #080110
}

.JambalayaBeforeBg:before,
.JambalayaAfterBg:after,
.JambalayaBg {
    background-color: #5b3013
}

.JambalayaColor {
    color: #5b3013
}

.JannaBeforeBg:before,
.JannaAfterBg:after,
.JannaBg {
    background-color: #f4ebd3
}

.JannaColor {
    color: #f4ebd3
}

.JapaneseCarmineBeforeBg:before,
.JapaneseCarmineAfterBg:after,
.JapaneseCarmineBg {
    background-color: #9d2933
}

.JapaneseCarmineColor {
    color: #9d2933
}

.JapaneseIndigoBeforeBg:before,
.JapaneseIndigoAfterBg:after,
.JapaneseIndigoBg {
    background-color: #264348
}

.JapaneseIndigoColor {
    color: #264348
}

.JapaneseLaurelBeforeBg:before,
.JapaneseLaurelAfterBg:after,
.JapaneseLaurelBg {
    background-color: #0a6906
}

.JapaneseLaurelColor {
    color: #0a6906
}

.JapaneseMapleBeforeBg:before,
.JapaneseMapleAfterBg:after,
.JapaneseMapleBg {
    background-color: #780109
}

.JapaneseMapleColor {
    color: #780109
}

.JapaneseVioletBeforeBg:before,
.JapaneseVioletAfterBg:after,
.JapaneseVioletBg {
    background-color: #5b3256
}

.JapaneseVioletColor {
    color: #5b3256
}

.JaponicaBeforeBg:before,
.JaponicaAfterBg:after,
.JaponicaBg {
    background-color: #d87c63
}

.JaponicaColor {
    color: #d87c63
}

.JasmineBeforeBg:before,
.JasmineAfterBg:after,
.JasmineBg {
    background-color: #f8de7e
}

.JasmineColor {
    color: #f8de7e
}

.JasperBeforeBg:before,
.JasperAfterBg:after,
.JasperBg {
    background-color: #d73b3e
}

.JasperColor {
    color: #d73b3e
}

.JavaBeforeBg:before,
.JavaAfterBg:after,
.JavaBg {
    background-color: #1fc2c2
}

.JavaColor {
    color: #1fc2c2
}

.JazzberryJamBeforeBg:before,
.JazzberryJamAfterBg:after,
.JazzberryJamBg {
    background-color: #a50b5e
}

.JazzberryJamColor {
    color: #a50b5e
}

.JellyBeanBeforeBg:before,
.JellyBeanAfterBg:after,
.JellyBeanBg {
    background-color: #da614e
}

.JellyBeanColor {
    color: #da614e
}

.JetBeforeBg:before,
.JetAfterBg:after,
.JetBg {
    background-color: #343434
}

.JetColor {
    color: #343434
}

.JetStreamBeforeBg:before,
.JetStreamAfterBg:after,
.JetStreamBg {
    background-color: #b5d2ce
}

.JetStreamColor {
    color: #b5d2ce
}

.JewelBeforeBg:before,
.JewelAfterBg:after,
.JewelBg {
    background-color: #126b40
}

.JewelColor {
    color: #126b40
}

.JonBeforeBg:before,
.JonAfterBg:after,
.JonBg {
    background-color: #3b1f1f
}

.JonColor {
    color: #3b1f1f
}

.JonquilBeforeBg:before,
.JonquilAfterBg:after,
.JonquilBg {
    background-color: #f4ca16
}

.JonquilColor {
    color: #f4ca16
}

.JordyBlueBeforeBg:before,
.JordyBlueAfterBg:after,
.JordyBlueBg {
    background-color: #8ab9f1
}

.JordyBlueColor {
    color: #8ab9f1
}

.JudgeGrayBeforeBg:before,
.JudgeGrayAfterBg:after,
.JudgeGrayBg {
    background-color: #544333
}

.JudgeGrayColor {
    color: #544333
}

.JumboBeforeBg:before,
.JumboAfterBg:after,
.JumboBg {
    background-color: #7c7b82
}

.JumboColor {
    color: #7c7b82
}

.JuneBudBeforeBg:before,
.JuneBudAfterBg:after,
.JuneBudBg {
    background-color: #bdda57
}

.JuneBudColor {
    color: #bdda57
}

.JungleGreenBeforeBg:before,
.JungleGreenAfterBg:after,
.JungleGreenBg {
    background-color: #29ab87
}

.JungleGreenColor {
    color: #29ab87
}

.JungleMistBeforeBg:before,
.JungleMistAfterBg:after,
.JungleMistBg {
    background-color: #b4cfd3
}

.JungleMistColor {
    color: #b4cfd3
}

.JuniperBeforeBg:before,
.JuniperAfterBg:after,
.JuniperBg {
    background-color: #6d9292
}

.JuniperColor {
    color: #6d9292
}

.JustRightBeforeBg:before,
.JustRightAfterBg:after,
.JustRightBg {
    background-color: #eccdb9
}

.JustRightColor {
    color: #eccdb9
}

.KUCrimsonBeforeBg:before,
.KUCrimsonAfterBg:after,
.KUCrimsonBg {
    background-color: #e8000d
}

.KUCrimsonColor {
    color: #e8000d
}

.KabulBeforeBg:before,
.KabulAfterBg:after,
.KabulBg {
    background-color: #5e483e
}

.KabulColor {
    color: #5e483e
}

.KaitokeGreenBeforeBg:before,
.KaitokeGreenAfterBg:after,
.KaitokeGreenBg {
    background-color: #004620
}

.KaitokeGreenColor {
    color: #004620
}

.KangarooBeforeBg:before,
.KangarooAfterBg:after,
.KangarooBg {
    background-color: #c6c8bd
}

.KangarooColor {
    color: #c6c8bd
}

.KarakaBeforeBg:before,
.KarakaAfterBg:after,
.KarakaBg {
    background-color: #1e1609
}

.KarakaColor {
    color: #1e1609
}

.KarryBeforeBg:before,
.KarryAfterBg:after,
.KarryBg {
    background-color: #ffead4
}

.KarryColor {
    color: #ffead4
}

.KashmirBlueBeforeBg:before,
.KashmirBlueAfterBg:after,
.KashmirBlueBg {
    background-color: #507096
}

.KashmirBlueColor {
    color: #507096
}

.KellyGreenBeforeBg:before,
.KellyGreenAfterBg:after,
.KellyGreenBg {
    background-color: #4cbb17
}

.KellyGreenColor {
    color: #4cbb17
}

.KelpBeforeBg:before,
.KelpAfterBg:after,
.KelpBg {
    background-color: #454936
}

.KelpColor {
    color: #454936
}

.KenyanCopperBeforeBg:before,
.KenyanCopperAfterBg:after,
.KenyanCopperBg {
    background-color: #7c1c05
}

.KenyanCopperColor {
    color: #7c1c05
}

.KeppelBeforeBg:before,
.KeppelAfterBg:after,
.KeppelBg {
    background-color: #3ab09e
}

.KeppelColor {
    color: #3ab09e
}

.KeyLimeBeforeBg:before,
.KeyLimeAfterBg:after,
.KeyLimeBg {
    background-color: #e8f48c
}

.KeyLimeColor {
    color: #e8f48c
}

.KeyLimePieBeforeBg:before,
.KeyLimePieAfterBg:after,
.KeyLimePieBg {
    background-color: #bfc921
}

.KeyLimePieColor {
    color: #bfc921
}

.KhakiBeforeBg:before,
.KhakiAfterBg:after,
.KhakiBg {
    background-color: #c3b091
}

.KhakiColor {
    color: #c3b091
}

.KidnapperBeforeBg:before,
.KidnapperAfterBg:after,
.KidnapperBg {
    background-color: #e1ead4
}

.KidnapperColor {
    color: #e1ead4
}

.KilamanjaroBeforeBg:before,
.KilamanjaroAfterBg:after,
.KilamanjaroBg {
    background-color: #240c02
}

.KilamanjaroColor {
    color: #240c02
}

.KillarneyBeforeBg:before,
.KillarneyAfterBg:after,
.KillarneyBg {
    background-color: #3a6a47
}

.KillarneyColor {
    color: #3a6a47
}

.KimberlyBeforeBg:before,
.KimberlyAfterBg:after,
.KimberlyBg {
    background-color: #736c9f
}

.KimberlyColor {
    color: #736c9f
}

.KingfisherDaisyBeforeBg:before,
.KingfisherDaisyAfterBg:after,
.KingfisherDaisyBg {
    background-color: #3e0480
}

.KingfisherDaisyColor {
    color: #3e0480
}

.KobiBeforeBg:before,
.KobiAfterBg:after,
.KobiBg {
    background-color: #e79fc4
}

.KobiColor {
    color: #e79fc4
}

.KobichaBeforeBg:before,
.KobichaAfterBg:after,
.KobichaBg {
    background-color: #6b4423
}

.KobichaColor {
    color: #6b4423
}

.KokodaBeforeBg:before,
.KokodaAfterBg:after,
.KokodaBg {
    background-color: #6e6d57
}

.KokodaColor {
    color: #6e6d57
}

.KombuGreenBeforeBg:before,
.KombuGreenAfterBg:after,
.KombuGreenBg {
    background-color: #354230
}

.KombuGreenColor {
    color: #354230
}

.KormaBeforeBg:before,
.KormaAfterBg:after,
.KormaBg {
    background-color: #8f4b0e
}

.KormaColor {
    color: #8f4b0e
}

.KoromikoBeforeBg:before,
.KoromikoAfterBg:after,
.KoromikoBg {
    background-color: #ffbd5f
}

.KoromikoColor {
    color: #ffbd5f
}

.KournikovaBeforeBg:before,
.KournikovaAfterBg:after,
.KournikovaBg {
    background-color: #ffe772
}

.KournikovaColor {
    color: #ffe772
}

.KumeraBeforeBg:before,
.KumeraAfterBg:after,
.KumeraBg {
    background-color: #886221
}

.KumeraColor {
    color: #886221
}

.LaPalmaBeforeBg:before,
.LaPalmaAfterBg:after,
.LaPalmaBg {
    background-color: #368716
}

.LaPalmaColor {
    color: #368716
}

.LaRiojaBeforeBg:before,
.LaRiojaAfterBg:after,
.LaRiojaBg {
    background-color: #b3c110
}

.LaRiojaColor {
    color: #b3c110
}

.LaSalleGreenBeforeBg:before,
.LaSalleGreenAfterBg:after,
.LaSalleGreenBg {
    background-color: #087830
}

.LaSalleGreenColor {
    color: #087830
}

.LanguidLavenderBeforeBg:before,
.LanguidLavenderAfterBg:after,
.LanguidLavenderBg {
    background-color: #d6cadd
}

.LanguidLavenderColor {
    color: #d6cadd
}

.LapisLazuliBeforeBg:before,
.LapisLazuliAfterBg:after,
.LapisLazuliBg {
    background-color: #26619c
}

.LapisLazuliColor {
    color: #26619c
}

.LasPalmasBeforeBg:before,
.LasPalmasAfterBg:after,
.LasPalmasBg {
    background-color: #c6e610
}

.LasPalmasColor {
    color: #c6e610
}

.LaserBeforeBg:before,
.LaserAfterBg:after,
.LaserBg {
    background-color: #c8b568
}

.LaserColor {
    color: #c8b568
}

.LaurelBeforeBg:before,
.LaurelAfterBg:after,
.LaurelBg {
    background-color: #749378
}

.LaurelColor {
    color: #749378
}

.LaurelGreenBeforeBg:before,
.LaurelGreenAfterBg:after,
.LaurelGreenBg {
    background-color: #a9ba9d
}

.LaurelGreenColor {
    color: #a9ba9d
}

.LavaBeforeBg:before,
.LavaAfterBg:after,
.LavaBg {
    background-color: #cf1020
}

.LavaColor {
    color: #cf1020
}

.LavenderBeforeBg:before,
.LavenderAfterBg:after,
.LavenderBg {
    background-color: #b57edc
}

.LavenderColor {
    color: #b57edc
}

.LavenderBlushBeforeBg:before,
.LavenderBlushAfterBg:after,
.LavenderBlushBg {
    background-color: #fff0f5
}

.LavenderBlushColor {
    color: #fff0f5
}

.LavenderGrayBeforeBg:before,
.LavenderGrayAfterBg:after,
.LavenderGrayBg {
    background-color: #c4c3d0
}

.LavenderGrayColor {
    color: #c4c3d0
}

.LavenderIndigoBeforeBg:before,
.LavenderIndigoAfterBg:after,
.LavenderIndigoBg {
    background-color: #9457eb
}

.LavenderIndigoColor {
    color: #9457eb
}

.LavenderMagentaBeforeBg:before,
.LavenderMagentaAfterBg:after,
.LavenderMagentaBg {
    background-color: #ee82ee
}

.LavenderMagentaColor {
    color: #ee82ee
}

.LavenderMistBeforeBg:before,
.LavenderMistAfterBg:after,
.LavenderMistBg {
    background-color: #e6e6fa
}

.LavenderMistColor {
    color: #e6e6fa
}

.LavenderPinkBeforeBg:before,
.LavenderPinkAfterBg:after,
.LavenderPinkBg {
    background-color: #fbaed2
}

.LavenderPinkColor {
    color: #fbaed2
}

.LavenderPurpleBeforeBg:before,
.LavenderPurpleAfterBg:after,
.LavenderPurpleBg {
    background-color: #967bb6
}

.LavenderPurpleColor {
    color: #967bb6
}

.LavenderRoseBeforeBg:before,
.LavenderRoseAfterBg:after,
.LavenderRoseBg {
    background-color: #fba0e3
}

.LavenderRoseColor {
    color: #fba0e3
}

.LawnGreenBeforeBg:before,
.LawnGreenAfterBg:after,
.LawnGreenBg {
    background-color: #7cfc00
}

.LawnGreenColor {
    color: #7cfc00
}

.LeatherBeforeBg:before,
.LeatherAfterBg:after,
.LeatherBg {
    background-color: #967059
}

.LeatherColor {
    color: #967059
}

.LemonBeforeBg:before,
.LemonAfterBg:after,
.LemonBg {
    background-color: #fff700
}

.LemonColor {
    color: #fff700
}

.LemonChiffonBeforeBg:before,
.LemonChiffonAfterBg:after,
.LemonChiffonBg {
    background-color: #fffacd
}

.LemonChiffonColor {
    color: #fffacd
}

.LemonCurryBeforeBg:before,
.LemonCurryAfterBg:after,
.LemonCurryBg {
    background-color: #cca01d
}

.LemonCurryColor {
    color: #cca01d
}

.LemonGingerBeforeBg:before,
.LemonGingerAfterBg:after,
.LemonGingerBg {
    background-color: #ac9e22
}

.LemonGingerColor {
    color: #ac9e22
}

.LemonGlacierBeforeBg:before,
.LemonGlacierAfterBg:after,
.LemonGlacierBg {
    background-color: #fdff00
}

.LemonGlacierColor {
    color: #fdff00
}

.LemonGrassBeforeBg:before,
.LemonGrassAfterBg:after,
.LemonGrassBg {
    background-color: #9b9e8f
}

.LemonGrassColor {
    color: #9b9e8f
}

.LemonLimeBeforeBg:before,
.LemonLimeAfterBg:after,
.LemonLimeBg {
    background-color: #e3ff00
}

.LemonLimeColor {
    color: #e3ff00
}

.LemonMeringueBeforeBg:before,
.LemonMeringueAfterBg:after,
.LemonMeringueBg {
    background-color: #f6eabe
}

.LemonMeringueColor {
    color: #f6eabe
}

.LemonYellowBeforeBg:before,
.LemonYellowAfterBg:after,
.LemonYellowBg {
    background-color: #fff44f
}

.LemonYellowColor {
    color: #fff44f
}

.LenurpleBeforeBg:before,
.LenurpleAfterBg:after,
.LenurpleBg {
    background-color: #ba93d8
}

.LenurpleColor {
    color: #ba93d8
}

.LibertyBeforeBg:before,
.LibertyAfterBg:after,
.LibertyBg {
    background-color: #545aa7
}

.LibertyColor {
    color: #545aa7
}

.LicoriceBeforeBg:before,
.LicoriceAfterBg:after,
.LicoriceBg {
    background-color: #1a1110
}

.LicoriceColor {
    color: #1a1110
}

.LightApricotBeforeBg:before,
.LightApricotAfterBg:after,
.LightApricotBg {
    background-color: #fdd5b1
}

.LightApricotColor {
    color: #fdd5b1
}

.LightBlueBeforeBg:before,
.LightBlueAfterBg:after,
.LightBlueBg {
    background-color: #add8e6
}

.LightBlueColor {
    color: #add8e6
}

.LightBrilliantRedBeforeBg:before,
.LightBrilliantRedAfterBg:after,
.LightBrilliantRedBg {
    background-color: #fe2e2e
}

.LightBrilliantRedColor {
    color: #fe2e2e
}

.LightBrownBeforeBg:before,
.LightBrownAfterBg:after,
.LightBrownBg {
    background-color: #b5651d
}

.LightBrownColor {
    color: #b5651d
}

.LightCarminePinkBeforeBg:before,
.LightCarminePinkAfterBg:after,
.LightCarminePinkBg {
    background-color: #e66771
}

.LightCarminePinkColor {
    color: #e66771
}

.LightCobaltBlueBeforeBg:before,
.LightCobaltBlueAfterBg:after,
.LightCobaltBlueBg {
    background-color: #88ace0
}

.LightCobaltBlueColor {
    color: #88ace0
}

.LightCoralBeforeBg:before,
.LightCoralAfterBg:after,
.LightCoralBg {
    background-color: #f08080
}

.LightCoralColor {
    color: #f08080
}

.LightCornflowerBlueBeforeBg:before,
.LightCornflowerBlueAfterBg:after,
.LightCornflowerBlueBg {
    background-color: #93ccea
}

.LightCornflowerBlueColor {
    color: #93ccea
}

.LightCrimsonBeforeBg:before,
.LightCrimsonAfterBg:after,
.LightCrimsonBg {
    background-color: #f56991
}

.LightCrimsonColor {
    color: #f56991
}

.LightCyanBeforeBg:before,
.LightCyanAfterBg:after,
.LightCyanBg {
    background-color: #e0ffff
}

.LightCyanColor {
    color: #e0ffff
}

.LightDeepPinkBeforeBg:before,
.LightDeepPinkAfterBg:after,
.LightDeepPinkBg {
    background-color: #ff5ccd
}

.LightDeepPinkColor {
    color: #ff5ccd
}

.LightFrenchBeigeBeforeBg:before,
.LightFrenchBeigeAfterBg:after,
.LightFrenchBeigeBg {
    background-color: #c8ad7f
}

.LightFrenchBeigeColor {
    color: #c8ad7f
}

.LightFuchsiaPinkBeforeBg:before,
.LightFuchsiaPinkAfterBg:after,
.LightFuchsiaPinkBg {
    background-color: #f984ef
}

.LightFuchsiaPinkColor {
    color: #f984ef
}

.LightGoldenrodYellowBeforeBg:before,
.LightGoldenrodYellowAfterBg:after,
.LightGoldenrodYellowBg {
    background-color: #fafad2
}

.LightGoldenrodYellowColor {
    color: #fafad2
}

.LightGrayBeforeBg:before,
.LightGrayAfterBg:after,
.LightGrayBg {
    background-color: #d3d3d3
}

.LightGrayColor {
    color: #d3d3d3
}

.LightGrayishMagentaBeforeBg:before,
.LightGrayishMagentaAfterBg:after,
.LightGrayishMagentaBg {
    background-color: #cc99cc
}

.LightGrayishMagentaColor {
    color: #cc99cc
}

.LightGreenBeforeBg:before,
.LightGreenAfterBg:after,
.LightGreenBg {
    background-color: #90ee90
}

.LightGreenColor {
    color: #90ee90
}

.LightHotPinkBeforeBg:before,
.LightHotPinkAfterBg:after,
.LightHotPinkBg {
    background-color: #ffb3de
}

.LightHotPinkColor {
    color: #ffb3de
}

.LightKhakiBeforeBg:before,
.LightKhakiAfterBg:after,
.LightKhakiBg {
    background-color: #f0e68c
}

.LightKhakiColor {
    color: #f0e68c
}

.LightMediumOrchidBeforeBg:before,
.LightMediumOrchidAfterBg:after,
.LightMediumOrchidBg {
    background-color: #d39bcb
}

.LightMediumOrchidColor {
    color: #d39bcb
}

.LightMossGreenBeforeBg:before,
.LightMossGreenAfterBg:after,
.LightMossGreenBg {
    background-color: #addfad
}

.LightMossGreenColor {
    color: #addfad
}

.LightOrchidBeforeBg:before,
.LightOrchidAfterBg:after,
.LightOrchidBg {
    background-color: #e6a8d7
}

.LightOrchidColor {
    color: #e6a8d7
}

.LightPastelPurpleBeforeBg:before,
.LightPastelPurpleAfterBg:after,
.LightPastelPurpleBg {
    background-color: #b19cd9
}

.LightPastelPurpleColor {
    color: #b19cd9
}

.LightPinkBeforeBg:before,
.LightPinkAfterBg:after,
.LightPinkBg {
    background-color: #ffb6c1
}

.LightPinkColor {
    color: #ffb6c1
}

.LightSalmonBeforeBg:before,
.LightSalmonAfterBg:after,
.LightSalmonBg {
    background-color: #ffa07a
}

.LightSalmonColor {
    color: #ffa07a
}

.LightSalmonPinkBeforeBg:before,
.LightSalmonPinkAfterBg:after,
.LightSalmonPinkBg {
    background-color: #ff9999
}

.LightSalmonPinkColor {
    color: #ff9999
}

.LightSeaGreenBeforeBg:before,
.LightSeaGreenAfterBg:after,
.LightSeaGreenBg {
    background-color: #20b2aa
}

.LightSeaGreenColor {
    color: #20b2aa
}

.LightSkyBlueBeforeBg:before,
.LightSkyBlueAfterBg:after,
.LightSkyBlueBg {
    background-color: #87cefa
}

.LightSkyBlueColor {
    color: #87cefa
}

.LightSlateGrayBeforeBg:before,
.LightSlateGrayAfterBg:after,
.LightSlateGrayBg {
    background-color: #778899
}

.LightSlateGrayColor {
    color: #778899
}

.LightSteelBlueBeforeBg:before,
.LightSteelBlueAfterBg:after,
.LightSteelBlueBg {
    background-color: #b0c4de
}

.LightSteelBlueColor {
    color: #b0c4de
}

.LightTaupeBeforeBg:before,
.LightTaupeAfterBg:after,
.LightTaupeBg {
    background-color: #b38b6d
}

.LightTaupeColor {
    color: #b38b6d
}

.LightTurquoiseBeforeBg:before,
.LightTurquoiseAfterBg:after,
.LightTurquoiseBg {
    background-color: #afeeee
}

.LightTurquoiseColor {
    color: #afeeee
}

.LightYellowBeforeBg:before,
.LightYellowAfterBg:after,
.LightYellowBg {
    background-color: #ffffe0
}

.LightYellowColor {
    color: #ffffe0
}

.LightningYellowBeforeBg:before,
.LightningYellowAfterBg:after,
.LightningYellowBg {
    background-color: #fcc01e
}

.LightningYellowColor {
    color: #fcc01e
}

.LilacBeforeBg:before,
.LilacAfterBg:after,
.LilacBg {
    background-color: #c8a2c8
}

.LilacColor {
    color: #c8a2c8
}

.LilacBushBeforeBg:before,
.LilacBushAfterBg:after,
.LilacBushBg {
    background-color: #9874d3
}

.LilacBushColor {
    color: #9874d3
}

.LilacLusterBeforeBg:before,
.LilacLusterAfterBg:after,
.LilacLusterBg {
    background-color: #ae98aa
}

.LilacLusterColor {
    color: #ae98aa
}

.LilyBeforeBg:before,
.LilyAfterBg:after,
.LilyBg {
    background-color: #c8aabf
}

.LilyColor {
    color: #c8aabf
}

.LilyWhiteBeforeBg:before,
.LilyWhiteAfterBg:after,
.LilyWhiteBg {
    background-color: #e7f8ff
}

.LilyWhiteColor {
    color: #e7f8ff
}

.LimaBeforeBg:before,
.LimaAfterBg:after,
.LimaBg {
    background-color: #76bd17
}

.LimaColor {
    color: #76bd17
}

.LimeBeforeBg:before,
.LimeAfterBg:after,
.LimeBg {
    background-color: #bfff00
}

.LimeColor {
    color: #bfff00
}

.LimeGreenBeforeBg:before,
.LimeGreenAfterBg:after,
.LimeGreenBg {
    background-color: #32cd32
}

.LimeGreenColor {
    color: #32cd32
}

.LimeadeBeforeBg:before,
.LimeadeAfterBg:after,
.LimeadeBg {
    background-color: #6f9d02
}

.LimeadeColor {
    color: #6f9d02
}

.LimedAshBeforeBg:before,
.LimedAshAfterBg:after,
.LimedAshBg {
    background-color: #747d63
}

.LimedAshColor {
    color: #747d63
}

.LimedOakBeforeBg:before,
.LimedOakAfterBg:after,
.LimedOakBg {
    background-color: #ac8a56
}

.LimedOakColor {
    color: #ac8a56
}

.LimedSpruceBeforeBg:before,
.LimedSpruceAfterBg:after,
.LimedSpruceBg {
    background-color: #394851
}

.LimedSpruceColor {
    color: #394851
}

.LimerickBeforeBg:before,
.LimerickAfterBg:after,
.LimerickBg {
    background-color: #9dc209
}

.LimerickColor {
    color: #9dc209
}

.LincolnGreenBeforeBg:before,
.LincolnGreenAfterBg:after,
.LincolnGreenBg {
    background-color: #195905
}

.LincolnGreenColor {
    color: #195905
}

.LinenBeforeBg:before,
.LinenAfterBg:after,
.LinenBg {
    background-color: #faf0e6
}

.LinenColor {
    color: #faf0e6
}

.LinkWaterBeforeBg:before,
.LinkWaterAfterBg:after,
.LinkWaterBg {
    background-color: #d9e4f5
}

.LinkWaterColor {
    color: #d9e4f5
}

.LipstickBeforeBg:before,
.LipstickAfterBg:after,
.LipstickBg {
    background-color: #ab0563
}

.LipstickColor {
    color: #ab0563
}

.LisbonBrownBeforeBg:before,
.LisbonBrownAfterBg:after,
.LisbonBrownBg {
    background-color: #423921
}

.LisbonBrownColor {
    color: #423921
}

.LittleBoyBlueBeforeBg:before,
.LittleBoyBlueAfterBg:after,
.LittleBoyBlueBg {
    background-color: #6ca0dc
}

.LittleBoyBlueColor {
    color: #6ca0dc
}

.LiverBeforeBg:before,
.LiverAfterBg:after,
.LiverBg {
    background-color: #674c47
}

.LiverColor {
    color: #674c47
}

.LiverChestnutBeforeBg:before,
.LiverChestnutAfterBg:after,
.LiverChestnutBg {
    background-color: #987456
}

.LiverChestnutColor {
    color: #987456
}

.LividBrownBeforeBg:before,
.LividBrownAfterBg:after,
.LividBrownBg {
    background-color: #4d282e
}

.LividBrownColor {
    color: #4d282e
}

.LoaferBeforeBg:before,
.LoaferAfterBg:after,
.LoaferBg {
    background-color: #eef4de
}

.LoaferColor {
    color: #eef4de
}

.LoblollyBeforeBg:before,
.LoblollyAfterBg:after,
.LoblollyBg {
    background-color: #bdc9ce
}

.LoblollyColor {
    color: #bdc9ce
}

.LochinvarBeforeBg:before,
.LochinvarAfterBg:after,
.LochinvarBg {
    background-color: #2c8c84
}

.LochinvarColor {
    color: #2c8c84
}

.LochmaraBeforeBg:before,
.LochmaraAfterBg:after,
.LochmaraBg {
    background-color: #007ec7
}

.LochmaraColor {
    color: #007ec7
}

.LocustBeforeBg:before,
.LocustAfterBg:after,
.LocustBg {
    background-color: #a8af8e
}

.LocustColor {
    color: #a8af8e
}

.LogCabinBeforeBg:before,
.LogCabinAfterBg:after,
.LogCabinBg {
    background-color: #242a1d
}

.LogCabinColor {
    color: #242a1d
}

.LoganBeforeBg:before,
.LoganAfterBg:after,
.LoganBg {
    background-color: #aaa9cd
}

.LoganColor {
    color: #aaa9cd
}

.LolaBeforeBg:before,
.LolaAfterBg:after,
.LolaBg {
    background-color: #dfcfdb
}

.LolaColor {
    color: #dfcfdb
}

.LondonHueBeforeBg:before,
.LondonHueAfterBg:after,
.LondonHueBg {
    background-color: #bea6c3
}

.LondonHueColor {
    color: #bea6c3
}

.LonestarBeforeBg:before,
.LonestarAfterBg:after,
.LonestarBg {
    background-color: #6d0101
}

.LonestarColor {
    color: #6d0101
}

.LotusBeforeBg:before,
.LotusAfterBg:after,
.LotusBg {
    background-color: #863c3c
}

.LotusColor {
    color: #863c3c
}

.LoulouBeforeBg:before,
.LoulouAfterBg:after,
.LoulouBg {
    background-color: #460b41
}

.LoulouColor {
    color: #460b41
}

.LuckyBeforeBg:before,
.LuckyAfterBg:after,
.LuckyBg {
    background-color: #af9f1c
}

.LuckyColor {
    color: #af9f1c
}

.LuckyPointBeforeBg:before,
.LuckyPointAfterBg:after,
.LuckyPointBg {
    background-color: #1a1a68
}

.LuckyPointColor {
    color: #1a1a68
}

.LumberBeforeBg:before,
.LumberAfterBg:after,
.LumberBg {
    background-color: #ffe4cd
}

.LumberColor {
    color: #ffe4cd
}

.LunarGreenBeforeBg:before,
.LunarGreenAfterBg:after,
.LunarGreenBg {
    background-color: #3c493a
}

.LunarGreenColor {
    color: #3c493a
}

.LustBeforeBg:before,
.LustAfterBg:after,
.LustBg {
    background-color: #e62020
}

.LustColor {
    color: #e62020
}

.LuxorGoldBeforeBg:before,
.LuxorGoldAfterBg:after,
.LuxorGoldBg {
    background-color: #a7882c
}

.LuxorGoldColor {
    color: #a7882c
}

.LynchBeforeBg:before,
.LynchAfterBg:after,
.LynchBg {
    background-color: #697e9a
}

.LynchColor {
    color: #697e9a
}

.MSUGreenBeforeBg:before,
.MSUGreenAfterBg:after,
.MSUGreenBg {
    background-color: #18453b
}

.MSUGreenColor {
    color: #18453b
}

.MabelBeforeBg:before,
.MabelAfterBg:after,
.MabelBg {
    background-color: #d9f7ff
}

.MabelColor {
    color: #d9f7ff
}

.MacaroniAndCheeseBeforeBg:before,
.MacaroniAndCheeseAfterBg:after,
.MacaroniAndCheeseBg {
    background-color: #ffbd88
}

.MacaroniAndCheeseColor {
    color: #ffbd88
}

.MacaroniandCheeseBeforeBg:before,
.MacaroniandCheeseAfterBg:after,
.MacaroniandCheeseBg {
    background-color: #ffb97b
}

.MacaroniandCheeseColor {
    color: #ffb97b
}

.MadangBeforeBg:before,
.MadangAfterBg:after,
.MadangBg {
    background-color: #b7f0be
}

.MadangColor {
    color: #b7f0be
}

.MadisonBeforeBg:before,
.MadisonAfterBg:after,
.MadisonBg {
    background-color: #09255d
}

.MadisonColor {
    color: #09255d
}

.MadrasBeforeBg:before,
.MadrasAfterBg:after,
.MadrasBg {
    background-color: #3f3002
}

.MadrasColor {
    color: #3f3002
}

.MagentaBeforeBg:before,
.MagentaAfterBg:after,
.MagentaBg {
    background-color: #ca1f7b
}

.MagentaColor {
    color: #ca1f7b
}

.MagentaHazeBeforeBg:before,
.MagentaHazeAfterBg:after,
.MagentaHazeBg {
    background-color: #9f4576
}

.MagentaHazeColor {
    color: #9f4576
}

.MagentaPinkBeforeBg:before,
.MagentaPinkAfterBg:after,
.MagentaPinkBg {
    background-color: #cc338b
}

.MagentaPinkColor {
    color: #cc338b
}

.MagicMintBeforeBg:before,
.MagicMintAfterBg:after,
.MagicMintBg {
    background-color: #aaf0d1
}

.MagicMintColor {
    color: #aaf0d1
}

.MagicPotionBeforeBg:before,
.MagicPotionAfterBg:after,
.MagicPotionBg {
    background-color: #ff4466
}

.MagicPotionColor {
    color: #ff4466
}

.MagnoliaBeforeBg:before,
.MagnoliaAfterBg:after,
.MagnoliaBg {
    background-color: #f8f4ff
}

.MagnoliaColor {
    color: #f8f4ff
}

.MahoganyBeforeBg:before,
.MahoganyAfterBg:after,
.MahoganyBg {
    background-color: #c04000
}

.MahoganyColor {
    color: #c04000
}

.MaiTaiBeforeBg:before,
.MaiTaiAfterBg:after,
.MaiTaiBg {
    background-color: #b06608
}

.MaiTaiColor {
    color: #b06608
}

.MaizeBeforeBg:before,
.MaizeAfterBg:after,
.MaizeBg {
    background-color: #fbec5d
}

.MaizeColor {
    color: #fbec5d
}

.MajorelleBlueBeforeBg:before,
.MajorelleBlueAfterBg:after,
.MajorelleBlueBg {
    background-color: #6050dc
}

.MajorelleBlueColor {
    color: #6050dc
}

.MakaraBeforeBg:before,
.MakaraAfterBg:after,
.MakaraBg {
    background-color: #897d6d
}

.MakaraColor {
    color: #897d6d
}

.MakoBeforeBg:before,
.MakoAfterBg:after,
.MakoBg {
    background-color: #444954
}

.MakoColor {
    color: #444954
}

.MalachiteBeforeBg:before,
.MalachiteAfterBg:after,
.MalachiteBg {
    background-color: #0bda51
}

.MalachiteColor {
    color: #0bda51
}

.MalibuBeforeBg:before,
.MalibuAfterBg:after,
.MalibuBg {
    background-color: #7dc8f7
}

.MalibuColor {
    color: #7dc8f7
}

.MallardBeforeBg:before,
.MallardAfterBg:after,
.MallardBg {
    background-color: #233418
}

.MallardColor {
    color: #233418
}

.MaltaBeforeBg:before,
.MaltaAfterBg:after,
.MaltaBg {
    background-color: #bdb2a1
}

.MaltaColor {
    color: #bdb2a1
}

.MambaBeforeBg:before,
.MambaAfterBg:after,
.MambaBg {
    background-color: #8e8190
}

.MambaColor {
    color: #8e8190
}

.ManateeBeforeBg:before,
.ManateeAfterBg:after,
.ManateeBg {
    background-color: #979aaa
}

.ManateeColor {
    color: #979aaa
}

.MandalayBeforeBg:before,
.MandalayAfterBg:after,
.MandalayBg {
    background-color: #ad781b
}

.MandalayColor {
    color: #ad781b
}

.MandarinBeforeBg:before,
.MandarinAfterBg:after,
.MandarinBg {
    background-color: #f37a48
}

.MandarinColor {
    color: #f37a48
}

.MandyBeforeBg:before,
.MandyAfterBg:after,
.MandyBg {
    background-color: #e25465
}

.MandyColor {
    color: #e25465
}

.MandysPinkBeforeBg:before,
.MandysPinkAfterBg:after,
.MandysPinkBg {
    background-color: #f2c3b2
}

.MandysPinkColor {
    color: #f2c3b2
}

.MangoTangoBeforeBg:before,
.MangoTangoAfterBg:after,
.MangoTangoBg {
    background-color: #ff8243
}

.MangoTangoColor {
    color: #ff8243
}

.ManhattanBeforeBg:before,
.ManhattanAfterBg:after,
.ManhattanBg {
    background-color: #f5c999
}

.ManhattanColor {
    color: #f5c999
}

.MantisBeforeBg:before,
.MantisAfterBg:after,
.MantisBg {
    background-color: #74c365
}

.MantisColor {
    color: #74c365
}

.MantleBeforeBg:before,
.MantleAfterBg:after,
.MantleBg {
    background-color: #8b9c90
}

.MantleColor {
    color: #8b9c90
}

.ManzBeforeBg:before,
.ManzAfterBg:after,
.ManzBg {
    background-color: #eeef78
}

.ManzColor {
    color: #eeef78
}

.MardiGrasBeforeBg:before,
.MardiGrasAfterBg:after,
.MardiGrasBg {
    background-color: #880085
}

.MardiGrasColor {
    color: #880085
}

.MarigoldBeforeBg:before,
.MarigoldAfterBg:after,
.MarigoldBg {
    background-color: #eaa221
}

.MarigoldColor {
    color: #eaa221
}

.MarigoldYellowBeforeBg:before,
.MarigoldYellowAfterBg:after,
.MarigoldYellowBg {
    background-color: #fbe870
}

.MarigoldYellowColor {
    color: #fbe870
}

.MarinerBeforeBg:before,
.MarinerAfterBg:after,
.MarinerBg {
    background-color: #286acd
}

.MarinerColor {
    color: #286acd
}

.MaroonBeforeBg:before,
.MaroonAfterBg:after,
.MaroonBg {
    background-color: #800000
}

.MaroonColor {
    color: #800000
}

.MaroonOakBeforeBg:before,
.MaroonOakAfterBg:after,
.MaroonOakBg {
    background-color: #520c17
}

.MaroonOakColor {
    color: #520c17
}

.MarshlandBeforeBg:before,
.MarshlandAfterBg:after,
.MarshlandBg {
    background-color: #0b0f08
}

.MarshlandColor {
    color: #0b0f08
}

.MartiniBeforeBg:before,
.MartiniAfterBg:after,
.MartiniBg {
    background-color: #afa09e
}

.MartiniColor {
    color: #afa09e
}

.MartiniqueBeforeBg:before,
.MartiniqueAfterBg:after,
.MartiniqueBg {
    background-color: #363050
}

.MartiniqueColor {
    color: #363050
}

.MarzipanBeforeBg:before,
.MarzipanAfterBg:after,
.MarzipanBg {
    background-color: #f8db9d
}

.MarzipanColor {
    color: #f8db9d
}

.MasalaBeforeBg:before,
.MasalaAfterBg:after,
.MasalaBg {
    background-color: #403b38
}

.MasalaColor {
    color: #403b38
}

.MatisseBeforeBg:before,
.MatisseAfterBg:after,
.MatisseBg {
    background-color: #1b659d
}

.MatisseColor {
    color: #1b659d
}

.MatrixBeforeBg:before,
.MatrixAfterBg:after,
.MatrixBg {
    background-color: #b05d54
}

.MatrixColor {
    color: #b05d54
}

.MatterhornBeforeBg:before,
.MatterhornAfterBg:after,
.MatterhornBg {
    background-color: #4e3b41
}

.MatterhornColor {
    color: #4e3b41
}

.MauveBeforeBg:before,
.MauveAfterBg:after,
.MauveBg {
    background-color: #e0b0ff
}

.MauveColor {
    color: #e0b0ff
}

.MauveTaupeBeforeBg:before,
.MauveTaupeAfterBg:after,
.MauveTaupeBg {
    background-color: #915f6d
}

.MauveTaupeColor {
    color: #915f6d
}

.MauvelousBeforeBg:before,
.MauvelousAfterBg:after,
.MauvelousBg {
    background-color: #ef98aa
}

.MauvelousColor {
    color: #ef98aa
}

.MaverickBeforeBg:before,
.MaverickAfterBg:after,
.MaverickBg {
    background-color: #d8c2d5
}

.MaverickColor {
    color: #d8c2d5
}

.MaximumBlueBeforeBg:before,
.MaximumBlueAfterBg:after,
.MaximumBlueBg {
    background-color: #47abcc
}

.MaximumBlueColor {
    color: #47abcc
}

.MaximumYellowBeforeBg:before,
.MaximumYellowAfterBg:after,
.MaximumYellowBg {
    background-color: #fafa37
}

.MaximumYellowColor {
    color: #fafa37
}

.MayGreenBeforeBg:before,
.MayGreenAfterBg:after,
.MayGreenBg {
    background-color: #4c9141
}

.MayGreenColor {
    color: #4c9141
}

.MayaBlueBeforeBg:before,
.MayaBlueAfterBg:after,
.MayaBlueBg {
    background-color: #73c2fb
}

.MayaBlueColor {
    color: #73c2fb
}

.MeatBrownBeforeBg:before,
.MeatBrownAfterBg:after,
.MeatBrownBg {
    background-color: #e5b73b
}

.MeatBrownColor {
    color: #e5b73b
}

.MediumAquamarineBeforeBg:before,
.MediumAquamarineAfterBg:after,
.MediumAquamarineBg {
    background-color: #66ddaa
}

.MediumAquamarineColor {
    color: #66ddaa
}

.MediumBlueBeforeBg:before,
.MediumBlueAfterBg:after,
.MediumBlueBg {
    background-color: #0000cd
}

.MediumBlueColor {
    color: #0000cd
}

.MediumCandyAppleRedBeforeBg:before,
.MediumCandyAppleRedAfterBg:after,
.MediumCandyAppleRedBg {
    background-color: #e2062c
}

.MediumCandyAppleRedColor {
    color: #e2062c
}

.MediumElectricBlueBeforeBg:before,
.MediumElectricBlueAfterBg:after,
.MediumElectricBlueBg {
    background-color: #035096
}

.MediumElectricBlueColor {
    color: #035096
}

.MediumJungleGreenBeforeBg:before,
.MediumJungleGreenAfterBg:after,
.MediumJungleGreenBg {
    background-color: #1c352d
}

.MediumJungleGreenColor {
    color: #1c352d
}

.MediumOrchidBeforeBg:before,
.MediumOrchidAfterBg:after,
.MediumOrchidBg {
    background-color: #ba55d3
}

.MediumOrchidColor {
    color: #ba55d3
}

.MediumPurpleBeforeBg:before,
.MediumPurpleAfterBg:after,
.MediumPurpleBg {
    background-color: #9370db
}

.MediumPurpleColor {
    color: #9370db
}

.MediumRedVioletBeforeBg:before,
.MediumRedVioletAfterBg:after,
.MediumRedVioletBg {
    background-color: #bb3385
}

.MediumRedVioletColor {
    color: #bb3385
}

.MediumRubyBeforeBg:before,
.MediumRubyAfterBg:after,
.MediumRubyBg {
    background-color: #aa4069
}

.MediumRubyColor {
    color: #aa4069
}

.MediumSeaGreenBeforeBg:before,
.MediumSeaGreenAfterBg:after,
.MediumSeaGreenBg {
    background-color: #3cb371
}

.MediumSeaGreenColor {
    color: #3cb371
}

.MediumSkyBlueBeforeBg:before,
.MediumSkyBlueAfterBg:after,
.MediumSkyBlueBg {
    background-color: #80daeb
}

.MediumSkyBlueColor {
    color: #80daeb
}

.MediumSlateBlueBeforeBg:before,
.MediumSlateBlueAfterBg:after,
.MediumSlateBlueBg {
    background-color: #7b68ee
}

.MediumSlateBlueColor {
    color: #7b68ee
}

.MediumSpringBudBeforeBg:before,
.MediumSpringBudAfterBg:after,
.MediumSpringBudBg {
    background-color: #c9dc87
}

.MediumSpringBudColor {
    color: #c9dc87
}

.MediumSpringGreenBeforeBg:before,
.MediumSpringGreenAfterBg:after,
.MediumSpringGreenBg {
    background-color: #00fa9a
}

.MediumSpringGreenColor {
    color: #00fa9a
}

.MediumTurquoiseBeforeBg:before,
.MediumTurquoiseAfterBg:after,
.MediumTurquoiseBg {
    background-color: #48d1cc
}

.MediumTurquoiseColor {
    color: #48d1cc
}

.MediumVermilionBeforeBg:before,
.MediumVermilionAfterBg:after,
.MediumVermilionBg {
    background-color: #d9603b
}

.MediumVermilionColor {
    color: #d9603b
}

.MelanieBeforeBg:before,
.MelanieAfterBg:after,
.MelanieBg {
    background-color: #e4c2d5
}

.MelanieColor {
    color: #e4c2d5
}

.MelanzaneBeforeBg:before,
.MelanzaneAfterBg:after,
.MelanzaneBg {
    background-color: #300529
}

.MelanzaneColor {
    color: #300529
}

.MellowApricotBeforeBg:before,
.MellowApricotAfterBg:after,
.MellowApricotBg {
    background-color: #f8b878
}

.MellowApricotColor {
    color: #f8b878
}

.MelonBeforeBg:before,
.MelonAfterBg:after,
.MelonBg {
    background-color: #fdbcb4
}

.MelonColor {
    color: #fdbcb4
}

.MelroseBeforeBg:before,
.MelroseAfterBg:after,
.MelroseBg {
    background-color: #c7c1ff
}

.MelroseColor {
    color: #c7c1ff
}

.MercuryBeforeBg:before,
.MercuryAfterBg:after,
.MercuryBg {
    background-color: #e5e5e5
}

.MercuryColor {
    color: #e5e5e5
}

.MerinoBeforeBg:before,
.MerinoAfterBg:after,
.MerinoBg {
    background-color: #f6f0e6
}

.MerinoColor {
    color: #f6f0e6
}

.MerlinBeforeBg:before,
.MerlinAfterBg:after,
.MerlinBg {
    background-color: #413c37
}

.MerlinColor {
    color: #413c37
}

.MerlotBeforeBg:before,
.MerlotAfterBg:after,
.MerlotBg {
    background-color: #831923
}

.MerlotColor {
    color: #831923
}

.MetalPinkBeforeBg:before,
.MetalPinkAfterBg:after,
.MetalPinkBg {
    background-color: #ff00fd
}

.MetalPinkColor {
    color: #ff00fd
}

.MetallicBronzeBeforeBg:before,
.MetallicBronzeAfterBg:after,
.MetallicBronzeBg {
    background-color: #49371b
}

.MetallicBronzeColor {
    color: #49371b
}

.MetallicCopperBeforeBg:before,
.MetallicCopperAfterBg:after,
.MetallicCopperBg {
    background-color: #71291d
}

.MetallicCopperColor {
    color: #71291d
}

.MetallicGoldBeforeBg:before,
.MetallicGoldAfterBg:after,
.MetallicGoldBg {
    background-color: #d4af37
}

.MetallicGoldColor {
    color: #d4af37
}

.MetallicSeaweedBeforeBg:before,
.MetallicSeaweedAfterBg:after,
.MetallicSeaweedBg {
    background-color: #0a7e8c
}

.MetallicSeaweedColor {
    color: #0a7e8c
}

.MetallicSunburstBeforeBg:before,
.MetallicSunburstAfterBg:after,
.MetallicSunburstBg {
    background-color: #9c7c38
}

.MetallicSunburstColor {
    color: #9c7c38
}

.MeteorBeforeBg:before,
.MeteorAfterBg:after,
.MeteorBg {
    background-color: #d07d12
}

.MeteorColor {
    color: #d07d12
}

.MeteoriteBeforeBg:before,
.MeteoriteAfterBg:after,
.MeteoriteBg {
    background-color: #3c1f76
}

.MeteoriteColor {
    color: #3c1f76
}

.MexicanPinkBeforeBg:before,
.MexicanPinkAfterBg:after,
.MexicanPinkBg {
    background-color: #e4007c
}

.MexicanPinkColor {
    color: #e4007c
}

.MexicanRedBeforeBg:before,
.MexicanRedAfterBg:after,
.MexicanRedBg {
    background-color: #a72525
}

.MexicanRedColor {
    color: #a72525
}

.MidGrayBeforeBg:before,
.MidGrayAfterBg:after,
.MidGrayBg {
    background-color: #5f5f6e
}

.MidGrayColor {
    color: #5f5f6e
}

.MidnightBeforeBg:before,
.MidnightAfterBg:after,
.MidnightBg {
    background-color: #702670
}

.MidnightColor {
    color: #702670
}

.MidnightBlueBeforeBg:before,
.MidnightBlueAfterBg:after,
.MidnightBlueBg {
    background-color: #191970
}

.MidnightBlueColor {
    color: #191970
}

.MidnightMossBeforeBg:before,
.MidnightMossAfterBg:after,
.MidnightMossBg {
    background-color: #041004
}

.MidnightMossColor {
    color: #041004
}

.MikadoBeforeBg:before,
.MikadoAfterBg:after,
.MikadoBg {
    background-color: #2d2510
}

.MikadoColor {
    color: #2d2510
}

.MikadoYellowBeforeBg:before,
.MikadoYellowAfterBg:after,
.MikadoYellowBg {
    background-color: #ffc40c
}

.MikadoYellowColor {
    color: #ffc40c
}

.MilanBeforeBg:before,
.MilanAfterBg:after,
.MilanBg {
    background-color: #faffa4
}

.MilanColor {
    color: #faffa4
}

.MilanoRedBeforeBg:before,
.MilanoRedAfterBg:after,
.MilanoRedBg {
    background-color: #b81104
}

.MilanoRedColor {
    color: #b81104
}

.MilkPunchBeforeBg:before,
.MilkPunchAfterBg:after,
.MilkPunchBg {
    background-color: #fff6d4
}

.MilkPunchColor {
    color: #fff6d4
}

.MillbrookBeforeBg:before,
.MillbrookAfterBg:after,
.MillbrookBg {
    background-color: #594433
}

.MillbrookColor {
    color: #594433
}

.MimosaBeforeBg:before,
.MimosaAfterBg:after,
.MimosaBg {
    background-color: #f8fdd3
}

.MimosaColor {
    color: #f8fdd3
}

.MindaroBeforeBg:before,
.MindaroAfterBg:after,
.MindaroBg {
    background-color: #e3f988
}

.MindaroColor {
    color: #e3f988
}

.MineShaftBeforeBg:before,
.MineShaftAfterBg:after,
.MineShaftBg {
    background-color: #323232
}

.MineShaftColor {
    color: #323232
}

.MineralGreenBeforeBg:before,
.MineralGreenAfterBg:after,
.MineralGreenBg {
    background-color: #3f5d53
}

.MineralGreenColor {
    color: #3f5d53
}

.MingBeforeBg:before,
.MingAfterBg:after,
.MingBg {
    background-color: #36747d
}

.MingColor {
    color: #36747d
}

.MinionYellowBeforeBg:before,
.MinionYellowAfterBg:after,
.MinionYellowBg {
    background-color: #f5e050
}

.MinionYellowColor {
    color: #f5e050
}

.MinskBeforeBg:before,
.MinskAfterBg:after,
.MinskBg {
    background-color: #3f307f
}

.MinskColor {
    color: #3f307f
}

.MintBeforeBg:before,
.MintAfterBg:after,
.MintBg {
    background-color: #3eb489
}

.MintColor {
    color: #3eb489
}

.MintCreamBeforeBg:before,
.MintCreamAfterBg:after,
.MintCreamBg {
    background-color: #f5fffa
}

.MintCreamColor {
    color: #f5fffa
}

.MintGreenBeforeBg:before,
.MintGreenAfterBg:after,
.MintGreenBg {
    background-color: #98ff98
}

.MintGreenColor {
    color: #98ff98
}

.MintJulepBeforeBg:before,
.MintJulepAfterBg:after,
.MintJulepBg {
    background-color: #f1eec1
}

.MintJulepColor {
    color: #f1eec1
}

.MintTulipBeforeBg:before,
.MintTulipAfterBg:after,
.MintTulipBg {
    background-color: #c4f4eb
}

.MintTulipColor {
    color: #c4f4eb
}

.MirageBeforeBg:before,
.MirageAfterBg:after,
.MirageBg {
    background-color: #161928
}

.MirageColor {
    color: #161928
}

.MischkaBeforeBg:before,
.MischkaAfterBg:after,
.MischkaBg {
    background-color: #d1d2dd
}

.MischkaColor {
    color: #d1d2dd
}

.MistGrayBeforeBg:before,
.MistGrayAfterBg:after,
.MistGrayBg {
    background-color: #c4c4bc
}

.MistGrayColor {
    color: #c4c4bc
}

.MistyMossBeforeBg:before,
.MistyMossAfterBg:after,
.MistyMossBg {
    background-color: #bbb477
}

.MistyMossColor {
    color: #bbb477
}

.MistyRoseBeforeBg:before,
.MistyRoseAfterBg:after,
.MistyRoseBg {
    background-color: #ffe4e1
}

.MistyRoseColor {
    color: #ffe4e1
}

.MobsterBeforeBg:before,
.MobsterAfterBg:after,
.MobsterBg {
    background-color: #7f7589
}

.MobsterColor {
    color: #7f7589
}

.MoccaccinoBeforeBg:before,
.MoccaccinoAfterBg:after,
.MoccaccinoBg {
    background-color: #6e1d14
}

.MoccaccinoColor {
    color: #6e1d14
}

.MoccasinBeforeBg:before,
.MoccasinAfterBg:after,
.MoccasinBg {
    background-color: #ffe4b5
}

.MoccasinColor {
    color: #ffe4b5
}

.MochaBeforeBg:before,
.MochaAfterBg:after,
.MochaBg {
    background-color: #782d19
}

.MochaColor {
    color: #782d19
}

.MojoBeforeBg:before,
.MojoAfterBg:after,
.MojoBg {
    background-color: #c04737
}

.MojoColor {
    color: #c04737
}

.MonaLisaBeforeBg:before,
.MonaLisaAfterBg:after,
.MonaLisaBg {
    background-color: #ffa194
}

.MonaLisaColor {
    color: #ffa194
}

.MonarchBeforeBg:before,
.MonarchAfterBg:after,
.MonarchBg {
    background-color: #8b0723
}

.MonarchColor {
    color: #8b0723
}

.MondoBeforeBg:before,
.MondoAfterBg:after,
.MondoBg {
    background-color: #4a3c30
}

.MondoColor {
    color: #4a3c30
}

.MongooseBeforeBg:before,
.MongooseAfterBg:after,
.MongooseBg {
    background-color: #b5a27f
}

.MongooseColor {
    color: #b5a27f
}

.MonsoonBeforeBg:before,
.MonsoonAfterBg:after,
.MonsoonBg {
    background-color: #8a8389
}

.MonsoonColor {
    color: #8a8389
}

.MonteCarloBeforeBg:before,
.MonteCarloAfterBg:after,
.MonteCarloBg {
    background-color: #83d0c6
}

.MonteCarloColor {
    color: #83d0c6
}

.MonzaBeforeBg:before,
.MonzaAfterBg:after,
.MonzaBg {
    background-color: #c7031e
}

.MonzaColor {
    color: #c7031e
}

.MoodyBlueBeforeBg:before,
.MoodyBlueAfterBg:after,
.MoodyBlueBg {
    background-color: #7f76d3
}

.MoodyBlueColor {
    color: #7f76d3
}

.MoonGlowBeforeBg:before,
.MoonGlowAfterBg:after,
.MoonGlowBg {
    background-color: #fcfeda
}

.MoonGlowColor {
    color: #fcfeda
}

.MoonMistBeforeBg:before,
.MoonMistAfterBg:after,
.MoonMistBg {
    background-color: #dcddcc
}

.MoonMistColor {
    color: #dcddcc
}

.MoonRakerBeforeBg:before,
.MoonRakerAfterBg:after,
.MoonRakerBg {
    background-color: #d6cef6
}

.MoonRakerColor {
    color: #d6cef6
}

.MoonstoneBlueBeforeBg:before,
.MoonstoneBlueAfterBg:after,
.MoonstoneBlueBg {
    background-color: #73a9c2
}

.MoonstoneBlueColor {
    color: #73a9c2
}

.MordantRedBeforeBg:before,
.MordantRedAfterBg:after,
.MordantRedBg {
    background-color: #ae0c00
}

.MordantRedColor {
    color: #ae0c00
}

.MorningGloryBeforeBg:before,
.MorningGloryAfterBg:after,
.MorningGloryBg {
    background-color: #9edee0
}

.MorningGloryColor {
    color: #9edee0
}

.MoroccoBrownBeforeBg:before,
.MoroccoBrownAfterBg:after,
.MoroccoBrownBg {
    background-color: #441d00
}

.MoroccoBrownColor {
    color: #441d00
}

.MortarBeforeBg:before,
.MortarAfterBg:after,
.MortarBg {
    background-color: #504351
}

.MortarColor {
    color: #504351
}

.MosqueBeforeBg:before,
.MosqueAfterBg:after,
.MosqueBg {
    background-color: #036a6e
}

.MosqueColor {
    color: #036a6e
}

.MossGreenBeforeBg:before,
.MossGreenAfterBg:after,
.MossGreenBg {
    background-color: #8a9a5b
}

.MossGreenColor {
    color: #8a9a5b
}

.MountainMeadowBeforeBg:before,
.MountainMeadowAfterBg:after,
.MountainMeadowBg {
    background-color: #30ba8f
}

.MountainMeadowColor {
    color: #30ba8f
}

.MountainMistBeforeBg:before,
.MountainMistAfterBg:after,
.MountainMistBg {
    background-color: #959396
}

.MountainMistColor {
    color: #959396
}

.MountbattenPinkBeforeBg:before,
.MountbattenPinkAfterBg:after,
.MountbattenPinkBg {
    background-color: #997a8d
}

.MountbattenPinkColor {
    color: #997a8d
}

.MuddyWatersBeforeBg:before,
.MuddyWatersAfterBg:after,
.MuddyWatersBg {
    background-color: #b78e5c
}

.MuddyWatersColor {
    color: #b78e5c
}

.MuesliBeforeBg:before,
.MuesliAfterBg:after,
.MuesliBg {
    background-color: #aa8b5b
}

.MuesliColor {
    color: #aa8b5b
}

.MughalGreenBeforeBg:before,
.MughalGreenAfterBg:after,
.MughalGreenBg {
    background-color: #306030
}

.MughalGreenColor {
    color: #306030
}

.MulberryBeforeBg:before,
.MulberryAfterBg:after,
.MulberryBg {
    background-color: #c54b8c
}

.MulberryColor {
    color: #c54b8c
}

.MulberryWoodBeforeBg:before,
.MulberryWoodAfterBg:after,
.MulberryWoodBg {
    background-color: #5c0536
}

.MulberryWoodColor {
    color: #5c0536
}

.MuleFawnBeforeBg:before,
.MuleFawnAfterBg:after,
.MuleFawnBg {
    background-color: #8c472f
}

.MuleFawnColor {
    color: #8c472f
}

.MulledWineBeforeBg:before,
.MulledWineAfterBg:after,
.MulledWineBg {
    background-color: #4e4562
}

.MulledWineColor {
    color: #4e4562
}

.MummysTombBeforeBg:before,
.MummysTombAfterBg:after,
.MummysTombBg {
    background-color: #828e84
}

.MummysTombColor {
    color: #828e84
}

.MunsellBlueBeforeBg:before,
.MunsellBlueAfterBg:after,
.MunsellBlueBg {
    background-color: #0093af
}

.MunsellBlueColor {
    color: #0093af
}

.MunsellGreenBeforeBg:before,
.MunsellGreenAfterBg:after,
.MunsellGreenBg {
    background-color: #00a877
}

.MunsellGreenColor {
    color: #00a877
}

.MunsellPurpleBeforeBg:before,
.MunsellPurpleAfterBg:after,
.MunsellPurpleBg {
    background-color: #9f00c5
}

.MunsellPurpleColor {
    color: #9f00c5
}

.MunsellRedBeforeBg:before,
.MunsellRedAfterBg:after,
.MunsellRedBg {
    background-color: #f2003c
}

.MunsellRedColor {
    color: #f2003c
}

.MunsellYellowBeforeBg:before,
.MunsellYellowAfterBg:after,
.MunsellYellowBg {
    background-color: #efcc00
}

.MunsellYellowColor {
    color: #efcc00
}

.MustardBeforeBg:before,
.MustardAfterBg:after,
.MustardBg {
    background-color: #ffdb58
}

.MustardColor {
    color: #ffdb58
}

.MyPinkBeforeBg:before,
.MyPinkAfterBg:after,
.MyPinkBg {
    background-color: #d69188
}

.MyPinkColor {
    color: #d69188
}

.MySinBeforeBg:before,
.MySinAfterBg:after,
.MySinBg {
    background-color: #ffb31f
}

.MySinColor {
    color: #ffb31f
}

.MyrtleGreenBeforeBg:before,
.MyrtleGreenAfterBg:after,
.MyrtleGreenBg {
    background-color: #317873
}

.MyrtleGreenColor {
    color: #317873
}

.MysticBeforeBg:before,
.MysticAfterBg:after,
.MysticBg {
    background-color: #d65282
}

.MysticColor {
    color: #d65282
}

.MysticMaroonBeforeBg:before,
.MysticMaroonAfterBg:after,
.MysticMaroonBg {
    background-color: #ad4379
}

.MysticMaroonColor {
    color: #ad4379
}

.NCSBlueBeforeBg:before,
.NCSBlueAfterBg:after,
.NCSBlueBg {
    background-color: #0087bd
}

.NCSBlueColor {
    color: #0087bd
}

.NCSGreenBeforeBg:before,
.NCSGreenAfterBg:after,
.NCSGreenBg {
    background-color: #009f6b
}

.NCSGreenColor {
    color: #009f6b
}

.NCSRedBeforeBg:before,
.NCSRedAfterBg:after,
.NCSRedBg {
    background-color: #c40233
}

.NCSRedColor {
    color: #c40233
}

.NadeshikoPinkBeforeBg:before,
.NadeshikoPinkAfterBg:after,
.NadeshikoPinkBg {
    background-color: #f6adc6
}

.NadeshikoPinkColor {
    color: #f6adc6
}

.NandorBeforeBg:before,
.NandorAfterBg:after,
.NandorBg {
    background-color: #4b5d52
}

.NandorColor {
    color: #4b5d52
}

.NapaBeforeBg:before,
.NapaAfterBg:after,
.NapaBg {
    background-color: #aca494
}

.NapaColor {
    color: #aca494
}

.NapierGreenBeforeBg:before,
.NapierGreenAfterBg:after,
.NapierGreenBg {
    background-color: #2a8000
}

.NapierGreenColor {
    color: #2a8000
}

.NaplesYellowBeforeBg:before,
.NaplesYellowAfterBg:after,
.NaplesYellowBg {
    background-color: #fada5e
}

.NaplesYellowColor {
    color: #fada5e
}

.NarvikBeforeBg:before,
.NarvikAfterBg:after,
.NarvikBg {
    background-color: #edf9f1
}

.NarvikColor {
    color: #edf9f1
}

.NaturalGrayBeforeBg:before,
.NaturalGrayAfterBg:after,
.NaturalGrayBg {
    background-color: #8b8680
}

.NaturalGrayColor {
    color: #8b8680
}

.NavajoWhiteBeforeBg:before,
.NavajoWhiteAfterBg:after,
.NavajoWhiteBg {
    background-color: #ffdead
}

.NavajoWhiteColor {
    color: #ffdead
}

.NavyBeforeBg:before,
.NavyAfterBg:after,
.NavyBg {
    background-color: #000080
}

.NavyColor {
    color: #000080
}

.NebulaBeforeBg:before,
.NebulaAfterBg:after,
.NebulaBg {
    background-color: #cbdbd6
}

.NebulaColor {
    color: #cbdbd6
}

.NegroniBeforeBg:before,
.NegroniAfterBg:after,
.NegroniBg {
    background-color: #ffe2c5
}

.NegroniColor {
    color: #ffe2c5
}

.NeonCarrotBeforeBg:before,
.NeonCarrotAfterBg:after,
.NeonCarrotBg {
    background-color: #ffa343
}

.NeonCarrotColor {
    color: #ffa343
}

.NeonFuchsiaBeforeBg:before,
.NeonFuchsiaAfterBg:after,
.NeonFuchsiaBg {
    background-color: #fe4164
}

.NeonFuchsiaColor {
    color: #fe4164
}

.NeonGreenBeforeBg:before,
.NeonGreenAfterBg:after,
.NeonGreenBg {
    background-color: #39ff14
}

.NeonGreenColor {
    color: #39ff14
}

.NepalBeforeBg:before,
.NepalAfterBg:after,
.NepalBg {
    background-color: #8eabc1
}

.NepalColor {
    color: #8eabc1
}

.NeptuneBeforeBg:before,
.NeptuneAfterBg:after,
.NeptuneBg {
    background-color: #7cb7bb
}

.NeptuneColor {
    color: #7cb7bb
}

.NeroBeforeBg:before,
.NeroAfterBg:after,
.NeroBg {
    background-color: #140600
}

.NeroColor {
    color: #140600
}

.NevadaBeforeBg:before,
.NevadaAfterBg:after,
.NevadaBg {
    background-color: #646e75
}

.NevadaColor {
    color: #646e75
}

.NewCarBeforeBg:before,
.NewCarAfterBg:after,
.NewCarBg {
    background-color: #214fc6
}

.NewCarColor {
    color: #214fc6
}

.NewOrleansBeforeBg:before,
.NewOrleansAfterBg:after,
.NewOrleansBg {
    background-color: #f3d69d
}

.NewOrleansColor {
    color: #f3d69d
}

.NewYorkPinkBeforeBg:before,
.NewYorkPinkAfterBg:after,
.NewYorkPinkBg {
    background-color: #d7837f
}

.NewYorkPinkColor {
    color: #d7837f
}

.NiagaraBeforeBg:before,
.NiagaraAfterBg:after,
.NiagaraBg {
    background-color: #06a189
}

.NiagaraColor {
    color: #06a189
}

.NickelBeforeBg:before,
.NickelAfterBg:after,
.NickelBg {
    background-color: #727472
}

.NickelColor {
    color: #727472
}

.NightRiderBeforeBg:before,
.NightRiderAfterBg:after,
.NightRiderBg {
    background-color: #1f120f
}

.NightRiderColor {
    color: #1f120f
}

.NightShadzBeforeBg:before,
.NightShadzAfterBg:after,
.NightShadzBg {
    background-color: #aa375a
}

.NightShadzColor {
    color: #aa375a
}

.NileBlueBeforeBg:before,
.NileBlueAfterBg:after,
.NileBlueBg {
    background-color: #193751
}

.NileBlueColor {
    color: #193751
}

.NobelBeforeBg:before,
.NobelAfterBg:after,
.NobelBg {
    background-color: #b7b1b1
}

.NobelColor {
    color: #b7b1b1
}

.NomadBeforeBg:before,
.NomadAfterBg:after,
.NomadBg {
    background-color: #bab1a2
}

.NomadColor {
    color: #bab1a2
}

.NonPhotoBlueBeforeBg:before,
.NonPhotoBlueAfterBg:after,
.NonPhotoBlueBg {
    background-color: #a4dded
}

.NonPhotoBlueColor {
    color: #a4dded
}

.NorthTexasGreenBeforeBg:before,
.NorthTexasGreenAfterBg:after,
.NorthTexasGreenBg {
    background-color: #059033
}

.NorthTexasGreenColor {
    color: #059033
}

.NorwayBeforeBg:before,
.NorwayAfterBg:after,
.NorwayBg {
    background-color: #a8bd9f
}

.NorwayColor {
    color: #a8bd9f
}

.NuggetBeforeBg:before,
.NuggetAfterBg:after,
.NuggetBg {
    background-color: #c59922
}

.NuggetColor {
    color: #c59922
}

.NutmegBeforeBg:before,
.NutmegAfterBg:after,
.NutmegBg {
    background-color: #81422c
}

.NutmegColor {
    color: #81422c
}

.NutmegWoodFinishBeforeBg:before,
.NutmegWoodFinishAfterBg:after,
.NutmegWoodFinishBg {
    background-color: #683600
}

.NutmegWoodFinishColor {
    color: #683600
}

.NyanzaBeforeBg:before,
.NyanzaAfterBg:after,
.NyanzaBg {
    background-color: #e9ffdb
}

.NyanzaColor {
    color: #e9ffdb
}

.OasisBeforeBg:before,
.OasisAfterBg:after,
.OasisBg {
    background-color: #feefce
}

.OasisColor {
    color: #feefce
}

.ObservatoryBeforeBg:before,
.ObservatoryAfterBg:after,
.ObservatoryBg {
    background-color: #02866f
}

.ObservatoryColor {
    color: #02866f
}

.OceanBlueBeforeBg:before,
.OceanBlueAfterBg:after,
.OceanBlueBg {
    background-color: #4f42b5
}

.OceanBlueColor {
    color: #4f42b5
}

.OceanBoatBlueBeforeBg:before,
.OceanBoatBlueAfterBg:after,
.OceanBoatBlueBg {
    background-color: #0077be
}

.OceanBoatBlueColor {
    color: #0077be
}

.OceanGreenBeforeBg:before,
.OceanGreenAfterBg:after,
.OceanGreenBg {
    background-color: #48bf91
}

.OceanGreenColor {
    color: #48bf91
}

.OchreBeforeBg:before,
.OchreAfterBg:after,
.OchreBg {
    background-color: #cc7722
}

.OchreColor {
    color: #cc7722
}

.OffGreenBeforeBg:before,
.OffGreenAfterBg:after,
.OffGreenBg {
    background-color: #e6f8f3
}

.OffGreenColor {
    color: #e6f8f3
}

.OffYellowBeforeBg:before,
.OffYellowAfterBg:after,
.OffYellowBg {
    background-color: #fef9e3
}

.OffYellowColor {
    color: #fef9e3
}

.OgreOdorBeforeBg:before,
.OgreOdorAfterBg:after,
.OgreOdorBg {
    background-color: #fd5240
}

.OgreOdorColor {
    color: #fd5240
}

.OilBeforeBg:before,
.OilAfterBg:after,
.OilBg {
    background-color: #281e15
}

.OilColor {
    color: #281e15
}

.OldBrickBeforeBg:before,
.OldBrickAfterBg:after,
.OldBrickBg {
    background-color: #901e1e
}

.OldBrickColor {
    color: #901e1e
}

.OldBurgundyBeforeBg:before,
.OldBurgundyAfterBg:after,
.OldBurgundyBg {
    background-color: #43302e
}

.OldBurgundyColor {
    color: #43302e
}

.OldCopperBeforeBg:before,
.OldCopperAfterBg:after,
.OldCopperBg {
    background-color: #724a2f
}

.OldCopperColor {
    color: #724a2f
}

.OldGoldBeforeBg:before,
.OldGoldAfterBg:after,
.OldGoldBg {
    background-color: #cfb53b
}

.OldGoldColor {
    color: #cfb53b
}

.OldHeliotropeBeforeBg:before,
.OldHeliotropeAfterBg:after,
.OldHeliotropeBg {
    background-color: #563c5c
}

.OldHeliotropeColor {
    color: #563c5c
}

.OldLaceBeforeBg:before,
.OldLaceAfterBg:after,
.OldLaceBg {
    background-color: #fdf5e6
}

.OldLaceColor {
    color: #fdf5e6
}

.OldLavenderBeforeBg:before,
.OldLavenderAfterBg:after,
.OldLavenderBg {
    background-color: #796878
}

.OldLavenderColor {
    color: #796878
}

.OldMossGreenBeforeBg:before,
.OldMossGreenAfterBg:after,
.OldMossGreenBg {
    background-color: #867e36
}

.OldMossGreenColor {
    color: #867e36
}

.OldRoseBeforeBg:before,
.OldRoseAfterBg:after,
.OldRoseBg {
    background-color: #c08081
}

.OldRoseColor {
    color: #c08081
}

.OldSilverBeforeBg:before,
.OldSilverAfterBg:after,
.OldSilverBg {
    background-color: #848482
}

.OldSilverColor {
    color: #848482
}

.OliveBeforeBg:before,
.OliveAfterBg:after,
.OliveBg {
    background-color: #808000
}

.OliveColor {
    color: #808000
}

.OliveDrabBeforeBg:before,
.OliveDrabAfterBg:after,
.OliveDrabBg {
    background-color: #6b8e23
}

.OliveDrabColor {
    color: #6b8e23
}

.OliveDrabSevenBeforeBg:before,
.OliveDrabSevenAfterBg:after,
.OliveDrabSevenBg {
    background-color: #3c341f
}

.OliveDrabSevenColor {
    color: #3c341f
}

.OliveGreenBeforeBg:before,
.OliveGreenAfterBg:after,
.OliveGreenBg {
    background-color: #b5b35c
}

.OliveGreenColor {
    color: #b5b35c
}

.OliveHazeBeforeBg:before,
.OliveHazeAfterBg:after,
.OliveHazeBg {
    background-color: #8b8470
}

.OliveHazeColor {
    color: #8b8470
}

.OlivetoneBeforeBg:before,
.OlivetoneAfterBg:after,
.OlivetoneBg {
    background-color: #716e10
}

.OlivetoneColor {
    color: #716e10
}

.OlivineBeforeBg:before,
.OlivineAfterBg:after,
.OlivineBg {
    background-color: #9ab973
}

.OlivineColor {
    color: #9ab973
}

.OnahauBeforeBg:before,
.OnahauAfterBg:after,
.OnahauBg {
    background-color: #cdf4ff
}

.OnahauColor {
    color: #cdf4ff
}

.OnionBeforeBg:before,
.OnionAfterBg:after,
.OnionBg {
    background-color: #2f270e
}

.OnionColor {
    color: #2f270e
}

.OnyxBeforeBg:before,
.OnyxAfterBg:after,
.OnyxBg {
    background-color: #353839
}

.OnyxColor {
    color: #353839
}

.OpalBeforeBg:before,
.OpalAfterBg:after,
.OpalBg {
    background-color: #a9c6c2
}

.OpalColor {
    color: #a9c6c2
}

.OperaMauveBeforeBg:before,
.OperaMauveAfterBg:after,
.OperaMauveBg {
    background-color: #b784a7
}

.OperaMauveColor {
    color: #b784a7
}

.OpiumBeforeBg:before,
.OpiumAfterBg:after,
.OpiumBg {
    background-color: #8e6f70
}

.OpiumColor {
    color: #8e6f70
}

.OracleBeforeBg:before,
.OracleAfterBg:after,
.OracleBg {
    background-color: #377475
}

.OracleColor {
    color: #377475
}

.OrangeBeforeBg:before,
.OrangeAfterBg:after,
.OrangeBg {
    background-color: #ff7f00
}

.OrangeColor {
    color: #ff7f00
}

.OrangePeelBeforeBg:before,
.OrangePeelAfterBg:after,
.OrangePeelBg {
    background-color: #ff9f00
}

.OrangePeelColor {
    color: #ff9f00
}

.OrangeRedBeforeBg:before,
.OrangeRedAfterBg:after,
.OrangeRedBg {
    background-color: #ff4500
}

.OrangeRedColor {
    color: #ff4500
}

.OrangeRoughyBeforeBg:before,
.OrangeRoughyAfterBg:after,
.OrangeRoughyBg {
    background-color: #c45719
}

.OrangeRoughyColor {
    color: #c45719
}

.OrangeSodaBeforeBg:before,
.OrangeSodaAfterBg:after,
.OrangeSodaBg {
    background-color: #fa5b3d
}

.OrangeSodaColor {
    color: #fa5b3d
}

.OrangeWhiteBeforeBg:before,
.OrangeWhiteAfterBg:after,
.OrangeWhiteBg {
    background-color: #fefced
}

.OrangeWhiteColor {
    color: #fefced
}

.OrangeYellowBeforeBg:before,
.OrangeYellowAfterBg:after,
.OrangeYellowBg {
    background-color: #f8d568
}

.OrangeYellowColor {
    color: #f8d568
}

.OrchidBeforeBg:before,
.OrchidAfterBg:after,
.OrchidBg {
    background-color: #da70d6
}

.OrchidColor {
    color: #da70d6
}

.OrchidPinkBeforeBg:before,
.OrchidPinkAfterBg:after,
.OrchidPinkBg {
    background-color: #f2bdcd
}

.OrchidPinkColor {
    color: #f2bdcd
}

.OrchidWhiteBeforeBg:before,
.OrchidWhiteAfterBg:after,
.OrchidWhiteBg {
    background-color: #fffdf3
}

.OrchidWhiteColor {
    color: #fffdf3
}

.OregonBeforeBg:before,
.OregonAfterBg:after,
.OregonBg {
    background-color: #9b4703
}

.OregonColor {
    color: #9b4703
}

.OrganBeforeBg:before,
.OrganAfterBg:after,
.OrganBg {
    background-color: #6c2e1f
}

.OrganColor {
    color: #6c2e1f
}

.OrientBeforeBg:before,
.OrientAfterBg:after,
.OrientBg {
    background-color: #015e85
}

.OrientColor {
    color: #015e85
}

.OrientalPinkBeforeBg:before,
.OrientalPinkAfterBg:after,
.OrientalPinkBg {
    background-color: #c69191
}

.OrientalPinkColor {
    color: #c69191
}

.OrinocoBeforeBg:before,
.OrinocoAfterBg:after,
.OrinocoBg {
    background-color: #f3fbd4
}

.OrinocoColor {
    color: #f3fbd4
}

.OriolesOrangeBeforeBg:before,
.OriolesOrangeAfterBg:after,
.OriolesOrangeBg {
    background-color: #fb4f14
}

.OriolesOrangeColor {
    color: #fb4f14
}

.OsloGrayBeforeBg:before,
.OsloGrayAfterBg:after,
.OsloGrayBg {
    background-color: #878d91
}

.OsloGrayColor {
    color: #878d91
}

.OttomanBeforeBg:before,
.OttomanAfterBg:after,
.OttomanBg {
    background-color: #e9f8ed
}

.OttomanColor {
    color: #e9f8ed
}

.OuterSpaceBeforeBg:before,
.OuterSpaceAfterBg:after,
.OuterSpaceBg {
    background-color: #414a4c
}

.OuterSpaceColor {
    color: #414a4c
}

.OutrageousOrangeBeforeBg:before,
.OutrageousOrangeAfterBg:after,
.OutrageousOrangeBg {
    background-color: #ff6e4a
}

.OutrageousOrangeColor {
    color: #ff6e4a
}

.OxfordBlueBeforeBg:before,
.OxfordBlueAfterBg:after,
.OxfordBlueBg {
    background-color: #002147
}

.OxfordBlueColor {
    color: #002147
}

.OxleyBeforeBg:before,
.OxleyAfterBg:after,
.OxleyBg {
    background-color: #779e86
}

.OxleyColor {
    color: #779e86
}

.OysterBayBeforeBg:before,
.OysterBayAfterBg:after,
.OysterBayBg {
    background-color: #dafaff
}

.OysterBayColor {
    color: #dafaff
}

.OysterPinkBeforeBg:before,
.OysterPinkAfterBg:after,
.OysterPinkBg {
    background-color: #e9cecd
}

.OysterPinkColor {
    color: #e9cecd
}

.PaarlBeforeBg:before,
.PaarlAfterBg:after,
.PaarlBg {
    background-color: #a65529
}

.PaarlColor {
    color: #a65529
}

.PabloBeforeBg:before,
.PabloAfterBg:after,
.PabloBg {
    background-color: #776f61
}

.PabloColor {
    color: #776f61
}

.PacificBlueBeforeBg:before,
.PacificBlueAfterBg:after,
.PacificBlueBg {
    background-color: #1ca9c9
}

.PacificBlueColor {
    color: #1ca9c9
}

.PacifikaBeforeBg:before,
.PacifikaAfterBg:after,
.PacifikaBg {
    background-color: #778120
}

.PacifikaColor {
    color: #778120
}

.PacoBeforeBg:before,
.PacoAfterBg:after,
.PacoBg {
    background-color: #411f10
}

.PacoColor {
    color: #411f10
}

.PaduaBeforeBg:before,
.PaduaAfterBg:after,
.PaduaBg {
    background-color: #ade6c4
}

.PaduaColor {
    color: #ade6c4
}

.PakistanGreenBeforeBg:before,
.PakistanGreenAfterBg:after,
.PakistanGreenBg {
    background-color: #006600
}

.PakistanGreenColor {
    color: #006600
}

.PalatinateBlueBeforeBg:before,
.PalatinateBlueAfterBg:after,
.PalatinateBlueBg {
    background-color: #273be2
}

.PalatinateBlueColor {
    color: #273be2
}

.PalatinatePurpleBeforeBg:before,
.PalatinatePurpleAfterBg:after,
.PalatinatePurpleBg {
    background-color: #682860
}

.PalatinatePurpleColor {
    color: #682860
}

.PaleBrownBeforeBg:before,
.PaleBrownAfterBg:after,
.PaleBrownBg {
    background-color: #987654
}

.PaleBrownColor {
    color: #987654
}

.PaleCanaryBeforeBg:before,
.PaleCanaryAfterBg:after,
.PaleCanaryBg {
    background-color: #ffff99
}

.PaleCanaryColor {
    color: #ffff99
}

.PaleCarmineBeforeBg:before,
.PaleCarmineAfterBg:after,
.PaleCarmineBg {
    background-color: #af4035
}

.PaleCarmineColor {
    color: #af4035
}

.PaleCeruleanBeforeBg:before,
.PaleCeruleanAfterBg:after,
.PaleCeruleanBg {
    background-color: #9bc4e2
}

.PaleCeruleanColor {
    color: #9bc4e2
}

.PaleChestnutBeforeBg:before,
.PaleChestnutAfterBg:after,
.PaleChestnutBg {
    background-color: #ddadaf
}

.PaleChestnutColor {
    color: #ddadaf
}

.PaleCopperBeforeBg:before,
.PaleCopperAfterBg:after,
.PaleCopperBg {
    background-color: #da8a67
}

.PaleCopperColor {
    color: #da8a67
}

.PaleCornflowerBlueBeforeBg:before,
.PaleCornflowerBlueAfterBg:after,
.PaleCornflowerBlueBg {
    background-color: #abcdef
}

.PaleCornflowerBlueColor {
    color: #abcdef
}

.PaleCyanBeforeBg:before,
.PaleCyanAfterBg:after,
.PaleCyanBg {
    background-color: #87d3f8
}

.PaleCyanColor {
    color: #87d3f8
}

.PaleGoldBeforeBg:before,
.PaleGoldAfterBg:after,
.PaleGoldBg {
    background-color: #e6be8a
}

.PaleGoldColor {
    color: #e6be8a
}

.PaleGoldenrodBeforeBg:before,
.PaleGoldenrodAfterBg:after,
.PaleGoldenrodBg {
    background-color: #eee8aa
}

.PaleGoldenrodColor {
    color: #eee8aa
}

.PaleGreenBeforeBg:before,
.PaleGreenAfterBg:after,
.PaleGreenBg {
    background-color: #98fb98
}

.PaleGreenColor {
    color: #98fb98
}

.PaleLavenderBeforeBg:before,
.PaleLavenderAfterBg:after,
.PaleLavenderBg {
    background-color: #dcd0ff
}

.PaleLavenderColor {
    color: #dcd0ff
}

.PaleLeafBeforeBg:before,
.PaleLeafAfterBg:after,
.PaleLeafBg {
    background-color: #c0d3b9
}

.PaleLeafColor {
    color: #c0d3b9
}

.PaleMagentaBeforeBg:before,
.PaleMagentaAfterBg:after,
.PaleMagentaBg {
    background-color: #f984e5
}

.PaleMagentaColor {
    color: #f984e5
}

.PaleMagentaPinkBeforeBg:before,
.PaleMagentaPinkAfterBg:after,
.PaleMagentaPinkBg {
    background-color: #ff99cc
}

.PaleMagentaPinkColor {
    color: #ff99cc
}

.PaleOysterBeforeBg:before,
.PaleOysterAfterBg:after,
.PaleOysterBg {
    background-color: #988d77
}

.PaleOysterColor {
    color: #988d77
}

.PalePinkBeforeBg:before,
.PalePinkAfterBg:after,
.PalePinkBg {
    background-color: #fadadd
}

.PalePinkColor {
    color: #fadadd
}

.PalePlumBeforeBg:before,
.PalePlumAfterBg:after,
.PalePlumBg {
    background-color: #dda0dd
}

.PalePlumColor {
    color: #dda0dd
}

.PalePrimBeforeBg:before,
.PalePrimAfterBg:after,
.PalePrimBg {
    background-color: #fdfeb8
}

.PalePrimColor {
    color: #fdfeb8
}

.PaleRedVioletBeforeBg:before,
.PaleRedVioletAfterBg:after,
.PaleRedVioletBg {
    background-color: #db7093
}

.PaleRedVioletColor {
    color: #db7093
}

.PaleRobinEggBlueBeforeBg:before,
.PaleRobinEggBlueAfterBg:after,
.PaleRobinEggBlueBg {
    background-color: #96ded1
}

.PaleRobinEggBlueColor {
    color: #96ded1
}

.PaleRoseBeforeBg:before,
.PaleRoseAfterBg:after,
.PaleRoseBg {
    background-color: #ffe1f2
}

.PaleRoseColor {
    color: #ffe1f2
}

.PaleSilverBeforeBg:before,
.PaleSilverAfterBg:after,
.PaleSilverBg {
    background-color: #c9c0bb
}

.PaleSilverColor {
    color: #c9c0bb
}

.PaleSkyBeforeBg:before,
.PaleSkyAfterBg:after,
.PaleSkyBg {
    background-color: #6e7783
}

.PaleSkyColor {
    color: #6e7783
}

.PaleSlateBeforeBg:before,
.PaleSlateAfterBg:after,
.PaleSlateBg {
    background-color: #c3bfc1
}

.PaleSlateColor {
    color: #c3bfc1
}

.PaleSpringBudBeforeBg:before,
.PaleSpringBudAfterBg:after,
.PaleSpringBudBg {
    background-color: #ecebbd
}

.PaleSpringBudColor {
    color: #ecebbd
}

.PaleTaupeBeforeBg:before,
.PaleTaupeAfterBg:after,
.PaleTaupeBg {
    background-color: #bc987e
}

.PaleTaupeColor {
    color: #bc987e
}

.PaleVioletBeforeBg:before,
.PaleVioletAfterBg:after,
.PaleVioletBg {
    background-color: #cc99ff
}

.PaleVioletColor {
    color: #cc99ff
}

.PalmGreenBeforeBg:before,
.PalmGreenAfterBg:after,
.PalmGreenBg {
    background-color: #09230f
}

.PalmGreenColor {
    color: #09230f
}

.PalmLeafBeforeBg:before,
.PalmLeafAfterBg:after,
.PalmLeafBg {
    background-color: #19330e
}

.PalmLeafColor {
    color: #19330e
}

.PampasBeforeBg:before,
.PampasAfterBg:after,
.PampasBg {
    background-color: #f4f2ee
}

.PampasColor {
    color: #f4f2ee
}

.PanacheBeforeBg:before,
.PanacheAfterBg:after,
.PanacheBg {
    background-color: #eaf6ee
}

.PanacheColor {
    color: #eaf6ee
}

.PanchoBeforeBg:before,
.PanchoAfterBg:after,
.PanchoBg {
    background-color: #edcdab
}

.PanchoColor {
    color: #edcdab
}

.PansyPurpleBeforeBg:before,
.PansyPurpleAfterBg:after,
.PansyPurpleBg {
    background-color: #78184a
}

.PansyPurpleColor {
    color: #78184a
}

.PantoneBlueBeforeBg:before,
.PantoneBlueAfterBg:after,
.PantoneBlueBg {
    background-color: #0018a8
}

.PantoneBlueColor {
    color: #0018a8
}

.PantoneGreenBeforeBg:before,
.PantoneGreenAfterBg:after,
.PantoneGreenBg {
    background-color: #00ad43
}

.PantoneGreenColor {
    color: #00ad43
}

.PantoneMagentaBeforeBg:before,
.PantoneMagentaAfterBg:after,
.PantoneMagentaBg {
    background-color: #d0417e
}

.PantoneMagentaColor {
    color: #d0417e
}

.PantoneOrangeBeforeBg:before,
.PantoneOrangeAfterBg:after,
.PantoneOrangeBg {
    background-color: #ff5800
}

.PantoneOrangeColor {
    color: #ff5800
}

.PantonePinkBeforeBg:before,
.PantonePinkAfterBg:after,
.PantonePinkBg {
    background-color: #d74894
}

.PantonePinkColor {
    color: #d74894
}

.PantoneYellowBeforeBg:before,
.PantoneYellowAfterBg:after,
.PantoneYellowBg {
    background-color: #fedf00
}

.PantoneYellowColor {
    color: #fedf00
}

.PaoloVeroneseGreenBeforeBg:before,
.PaoloVeroneseGreenAfterBg:after,
.PaoloVeroneseGreenBg {
    background-color: #009b7d
}

.PaoloVeroneseGreenColor {
    color: #009b7d
}

.PapayaWhipBeforeBg:before,
.PapayaWhipAfterBg:after,
.PapayaWhipBg {
    background-color: #ffefd5
}

.PapayaWhipColor {
    color: #ffefd5
}

.PaprikaBeforeBg:before,
.PaprikaAfterBg:after,
.PaprikaBg {
    background-color: #8d0226
}

.PaprikaColor {
    color: #8d0226
}

.ParadisePinkBeforeBg:before,
.ParadisePinkAfterBg:after,
.ParadisePinkBg {
    background-color: #e63e62
}

.ParadisePinkColor {
    color: #e63e62
}

.ParadisoBeforeBg:before,
.ParadisoAfterBg:after,
.ParadisoBg {
    background-color: #317d82
}

.ParadisoColor {
    color: #317d82
}

.ParchmentBeforeBg:before,
.ParchmentAfterBg:after,
.ParchmentBg {
    background-color: #f1e9d2
}

.ParchmentColor {
    color: #f1e9d2
}

.ParisDaisyBeforeBg:before,
.ParisDaisyAfterBg:after,
.ParisDaisyBg {
    background-color: #fff46e
}

.ParisDaisyColor {
    color: #fff46e
}

.ParisMBeforeBg:before,
.ParisMAfterBg:after,
.ParisMBg {
    background-color: #26056a
}

.ParisMColor {
    color: #26056a
}

.ParisWhiteBeforeBg:before,
.ParisWhiteAfterBg:after,
.ParisWhiteBg {
    background-color: #cadcd4
}

.ParisWhiteColor {
    color: #cadcd4
}

.ParsleyBeforeBg:before,
.ParsleyAfterBg:after,
.ParsleyBg {
    background-color: #134f19
}

.ParsleyColor {
    color: #134f19
}

.PastelBlueBeforeBg:before,
.PastelBlueAfterBg:after,
.PastelBlueBg {
    background-color: #aec6cf
}

.PastelBlueColor {
    color: #aec6cf
}

.PastelBrownBeforeBg:before,
.PastelBrownAfterBg:after,
.PastelBrownBg {
    background-color: #836953
}

.PastelBrownColor {
    color: #836953
}

.PastelGrayBeforeBg:before,
.PastelGrayAfterBg:after,
.PastelGrayBg {
    background-color: #cfcfc4
}

.PastelGrayColor {
    color: #cfcfc4
}

.PastelGreenBeforeBg:before,
.PastelGreenAfterBg:after,
.PastelGreenBg {
    background-color: #77dd77
}

.PastelGreenColor {
    color: #77dd77
}

.PastelMagentaBeforeBg:before,
.PastelMagentaAfterBg:after,
.PastelMagentaBg {
    background-color: #f49ac2
}

.PastelMagentaColor {
    color: #f49ac2
}

.PastelOrangeBeforeBg:before,
.PastelOrangeAfterBg:after,
.PastelOrangeBg {
    background-color: #ffb347
}

.PastelOrangeColor {
    color: #ffb347
}

.PastelPinkBeforeBg:before,
.PastelPinkAfterBg:after,
.PastelPinkBg {
    background-color: #dea5a4
}

.PastelPinkColor {
    color: #dea5a4
}

.PastelPurpleBeforeBg:before,
.PastelPurpleAfterBg:after,
.PastelPurpleBg {
    background-color: #b39eb5
}

.PastelPurpleColor {
    color: #b39eb5
}

.PastelRedBeforeBg:before,
.PastelRedAfterBg:after,
.PastelRedBg {
    background-color: #ff6961
}

.PastelRedColor {
    color: #ff6961
}

.PastelVioletBeforeBg:before,
.PastelVioletAfterBg:after,
.PastelVioletBg {
    background-color: #cb99c9
}

.PastelVioletColor {
    color: #cb99c9
}

.PastelYellowBeforeBg:before,
.PastelYellowAfterBg:after,
.PastelYellowBg {
    background-color: #fdfd96
}

.PastelYellowColor {
    color: #fdfd96
}

.PatinaBeforeBg:before,
.PatinaAfterBg:after,
.PatinaBg {
    background-color: #639a8f
}

.PatinaColor {
    color: #639a8f
}

.PattensBlueBeforeBg:before,
.PattensBlueAfterBg:after,
.PattensBlueBg {
    background-color: #def5ff
}

.PattensBlueColor {
    color: #def5ff
}

.PauaBeforeBg:before,
.PauaAfterBg:after,
.PauaBg {
    background-color: #260368
}

.PauaColor {
    color: #260368
}

.PavlovaBeforeBg:before,
.PavlovaAfterBg:after,
.PavlovaBg {
    background-color: #d7c498
}

.PavlovaColor {
    color: #d7c498
}

.PaynesGreyBeforeBg:before,
.PaynesGreyAfterBg:after,
.PaynesGreyBg {
    background-color: #536878
}

.PaynesGreyColor {
    color: #536878
}

.PeachBeforeBg:before,
.PeachAfterBg:after,
.PeachBg {
    background-color: #ffcba4
}

.PeachColor {
    color: #ffcba4
}

.PeachCreamBeforeBg:before,
.PeachCreamAfterBg:after,
.PeachCreamBg {
    background-color: #fff0db
}

.PeachCreamColor {
    color: #fff0db
}

.PeachOrangeBeforeBg:before,
.PeachOrangeAfterBg:after,
.PeachOrangeBg {
    background-color: #ffcc99
}

.PeachOrangeColor {
    color: #ffcc99
}

.PeachPuffBeforeBg:before,
.PeachPuffAfterBg:after,
.PeachPuffBg {
    background-color: #ffdab9
}

.PeachPuffColor {
    color: #ffdab9
}

.PeachSchnappsBeforeBg:before,
.PeachSchnappsAfterBg:after,
.PeachSchnappsBg {
    background-color: #ffdcd6
}

.PeachSchnappsColor {
    color: #ffdcd6
}

.PeachYellowBeforeBg:before,
.PeachYellowAfterBg:after,
.PeachYellowBg {
    background-color: #fadfad
}

.PeachYellowColor {
    color: #fadfad
}

.PeanutBeforeBg:before,
.PeanutAfterBg:after,
.PeanutBg {
    background-color: #782f16
}

.PeanutColor {
    color: #782f16
}

.PearBeforeBg:before,
.PearAfterBg:after,
.PearBg {
    background-color: #d1e231
}

.PearColor {
    color: #d1e231
}

.PearlBeforeBg:before,
.PearlAfterBg:after,
.PearlBg {
    background-color: #eae0c8
}

.PearlColor {
    color: #eae0c8
}

.PearlAquaBeforeBg:before,
.PearlAquaAfterBg:after,
.PearlAquaBg {
    background-color: #88d8c0
}

.PearlAquaColor {
    color: #88d8c0
}

.PearlBushBeforeBg:before,
.PearlBushAfterBg:after,
.PearlBushBg {
    background-color: #e8e0d5
}

.PearlBushColor {
    color: #e8e0d5
}

.PearlLustaBeforeBg:before,
.PearlLustaAfterBg:after,
.PearlLustaBg {
    background-color: #fcf4dc
}

.PearlLustaColor {
    color: #fcf4dc
}

.PearlMysticTurquoiseBeforeBg:before,
.PearlMysticTurquoiseAfterBg:after,
.PearlMysticTurquoiseBg {
    background-color: #32c6a6
}

.PearlMysticTurquoiseColor {
    color: #32c6a6
}

.PearlyPurpleBeforeBg:before,
.PearlyPurpleAfterBg:after,
.PearlyPurpleBg {
    background-color: #b768a2
}

.PearlyPurpleColor {
    color: #b768a2
}

.PeatBeforeBg:before,
.PeatAfterBg:after,
.PeatBg {
    background-color: #716b56
}

.PeatColor {
    color: #716b56
}

.PelorousBeforeBg:before,
.PelorousAfterBg:after,
.PelorousBg {
    background-color: #3eabbf
}

.PelorousColor {
    color: #3eabbf
}

.PeppermintBeforeBg:before,
.PeppermintAfterBg:after,
.PeppermintBg {
    background-color: #e3f5e1
}

.PeppermintColor {
    color: #e3f5e1
}

.PeranoBeforeBg:before,
.PeranoAfterBg:after,
.PeranoBg {
    background-color: #a9bef2
}

.PeranoColor {
    color: #a9bef2
}

.PerfumeBeforeBg:before,
.PerfumeAfterBg:after,
.PerfumeBg {
    background-color: #d0bef8
}

.PerfumeColor {
    color: #d0bef8
}

.PeridotBeforeBg:before,
.PeridotAfterBg:after,
.PeridotBg {
    background-color: #e6e200
}

.PeridotColor {
    color: #e6e200
}

.PeriglacialBlueBeforeBg:before,
.PeriglacialBlueAfterBg:after,
.PeriglacialBlueBg {
    background-color: #e1e6d6
}

.PeriglacialBlueColor {
    color: #e1e6d6
}

.PeriwinkleBeforeBg:before,
.PeriwinkleAfterBg:after,
.PeriwinkleBg {
    background-color: #ccccff
}

.PeriwinkleColor {
    color: #ccccff
}

.PeriwinkleGrayBeforeBg:before,
.PeriwinkleGrayAfterBg:after,
.PeriwinkleGrayBg {
    background-color: #c3cde6
}

.PeriwinkleGrayColor {
    color: #c3cde6
}

.PermanentGeraniumLakeBeforeBg:before,
.PermanentGeraniumLakeAfterBg:after,
.PermanentGeraniumLakeBg {
    background-color: #e12c2c
}

.PermanentGeraniumLakeColor {
    color: #e12c2c
}

.PersianBlueBeforeBg:before,
.PersianBlueAfterBg:after,
.PersianBlueBg {
    background-color: #1c39bb
}

.PersianBlueColor {
    color: #1c39bb
}

.PersianGreenBeforeBg:before,
.PersianGreenAfterBg:after,
.PersianGreenBg {
    background-color: #00a693
}

.PersianGreenColor {
    color: #00a693
}

.PersianIndigoBeforeBg:before,
.PersianIndigoAfterBg:after,
.PersianIndigoBg {
    background-color: #32127a
}

.PersianIndigoColor {
    color: #32127a
}

.PersianOrangeBeforeBg:before,
.PersianOrangeAfterBg:after,
.PersianOrangeBg {
    background-color: #d99058
}

.PersianOrangeColor {
    color: #d99058
}

.PersianPinkBeforeBg:before,
.PersianPinkAfterBg:after,
.PersianPinkBg {
    background-color: #f77fbe
}

.PersianPinkColor {
    color: #f77fbe
}

.PersianPlumBeforeBg:before,
.PersianPlumAfterBg:after,
.PersianPlumBg {
    background-color: #701c1c
}

.PersianPlumColor {
    color: #701c1c
}

.PersianRedBeforeBg:before,
.PersianRedAfterBg:after,
.PersianRedBg {
    background-color: #cc3333
}

.PersianRedColor {
    color: #cc3333
}

.PersianRoseBeforeBg:before,
.PersianRoseAfterBg:after,
.PersianRoseBg {
    background-color: #fe28a2
}

.PersianRoseColor {
    color: #fe28a2
}

.PersimmonBeforeBg:before,
.PersimmonAfterBg:after,
.PersimmonBg {
    background-color: #ec5800
}

.PersimmonColor {
    color: #ec5800
}

.PeruBeforeBg:before,
.PeruAfterBg:after,
.PeruBg {
    background-color: #cd853f
}

.PeruColor {
    color: #cd853f
}

.PeruTanBeforeBg:before,
.PeruTanAfterBg:after,
.PeruTanBg {
    background-color: #7f3a02
}

.PeruTanColor {
    color: #7f3a02
}

.PestoBeforeBg:before,
.PestoAfterBg:after,
.PestoBg {
    background-color: #7c7631
}

.PestoColor {
    color: #7c7631
}

.PetiteOrchidBeforeBg:before,
.PetiteOrchidAfterBg:after,
.PetiteOrchidBg {
    background-color: #db9690
}

.PetiteOrchidColor {
    color: #db9690
}

.PewterBeforeBg:before,
.PewterAfterBg:after,
.PewterBg {
    background-color: #96a8a1
}

.PewterColor {
    color: #96a8a1
}

.PewterBlueBeforeBg:before,
.PewterBlueAfterBg:after,
.PewterBlueBg {
    background-color: #8ba8b7
}

.PewterBlueColor {
    color: #8ba8b7
}

.PharlapBeforeBg:before,
.PharlapAfterBg:after,
.PharlapBg {
    background-color: #a3807b
}

.PharlapColor {
    color: #a3807b
}

.PhthaloBlueBeforeBg:before,
.PhthaloBlueAfterBg:after,
.PhthaloBlueBg {
    background-color: #000f89
}

.PhthaloBlueColor {
    color: #000f89
}

.PhthaloGreenBeforeBg:before,
.PhthaloGreenAfterBg:after,
.PhthaloGreenBg {
    background-color: #123524
}

.PhthaloGreenColor {
    color: #123524
}

.PicassoBeforeBg:before,
.PicassoAfterBg:after,
.PicassoBg {
    background-color: #fff39d
}

.PicassoColor {
    color: #fff39d
}

.PickledBeanBeforeBg:before,
.PickledBeanAfterBg:after,
.PickledBeanBg {
    background-color: #6e4826
}

.PickledBeanColor {
    color: #6e4826
}

.PickledBluewoodBeforeBg:before,
.PickledBluewoodAfterBg:after,
.PickledBluewoodBg {
    background-color: #314459
}

.PickledBluewoodColor {
    color: #314459
}

.PictonBlueBeforeBg:before,
.PictonBlueAfterBg:after,
.PictonBlueBg {
    background-color: #45b1e8
}

.PictonBlueColor {
    color: #45b1e8
}

.PictorialCarmineBeforeBg:before,
.PictorialCarmineAfterBg:after,
.PictorialCarmineBg {
    background-color: #c30b4e
}

.PictorialCarmineColor {
    color: #c30b4e
}

.PigPinkBeforeBg:before,
.PigPinkAfterBg:after,
.PigPinkBg {
    background-color: #fdd7e4
}

.PigPinkColor {
    color: #fdd7e4
}

.PigeonPostBeforeBg:before,
.PigeonPostAfterBg:after,
.PigeonPostBg {
    background-color: #afbdd9
}

.PigeonPostColor {
    color: #afbdd9
}

.PiggyPinkBeforeBg:before,
.PiggyPinkAfterBg:after,
.PiggyPinkBg {
    background-color: #fddde6
}

.PiggyPinkColor {
    color: #fddde6
}

.PigmentBlueBeforeBg:before,
.PigmentBlueAfterBg:after,
.PigmentBlueBg {
    background-color: #333399
}

.PigmentBlueColor {
    color: #333399
}

.PigmentGreenBeforeBg:before,
.PigmentGreenAfterBg:after,
.PigmentGreenBg {
    background-color: #00a550
}

.PigmentGreenColor {
    color: #00a550
}

.PigmentRedBeforeBg:before,
.PigmentRedAfterBg:after,
.PigmentRedBg {
    background-color: #ed1c24
}

.PigmentRedColor {
    color: #ed1c24
}

.PineConeBeforeBg:before,
.PineConeAfterBg:after,
.PineConeBg {
    background-color: #6d5e54
}

.PineConeColor {
    color: #6d5e54
}

.PineGladeBeforeBg:before,
.PineGladeAfterBg:after,
.PineGladeBg {
    background-color: #c7cd90
}

.PineGladeColor {
    color: #c7cd90
}

.PineGreenBeforeBg:before,
.PineGreenAfterBg:after,
.PineGreenBg {
    background-color: #01796f
}

.PineGreenColor {
    color: #01796f
}

.PineTreeBeforeBg:before,
.PineTreeAfterBg:after,
.PineTreeBg {
    background-color: #171f04
}

.PineTreeColor {
    color: #171f04
}

.PinkBeforeBg:before,
.PinkAfterBg:after,
.PinkBg {
    background-color: #ffc0cb
}

.PinkColor {
    color: #ffc0cb
}

.PinkFlamingoBeforeBg:before,
.PinkFlamingoAfterBg:after,
.PinkFlamingoBg {
    background-color: #fc74fd
}

.PinkFlamingoColor {
    color: #fc74fd
}

.PinkFlareBeforeBg:before,
.PinkFlareAfterBg:after,
.PinkFlareBg {
    background-color: #e1c0c8
}

.PinkFlareColor {
    color: #e1c0c8
}

.PinkLaceBeforeBg:before,
.PinkLaceAfterBg:after,
.PinkLaceBg {
    background-color: #ffddf4
}

.PinkLaceColor {
    color: #ffddf4
}

.PinkLadyBeforeBg:before,
.PinkLadyAfterBg:after,
.PinkLadyBg {
    background-color: #fff1d8
}

.PinkLadyColor {
    color: #fff1d8
}

.PinkLavenderBeforeBg:before,
.PinkLavenderAfterBg:after,
.PinkLavenderBg {
    background-color: #d8b2d1
}

.PinkLavenderColor {
    color: #d8b2d1
}

.PinkOrangeBeforeBg:before,
.PinkOrangeAfterBg:after,
.PinkOrangeBg {
    background-color: #ff9966
}

.PinkOrangeColor {
    color: #ff9966
}

.PinkPearlBeforeBg:before,
.PinkPearlAfterBg:after,
.PinkPearlBg {
    background-color: #e7accf
}

.PinkPearlColor {
    color: #e7accf
}

.PinkRaspberryBeforeBg:before,
.PinkRaspberryAfterBg:after,
.PinkRaspberryBg {
    background-color: #980036
}

.PinkRaspberryColor {
    color: #980036
}

.PinkSherbetBeforeBg:before,
.PinkSherbetAfterBg:after,
.PinkSherbetBg {
    background-color: #f78fa7
}

.PinkSherbetColor {
    color: #f78fa7
}

.PinkSwanBeforeBg:before,
.PinkSwanAfterBg:after,
.PinkSwanBg {
    background-color: #beb5b7
}

.PinkSwanColor {
    color: #beb5b7
}

.PiperBeforeBg:before,
.PiperAfterBg:after,
.PiperBg {
    background-color: #c96323
}

.PiperColor {
    color: #c96323
}

.PipiBeforeBg:before,
.PipiAfterBg:after,
.PipiBg {
    background-color: #fef4cc
}

.PipiColor {
    color: #fef4cc
}

.PippinBeforeBg:before,
.PippinAfterBg:after,
.PippinBg {
    background-color: #ffe1df
}

.PippinColor {
    color: #ffe1df
}

.PirateGoldBeforeBg:before,
.PirateGoldAfterBg:after,
.PirateGoldBg {
    background-color: #ba7f03
}

.PirateGoldColor {
    color: #ba7f03
}

.PistachioBeforeBg:before,
.PistachioAfterBg:after,
.PistachioBg {
    background-color: #93c572
}

.PistachioColor {
    color: #93c572
}

.PixieGreenBeforeBg:before,
.PixieGreenAfterBg:after,
.PixieGreenBg {
    background-color: #c0d8b6
}

.PixieGreenColor {
    color: #c0d8b6
}

.PixiePowderBeforeBg:before,
.PixiePowderAfterBg:after,
.PixiePowderBg {
    background-color: #391285
}

.PixiePowderColor {
    color: #391285
}

.PizazzBeforeBg:before,
.PizazzAfterBg:after,
.PizazzBg {
    background-color: #ff9000
}

.PizazzColor {
    color: #ff9000
}

.PizzaBeforeBg:before,
.PizzaAfterBg:after,
.PizzaBg {
    background-color: #c99415
}

.PizzaColor {
    color: #c99415
}

.PlantationBeforeBg:before,
.PlantationAfterBg:after,
.PlantationBg {
    background-color: #27504b
}

.PlantationColor {
    color: #27504b
}

.PlatinumBeforeBg:before,
.PlatinumAfterBg:after,
.PlatinumBg {
    background-color: #e5e4e2
}

.PlatinumColor {
    color: #e5e4e2
}

.PlumBeforeBg:before,
.PlumAfterBg:after,
.PlumBg {
    background-color: #8e4585
}

.PlumColor {
    color: #8e4585
}

.PlumpPurpleBeforeBg:before,
.PlumpPurpleAfterBg:after,
.PlumpPurpleBg {
    background-color: #5946b2
}

.PlumpPurpleColor {
    color: #5946b2
}

.PohutukawaBeforeBg:before,
.PohutukawaAfterBg:after,
.PohutukawaBg {
    background-color: #8f021c
}

.PohutukawaColor {
    color: #8f021c
}

.PolarBeforeBg:before,
.PolarAfterBg:after,
.PolarBg {
    background-color: #e5f9f6
}

.PolarColor {
    color: #e5f9f6
}

.PolishedPineBeforeBg:before,
.PolishedPineAfterBg:after,
.PolishedPineBg {
    background-color: #5da493
}

.PolishedPineColor {
    color: #5da493
}

.PoloBlueBeforeBg:before,
.PoloBlueAfterBg:after,
.PoloBlueBg {
    background-color: #8da8cc
}

.PoloBlueColor {
    color: #8da8cc
}

.PomegranateBeforeBg:before,
.PomegranateAfterBg:after,
.PomegranateBg {
    background-color: #f34723
}

.PomegranateColor {
    color: #f34723
}

.PompadourBeforeBg:before,
.PompadourAfterBg:after,
.PompadourBg {
    background-color: #660045
}

.PompadourColor {
    color: #660045
}

.PopstarBeforeBg:before,
.PopstarAfterBg:after,
.PopstarBg {
    background-color: #be4f62
}

.PopstarColor {
    color: #be4f62
}

.PorcelainBeforeBg:before,
.PorcelainAfterBg:after,
.PorcelainBg {
    background-color: #eff2f3
}

.PorcelainColor {
    color: #eff2f3
}

.PorscheBeforeBg:before,
.PorscheAfterBg:after,
.PorscheBg {
    background-color: #eaae69
}

.PorscheColor {
    color: #eaae69
}

.PortGoreBeforeBg:before,
.PortGoreAfterBg:after,
.PortGoreBg {
    background-color: #251f4f
}

.PortGoreColor {
    color: #251f4f
}

.PortafinoBeforeBg:before,
.PortafinoAfterBg:after,
.PortafinoBg {
    background-color: #ffffb4
}

.PortafinoColor {
    color: #ffffb4
}

.PortageBeforeBg:before,
.PortageAfterBg:after,
.PortageBg {
    background-color: #8b9fee
}

.PortageColor {
    color: #8b9fee
}

.PorticaBeforeBg:before,
.PorticaAfterBg:after,
.PorticaBg {
    background-color: #f9e663
}

.PorticaColor {
    color: #f9e663
}

.PortlandOrangeBeforeBg:before,
.PortlandOrangeAfterBg:after,
.PortlandOrangeBg {
    background-color: #ff5a36
}

.PortlandOrangeColor {
    color: #ff5a36
}

.PotPourriBeforeBg:before,
.PotPourriAfterBg:after,
.PotPourriBg {
    background-color: #f5e7e2
}

.PotPourriColor {
    color: #f5e7e2
}

.PottersClayBeforeBg:before,
.PottersClayAfterBg:after,
.PottersClayBg {
    background-color: #8c5738
}

.PottersClayColor {
    color: #8c5738
}

.PowderAshBeforeBg:before,
.PowderAshAfterBg:after,
.PowderAshBg {
    background-color: #bcc9c2
}

.PowderAshColor {
    color: #bcc9c2
}

.PowderBlueBeforeBg:before,
.PowderBlueAfterBg:after,
.PowderBlueBg {
    background-color: #b0e0e6
}

.PowderBlueColor {
    color: #b0e0e6
}

.PrairieSandBeforeBg:before,
.PrairieSandAfterBg:after,
.PrairieSandBg {
    background-color: #9a3820
}

.PrairieSandColor {
    color: #9a3820
}

.PreludeBeforeBg:before,
.PreludeAfterBg:after,
.PreludeBg {
    background-color: #d0c0e5
}

.PreludeColor {
    color: #d0c0e5
}

.PrimBeforeBg:before,
.PrimAfterBg:after,
.PrimBg {
    background-color: #f0e2ec
}

.PrimColor {
    color: #f0e2ec
}

.PrimroseBeforeBg:before,
.PrimroseAfterBg:after,
.PrimroseBg {
    background-color: #edea99
}

.PrimroseColor {
    color: #edea99
}

.PrincessPerfumeBeforeBg:before,
.PrincessPerfumeAfterBg:after,
.PrincessPerfumeBg {
    background-color: #ff85cf
}

.PrincessPerfumeColor {
    color: #ff85cf
}

.PrincetonOrangeBeforeBg:before,
.PrincetonOrangeAfterBg:after,
.PrincetonOrangeBg {
    background-color: #f58025
}

.PrincetonOrangeColor {
    color: #f58025
}

.ProcessCyanBeforeBg:before,
.ProcessCyanAfterBg:after,
.ProcessCyanBg {
    background-color: #00b7eb
}

.ProcessCyanColor {
    color: #00b7eb
}

.ProcessMagentaBeforeBg:before,
.ProcessMagentaAfterBg:after,
.ProcessMagentaBg {
    background-color: #ff0090
}

.ProcessMagentaColor {
    color: #ff0090
}

.ProvincialPinkBeforeBg:before,
.ProvincialPinkAfterBg:after,
.ProvincialPinkBg {
    background-color: #fef5f1
}

.ProvincialPinkColor {
    color: #fef5f1
}

.PrussianBlueBeforeBg:before,
.PrussianBlueAfterBg:after,
.PrussianBlueBg {
    background-color: #003153
}

.PrussianBlueColor {
    color: #003153
}

.PsychedelicPurpleBeforeBg:before,
.PsychedelicPurpleAfterBg:after,
.PsychedelicPurpleBg {
    background-color: #df00ff
}

.PsychedelicPurpleColor {
    color: #df00ff
}

.PuceBeforeBg:before,
.PuceAfterBg:after,
.PuceBg {
    background-color: #cc8899
}

.PuceColor {
    color: #cc8899
}

.PuebloBeforeBg:before,
.PuebloAfterBg:after,
.PuebloBg {
    background-color: #7d2c14
}

.PuebloColor {
    color: #7d2c14
}

.PuertoRicoBeforeBg:before,
.PuertoRicoAfterBg:after,
.PuertoRicoBg {
    background-color: #3fc1aa
}

.PuertoRicoColor {
    color: #3fc1aa
}

.PullmanBrownBeforeBg:before,
.PullmanBrownAfterBg:after,
.PullmanBrownBg {
    background-color: #644117
}

.PullmanBrownColor {
    color: #644117
}

.PullmanGreenBeforeBg:before,
.PullmanGreenAfterBg:after,
.PullmanGreenBg {
    background-color: #3b331c
}

.PullmanGreenColor {
    color: #3b331c
}

.PumiceBeforeBg:before,
.PumiceAfterBg:after,
.PumiceBg {
    background-color: #c2cac4
}

.PumiceColor {
    color: #c2cac4
}

.PumpkinBeforeBg:before,
.PumpkinAfterBg:after,
.PumpkinBg {
    background-color: #ff7518
}

.PumpkinColor {
    color: #ff7518
}

.PumpkinSkinBeforeBg:before,
.PumpkinSkinAfterBg:after,
.PumpkinSkinBg {
    background-color: #b1610b
}

.PumpkinSkinColor {
    color: #b1610b
}

.PunchBeforeBg:before,
.PunchAfterBg:after,
.PunchBg {
    background-color: #dc4333
}

.PunchColor {
    color: #dc4333
}

.PungaBeforeBg:before,
.PungaAfterBg:after,
.PungaBg {
    background-color: #4d3d14
}

.PungaColor {
    color: #4d3d14
}

.PurpleBeforeBg:before,
.PurpleAfterBg:after,
.PurpleBg {
    background-color: #800080
}

.PurpleColor {
    color: #800080
}

.PurpleHeartBeforeBg:before,
.PurpleHeartAfterBg:after,
.PurpleHeartBg {
    background-color: #69359c
}

.PurpleHeartColor {
    color: #69359c
}

.PurpleMountainMajestyBeforeBg:before,
.PurpleMountainMajestyAfterBg:after,
.PurpleMountainMajestyBg {
    background-color: #9678b6
}

.PurpleMountainMajestyColor {
    color: #9678b6
}

.PurpleNavyBeforeBg:before,
.PurpleNavyAfterBg:after,
.PurpleNavyBg {
    background-color: #4e5180
}

.PurpleNavyColor {
    color: #4e5180
}

.PurplePizzazzBeforeBg:before,
.PurplePizzazzAfterBg:after,
.PurplePizzazzBg {
    background-color: #fe4eda
}

.PurplePizzazzColor {
    color: #fe4eda
}

.PurplePlumBeforeBg:before,
.PurplePlumAfterBg:after,
.PurplePlumBg {
    background-color: #9c51b6
}

.PurplePlumColor {
    color: #9c51b6
}

.PurpleTaupeBeforeBg:before,
.PurpleTaupeAfterBg:after,
.PurpleTaupeBg {
    background-color: #50404d
}

.PurpleTaupeColor {
    color: #50404d
}

.PurpureusBeforeBg:before,
.PurpureusAfterBg:after,
.PurpureusBg {
    background-color: #9a4eae
}

.PurpureusColor {
    color: #9a4eae
}

.PuttyBeforeBg:before,
.PuttyAfterBg:after,
.PuttyBg {
    background-color: #e7cd8c
}

.PuttyColor {
    color: #e7cd8c
}

.QuarterPearlLustaBeforeBg:before,
.QuarterPearlLustaAfterBg:after,
.QuarterPearlLustaBg {
    background-color: #fffdf4
}

.QuarterPearlLustaColor {
    color: #fffdf4
}

.QuarterSpanishWhiteBeforeBg:before,
.QuarterSpanishWhiteAfterBg:after,
.QuarterSpanishWhiteBg {
    background-color: #f7f2e1
}

.QuarterSpanishWhiteColor {
    color: #f7f2e1
}

.QuartzBeforeBg:before,
.QuartzAfterBg:after,
.QuartzBg {
    background-color: #51484f
}

.QuartzColor {
    color: #51484f
}

.QueenBlueBeforeBg:before,
.QueenBlueAfterBg:after,
.QueenBlueBg {
    background-color: #436b95
}

.QueenBlueColor {
    color: #436b95
}

.QueenPinkBeforeBg:before,
.QueenPinkAfterBg:after,
.QueenPinkBg {
    background-color: #e8ccd7
}

.QueenPinkColor {
    color: #e8ccd7
}

.QuickSilverBeforeBg:before,
.QuickSilverAfterBg:after,
.QuickSilverBg {
    background-color: #a6a6a6
}

.QuickSilverColor {
    color: #a6a6a6
}

.QuicksandBeforeBg:before,
.QuicksandAfterBg:after,
.QuicksandBg {
    background-color: #bd978e
}

.QuicksandColor {
    color: #bd978e
}

.QuillGrayBeforeBg:before,
.QuillGrayAfterBg:after,
.QuillGrayBg {
    background-color: #d6d6d1
}

.QuillGrayColor {
    color: #d6d6d1
}

.QuinacridoneMagentaBeforeBg:before,
.QuinacridoneMagentaAfterBg:after,
.QuinacridoneMagentaBg {
    background-color: #8e3a59
}

.QuinacridoneMagentaColor {
    color: #8e3a59
}

.QuincyBeforeBg:before,
.QuincyAfterBg:after,
.QuincyBg {
    background-color: #623f2d
}

.QuincyColor {
    color: #623f2d
}

.RYBBlueBeforeBg:before,
.RYBBlueAfterBg:after,
.RYBBlueBg {
    background-color: #0247fe
}

.RYBBlueColor {
    color: #0247fe
}

.RYBGreenBeforeBg:before,
.RYBGreenAfterBg:after,
.RYBGreenBg {
    background-color: #66b032
}

.RYBGreenColor {
    color: #66b032
}

.RYBOrangeBeforeBg:before,
.RYBOrangeAfterBg:after,
.RYBOrangeBg {
    background-color: #fb9902
}

.RYBOrangeColor {
    color: #fb9902
}

.RYBRedBeforeBg:before,
.RYBRedAfterBg:after,
.RYBRedBg {
    background-color: #fe2712
}

.RYBRedColor {
    color: #fe2712
}

.RYBVioletBeforeBg:before,
.RYBVioletAfterBg:after,
.RYBVioletBg {
    background-color: #8601af
}

.RYBVioletColor {
    color: #8601af
}

.RYBYellowBeforeBg:before,
.RYBYellowAfterBg:after,
.RYBYellowBg {
    background-color: #fefe33
}

.RYBYellowColor {
    color: #fefe33
}

.RacingGreenBeforeBg:before,
.RacingGreenAfterBg:after,
.RacingGreenBg {
    background-color: #0c1911
}

.RacingGreenColor {
    color: #0c1911
}

.RadicalRedBeforeBg:before,
.RadicalRedAfterBg:after,
.RadicalRedBg {
    background-color: #ff355e
}

.RadicalRedColor {
    color: #ff355e
}

.RaffiaBeforeBg:before,
.RaffiaAfterBg:after,
.RaffiaBg {
    background-color: #eadab8
}

.RaffiaColor {
    color: #eadab8
}

.RaineeBeforeBg:before,
.RaineeAfterBg:after,
.RaineeBg {
    background-color: #b9c8ac
}

.RaineeColor {
    color: #b9c8ac
}

.RaisinBlackBeforeBg:before,
.RaisinBlackAfterBg:after,
.RaisinBlackBg {
    background-color: #242124
}

.RaisinBlackColor {
    color: #242124
}

.RajahBeforeBg:before,
.RajahAfterBg:after,
.RajahBg {
    background-color: #fbab60
}

.RajahColor {
    color: #fbab60
}

.RangitotoBeforeBg:before,
.RangitotoAfterBg:after,
.RangitotoBg {
    background-color: #2e3222
}

.RangitotoColor {
    color: #2e3222
}

.RangoonGreenBeforeBg:before,
.RangoonGreenAfterBg:after,
.RangoonGreenBg {
    background-color: #1c1e13
}

.RangoonGreenColor {
    color: #1c1e13
}

.RaspberryBeforeBg:before,
.RaspberryAfterBg:after,
.RaspberryBg {
    background-color: #e30b5d
}

.RaspberryColor {
    color: #e30b5d
}

.RaspberryPinkBeforeBg:before,
.RaspberryPinkAfterBg:after,
.RaspberryPinkBg {
    background-color: #e25098
}

.RaspberryPinkColor {
    color: #e25098
}

.RavenBeforeBg:before,
.RavenAfterBg:after,
.RavenBg {
    background-color: #727b89
}

.RavenColor {
    color: #727b89
}

.RawSiennaBeforeBg:before,
.RawSiennaAfterBg:after,
.RawSiennaBg {
    background-color: #d68a59
}

.RawSiennaColor {
    color: #d68a59
}

.RawUmberBeforeBg:before,
.RawUmberAfterBg:after,
.RawUmberBg {
    background-color: #826644
}

.RawUmberColor {
    color: #826644
}

.RazzleDazzleRoseBeforeBg:before,
.RazzleDazzleRoseAfterBg:after,
.RazzleDazzleRoseBg {
    background-color: #ff33cc
}

.RazzleDazzleRoseColor {
    color: #ff33cc
}

.RazzmatazzBeforeBg:before,
.RazzmatazzAfterBg:after,
.RazzmatazzBg {
    background-color: #e3256b
}

.RazzmatazzColor {
    color: #e3256b
}

.RazzmicBerryBeforeBg:before,
.RazzmicBerryAfterBg:after,
.RazzmicBerryBg {
    background-color: #8d4e85
}

.RazzmicBerryColor {
    color: #8d4e85
}

.RebeccaPurpleBeforeBg:before,
.RebeccaPurpleAfterBg:after,
.RebeccaPurpleBg {
    background-color: #663399
}

.RebeccaPurpleColor {
    color: #663399
}

.RebelBeforeBg:before,
.RebelAfterBg:after,
.RebelBg {
    background-color: #3c1206
}

.RebelColor {
    color: #3c1206
}

.RedBeforeBg:before,
.RedAfterBg:after,
.RedBg {
    background-color: #ff0000
}

.RedColor {
    color: #ff0000
}

.RedBeechBeforeBg:before,
.RedBeechAfterBg:after,
.RedBeechBg {
    background-color: #7b3801
}

.RedBeechColor {
    color: #7b3801
}

.RedBerryBeforeBg:before,
.RedBerryAfterBg:after,
.RedBerryBg {
    background-color: #8e0000
}

.RedBerryColor {
    color: #8e0000
}

.RedDamaskBeforeBg:before,
.RedDamaskAfterBg:after,
.RedDamaskBg {
    background-color: #da6a41
}

.RedDamaskColor {
    color: #da6a41
}

.RedDevilBeforeBg:before,
.RedDevilAfterBg:after,
.RedDevilBg {
    background-color: #860111
}

.RedDevilColor {
    color: #860111
}

.RedOrangeBeforeBg:before,
.RedOrangeAfterBg:after,
.RedOrangeBg {
    background-color: #ff5349
}

.RedOrangeColor {
    color: #ff5349
}

.RedOxideBeforeBg:before,
.RedOxideAfterBg:after,
.RedOxideBg {
    background-color: #6e0902
}

.RedOxideColor {
    color: #6e0902
}

.RedPurpleBeforeBg:before,
.RedPurpleAfterBg:after,
.RedPurpleBg {
    background-color: #e40078
}

.RedPurpleColor {
    color: #e40078
}

.RedRibbonBeforeBg:before,
.RedRibbonAfterBg:after,
.RedRibbonBg {
    background-color: #ed0a3f
}

.RedRibbonColor {
    color: #ed0a3f
}

.RedRobinBeforeBg:before,
.RedRobinAfterBg:after,
.RedRobinBg {
    background-color: #80341f
}

.RedRobinColor {
    color: #80341f
}

.RedSalsaBeforeBg:before,
.RedSalsaAfterBg:after,
.RedSalsaBg {
    background-color: #fd3a4a
}

.RedSalsaColor {
    color: #fd3a4a
}

.RedStageBeforeBg:before,
.RedStageAfterBg:after,
.RedStageBg {
    background-color: #d05f04
}

.RedStageColor {
    color: #d05f04
}

.RedVioletBeforeBg:before,
.RedVioletAfterBg:after,
.RedVioletBg {
    background-color: #c71585
}

.RedVioletColor {
    color: #c71585
}

.RedwoodBeforeBg:before,
.RedwoodAfterBg:after,
.RedwoodBg {
    background-color: #a45a52
}

.RedwoodColor {
    color: #a45a52
}

.ReefBeforeBg:before,
.ReefAfterBg:after,
.ReefBg {
    background-color: #c9ffa2
}

.ReefColor {
    color: #c9ffa2
}

.ReefGoldBeforeBg:before,
.ReefGoldAfterBg:after,
.ReefGoldBg {
    background-color: #9f821c
}

.ReefGoldColor {
    color: #9f821c
}

.RegalBlueBeforeBg:before,
.RegalBlueAfterBg:after,
.RegalBlueBg {
    background-color: #013f6a
}

.RegalBlueColor {
    color: #013f6a
}

.RegaliaBeforeBg:before,
.RegaliaAfterBg:after,
.RegaliaBg {
    background-color: #522d80
}

.RegaliaColor {
    color: #522d80
}

.RegentGrayBeforeBg:before,
.RegentGrayAfterBg:after,
.RegentGrayBg {
    background-color: #86949f
}

.RegentGrayColor {
    color: #86949f
}

.RegentStBlueBeforeBg:before,
.RegentStBlueAfterBg:after,
.RegentStBlueBg {
    background-color: #aad6e6
}

.RegentStBlueColor {
    color: #aad6e6
}

.RemyBeforeBg:before,
.RemyAfterBg:after,
.RemyBg {
    background-color: #feebf3
}

.RemyColor {
    color: #feebf3
}

.RenoSandBeforeBg:before,
.RenoSandAfterBg:after,
.RenoSandBg {
    background-color: #a86515
}

.RenoSandColor {
    color: #a86515
}

.ResolutionBlueBeforeBg:before,
.ResolutionBlueAfterBg:after,
.ResolutionBlueBg {
    background-color: #002387
}

.ResolutionBlueColor {
    color: #002387
}

.RevolverBeforeBg:before,
.RevolverAfterBg:after,
.RevolverBg {
    background-color: #2c1632
}

.RevolverColor {
    color: #2c1632
}

.RhinoBeforeBg:before,
.RhinoAfterBg:after,
.RhinoBg {
    background-color: #2e3f62
}

.RhinoColor {
    color: #2e3f62
}

.RhythmBeforeBg:before,
.RhythmAfterBg:after,
.RhythmBg {
    background-color: #777696
}

.RhythmColor {
    color: #777696
}

.RiceCakeBeforeBg:before,
.RiceCakeAfterBg:after,
.RiceCakeBg {
    background-color: #fffef0
}

.RiceCakeColor {
    color: #fffef0
}

.RiceFlowerBeforeBg:before,
.RiceFlowerAfterBg:after,
.RiceFlowerBg {
    background-color: #eeffe2
}

.RiceFlowerColor {
    color: #eeffe2
}

.RichBlackBeforeBg:before,
.RichBlackAfterBg:after,
.RichBlackBg {
    background-color: #004040
}

.RichBlackColor {
    color: #004040
}

.RichBrilliantLavenderBeforeBg:before,
.RichBrilliantLavenderAfterBg:after,
.RichBrilliantLavenderBg {
    background-color: #f1a7fe
}

.RichBrilliantLavenderColor {
    color: #f1a7fe
}

.RichCarmineBeforeBg:before,
.RichCarmineAfterBg:after,
.RichCarmineBg {
    background-color: #d70040
}

.RichCarmineColor {
    color: #d70040
}

.RichElectricBlueBeforeBg:before,
.RichElectricBlueAfterBg:after,
.RichElectricBlueBg {
    background-color: #0892d0
}

.RichElectricBlueColor {
    color: #0892d0
}

.RichGoldBeforeBg:before,
.RichGoldAfterBg:after,
.RichGoldBg {
    background-color: #a85307
}

.RichGoldColor {
    color: #a85307
}

.RichLavenderBeforeBg:before,
.RichLavenderAfterBg:after,
.RichLavenderBg {
    background-color: #a76bcf
}

.RichLavenderColor {
    color: #a76bcf
}

.RichLilacBeforeBg:before,
.RichLilacAfterBg:after,
.RichLilacBg {
    background-color: #b666d2
}

.RichLilacColor {
    color: #b666d2
}

.RichMaroonBeforeBg:before,
.RichMaroonAfterBg:after,
.RichMaroonBg {
    background-color: #b03060
}

.RichMaroonColor {
    color: #b03060
}

.RifleGreenBeforeBg:before,
.RifleGreenAfterBg:after,
.RifleGreenBg {
    background-color: #444c38
}

.RifleGreenColor {
    color: #444c38
}

.RioGrandeBeforeBg:before,
.RioGrandeAfterBg:after,
.RioGrandeBg {
    background-color: #bbd009
}

.RioGrandeColor {
    color: #bbd009
}

.RipeLemonBeforeBg:before,
.RipeLemonAfterBg:after,
.RipeLemonBg {
    background-color: #f4d81c
}

.RipeLemonColor {
    color: #f4d81c
}

.RipePlumBeforeBg:before,
.RipePlumAfterBg:after,
.RipePlumBg {
    background-color: #410056
}

.RipePlumColor {
    color: #410056
}

.RiptideBeforeBg:before,
.RiptideAfterBg:after,
.RiptideBg {
    background-color: #8be6d8
}

.RiptideColor {
    color: #8be6d8
}

.RiverBedBeforeBg:before,
.RiverBedAfterBg:after,
.RiverBedBg {
    background-color: #434c59
}

.RiverBedColor {
    color: #434c59
}

.RoastCoffeeBeforeBg:before,
.RoastCoffeeAfterBg:after,
.RoastCoffeeBg {
    background-color: #704241
}

.RoastCoffeeColor {
    color: #704241
}

.RobRoyBeforeBg:before,
.RobRoyAfterBg:after,
.RobRoyBg {
    background-color: #eac674
}

.RobRoyColor {
    color: #eac674
}

.RobinEggBlueBeforeBg:before,
.RobinEggBlueAfterBg:after,
.RobinEggBlueBg {
    background-color: #00cccc
}

.RobinEggBlueColor {
    color: #00cccc
}

.RockBeforeBg:before,
.RockAfterBg:after,
.RockBg {
    background-color: #4d3833
}

.RockColor {
    color: #4d3833
}

.RockBlueBeforeBg:before,
.RockBlueAfterBg:after,
.RockBlueBg {
    background-color: #9eb1cd
}

.RockBlueColor {
    color: #9eb1cd
}

.RockSprayBeforeBg:before,
.RockSprayAfterBg:after,
.RockSprayBg {
    background-color: #ba450c
}

.RockSprayColor {
    color: #ba450c
}

.RocketMetallicBeforeBg:before,
.RocketMetallicAfterBg:after,
.RocketMetallicBg {
    background-color: #8a7f80
}

.RocketMetallicColor {
    color: #8a7f80
}

.RodeoDustBeforeBg:before,
.RodeoDustAfterBg:after,
.RodeoDustBg {
    background-color: #c9b29b
}

.RodeoDustColor {
    color: #c9b29b
}

.RollingStoneBeforeBg:before,
.RollingStoneAfterBg:after,
.RollingStoneBg {
    background-color: #747d83
}

.RollingStoneColor {
    color: #747d83
}

.RomanBeforeBg:before,
.RomanAfterBg:after,
.RomanBg {
    background-color: #de6360
}

.RomanColor {
    color: #de6360
}

.RomanCoffeeBeforeBg:before,
.RomanCoffeeAfterBg:after,
.RomanCoffeeBg {
    background-color: #795d4c
}

.RomanCoffeeColor {
    color: #795d4c
}

.RomanSilverBeforeBg:before,
.RomanSilverAfterBg:after,
.RomanSilverBg {
    background-color: #838996
}

.RomanSilverColor {
    color: #838996
}

.RomanceBeforeBg:before,
.RomanceAfterBg:after,
.RomanceBg {
    background-color: #fffefd
}

.RomanceColor {
    color: #fffefd
}

.RomanticBeforeBg:before,
.RomanticAfterBg:after,
.RomanticBg {
    background-color: #ffd2b7
}

.RomanticColor {
    color: #ffd2b7
}

.RonchiBeforeBg:before,
.RonchiAfterBg:after,
.RonchiBg {
    background-color: #ecc54e
}

.RonchiColor {
    color: #ecc54e
}

.RoofTerracottaBeforeBg:before,
.RoofTerracottaAfterBg:after,
.RoofTerracottaBg {
    background-color: #a62f20
}

.RoofTerracottaColor {
    color: #a62f20
}

.RopeBeforeBg:before,
.RopeAfterBg:after,
.RopeBg {
    background-color: #8e4d1e
}

.RopeColor {
    color: #8e4d1e
}

.RoseBeforeBg:before,
.RoseAfterBg:after,
.RoseBg {
    background-color: #ff007f
}

.RoseColor {
    color: #ff007f
}

.RoseBonbonBeforeBg:before,
.RoseBonbonAfterBg:after,
.RoseBonbonBg {
    background-color: #f9429e
}

.RoseBonbonColor {
    color: #f9429e
}

.RoseBudBeforeBg:before,
.RoseBudAfterBg:after,
.RoseBudBg {
    background-color: #fbb2a3
}

.RoseBudColor {
    color: #fbb2a3
}

.RoseBudCherryBeforeBg:before,
.RoseBudCherryAfterBg:after,
.RoseBudCherryBg {
    background-color: #800b47
}

.RoseBudCherryColor {
    color: #800b47
}

.RoseDustBeforeBg:before,
.RoseDustAfterBg:after,
.RoseDustBg {
    background-color: #9e5e6f
}

.RoseDustColor {
    color: #9e5e6f
}

.RoseEbonyBeforeBg:before,
.RoseEbonyAfterBg:after,
.RoseEbonyBg {
    background-color: #674846
}

.RoseEbonyColor {
    color: #674846
}

.RoseFogBeforeBg:before,
.RoseFogAfterBg:after,
.RoseFogBg {
    background-color: #e7bcb4
}

.RoseFogColor {
    color: #e7bcb4
}

.RoseGoldBeforeBg:before,
.RoseGoldAfterBg:after,
.RoseGoldBg {
    background-color: #b76e79
}

.RoseGoldColor {
    color: #b76e79
}

.RosePinkBeforeBg:before,
.RosePinkAfterBg:after,
.RosePinkBg {
    background-color: #ff66cc
}

.RosePinkColor {
    color: #ff66cc
}

.RoseRedBeforeBg:before,
.RoseRedAfterBg:after,
.RoseRedBg {
    background-color: #c21e56
}

.RoseRedColor {
    color: #c21e56
}

.RoseTaupeBeforeBg:before,
.RoseTaupeAfterBg:after,
.RoseTaupeBg {
    background-color: #905d5d
}

.RoseTaupeColor {
    color: #905d5d
}

.RoseValeBeforeBg:before,
.RoseValeAfterBg:after,
.RoseValeBg {
    background-color: #ab4e52
}

.RoseValeColor {
    color: #ab4e52
}

.RoseWhiteBeforeBg:before,
.RoseWhiteAfterBg:after,
.RoseWhiteBg {
    background-color: #fff6f5
}

.RoseWhiteColor {
    color: #fff6f5
}

.RoseofSharonBeforeBg:before,
.RoseofSharonAfterBg:after,
.RoseofSharonBg {
    background-color: #bf5500
}

.RoseofSharonColor {
    color: #bf5500
}

.RosewoodBeforeBg:before,
.RosewoodAfterBg:after,
.RosewoodBg {
    background-color: #65000b
}

.RosewoodColor {
    color: #65000b
}

.RossoCorsaBeforeBg:before,
.RossoCorsaAfterBg:after,
.RossoCorsaBg {
    background-color: #d40000
}

.RossoCorsaColor {
    color: #d40000
}

.RosyBrownBeforeBg:before,
.RosyBrownAfterBg:after,
.RosyBrownBg {
    background-color: #bc8f8f
}

.RosyBrownColor {
    color: #bc8f8f
}

.RotiBeforeBg:before,
.RotiAfterBg:after,
.RotiBg {
    background-color: #c6a84b
}

.RotiColor {
    color: #c6a84b
}

.RougeBeforeBg:before,
.RougeAfterBg:after,
.RougeBg {
    background-color: #a23b6c
}

.RougeColor {
    color: #a23b6c
}

.RoyalAirForceBlueBeforeBg:before,
.RoyalAirForceBlueAfterBg:after,
.RoyalAirForceBlueBg {
    background-color: #5d8aa8
}

.RoyalAirForceBlueColor {
    color: #5d8aa8
}

.RoyalAzureBeforeBg:before,
.RoyalAzureAfterBg:after,
.RoyalAzureBg {
    background-color: #0038a8
}

.RoyalAzureColor {
    color: #0038a8
}

.RoyalBlueBeforeBg:before,
.RoyalBlueAfterBg:after,
.RoyalBlueBg {
    background-color: #4169e1
}

.RoyalBlueColor {
    color: #4169e1
}

.RoyalFuchsiaBeforeBg:before,
.RoyalFuchsiaAfterBg:after,
.RoyalFuchsiaBg {
    background-color: #ca2c92
}

.RoyalFuchsiaColor {
    color: #ca2c92
}

.RoyalHeathBeforeBg:before,
.RoyalHeathAfterBg:after,
.RoyalHeathBg {
    background-color: #ab3472
}

.RoyalHeathColor {
    color: #ab3472
}

.RoyalPurpleBeforeBg:before,
.RoyalPurpleAfterBg:after,
.RoyalPurpleBg {
    background-color: #7851a9
}

.RoyalPurpleColor {
    color: #7851a9
}

.RuberBeforeBg:before,
.RuberAfterBg:after,
.RuberBg {
    background-color: #ce4676
}

.RuberColor {
    color: #ce4676
}

.RubineRedBeforeBg:before,
.RubineRedAfterBg:after,
.RubineRedBg {
    background-color: #d10056
}

.RubineRedColor {
    color: #d10056
}

.RubyBeforeBg:before,
.RubyAfterBg:after,
.RubyBg {
    background-color: #e0115f
}

.RubyColor {
    color: #e0115f
}

.RubyRedBeforeBg:before,
.RubyRedAfterBg:after,
.RubyRedBg {
    background-color: #9b111e
}

.RubyRedColor {
    color: #9b111e
}

.RuddyBeforeBg:before,
.RuddyAfterBg:after,
.RuddyBg {
    background-color: #ff0028
}

.RuddyColor {
    color: #ff0028
}

.RuddyBrownBeforeBg:before,
.RuddyBrownAfterBg:after,
.RuddyBrownBg {
    background-color: #bb6528
}

.RuddyBrownColor {
    color: #bb6528
}

.RuddyPinkBeforeBg:before,
.RuddyPinkAfterBg:after,
.RuddyPinkBg {
    background-color: #e18e96
}

.RuddyPinkColor {
    color: #e18e96
}

.RufousBeforeBg:before,
.RufousAfterBg:after,
.RufousBg {
    background-color: #a81c07
}

.RufousColor {
    color: #a81c07
}

.RumBeforeBg:before,
.RumAfterBg:after,
.RumBg {
    background-color: #796989
}

.RumColor {
    color: #796989
}

.RumSwizzleBeforeBg:before,
.RumSwizzleAfterBg:after,
.RumSwizzleBg {
    background-color: #f9f8e4
}

.RumSwizzleColor {
    color: #f9f8e4
}

.RussetBeforeBg:before,
.RussetAfterBg:after,
.RussetBg {
    background-color: #80461b
}

.RussetColor {
    color: #80461b
}

.RussettBeforeBg:before,
.RussettAfterBg:after,
.RussettBg {
    background-color: #755a57
}

.RussettColor {
    color: #755a57
}

.RussianGreenBeforeBg:before,
.RussianGreenAfterBg:after,
.RussianGreenBg {
    background-color: #679267
}

.RussianGreenColor {
    color: #679267
}

.RussianVioletBeforeBg:before,
.RussianVioletAfterBg:after,
.RussianVioletBg {
    background-color: #32174d
}

.RussianVioletColor {
    color: #32174d
}

.RustBeforeBg:before,
.RustAfterBg:after,
.RustBg {
    background-color: #b7410e
}

.RustColor {
    color: #b7410e
}

.RusticRedBeforeBg:before,
.RusticRedAfterBg:after,
.RusticRedBg {
    background-color: #480404
}

.RusticRedColor {
    color: #480404
}

.RustyNailBeforeBg:before,
.RustyNailAfterBg:after,
.RustyNailBg {
    background-color: #86560a
}

.RustyNailColor {
    color: #86560a
}

.RustyRedBeforeBg:before,
.RustyRedAfterBg:after,
.RustyRedBg {
    background-color: #da2c43
}

.RustyRedColor {
    color: #da2c43
}

.SAEECEAmberBeforeBg:before,
.SAEECEAmberAfterBg:after,
.SAEECEAmberBg {
    background-color: #ff7e00
}

.SAEECEAmberColor {
    color: #ff7e00
}

.SacramentoStateGreenBeforeBg:before,
.SacramentoStateGreenAfterBg:after,
.SacramentoStateGreenBg {
    background-color: #043927
}

.SacramentoStateGreenColor {
    color: #043927
}

.SaddleBeforeBg:before,
.SaddleAfterBg:after,
.SaddleBg {
    background-color: #4c3024
}

.SaddleColor {
    color: #4c3024
}

.SaddleBrownBeforeBg:before,
.SaddleBrownAfterBg:after,
.SaddleBrownBg {
    background-color: #8b4513
}

.SaddleBrownColor {
    color: #8b4513
}

.SafetyOrangeBeforeBg:before,
.SafetyOrangeAfterBg:after,
.SafetyOrangeBg {
    background-color: #ff7800
}

.SafetyOrangeColor {
    color: #ff7800
}

.SafetyYellowBeforeBg:before,
.SafetyYellowAfterBg:after,
.SafetyYellowBg {
    background-color: #eed202
}

.SafetyYellowColor {
    color: #eed202
}

.SaffronBeforeBg:before,
.SaffronAfterBg:after,
.SaffronBg {
    background-color: #f4c430
}

.SaffronColor {
    color: #f4c430
}

.SaffronMangoBeforeBg:before,
.SaffronMangoAfterBg:after,
.SaffronMangoBg {
    background-color: #f9bf58
}

.SaffronMangoColor {
    color: #f9bf58
}

.SageBeforeBg:before,
.SageAfterBg:after,
.SageBg {
    background-color: #bcb88a
}

.SageColor {
    color: #bcb88a
}

.SaharaBeforeBg:before,
.SaharaAfterBg:after,
.SaharaBg {
    background-color: #b7a214
}

.SaharaColor {
    color: #b7a214
}

.SaharaSandBeforeBg:before,
.SaharaSandAfterBg:after,
.SaharaSandBg {
    background-color: #f1e788
}

.SaharaSandColor {
    color: #f1e788
}

.SailBeforeBg:before,
.SailAfterBg:after,
.SailBg {
    background-color: #b8e0f9
}

.SailColor {
    color: #b8e0f9
}

.SalemBeforeBg:before,
.SalemAfterBg:after,
.SalemBg {
    background-color: #097f4b
}

.SalemColor {
    color: #097f4b
}

.SalmonBeforeBg:before,
.SalmonAfterBg:after,
.SalmonBg {
    background-color: #fa8072
}

.SalmonColor {
    color: #fa8072
}

.SalmonPinkBeforeBg:before,
.SalmonPinkAfterBg:after,
.SalmonPinkBg {
    background-color: #ff91a4
}

.SalmonPinkColor {
    color: #ff91a4
}

.SalomieBeforeBg:before,
.SalomieAfterBg:after,
.SalomieBg {
    background-color: #fedb8d
}

.SalomieColor {
    color: #fedb8d
}

.SaltBoxBeforeBg:before,
.SaltBoxAfterBg:after,
.SaltBoxBg {
    background-color: #685e6e
}

.SaltBoxColor {
    color: #685e6e
}

.SaltpanBeforeBg:before,
.SaltpanAfterBg:after,
.SaltpanBg {
    background-color: #f1f7f2
}

.SaltpanColor {
    color: #f1f7f2
}

.SambucaBeforeBg:before,
.SambucaAfterBg:after,
.SambucaBg {
    background-color: #3a2010
}

.SambucaColor {
    color: #3a2010
}

.SanFelixBeforeBg:before,
.SanFelixAfterBg:after,
.SanFelixBg {
    background-color: #0b6207
}

.SanFelixColor {
    color: #0b6207
}

.SanJuanBeforeBg:before,
.SanJuanAfterBg:after,
.SanJuanBg {
    background-color: #304b6a
}

.SanJuanColor {
    color: #304b6a
}

.SanMarinoBeforeBg:before,
.SanMarinoAfterBg:after,
.SanMarinoBg {
    background-color: #456cac
}

.SanMarinoColor {
    color: #456cac
}

.SandDuneBeforeBg:before,
.SandDuneAfterBg:after,
.SandDuneBg {
    background-color: #967117
}

.SandDuneColor {
    color: #967117
}

.SandalBeforeBg:before,
.SandalAfterBg:after,
.SandalBg {
    background-color: #aa8d6f
}

.SandalColor {
    color: #aa8d6f
}

.SandriftBeforeBg:before,
.SandriftAfterBg:after,
.SandriftBg {
    background-color: #ab917a
}

.SandriftColor {
    color: #ab917a
}

.SandstoneBeforeBg:before,
.SandstoneAfterBg:after,
.SandstoneBg {
    background-color: #796d62
}

.SandstoneColor {
    color: #796d62
}

.SandstormBeforeBg:before,
.SandstormAfterBg:after,
.SandstormBg {
    background-color: #ecd540
}

.SandstormColor {
    color: #ecd540
}

.SandwispBeforeBg:before,
.SandwispAfterBg:after,
.SandwispBg {
    background-color: #f5e7a2
}

.SandwispColor {
    color: #f5e7a2
}

.SandyBeachBeforeBg:before,
.SandyBeachAfterBg:after,
.SandyBeachBg {
    background-color: #ffeac8
}

.SandyBeachColor {
    color: #ffeac8
}

.SandyBrownBeforeBg:before,
.SandyBrownAfterBg:after,
.SandyBrownBg {
    background-color: #f4a460
}

.SandyBrownColor {
    color: #f4a460
}

.SangriaBeforeBg:before,
.SangriaAfterBg:after,
.SangriaBg {
    background-color: #92000a
}

.SangriaColor {
    color: #92000a
}

.SanguineBrownBeforeBg:before,
.SanguineBrownAfterBg:after,
.SanguineBrownBg {
    background-color: #8d3d38
}

.SanguineBrownColor {
    color: #8d3d38
}

.SantaFeBeforeBg:before,
.SantaFeAfterBg:after,
.SantaFeBg {
    background-color: #b16d52
}

.SantaFeColor {
    color: #b16d52
}

.SantasGrayBeforeBg:before,
.SantasGrayAfterBg:after,
.SantasGrayBg {
    background-color: #9fa0b1
}

.SantasGrayColor {
    color: #9fa0b1
}

.SapGreenBeforeBg:before,
.SapGreenAfterBg:after,
.SapGreenBg {
    background-color: #507d2a
}

.SapGreenColor {
    color: #507d2a
}

.SaplingBeforeBg:before,
.SaplingAfterBg:after,
.SaplingBg {
    background-color: #ded4a4
}

.SaplingColor {
    color: #ded4a4
}

.SapphireBeforeBg:before,
.SapphireAfterBg:after,
.SapphireBg {
    background-color: #0f52ba
}

.SapphireColor {
    color: #0f52ba
}

.SapphireBlueBeforeBg:before,
.SapphireBlueAfterBg:after,
.SapphireBlueBg {
    background-color: #0067a5
}

.SapphireBlueColor {
    color: #0067a5
}

.SaratogaBeforeBg:before,
.SaratogaAfterBg:after,
.SaratogaBg {
    background-color: #555b10
}

.SaratogaColor {
    color: #555b10
}

.SasquatchSocksBeforeBg:before,
.SasquatchSocksAfterBg:after,
.SasquatchSocksBg {
    background-color: #ff4681
}

.SasquatchSocksColor {
    color: #ff4681
}

.SatinLinenBeforeBg:before,
.SatinLinenAfterBg:after,
.SatinLinenBg {
    background-color: #e6e4d4
}

.SatinLinenColor {
    color: #e6e4d4
}

.SatinSheenGoldBeforeBg:before,
.SatinSheenGoldAfterBg:after,
.SatinSheenGoldBg {
    background-color: #cba135
}

.SatinSheenGoldColor {
    color: #cba135
}

.SauvignonBeforeBg:before,
.SauvignonAfterBg:after,
.SauvignonBg {
    background-color: #fff5f3
}

.SauvignonColor {
    color: #fff5f3
}

.SazeracBeforeBg:before,
.SazeracAfterBg:after,
.SazeracBg {
    background-color: #fff4e0
}

.SazeracColor {
    color: #fff4e0
}

.ScampiBeforeBg:before,
.ScampiAfterBg:after,
.ScampiBg {
    background-color: #675fa6
}

.ScampiColor {
    color: #675fa6
}

.ScandalBeforeBg:before,
.ScandalAfterBg:after,
.ScandalBg {
    background-color: #cffaf4
}

.ScandalColor {
    color: #cffaf4
}

.ScarletBeforeBg:before,
.ScarletAfterBg:after,
.ScarletBg {
    background-color: #ff2400
}

.ScarletColor {
    color: #ff2400
}

.ScarletGumBeforeBg:before,
.ScarletGumAfterBg:after,
.ScarletGumBg {
    background-color: #431560
}

.ScarletGumColor {
    color: #431560
}

.ScarlettBeforeBg:before,
.ScarlettAfterBg:after,
.ScarlettBg {
    background-color: #950015
}

.ScarlettColor {
    color: #950015
}

.ScarpaFlowBeforeBg:before,
.ScarpaFlowAfterBg:after,
.ScarpaFlowBg {
    background-color: #585562
}

.ScarpaFlowColor {
    color: #585562
}

.SchistBeforeBg:before,
.SchistAfterBg:after,
.SchistBg {
    background-color: #a9b497
}

.SchistColor {
    color: #a9b497
}

.SchoolBusYellowBeforeBg:before,
.SchoolBusYellowAfterBg:after,
.SchoolBusYellowBg {
    background-color: #ffd800
}

.SchoolBusYellowColor {
    color: #ffd800
}

.SchoonerBeforeBg:before,
.SchoonerAfterBg:after,
.SchoonerBg {
    background-color: #8b847e
}

.SchoonerColor {
    color: #8b847e
}

.ScienceBlueBeforeBg:before,
.ScienceBlueAfterBg:after,
.ScienceBlueBg {
    background-color: #0066cc
}

.ScienceBlueColor {
    color: #0066cc
}

.ScooterBeforeBg:before,
.ScooterAfterBg:after,
.ScooterBg {
    background-color: #2ebfd4
}

.ScooterColor {
    color: #2ebfd4
}

.ScorpionBeforeBg:before,
.ScorpionAfterBg:after,
.ScorpionBg {
    background-color: #695f62
}

.ScorpionColor {
    color: #695f62
}

.ScotchMistBeforeBg:before,
.ScotchMistAfterBg:after,
.ScotchMistBg {
    background-color: #fffbdc
}

.ScotchMistColor {
    color: #fffbdc
}

.ScreaminGreenBeforeBg:before,
.ScreaminGreenAfterBg:after,
.ScreaminGreenBg {
    background-color: #66ff66
}

.ScreaminGreenColor {
    color: #66ff66
}

.SeaBlueBeforeBg:before,
.SeaBlueAfterBg:after,
.SeaBlueBg {
    background-color: #006994
}

.SeaBlueColor {
    color: #006994
}

.SeaBuckthornBeforeBg:before,
.SeaBuckthornAfterBg:after,
.SeaBuckthornBg {
    background-color: #fba129
}

.SeaBuckthornColor {
    color: #fba129
}

.SeaGreenBeforeBg:before,
.SeaGreenAfterBg:after,
.SeaGreenBg {
    background-color: #2e8b57
}

.SeaGreenColor {
    color: #2e8b57
}

.SeaMistBeforeBg:before,
.SeaMistAfterBg:after,
.SeaMistBg {
    background-color: #c5dbca
}

.SeaMistColor {
    color: #c5dbca
}

.SeaNymphBeforeBg:before,
.SeaNymphAfterBg:after,
.SeaNymphBg {
    background-color: #78a39c
}

.SeaNymphColor {
    color: #78a39c
}

.SeaPinkBeforeBg:before,
.SeaPinkAfterBg:after,
.SeaPinkBg {
    background-color: #ed989e
}

.SeaPinkColor {
    color: #ed989e
}

.SeaSerpentBeforeBg:before,
.SeaSerpentAfterBg:after,
.SeaSerpentBg {
    background-color: #4bc7cf
}

.SeaSerpentColor {
    color: #4bc7cf
}

.SeagullBeforeBg:before,
.SeagullAfterBg:after,
.SeagullBg {
    background-color: #80ccea
}

.SeagullColor {
    color: #80ccea
}

.SealBrownBeforeBg:before,
.SealBrownAfterBg:after,
.SealBrownBg {
    background-color: #59260b
}

.SealBrownColor {
    color: #59260b
}

.SeanceBeforeBg:before,
.SeanceAfterBg:after,
.SeanceBg {
    background-color: #731e8f
}

.SeanceColor {
    color: #731e8f
}

.SeashellBeforeBg:before,
.SeashellAfterBg:after,
.SeashellBg {
    background-color: #fff5ee
}

.SeashellColor {
    color: #fff5ee
}

.SeaweedBeforeBg:before,
.SeaweedAfterBg:after,
.SeaweedBg {
    background-color: #1b2f11
}

.SeaweedColor {
    color: #1b2f11
}

.SelagoBeforeBg:before,
.SelagoAfterBg:after,
.SelagoBg {
    background-color: #f0eefd
}

.SelagoColor {
    color: #f0eefd
}

.SelectiveYellowBeforeBg:before,
.SelectiveYellowAfterBg:after,
.SelectiveYellowBg {
    background-color: #ffba00
}

.SelectiveYellowColor {
    color: #ffba00
}

.SepiaBeforeBg:before,
.SepiaAfterBg:after,
.SepiaBg {
    background-color: #704214
}

.SepiaColor {
    color: #704214
}

.SepiaBlackBeforeBg:before,
.SepiaBlackAfterBg:after,
.SepiaBlackBg {
    background-color: #2b0202
}

.SepiaBlackColor {
    color: #2b0202
}

.SepiaSkinBeforeBg:before,
.SepiaSkinAfterBg:after,
.SepiaSkinBg {
    background-color: #9e5b40
}

.SepiaSkinColor {
    color: #9e5b40
}

.SerenadeBeforeBg:before,
.SerenadeAfterBg:after,
.SerenadeBg {
    background-color: #fff4e8
}

.SerenadeColor {
    color: #fff4e8
}

.ShadowBeforeBg:before,
.ShadowAfterBg:after,
.ShadowBg {
    background-color: #8a795d
}

.ShadowColor {
    color: #8a795d
}

.ShadowBlueBeforeBg:before,
.ShadowBlueAfterBg:after,
.ShadowBlueBg {
    background-color: #778ba5
}

.ShadowBlueColor {
    color: #778ba5
}

.ShadowGreenBeforeBg:before,
.ShadowGreenAfterBg:after,
.ShadowGreenBg {
    background-color: #9ac2b8
}

.ShadowGreenColor {
    color: #9ac2b8
}

.ShadyLadyBeforeBg:before,
.ShadyLadyAfterBg:after,
.ShadyLadyBg {
    background-color: #aaa5a9
}

.ShadyLadyColor {
    color: #aaa5a9
}

.ShakespeareBeforeBg:before,
.ShakespeareAfterBg:after,
.ShakespeareBg {
    background-color: #4eabd1
}

.ShakespeareColor {
    color: #4eabd1
}

.ShalimarBeforeBg:before,
.ShalimarAfterBg:after,
.ShalimarBg {
    background-color: #fbffba
}

.ShalimarColor {
    color: #fbffba
}

.ShampooBeforeBg:before,
.ShampooAfterBg:after,
.ShampooBg {
    background-color: #ffcff1
}

.ShampooColor {
    color: #ffcff1
}

.ShamrockBeforeBg:before,
.ShamrockAfterBg:after,
.ShamrockBg {
    background-color: #33cc99
}

.ShamrockColor {
    color: #33cc99
}

.ShamrockGreenBeforeBg:before,
.ShamrockGreenAfterBg:after,
.ShamrockGreenBg {
    background-color: #009e60
}

.ShamrockGreenColor {
    color: #009e60
}

.SharkBeforeBg:before,
.SharkAfterBg:after,
.SharkBg {
    background-color: #25272c
}

.SharkColor {
    color: #25272c
}

.SheenGreenBeforeBg:before,
.SheenGreenAfterBg:after,
.SheenGreenBg {
    background-color: #8fd400
}

.SheenGreenColor {
    color: #8fd400
}

.SherpaBlueBeforeBg:before,
.SherpaBlueAfterBg:after,
.SherpaBlueBg {
    background-color: #004950
}

.SherpaBlueColor {
    color: #004950
}

.SherwoodGreenBeforeBg:before,
.SherwoodGreenAfterBg:after,
.SherwoodGreenBg {
    background-color: #02402c
}

.SherwoodGreenColor {
    color: #02402c
}

.ShiloBeforeBg:before,
.ShiloAfterBg:after,
.ShiloBg {
    background-color: #e8b9b3
}

.ShiloColor {
    color: #e8b9b3
}

.ShimmeringBlushBeforeBg:before,
.ShimmeringBlushAfterBg:after,
.ShimmeringBlushBg {
    background-color: #d98695
}

.ShimmeringBlushColor {
    color: #d98695
}

.ShingleFawnBeforeBg:before,
.ShingleFawnAfterBg:after,
.ShingleFawnBg {
    background-color: #6b4e31
}

.ShingleFawnColor {
    color: #6b4e31
}

.ShinyShamrockBeforeBg:before,
.ShinyShamrockAfterBg:after,
.ShinyShamrockBg {
    background-color: #5fa778
}

.ShinyShamrockColor {
    color: #5fa778
}

.ShipCoveBeforeBg:before,
.ShipCoveAfterBg:after,
.ShipCoveBg {
    background-color: #788bba
}

.ShipCoveColor {
    color: #788bba
}

.ShipGrayBeforeBg:before,
.ShipGrayAfterBg:after,
.ShipGrayBg {
    background-color: #3e3a44
}

.ShipGrayColor {
    color: #3e3a44
}

.ShirazBeforeBg:before,
.ShirazAfterBg:after,
.ShirazBg {
    background-color: #b20931
}

.ShirazColor {
    color: #b20931
}

.ShockingBeforeBg:before,
.ShockingAfterBg:after,
.ShockingBg {
    background-color: #e292c0
}

.ShockingColor {
    color: #e292c0
}

.ShockingPinkBeforeBg:before,
.ShockingPinkAfterBg:after,
.ShockingPinkBg {
    background-color: #fc0fc0
}

.ShockingPinkColor {
    color: #fc0fc0
}

.ShuttleGrayBeforeBg:before,
.ShuttleGrayAfterBg:after,
.ShuttleGrayBg {
    background-color: #5f6672
}

.ShuttleGrayColor {
    color: #5f6672
}

.SiamBeforeBg:before,
.SiamAfterBg:after,
.SiamBg {
    background-color: #646a54
}

.SiamColor {
    color: #646a54
}

.SidecarBeforeBg:before,
.SidecarAfterBg:after,
.SidecarBg {
    background-color: #f3e7bb
}

.SidecarColor {
    color: #f3e7bb
}

.SiennaBeforeBg:before,
.SiennaAfterBg:after,
.SiennaBg {
    background-color: #882d17
}

.SiennaColor {
    color: #882d17
}

.SilkBeforeBg:before,
.SilkAfterBg:after,
.SilkBg {
    background-color: #bdb1a8
}

.SilkColor {
    color: #bdb1a8
}

.SilverBeforeBg:before,
.SilverAfterBg:after,
.SilverBg {
    background-color: #c0c0c0
}

.SilverColor {
    color: #c0c0c0
}

.SilverChaliceBeforeBg:before,
.SilverChaliceAfterBg:after,
.SilverChaliceBg {
    background-color: #acacac
}

.SilverChaliceColor {
    color: #acacac
}

.SilverLakeBlueBeforeBg:before,
.SilverLakeBlueAfterBg:after,
.SilverLakeBlueBg {
    background-color: #5d89ba
}

.SilverLakeBlueColor {
    color: #5d89ba
}

.SilverPinkBeforeBg:before,
.SilverPinkAfterBg:after,
.SilverPinkBg {
    background-color: #c4aead
}

.SilverPinkColor {
    color: #c4aead
}

.SilverSandBeforeBg:before,
.SilverSandAfterBg:after,
.SilverSandBg {
    background-color: #bfc1c2
}

.SilverSandColor {
    color: #bfc1c2
}

.SilverTreeBeforeBg:before,
.SilverTreeAfterBg:after,
.SilverTreeBg {
    background-color: #66b58f
}

.SilverTreeColor {
    color: #66b58f
}

.SinbadBeforeBg:before,
.SinbadAfterBg:after,
.SinbadBg {
    background-color: #9fd7d3
}

.SinbadColor {
    color: #9fd7d3
}

.SinopiaBeforeBg:before,
.SinopiaAfterBg:after,
.SinopiaBg {
    background-color: #cb410b
}

.SinopiaColor {
    color: #cb410b
}

.SirenBeforeBg:before,
.SirenAfterBg:after,
.SirenBg {
    background-color: #7a013a
}

.SirenColor {
    color: #7a013a
}

.SiroccoBeforeBg:before,
.SiroccoAfterBg:after,
.SiroccoBg {
    background-color: #718080
}

.SiroccoColor {
    color: #718080
}

.SisalBeforeBg:before,
.SisalAfterBg:after,
.SisalBg {
    background-color: #d3cbba
}

.SisalColor {
    color: #d3cbba
}

.SizzlingRedBeforeBg:before,
.SizzlingRedAfterBg:after,
.SizzlingRedBg {
    background-color: #ff3855
}

.SizzlingRedColor {
    color: #ff3855
}

.SizzlingSunriseBeforeBg:before,
.SizzlingSunriseAfterBg:after,
.SizzlingSunriseBg {
    background-color: #ffdb00
}

.SizzlingSunriseColor {
    color: #ffdb00
}

.SkepticBeforeBg:before,
.SkepticAfterBg:after,
.SkepticBg {
    background-color: #cae6da
}

.SkepticColor {
    color: #cae6da
}

.SkobeloffBeforeBg:before,
.SkobeloffAfterBg:after,
.SkobeloffBg {
    background-color: #007474
}

.SkobeloffColor {
    color: #007474
}

.SkyBlueBeforeBg:before,
.SkyBlueAfterBg:after,
.SkyBlueBg {
    background-color: #87ceeb
}

.SkyBlueColor {
    color: #87ceeb
}

.SkyMagentaBeforeBg:before,
.SkyMagentaAfterBg:after,
.SkyMagentaBg {
    background-color: #cf71af
}

.SkyMagentaColor {
    color: #cf71af
}

.SlateBlueBeforeBg:before,
.SlateBlueAfterBg:after,
.SlateBlueBg {
    background-color: #6a5acd
}

.SlateBlueColor {
    color: #6a5acd
}

.SlateGrayBeforeBg:before,
.SlateGrayAfterBg:after,
.SlateGrayBg {
    background-color: #708090
}

.SlateGrayColor {
    color: #708090
}

.SlimyGreenBeforeBg:before,
.SlimyGreenAfterBg:after,
.SlimyGreenBg {
    background-color: #299617
}

.SlimyGreenColor {
    color: #299617
}

.SmaltBeforeBg:before,
.SmaltAfterBg:after,
.SmaltBg {
    background-color: #003399
}

.SmaltColor {
    color: #003399
}

.SmaltBlueBeforeBg:before,
.SmaltBlueAfterBg:after,
.SmaltBlueBg {
    background-color: #51808f
}

.SmaltBlueColor {
    color: #51808f
}

.SmashedPumpkinBeforeBg:before,
.SmashedPumpkinAfterBg:after,
.SmashedPumpkinBg {
    background-color: #ff6d3a
}

.SmashedPumpkinColor {
    color: #ff6d3a
}

.SmittenBeforeBg:before,
.SmittenAfterBg:after,
.SmittenBg {
    background-color: #c84186
}

.SmittenColor {
    color: #c84186
}

.SmokeBeforeBg:before,
.SmokeAfterBg:after,
.SmokeBg {
    background-color: #738276
}

.SmokeColor {
    color: #738276
}

.SmokeyTopazBeforeBg:before,
.SmokeyTopazAfterBg:after,
.SmokeyTopazBg {
    background-color: #832a0d
}

.SmokeyTopazColor {
    color: #832a0d
}

.SmokyBeforeBg:before,
.SmokyAfterBg:after,
.SmokyBg {
    background-color: #605b73
}

.SmokyColor {
    color: #605b73
}

.SmokyBlackBeforeBg:before,
.SmokyBlackAfterBg:after,
.SmokyBlackBg {
    background-color: #100c08
}

.SmokyBlackColor {
    color: #100c08
}

.SmokyTopazBeforeBg:before,
.SmokyTopazAfterBg:after,
.SmokyTopazBg {
    background-color: #933d41
}

.SmokyTopazColor {
    color: #933d41
}

.SnowBeforeBg:before,
.SnowAfterBg:after,
.SnowBg {
    background-color: #fffafa
}

.SnowColor {
    color: #fffafa
}

.SnowDriftBeforeBg:before,
.SnowDriftAfterBg:after,
.SnowDriftBg {
    background-color: #f7faf7
}

.SnowDriftColor {
    color: #f7faf7
}

.SnowFlurryBeforeBg:before,
.SnowFlurryAfterBg:after,
.SnowFlurryBg {
    background-color: #e4ffd1
}

.SnowFlurryColor {
    color: #e4ffd1
}

.SnowyMintBeforeBg:before,
.SnowyMintAfterBg:after,
.SnowyMintBg {
    background-color: #d6ffdb
}

.SnowyMintColor {
    color: #d6ffdb
}

.SnuffBeforeBg:before,
.SnuffAfterBg:after,
.SnuffBg {
    background-color: #e2d8ed
}

.SnuffColor {
    color: #e2d8ed
}

.SoapBeforeBg:before,
.SoapAfterBg:after,
.SoapBg {
    background-color: #cec8ef
}

.SoapColor {
    color: #cec8ef
}

.SoapstoneBeforeBg:before,
.SoapstoneAfterBg:after,
.SoapstoneBg {
    background-color: #fffbf9
}

.SoapstoneColor {
    color: #fffbf9
}

.SoftAmberBeforeBg:before,
.SoftAmberAfterBg:after,
.SoftAmberBg {
    background-color: #d1c6b4
}

.SoftAmberColor {
    color: #d1c6b4
}

.SoftPeachBeforeBg:before,
.SoftPeachAfterBg:after,
.SoftPeachBg {
    background-color: #f5edef
}

.SoftPeachColor {
    color: #f5edef
}

.SolidPinkBeforeBg:before,
.SolidPinkAfterBg:after,
.SolidPinkBg {
    background-color: #893843
}

.SolidPinkColor {
    color: #893843
}

.SolitaireBeforeBg:before,
.SolitaireAfterBg:after,
.SolitaireBg {
    background-color: #fef8e2
}

.SolitaireColor {
    color: #fef8e2
}

.SolitudeBeforeBg:before,
.SolitudeAfterBg:after,
.SolitudeBg {
    background-color: #eaf6ff
}

.SolitudeColor {
    color: #eaf6ff
}

.SonicSilverBeforeBg:before,
.SonicSilverAfterBg:after,
.SonicSilverBg {
    background-color: #757575
}

.SonicSilverColor {
    color: #757575
}

.SorbusBeforeBg:before,
.SorbusAfterBg:after,
.SorbusBg {
    background-color: #fd7c07
}

.SorbusColor {
    color: #fd7c07
}

.SorrellBrownBeforeBg:before,
.SorrellBrownAfterBg:after,
.SorrellBrownBg {
    background-color: #ceb98f
}

.SorrellBrownColor {
    color: #ceb98f
}

.SoyaBeanBeforeBg:before,
.SoyaBeanAfterBg:after,
.SoyaBeanBg {
    background-color: #6a6051
}

.SoyaBeanColor {
    color: #6a6051
}

.SpaceCadetBeforeBg:before,
.SpaceCadetAfterBg:after,
.SpaceCadetBg {
    background-color: #1d2951
}

.SpaceCadetColor {
    color: #1d2951
}

.SpanishBistreBeforeBg:before,
.SpanishBistreAfterBg:after,
.SpanishBistreBg {
    background-color: #807532
}

.SpanishBistreColor {
    color: #807532
}

.SpanishBlueBeforeBg:before,
.SpanishBlueAfterBg:after,
.SpanishBlueBg {
    background-color: #0070b8
}

.SpanishBlueColor {
    color: #0070b8
}

.SpanishCarmineBeforeBg:before,
.SpanishCarmineAfterBg:after,
.SpanishCarmineBg {
    background-color: #d10047
}

.SpanishCarmineColor {
    color: #d10047
}

.SpanishCrimsonBeforeBg:before,
.SpanishCrimsonAfterBg:after,
.SpanishCrimsonBg {
    background-color: #e51a4c
}

.SpanishCrimsonColor {
    color: #e51a4c
}

.SpanishGrayBeforeBg:before,
.SpanishGrayAfterBg:after,
.SpanishGrayBg {
    background-color: #989898
}

.SpanishGrayColor {
    color: #989898
}

.SpanishGreenBeforeBg:before,
.SpanishGreenAfterBg:after,
.SpanishGreenBg {
    background-color: #009150
}

.SpanishGreenColor {
    color: #009150
}

.SpanishOrangeBeforeBg:before,
.SpanishOrangeAfterBg:after,
.SpanishOrangeBg {
    background-color: #e86100
}

.SpanishOrangeColor {
    color: #e86100
}

.SpanishPinkBeforeBg:before,
.SpanishPinkAfterBg:after,
.SpanishPinkBg {
    background-color: #f7bfbe
}

.SpanishPinkColor {
    color: #f7bfbe
}

.SpanishRedBeforeBg:before,
.SpanishRedAfterBg:after,
.SpanishRedBg {
    background-color: #e60026
}

.SpanishRedColor {
    color: #e60026
}

.SpanishSkyBlueBeforeBg:before,
.SpanishSkyBlueAfterBg:after,
.SpanishSkyBlueBg {
    background-color: #00aae4
}

.SpanishSkyBlueColor {
    color: #00aae4
}

.SpanishVioletBeforeBg:before,
.SpanishVioletAfterBg:after,
.SpanishVioletBg {
    background-color: #4c2882
}

.SpanishVioletColor {
    color: #4c2882
}

.SpanishViridianBeforeBg:before,
.SpanishViridianAfterBg:after,
.SpanishViridianBg {
    background-color: #007f5c
}

.SpanishViridianColor {
    color: #007f5c
}

.SpartanCrimsonBeforeBg:before,
.SpartanCrimsonAfterBg:after,
.SpartanCrimsonBg {
    background-color: #9e1316
}

.SpartanCrimsonColor {
    color: #9e1316
}

.SpectraBeforeBg:before,
.SpectraAfterBg:after,
.SpectraBg {
    background-color: #2f5a57
}

.SpectraColor {
    color: #2f5a57
}

.SpiceBeforeBg:before,
.SpiceAfterBg:after,
.SpiceBg {
    background-color: #6a442e
}

.SpiceColor {
    color: #6a442e
}

.SpicyMixBeforeBg:before,
.SpicyMixAfterBg:after,
.SpicyMixBg {
    background-color: #8b5f4d
}

.SpicyMixColor {
    color: #8b5f4d
}

.SpicyMustardBeforeBg:before,
.SpicyMustardAfterBg:after,
.SpicyMustardBg {
    background-color: #74640d
}

.SpicyMustardColor {
    color: #74640d
}

.SpicyPinkBeforeBg:before,
.SpicyPinkAfterBg:after,
.SpicyPinkBg {
    background-color: #816e71
}

.SpicyPinkColor {
    color: #816e71
}

.SpindleBeforeBg:before,
.SpindleAfterBg:after,
.SpindleBg {
    background-color: #b6d1ea
}

.SpindleColor {
    color: #b6d1ea
}

.SpiroDiscoBallBeforeBg:before,
.SpiroDiscoBallAfterBg:after,
.SpiroDiscoBallBg {
    background-color: #0fc0fc
}

.SpiroDiscoBallColor {
    color: #0fc0fc
}

.SprayBeforeBg:before,
.SprayAfterBg:after,
.SprayBg {
    background-color: #79deec
}

.SprayColor {
    color: #79deec
}

.SpringBudBeforeBg:before,
.SpringBudAfterBg:after,
.SpringBudBg {
    background-color: #a7fc00
}

.SpringBudColor {
    color: #a7fc00
}

.SpringFrostBeforeBg:before,
.SpringFrostAfterBg:after,
.SpringFrostBg {
    background-color: #87ff2a
}

.SpringFrostColor {
    color: #87ff2a
}

.SpringGreenBeforeBg:before,
.SpringGreenAfterBg:after,
.SpringGreenBg {
    background-color: #00ff7f
}

.SpringGreenColor {
    color: #00ff7f
}

.SpringLeavesBeforeBg:before,
.SpringLeavesAfterBg:after,
.SpringLeavesBg {
    background-color: #578363
}

.SpringLeavesColor {
    color: #578363
}

.SpringRainBeforeBg:before,
.SpringRainAfterBg:after,
.SpringRainBg {
    background-color: #accbb1
}

.SpringRainColor {
    color: #accbb1
}

.SpringSunBeforeBg:before,
.SpringSunAfterBg:after,
.SpringSunBg {
    background-color: #f6ffdc
}

.SpringSunColor {
    color: #f6ffdc
}

.SpringWoodBeforeBg:before,
.SpringWoodAfterBg:after,
.SpringWoodBg {
    background-color: #f8f6f1
}

.SpringWoodColor {
    color: #f8f6f1
}

.SproutBeforeBg:before,
.SproutAfterBg:after,
.SproutBg {
    background-color: #c1d7b0
}

.SproutColor {
    color: #c1d7b0
}

.SpunPearlBeforeBg:before,
.SpunPearlAfterBg:after,
.SpunPearlBg {
    background-color: #aaabb7
}

.SpunPearlColor {
    color: #aaabb7
}

.SquirrelBeforeBg:before,
.SquirrelAfterBg:after,
.SquirrelBg {
    background-color: #8f8176
}

.SquirrelColor {
    color: #8f8176
}

.StPatricksBlueBeforeBg:before,
.StPatricksBlueAfterBg:after,
.StPatricksBlueBg {
    background-color: #23297a
}

.StPatricksBlueColor {
    color: #23297a
}

.StTropazBeforeBg:before,
.StTropazAfterBg:after,
.StTropazBg {
    background-color: #2d569b
}

.StTropazColor {
    color: #2d569b
}

.StackBeforeBg:before,
.StackAfterBg:after,
.StackBg {
    background-color: #8a8f8a
}

.StackColor {
    color: #8a8f8a
}

.StarCommandBlueBeforeBg:before,
.StarCommandBlueAfterBg:after,
.StarCommandBlueBg {
    background-color: #007bb8
}

.StarCommandBlueColor {
    color: #007bb8
}

.StarDustBeforeBg:before,
.StarDustAfterBg:after,
.StarDustBg {
    background-color: #9f9f9c
}

.StarDustColor {
    color: #9f9f9c
}

.StarkWhiteBeforeBg:before,
.StarkWhiteAfterBg:after,
.StarkWhiteBg {
    background-color: #e5d7bd
}

.StarkWhiteColor {
    color: #e5d7bd
}

.StarshipBeforeBg:before,
.StarshipAfterBg:after,
.StarshipBg {
    background-color: #ecf245
}

.StarshipColor {
    color: #ecf245
}

.SteelBlueBeforeBg:before,
.SteelBlueAfterBg:after,
.SteelBlueBg {
    background-color: #4682b4
}

.SteelBlueColor {
    color: #4682b4
}

.SteelGrayBeforeBg:before,
.SteelGrayAfterBg:after,
.SteelGrayBg {
    background-color: #262335
}

.SteelGrayColor {
    color: #262335
}

.SteelPinkBeforeBg:before,
.SteelPinkAfterBg:after,
.SteelPinkBg {
    background-color: #cc33cc
}

.SteelPinkColor {
    color: #cc33cc
}

.SteelTealBeforeBg:before,
.SteelTealAfterBg:after,
.SteelTealBg {
    background-color: #5f8a8b
}

.SteelTealColor {
    color: #5f8a8b
}

.StilettoBeforeBg:before,
.StilettoAfterBg:after,
.StilettoBg {
    background-color: #9c3336
}

.StilettoColor {
    color: #9c3336
}

.StonewallBeforeBg:before,
.StonewallAfterBg:after,
.StonewallBg {
    background-color: #928573
}

.StonewallColor {
    color: #928573
}

.StormDustBeforeBg:before,
.StormDustAfterBg:after,
.StormDustBg {
    background-color: #646463
}

.StormDustColor {
    color: #646463
}

.StormGrayBeforeBg:before,
.StormGrayAfterBg:after,
.StormGrayBg {
    background-color: #717486
}

.StormGrayColor {
    color: #717486
}

.StormcloudBeforeBg:before,
.StormcloudAfterBg:after,
.StormcloudBg {
    background-color: #4f666a
}

.StormcloudColor {
    color: #4f666a
}

.StratosBeforeBg:before,
.StratosAfterBg:after,
.StratosBg {
    background-color: #000741
}

.StratosColor {
    color: #000741
}

.StrawBeforeBg:before,
.StrawAfterBg:after,
.StrawBg {
    background-color: #e4d96f
}

.StrawColor {
    color: #e4d96f
}

.StrawberryBeforeBg:before,
.StrawberryAfterBg:after,
.StrawberryBg {
    background-color: #fc5a8d
}

.StrawberryColor {
    color: #fc5a8d
}

.StrikemasterBeforeBg:before,
.StrikemasterAfterBg:after,
.StrikemasterBg {
    background-color: #956387
}

.StrikemasterColor {
    color: #956387
}

.StromboliBeforeBg:before,
.StromboliAfterBg:after,
.StromboliBg {
    background-color: #325d52
}

.StromboliColor {
    color: #325d52
}

.StudioBeforeBg:before,
.StudioAfterBg:after,
.StudioBg {
    background-color: #714ab2
}

.StudioColor {
    color: #714ab2
}

.SubmarineBeforeBg:before,
.SubmarineAfterBg:after,
.SubmarineBg {
    background-color: #bac7c9
}

.SubmarineColor {
    color: #bac7c9
}

.SugarCaneBeforeBg:before,
.SugarCaneAfterBg:after,
.SugarCaneBg {
    background-color: #f9fff6
}

.SugarCaneColor {
    color: #f9fff6
}

.SugarPlumBeforeBg:before,
.SugarPlumAfterBg:after,
.SugarPlumBg {
    background-color: #914e75
}

.SugarPlumColor {
    color: #914e75
}

.SuluBeforeBg:before,
.SuluAfterBg:after,
.SuluBg {
    background-color: #c1f07c
}

.SuluColor {
    color: #c1f07c
}

.SummerGreenBeforeBg:before,
.SummerGreenAfterBg:after,
.SummerGreenBg {
    background-color: #96bbab
}

.SummerGreenColor {
    color: #96bbab
}

.SunBeforeBg:before,
.SunAfterBg:after,
.SunBg {
    background-color: #fbac13
}

.SunColor {
    color: #fbac13
}

.SunburntCyclopsBeforeBg:before,
.SunburntCyclopsAfterBg:after,
.SunburntCyclopsBg {
    background-color: #ff404c
}

.SunburntCyclopsColor {
    color: #ff404c
}

.SundanceBeforeBg:before,
.SundanceAfterBg:after,
.SundanceBg {
    background-color: #c9b35b
}

.SundanceColor {
    color: #c9b35b
}

.SundownBeforeBg:before,
.SundownAfterBg:after,
.SundownBg {
    background-color: #ffb1b3
}

.SundownColor {
    color: #ffb1b3
}

.SunflowerBeforeBg:before,
.SunflowerAfterBg:after,
.SunflowerBg {
    background-color: #e4d422
}

.SunflowerColor {
    color: #e4d422
}

.SungloBeforeBg:before,
.SungloAfterBg:after,
.SungloBg {
    background-color: #e16865
}

.SungloColor {
    color: #e16865
}

.SunglowBeforeBg:before,
.SunglowAfterBg:after,
.SunglowBg {
    background-color: #ffcc33
}

.SunglowColor {
    color: #ffcc33
}

.SunnyBeforeBg:before,
.SunnyAfterBg:after,
.SunnyBg {
    background-color: #f2f27a
}

.SunnyColor {
    color: #f2f27a
}

.SunrayBeforeBg:before,
.SunrayAfterBg:after,
.SunrayBg {
    background-color: #e3ab57
}

.SunrayColor {
    color: #e3ab57
}

.SunsetBeforeBg:before,
.SunsetAfterBg:after,
.SunsetBg {
    background-color: #fad6a5
}

.SunsetColor {
    color: #fad6a5
}

.SunsetOrangeBeforeBg:before,
.SunsetOrangeAfterBg:after,
.SunsetOrangeBg {
    background-color: #fd5e53
}

.SunsetOrangeColor {
    color: #fd5e53
}

.SunshadeBeforeBg:before,
.SunshadeAfterBg:after,
.SunshadeBg {
    background-color: #ff9e2c
}

.SunshadeColor {
    color: #ff9e2c
}

.SuperPinkBeforeBg:before,
.SuperPinkAfterBg:after,
.SuperPinkBg {
    background-color: #cf6ba9
}

.SuperPinkColor {
    color: #cf6ba9
}

.SupernovaBeforeBg:before,
.SupernovaAfterBg:after,
.SupernovaBg {
    background-color: #ffc901
}

.SupernovaColor {
    color: #ffc901
}

.SurfBeforeBg:before,
.SurfAfterBg:after,
.SurfBg {
    background-color: #bbd7c1
}

.SurfColor {
    color: #bbd7c1
}

.SurfCrestBeforeBg:before,
.SurfCrestAfterBg:after,
.SurfCrestBg {
    background-color: #cfe5d2
}

.SurfCrestColor {
    color: #cfe5d2
}

.SurfieGreenBeforeBg:before,
.SurfieGreenAfterBg:after,
.SurfieGreenBg {
    background-color: #0c7a79
}

.SurfieGreenColor {
    color: #0c7a79
}

.SushiBeforeBg:before,
.SushiAfterBg:after,
.SushiBg {
    background-color: #87ab39
}

.SushiColor {
    color: #87ab39
}

.SuvaGrayBeforeBg:before,
.SuvaGrayAfterBg:after,
.SuvaGrayBg {
    background-color: #888387
}

.SuvaGrayColor {
    color: #888387
}

.SwampBeforeBg:before,
.SwampAfterBg:after,
.SwampBg {
    background-color: #001b1c
}

.SwampColor {
    color: #001b1c
}

.SwampGreenBeforeBg:before,
.SwampGreenAfterBg:after,
.SwampGreenBg {
    background-color: #acb78e
}

.SwampGreenColor {
    color: #acb78e
}

.SwansDownBeforeBg:before,
.SwansDownAfterBg:after,
.SwansDownBg {
    background-color: #dcf0ea
}

.SwansDownColor {
    color: #dcf0ea
}

.SweetBrownBeforeBg:before,
.SweetBrownAfterBg:after,
.SweetBrownBg {
    background-color: #a83731
}

.SweetBrownColor {
    color: #a83731
}

.SweetCornBeforeBg:before,
.SweetCornAfterBg:after,
.SweetCornBg {
    background-color: #fbea8c
}

.SweetCornColor {
    color: #fbea8c
}

.SweetPinkBeforeBg:before,
.SweetPinkAfterBg:after,
.SweetPinkBg {
    background-color: #fd9fa2
}

.SweetPinkColor {
    color: #fd9fa2
}

.SwirlBeforeBg:before,
.SwirlAfterBg:after,
.SwirlBg {
    background-color: #d3cdc5
}

.SwirlColor {
    color: #d3cdc5
}

.SwissCoffeeBeforeBg:before,
.SwissCoffeeAfterBg:after,
.SwissCoffeeBg {
    background-color: #ddd6d5
}

.SwissCoffeeColor {
    color: #ddd6d5
}

.SycamoreBeforeBg:before,
.SycamoreAfterBg:after,
.SycamoreBg {
    background-color: #908d39
}

.SycamoreColor {
    color: #908d39
}

.TabascoBeforeBg:before,
.TabascoAfterBg:after,
.TabascoBg {
    background-color: #a02712
}

.TabascoColor {
    color: #a02712
}

.TacaoBeforeBg:before,
.TacaoAfterBg:after,
.TacaoBg {
    background-color: #edb381
}

.TacaoColor {
    color: #edb381
}

.TachaBeforeBg:before,
.TachaAfterBg:after,
.TachaBg {
    background-color: #d6c562
}

.TachaColor {
    color: #d6c562
}

.TahitiGoldBeforeBg:before,
.TahitiGoldAfterBg:after,
.TahitiGoldBg {
    background-color: #e97c07
}

.TahitiGoldColor {
    color: #e97c07
}

.TahunaSandsBeforeBg:before,
.TahunaSandsAfterBg:after,
.TahunaSandsBg {
    background-color: #eef0c8
}

.TahunaSandsColor {
    color: #eef0c8
}

.TallPoppyBeforeBg:before,
.TallPoppyAfterBg:after,
.TallPoppyBg {
    background-color: #b32d29
}

.TallPoppyColor {
    color: #b32d29
}

.TallowBeforeBg:before,
.TallowAfterBg:after,
.TallowBg {
    background-color: #a8a589
}

.TallowColor {
    color: #a8a589
}

.TamarilloBeforeBg:before,
.TamarilloAfterBg:after,
.TamarilloBg {
    background-color: #991613
}

.TamarilloColor {
    color: #991613
}

.TamarindBeforeBg:before,
.TamarindAfterBg:after,
.TamarindBg {
    background-color: #341515
}

.TamarindColor {
    color: #341515
}

.TanBeforeBg:before,
.TanAfterBg:after,
.TanBg {
    background-color: #d2b48c
}

.TanColor {
    color: #d2b48c
}

.TanHideBeforeBg:before,
.TanHideAfterBg:after,
.TanHideBg {
    background-color: #fa9d5a
}

.TanHideColor {
    color: #fa9d5a
}

.TanaBeforeBg:before,
.TanaAfterBg:after,
.TanaBg {
    background-color: #d9dcc1
}

.TanaColor {
    color: #d9dcc1
}

.TangaroaBeforeBg:before,
.TangaroaAfterBg:after,
.TangaroaBg {
    background-color: #03163c
}

.TangaroaColor {
    color: #03163c
}

.TangeloBeforeBg:before,
.TangeloAfterBg:after,
.TangeloBg {
    background-color: #f94d00
}

.TangeloColor {
    color: #f94d00
}

.TangerineBeforeBg:before,
.TangerineAfterBg:after,
.TangerineBg {
    background-color: #f28500
}

.TangerineColor {
    color: #f28500
}

.TangerineYellowBeforeBg:before,
.TangerineYellowAfterBg:after,
.TangerineYellowBg {
    background-color: #ffcc00
}

.TangerineYellowColor {
    color: #ffcc00
}

.TangoBeforeBg:before,
.TangoAfterBg:after,
.TangoBg {
    background-color: #ed7a1c
}

.TangoColor {
    color: #ed7a1c
}

.TangoPinkBeforeBg:before,
.TangoPinkAfterBg:after,
.TangoPinkBg {
    background-color: #e4717a
}

.TangoPinkColor {
    color: #e4717a
}

.TapaBeforeBg:before,
.TapaAfterBg:after,
.TapaBg {
    background-color: #7b7874
}

.TapaColor {
    color: #7b7874
}

.TapestryBeforeBg:before,
.TapestryAfterBg:after,
.TapestryBg {
    background-color: #b05e81
}

.TapestryColor {
    color: #b05e81
}

.TaraBeforeBg:before,
.TaraAfterBg:after,
.TaraBg {
    background-color: #e1f6e8
}

.TaraColor {
    color: #e1f6e8
}

.TaraweraBeforeBg:before,
.TaraweraAfterBg:after,
.TaraweraBg {
    background-color: #073a50
}

.TaraweraColor {
    color: #073a50
}

.TartOrangeBeforeBg:before,
.TartOrangeAfterBg:after,
.TartOrangeBg {
    background-color: #fb4d46
}

.TartOrangeColor {
    color: #fb4d46
}

.TasmanBeforeBg:before,
.TasmanAfterBg:after,
.TasmanBg {
    background-color: #cfdccf
}

.TasmanColor {
    color: #cfdccf
}

.TaupeBeforeBg:before,
.TaupeAfterBg:after,
.TaupeBg {
    background-color: #483c32
}

.TaupeColor {
    color: #483c32
}

.TaupeGrayBeforeBg:before,
.TaupeGrayAfterBg:after,
.TaupeGrayBg {
    background-color: #8b8589
}

.TaupeGrayColor {
    color: #8b8589
}

.TawnyPortBeforeBg:before,
.TawnyPortAfterBg:after,
.TawnyPortBg {
    background-color: #692545
}

.TawnyPortColor {
    color: #692545
}

.TePapaGreenBeforeBg:before,
.TePapaGreenAfterBg:after,
.TePapaGreenBg {
    background-color: #1e433c
}

.TePapaGreenColor {
    color: #1e433c
}

.TeaBeforeBg:before,
.TeaAfterBg:after,
.TeaBg {
    background-color: #c1bab0
}

.TeaColor {
    color: #c1bab0
}

.TeaGreenBeforeBg:before,
.TeaGreenAfterBg:after,
.TeaGreenBg {
    background-color: #d0f0c0
}

.TeaGreenColor {
    color: #d0f0c0
}

.TeaRoseBeforeBg:before,
.TeaRoseAfterBg:after,
.TeaRoseBg {
    background-color: #f4c2c2
}

.TeaRoseColor {
    color: #f4c2c2
}

.TeakBeforeBg:before,
.TeakAfterBg:after,
.TeakBg {
    background-color: #b19461
}

.TeakColor {
    color: #b19461
}

.TealBeforeBg:before,
.TealAfterBg:after,
.TealBg {
    background-color: #008080
}

.TealColor {
    color: #008080
}

.TealBlueBeforeBg:before,
.TealBlueAfterBg:after,
.TealBlueBg {
    background-color: #367588
}

.TealBlueColor {
    color: #367588
}

.TealDeerBeforeBg:before,
.TealDeerAfterBg:after,
.TealDeerBg {
    background-color: #99e6b3
}

.TealDeerColor {
    color: #99e6b3
}

.TealGreenBeforeBg:before,
.TealGreenAfterBg:after,
.TealGreenBg {
    background-color: #00827f
}

.TealGreenColor {
    color: #00827f
}

.TelemagentaBeforeBg:before,
.TelemagentaAfterBg:after,
.TelemagentaBg {
    background-color: #cf3476
}

.TelemagentaColor {
    color: #cf3476
}

.TemptressBeforeBg:before,
.TemptressAfterBg:after,
.TemptressBg {
    background-color: #3b000b
}

.TemptressColor {
    color: #3b000b
}

.TenneBeforeBg:before,
.TenneAfterBg:after,
.TenneBg {
    background-color: #cd5700
}

.TenneColor {
    color: #cd5700
}

.TequilaBeforeBg:before,
.TequilaAfterBg:after,
.TequilaBg {
    background-color: #ffe6c7
}

.TequilaColor {
    color: #ffe6c7
}

.TerraCottaBeforeBg:before,
.TerraCottaAfterBg:after,
.TerraCottaBg {
    background-color: #e2725b
}

.TerraCottaColor {
    color: #e2725b
}

.TexasBeforeBg:before,
.TexasAfterBg:after,
.TexasBg {
    background-color: #f8f99c
}

.TexasColor {
    color: #f8f99c
}

.TexasRoseBeforeBg:before,
.TexasRoseAfterBg:after,
.TexasRoseBg {
    background-color: #ffb555
}

.TexasRoseColor {
    color: #ffb555
}

.ThatchBeforeBg:before,
.ThatchAfterBg:after,
.ThatchBg {
    background-color: #b69d98
}

.ThatchColor {
    color: #b69d98
}

.ThatchGreenBeforeBg:before,
.ThatchGreenAfterBg:after,
.ThatchGreenBg {
    background-color: #403d19
}

.ThatchGreenColor {
    color: #403d19
}

.ThistleBeforeBg:before,
.ThistleAfterBg:after,
.ThistleBg {
    background-color: #d8bfd8
}

.ThistleColor {
    color: #d8bfd8
}

.ThistleGreenBeforeBg:before,
.ThistleGreenAfterBg:after,
.ThistleGreenBg {
    background-color: #cccaa8
}

.ThistleGreenColor {
    color: #cccaa8
}

.ThulianPinkBeforeBg:before,
.ThulianPinkAfterBg:after,
.ThulianPinkBg {
    background-color: #de6fa1
}

.ThulianPinkColor {
    color: #de6fa1
}

.ThunderBeforeBg:before,
.ThunderAfterBg:after,
.ThunderBg {
    background-color: #33292f
}

.ThunderColor {
    color: #33292f
}

.ThunderbirdBeforeBg:before,
.ThunderbirdAfterBg:after,
.ThunderbirdBg {
    background-color: #c02b18
}

.ThunderbirdColor {
    color: #c02b18
}

.TiaMariaBeforeBg:before,
.TiaMariaAfterBg:after,
.TiaMariaBg {
    background-color: #c1440e
}

.TiaMariaColor {
    color: #c1440e
}

.TiaraBeforeBg:before,
.TiaraAfterBg:after,
.TiaraBg {
    background-color: #c3d1d1
}

.TiaraColor {
    color: #c3d1d1
}

.TiberBeforeBg:before,
.TiberAfterBg:after,
.TiberBg {
    background-color: #063537
}

.TiberColor {
    color: #063537
}

.TickleMePinkBeforeBg:before,
.TickleMePinkAfterBg:after,
.TickleMePinkBg {
    background-color: #fc89ac
}

.TickleMePinkColor {
    color: #fc89ac
}

.TidalBeforeBg:before,
.TidalAfterBg:after,
.TidalBg {
    background-color: #f1ffad
}

.TidalColor {
    color: #f1ffad
}

.TideBeforeBg:before,
.TideAfterBg:after,
.TideBg {
    background-color: #bfb8b0
}

.TideColor {
    color: #bfb8b0
}

.TiffanyBlueBeforeBg:before,
.TiffanyBlueAfterBg:after,
.TiffanyBlueBg {
    background-color: #0abab5
}

.TiffanyBlueColor {
    color: #0abab5
}

.TigersEyeBeforeBg:before,
.TigersEyeAfterBg:after,
.TigersEyeBg {
    background-color: #e08d3c
}

.TigersEyeColor {
    color: #e08d3c
}

.TimberGreenBeforeBg:before,
.TimberGreenAfterBg:after,
.TimberGreenBg {
    background-color: #16322c
}

.TimberGreenColor {
    color: #16322c
}

.TimberwolfBeforeBg:before,
.TimberwolfAfterBg:after,
.TimberwolfBg {
    background-color: #dbd7d2
}

.TimberwolfColor {
    color: #dbd7d2
}

.TitanWhiteBeforeBg:before,
.TitanWhiteAfterBg:after,
.TitanWhiteBg {
    background-color: #f0eeff
}

.TitanWhiteColor {
    color: #f0eeff
}

.TitaniumYellowBeforeBg:before,
.TitaniumYellowAfterBg:after,
.TitaniumYellowBg {
    background-color: #eee600
}

.TitaniumYellowColor {
    color: #eee600
}

.ToastBeforeBg:before,
.ToastAfterBg:after,
.ToastBg {
    background-color: #9a6e61
}

.ToastColor {
    color: #9a6e61
}

.TobaccoBrownBeforeBg:before,
.TobaccoBrownAfterBg:after,
.TobaccoBrownBg {
    background-color: #715d47
}

.TobaccoBrownColor {
    color: #715d47
}

.ToledoBeforeBg:before,
.ToledoAfterBg:after,
.ToledoBg {
    background-color: #3a0020
}

.ToledoColor {
    color: #3a0020
}

.TolopeaBeforeBg:before,
.TolopeaAfterBg:after,
.TolopeaBg {
    background-color: #1b0245
}

.TolopeaColor {
    color: #1b0245
}

.TomThumbBeforeBg:before,
.TomThumbAfterBg:after,
.TomThumbBg {
    background-color: #3f583b
}

.TomThumbColor {
    color: #3f583b
}

.TomatoBeforeBg:before,
.TomatoAfterBg:after,
.TomatoBg {
    background-color: #ff6347
}

.TomatoColor {
    color: #ff6347
}

.TonysPinkBeforeBg:before,
.TonysPinkAfterBg:after,
.TonysPinkBg {
    background-color: #e79f8c
}

.TonysPinkColor {
    color: #e79f8c
}

.ToolboxBeforeBg:before,
.ToolboxAfterBg:after,
.ToolboxBg {
    background-color: #746cc0
}

.ToolboxColor {
    color: #746cc0
}

.TopazBeforeBg:before,
.TopazAfterBg:after,
.TopazBg {
    background-color: #ffc87c
}

.TopazColor {
    color: #ffc87c
}

.ToreaBayBeforeBg:before,
.ToreaBayAfterBg:after,
.ToreaBayBg {
    background-color: #0f2d9e
}

.ToreaBayColor {
    color: #0f2d9e
}

.ToryBlueBeforeBg:before,
.ToryBlueAfterBg:after,
.ToryBlueBg {
    background-color: #1450aa
}

.ToryBlueColor {
    color: #1450aa
}

.ToscaBeforeBg:before,
.ToscaAfterBg:after,
.ToscaBg {
    background-color: #8d3f3f
}

.ToscaColor {
    color: #8d3f3f
}

.TotemPoleBeforeBg:before,
.TotemPoleAfterBg:after,
.TotemPoleBg {
    background-color: #991b07
}

.TotemPoleColor {
    color: #991b07
}

.TowerGrayBeforeBg:before,
.TowerGrayAfterBg:after,
.TowerGrayBg {
    background-color: #a9bdbf
}

.TowerGrayColor {
    color: #a9bdbf
}

.TractorRedBeforeBg:before,
.TractorRedAfterBg:after,
.TractorRedBg {
    background-color: #fd0e35
}

.TractorRedColor {
    color: #fd0e35
}

.TradewindBeforeBg:before,
.TradewindAfterBg:after,
.TradewindBg {
    background-color: #5fb3ac
}

.TradewindColor {
    color: #5fb3ac
}

.TranquilBeforeBg:before,
.TranquilAfterBg:after,
.TranquilBg {
    background-color: #e6ffff
}

.TranquilColor {
    color: #e6ffff
}

.TravertineBeforeBg:before,
.TravertineAfterBg:after,
.TravertineBg {
    background-color: #fffde8
}

.TravertineColor {
    color: #fffde8
}

.TreePoppyBeforeBg:before,
.TreePoppyAfterBg:after,
.TreePoppyBg {
    background-color: #fc9c1d
}

.TreePoppyColor {
    color: #fc9c1d
}

.TreehouseBeforeBg:before,
.TreehouseAfterBg:after,
.TreehouseBg {
    background-color: #3b2820
}

.TreehouseColor {
    color: #3b2820
}

.TrendyGreenBeforeBg:before,
.TrendyGreenAfterBg:after,
.TrendyGreenBg {
    background-color: #7c881a
}

.TrendyGreenColor {
    color: #7c881a
}

.TrendyPinkBeforeBg:before,
.TrendyPinkAfterBg:after,
.TrendyPinkBg {
    background-color: #8c6495
}

.TrendyPinkColor {
    color: #8c6495
}

.TrinidadBeforeBg:before,
.TrinidadAfterBg:after,
.TrinidadBg {
    background-color: #e64e03
}

.TrinidadColor {
    color: #e64e03
}

.TropicalBlueBeforeBg:before,
.TropicalBlueAfterBg:after,
.TropicalBlueBg {
    background-color: #c3ddf9
}

.TropicalBlueColor {
    color: #c3ddf9
}

.TropicalRainForestBeforeBg:before,
.TropicalRainForestAfterBg:after,
.TropicalRainForestBg {
    background-color: #00755e
}

.TropicalRainForestColor {
    color: #00755e
}

.TropicalVioletBeforeBg:before,
.TropicalVioletAfterBg:after,
.TropicalVioletBg {
    background-color: #cda4de
}

.TropicalVioletColor {
    color: #cda4de
}

.TroutBeforeBg:before,
.TroutAfterBg:after,
.TroutBg {
    background-color: #4a4e5a
}

.TroutColor {
    color: #4a4e5a
}

.TrueBlueBeforeBg:before,
.TrueBlueAfterBg:after,
.TrueBlueBg {
    background-color: #0073cf
}

.TrueBlueColor {
    color: #0073cf
}

.TrueVBeforeBg:before,
.TrueVAfterBg:after,
.TrueVBg {
    background-color: #8a73d6
}

.TrueVColor {
    color: #8a73d6
}

.TuataraBeforeBg:before,
.TuataraAfterBg:after,
.TuataraBg {
    background-color: #363534
}

.TuataraColor {
    color: #363534
}

.TuftBushBeforeBg:before,
.TuftBushAfterBg:after,
.TuftBushBg {
    background-color: #ffddcd
}

.TuftBushColor {
    color: #ffddcd
}

.TuftsBlueBeforeBg:before,
.TuftsBlueAfterBg:after,
.TuftsBlueBg {
    background-color: #417dc1
}

.TuftsBlueColor {
    color: #417dc1
}

.TulipBeforeBg:before,
.TulipAfterBg:after,
.TulipBg {
    background-color: #ff878d
}

.TulipColor {
    color: #ff878d
}

.TulipTreeBeforeBg:before,
.TulipTreeAfterBg:after,
.TulipTreeBg {
    background-color: #eab33b
}

.TulipTreeColor {
    color: #eab33b
}

.TumbleweedBeforeBg:before,
.TumbleweedAfterBg:after,
.TumbleweedBg {
    background-color: #deaa88
}

.TumbleweedColor {
    color: #deaa88
}

.TunaBeforeBg:before,
.TunaAfterBg:after,
.TunaBg {
    background-color: #353542
}

.TunaColor {
    color: #353542
}

.TundoraBeforeBg:before,
.TundoraAfterBg:after,
.TundoraBg {
    background-color: #4a4244
}

.TundoraColor {
    color: #4a4244
}

.TurboBeforeBg:before,
.TurboAfterBg:after,
.TurboBg {
    background-color: #fae600
}

.TurboColor {
    color: #fae600
}

.TurkishRoseBeforeBg:before,
.TurkishRoseAfterBg:after,
.TurkishRoseBg {
    background-color: #b57281
}

.TurkishRoseColor {
    color: #b57281
}

.TurmericBeforeBg:before,
.TurmericAfterBg:after,
.TurmericBg {
    background-color: #cabb48
}

.TurmericColor {
    color: #cabb48
}

.TurquoiseBeforeBg:before,
.TurquoiseAfterBg:after,
.TurquoiseBg {
    background-color: #40e0d0
}

.TurquoiseColor {
    color: #40e0d0
}

.TurquoiseBlueBeforeBg:before,
.TurquoiseBlueAfterBg:after,
.TurquoiseBlueBg {
    background-color: #00ffef
}

.TurquoiseBlueColor {
    color: #00ffef
}

.TurquoiseGreenBeforeBg:before,
.TurquoiseGreenAfterBg:after,
.TurquoiseGreenBg {
    background-color: #a0d6b4
}

.TurquoiseGreenColor {
    color: #a0d6b4
}

.TurtleGreenBeforeBg:before,
.TurtleGreenAfterBg:after,
.TurtleGreenBg {
    background-color: #2a380b
}

.TurtleGreenColor {
    color: #2a380b
}

.TuscanRedBeforeBg:before,
.TuscanRedAfterBg:after,
.TuscanRedBg {
    background-color: #7c4848
}

.TuscanRedColor {
    color: #7c4848
}

.TuscanTanBeforeBg:before,
.TuscanTanAfterBg:after,
.TuscanTanBg {
    background-color: #a67b5b
}

.TuscanTanColor {
    color: #a67b5b
}

.TuscanyBeforeBg:before,
.TuscanyAfterBg:after,
.TuscanyBg {
    background-color: #c09999
}

.TuscanyColor {
    color: #c09999
}

.TuskBeforeBg:before,
.TuskAfterBg:after,
.TuskBg {
    background-color: #eef3c3
}

.TuskColor {
    color: #eef3c3
}

.TussockBeforeBg:before,
.TussockAfterBg:after,
.TussockBg {
    background-color: #c5994b
}

.TussockColor {
    color: #c5994b
}

.TutuBeforeBg:before,
.TutuAfterBg:after,
.TutuBg {
    background-color: #fff1f9
}

.TutuColor {
    color: #fff1f9
}

.TwilightBeforeBg:before,
.TwilightAfterBg:after,
.TwilightBg {
    background-color: #e4cfde
}

.TwilightColor {
    color: #e4cfde
}

.TwilightBlueBeforeBg:before,
.TwilightBlueAfterBg:after,
.TwilightBlueBg {
    background-color: #eefdff
}

.TwilightBlueColor {
    color: #eefdff
}

.TwilightLavenderBeforeBg:before,
.TwilightLavenderAfterBg:after,
.TwilightLavenderBg {
    background-color: #8a496b
}

.TwilightLavenderColor {
    color: #8a496b
}

.TwineBeforeBg:before,
.TwineAfterBg:after,
.TwineBg {
    background-color: #c2955d
}

.TwineColor {
    color: #c2955d
}

.TyrianPurpleBeforeBg:before,
.TyrianPurpleAfterBg:after,
.TyrianPurpleBg {
    background-color: #66023c
}

.TyrianPurpleColor {
    color: #66023c
}

.UABlueBeforeBg:before,
.UABlueAfterBg:after,
.UABlueBg {
    background-color: #0033aa
}

.UABlueColor {
    color: #0033aa
}

.UARedBeforeBg:before,
.UARedAfterBg:after,
.UARedBg {
    background-color: #d9004c
}

.UARedColor {
    color: #d9004c
}

.UCLABlueBeforeBg:before,
.UCLABlueAfterBg:after,
.UCLABlueBg {
    background-color: #536895
}

.UCLABlueColor {
    color: #536895
}

.UCLAGoldBeforeBg:before,
.UCLAGoldAfterBg:after,
.UCLAGoldBg {
    background-color: #ffb300
}

.UCLAGoldColor {
    color: #ffb300
}

.UFOGreenBeforeBg:before,
.UFOGreenAfterBg:after,
.UFOGreenBg {
    background-color: #3cd070
}

.UFOGreenColor {
    color: #3cd070
}

.UPForestGreenBeforeBg:before,
.UPForestGreenAfterBg:after,
.UPForestGreenBg {
    background-color: #014421
}

.UPForestGreenColor {
    color: #014421
}

.UPMaroonBeforeBg:before,
.UPMaroonAfterBg:after,
.UPMaroonBg {
    background-color: #7b1113
}

.UPMaroonColor {
    color: #7b1113
}

.USAFABlueBeforeBg:before,
.USAFABlueAfterBg:after,
.USAFABlueBg {
    background-color: #004f98
}

.USAFABlueColor {
    color: #004f98
}

.UbeBeforeBg:before,
.UbeAfterBg:after,
.UbeBg {
    background-color: #8878c3
}

.UbeColor {
    color: #8878c3
}

.UltraPinkBeforeBg:before,
.UltraPinkAfterBg:after,
.UltraPinkBg {
    background-color: #ff6fff
}

.UltraPinkColor {
    color: #ff6fff
}

.UltramarineBeforeBg:before,
.UltramarineAfterBg:after,
.UltramarineBg {
    background-color: #3f00ff
}

.UltramarineColor {
    color: #3f00ff
}

.UltramarineBlueBeforeBg:before,
.UltramarineBlueAfterBg:after,
.UltramarineBlueBg {
    background-color: #4166f5
}

.UltramarineBlueColor {
    color: #4166f5
}

.UmberBeforeBg:before,
.UmberAfterBg:after,
.UmberBg {
    background-color: #635147
}

.UmberColor {
    color: #635147
}

.UnbleachedSilkBeforeBg:before,
.UnbleachedSilkAfterBg:after,
.UnbleachedSilkBg {
    background-color: #ffddca
}

.UnbleachedSilkColor {
    color: #ffddca
}

.UnderagePinkBeforeBg:before,
.UnderagePinkAfterBg:after,
.UnderagePinkBg {
    background-color: #f9e6f4
}

.UnderagePinkColor {
    color: #f9e6f4
}

.UnitedNationsBlueBeforeBg:before,
.UnitedNationsBlueAfterBg:after,
.UnitedNationsBlueBg {
    background-color: #5b92e5
}

.UnitedNationsBlueColor {
    color: #5b92e5
}

.UniversityOfCaliforniaGoldBeforeBg:before,
.UniversityOfCaliforniaGoldAfterBg:after,
.UniversityOfCaliforniaGoldBg {
    background-color: #b78727
}

.UniversityOfCaliforniaGoldColor {
    color: #b78727
}

.UniversityOfTennesseeOrangeBeforeBg:before,
.UniversityOfTennesseeOrangeAfterBg:after,
.UniversityOfTennesseeOrangeBg {
    background-color: #f77f00
}

.UniversityOfTennesseeOrangeColor {
    color: #f77f00
}

.UnmellowYellowBeforeBg:before,
.UnmellowYellowAfterBg:after,
.UnmellowYellowBg {
    background-color: #ffff66
}

.UnmellowYellowColor {
    color: #ffff66
}

.UpsdellRedBeforeBg:before,
.UpsdellRedAfterBg:after,
.UpsdellRedBg {
    background-color: #ae2029
}

.UpsdellRedColor {
    color: #ae2029
}

.UrobilinBeforeBg:before,
.UrobilinAfterBg:after,
.UrobilinBg {
    background-color: #e1ad21
}

.UrobilinColor {
    color: #e1ad21
}

.UtahCrimsonBeforeBg:before,
.UtahCrimsonAfterBg:after,
.UtahCrimsonBg {
    background-color: #d3003f
}

.UtahCrimsonColor {
    color: #d3003f
}

.ValenciaBeforeBg:before,
.ValenciaAfterBg:after,
.ValenciaBg {
    background-color: #d84437
}

.ValenciaColor {
    color: #d84437
}

.ValentinoBeforeBg:before,
.ValentinoAfterBg:after,
.ValentinoBg {
    background-color: #350e42
}

.ValentinoColor {
    color: #350e42
}

.ValhallaBeforeBg:before,
.ValhallaAfterBg:after,
.ValhallaBg {
    background-color: #2b194f
}

.ValhallaColor {
    color: #2b194f
}

.VanCleefBeforeBg:before,
.VanCleefAfterBg:after,
.VanCleefBg {
    background-color: #49170c
}

.VanCleefColor {
    color: #49170c
}

.VanDykeBrownBeforeBg:before,
.VanDykeBrownAfterBg:after,
.VanDykeBrownBg {
    background-color: #664228
}

.VanDykeBrownColor {
    color: #664228
}

.VanillaBeforeBg:before,
.VanillaAfterBg:after,
.VanillaBg {
    background-color: #f3e5ab
}

.VanillaColor {
    color: #f3e5ab
}

.VanillaIceBeforeBg:before,
.VanillaIceAfterBg:after,
.VanillaIceBg {
    background-color: #f38fa9
}

.VanillaIceColor {
    color: #f38fa9
}

.VardenBeforeBg:before,
.VardenAfterBg:after,
.VardenBg {
    background-color: #fff6df
}

.VardenColor {
    color: #fff6df
}

.VegasGoldBeforeBg:before,
.VegasGoldAfterBg:after,
.VegasGoldBg {
    background-color: #c5b358
}

.VegasGoldColor {
    color: #c5b358
}

.VenetianRedBeforeBg:before,
.VenetianRedAfterBg:after,
.VenetianRedBg {
    background-color: #c80815
}

.VenetianRedColor {
    color: #c80815
}

.VeniceBlueBeforeBg:before,
.VeniceBlueAfterBg:after,
.VeniceBlueBg {
    background-color: #055989
}

.VeniceBlueColor {
    color: #055989
}

.VenusBeforeBg:before,
.VenusAfterBg:after,
.VenusBg {
    background-color: #928590
}

.VenusColor {
    color: #928590
}

.VerdigrisBeforeBg:before,
.VerdigrisAfterBg:after,
.VerdigrisBg {
    background-color: #43b3ae
}

.VerdigrisColor {
    color: #43b3ae
}

.VerdunGreenBeforeBg:before,
.VerdunGreenAfterBg:after,
.VerdunGreenBg {
    background-color: #495400
}

.VerdunGreenColor {
    color: #495400
}

.VermilionBeforeBg:before,
.VermilionAfterBg:after,
.VermilionBg {
    background-color: #d9381e
}

.VermilionColor {
    color: #d9381e
}

.VeronicaBeforeBg:before,
.VeronicaAfterBg:after,
.VeronicaBg {
    background-color: #a020f0
}

.VeronicaColor {
    color: #a020f0
}

.VeryLightAzureBeforeBg:before,
.VeryLightAzureAfterBg:after,
.VeryLightAzureBg {
    background-color: #74bbfb
}

.VeryLightAzureColor {
    color: #74bbfb
}

.VeryLightBlueBeforeBg:before,
.VeryLightBlueAfterBg:after,
.VeryLightBlueBg {
    background-color: #6666ff
}

.VeryLightBlueColor {
    color: #6666ff
}

.VeryLightMalachiteGreenBeforeBg:before,
.VeryLightMalachiteGreenAfterBg:after,
.VeryLightMalachiteGreenBg {
    background-color: #64e986
}

.VeryLightMalachiteGreenColor {
    color: #64e986
}

.VeryLightTangeloBeforeBg:before,
.VeryLightTangeloAfterBg:after,
.VeryLightTangeloBg {
    background-color: #ffb077
}

.VeryLightTangeloColor {
    color: #ffb077
}

.VeryPaleOrangeBeforeBg:before,
.VeryPaleOrangeAfterBg:after,
.VeryPaleOrangeBg {
    background-color: #ffdfbf
}

.VeryPaleOrangeColor {
    color: #ffdfbf
}

.VeryPaleYellowBeforeBg:before,
.VeryPaleYellowAfterBg:after,
.VeryPaleYellowBg {
    background-color: #ffffbf
}

.VeryPaleYellowColor {
    color: #ffffbf
}

.VesuviusBeforeBg:before,
.VesuviusAfterBg:after,
.VesuviusBg {
    background-color: #b14a0b
}

.VesuviusColor {
    color: #b14a0b
}

.VictoriaBeforeBg:before,
.VictoriaAfterBg:after,
.VictoriaBg {
    background-color: #534491
}

.VictoriaColor {
    color: #534491
}

.VidaLocaBeforeBg:before,
.VidaLocaAfterBg:after,
.VidaLocaBg {
    background-color: #549019
}

.VidaLocaColor {
    color: #549019
}

.VikingBeforeBg:before,
.VikingAfterBg:after,
.VikingBg {
    background-color: #64ccdb
}

.VikingColor {
    color: #64ccdb
}

.VinRougeBeforeBg:before,
.VinRougeAfterBg:after,
.VinRougeBg {
    background-color: #983d61
}

.VinRougeColor {
    color: #983d61
}

.ViolaBeforeBg:before,
.ViolaAfterBg:after,
.ViolaBg {
    background-color: #cb8fa9
}

.ViolaColor {
    color: #cb8fa9
}

.ViolentVioletBeforeBg:before,
.ViolentVioletAfterBg:after,
.ViolentVioletBg {
    background-color: #290c5e
}

.ViolentVioletColor {
    color: #290c5e
}

.VioletBeforeBg:before,
.VioletAfterBg:after,
.VioletBg {
    background-color: #7f00ff
}

.VioletColor {
    color: #7f00ff
}

.VioletBlueBeforeBg:before,
.VioletBlueAfterBg:after,
.VioletBlueBg {
    background-color: #324ab2
}

.VioletBlueColor {
    color: #324ab2
}

.VioletEggplantBeforeBg:before,
.VioletEggplantAfterBg:after,
.VioletEggplantBg {
    background-color: #991199
}

.VioletEggplantColor {
    color: #991199
}

.VioletRedBeforeBg:before,
.VioletRedAfterBg:after,
.VioletRedBg {
    background-color: #f75394
}

.VioletRedColor {
    color: #f75394
}

.ViridianBeforeBg:before,
.ViridianAfterBg:after,
.ViridianBg {
    background-color: #40826d
}

.ViridianColor {
    color: #40826d
}

.ViridianGreenBeforeBg:before,
.ViridianGreenAfterBg:after,
.ViridianGreenBg {
    background-color: #009698
}

.ViridianGreenColor {
    color: #009698
}

.VisVisBeforeBg:before,
.VisVisAfterBg:after,
.VisVisBg {
    background-color: #ffefa1
}

.VisVisColor {
    color: #ffefa1
}

.VistaBlueBeforeBg:before,
.VistaBlueAfterBg:after,
.VistaBlueBg {
    background-color: #7c9ed9
}

.VistaBlueColor {
    color: #7c9ed9
}

.VistaWhiteBeforeBg:before,
.VistaWhiteAfterBg:after,
.VistaWhiteBg {
    background-color: #fcf8f7
}

.VistaWhiteColor {
    color: #fcf8f7
}

.VividAmberBeforeBg:before,
.VividAmberAfterBg:after,
.VividAmberBg {
    background-color: #cc9900
}

.VividAmberColor {
    color: #cc9900
}

.VividAuburnBeforeBg:before,
.VividAuburnAfterBg:after,
.VividAuburnBg {
    background-color: #922724
}

.VividAuburnColor {
    color: #922724
}

.VividBurgundyBeforeBg:before,
.VividBurgundyAfterBg:after,
.VividBurgundyBg {
    background-color: #9f1d35
}

.VividBurgundyColor {
    color: #9f1d35
}

.VividCeriseBeforeBg:before,
.VividCeriseAfterBg:after,
.VividCeriseBg {
    background-color: #da1d81
}

.VividCeriseColor {
    color: #da1d81
}

.VividCeruleanBeforeBg:before,
.VividCeruleanAfterBg:after,
.VividCeruleanBg {
    background-color: #00aaee
}

.VividCeruleanColor {
    color: #00aaee
}

.VividCrimsonBeforeBg:before,
.VividCrimsonAfterBg:after,
.VividCrimsonBg {
    background-color: #cc0033
}

.VividCrimsonColor {
    color: #cc0033
}

.VividGambogeBeforeBg:before,
.VividGambogeAfterBg:after,
.VividGambogeBg {
    background-color: #ff9900
}

.VividGambogeColor {
    color: #ff9900
}

.VividLimeGreenBeforeBg:before,
.VividLimeGreenAfterBg:after,
.VividLimeGreenBg {
    background-color: #a6d608
}

.VividLimeGreenColor {
    color: #a6d608
}

.VividMalachiteBeforeBg:before,
.VividMalachiteAfterBg:after,
.VividMalachiteBg {
    background-color: #00cc33
}

.VividMalachiteColor {
    color: #00cc33
}

.VividMulberryBeforeBg:before,
.VividMulberryAfterBg:after,
.VividMulberryBg {
    background-color: #b80ce3
}

.VividMulberryColor {
    color: #b80ce3
}

.VividOrangeBeforeBg:before,
.VividOrangeAfterBg:after,
.VividOrangeBg {
    background-color: #ff5f00
}

.VividOrangeColor {
    color: #ff5f00
}

.VividOrangePeelBeforeBg:before,
.VividOrangePeelAfterBg:after,
.VividOrangePeelBg {
    background-color: #ffa000
}

.VividOrangePeelColor {
    color: #ffa000
}

.VividOrchidBeforeBg:before,
.VividOrchidAfterBg:after,
.VividOrchidBg {
    background-color: #cc00ff
}

.VividOrchidColor {
    color: #cc00ff
}

.VividRaspberryBeforeBg:before,
.VividRaspberryAfterBg:after,
.VividRaspberryBg {
    background-color: #ff006c
}

.VividRaspberryColor {
    color: #ff006c
}

.VividRedBeforeBg:before,
.VividRedAfterBg:after,
.VividRedBg {
    background-color: #f70d1a
}

.VividRedColor {
    color: #f70d1a
}

.VividRedTangeloBeforeBg:before,
.VividRedTangeloAfterBg:after,
.VividRedTangeloBg {
    background-color: #df6124
}

.VividRedTangeloColor {
    color: #df6124
}

.VividSkyBlueBeforeBg:before,
.VividSkyBlueAfterBg:after,
.VividSkyBlueBg {
    background-color: #00ccff
}

.VividSkyBlueColor {
    color: #00ccff
}

.VividTangeloBeforeBg:before,
.VividTangeloAfterBg:after,
.VividTangeloBg {
    background-color: #f07427
}

.VividTangeloColor {
    color: #f07427
}

.VividTangerineBeforeBg:before,
.VividTangerineAfterBg:after,
.VividTangerineBg {
    background-color: #ffa089
}

.VividTangerineColor {
    color: #ffa089
}

.VividVermilionBeforeBg:before,
.VividVermilionAfterBg:after,
.VividVermilionBg {
    background-color: #e56024
}

.VividVermilionColor {
    color: #e56024
}

.VividVioletBeforeBg:before,
.VividVioletAfterBg:after,
.VividVioletBg {
    background-color: #9f00ff
}

.VividVioletColor {
    color: #9f00ff
}

.VividYellowBeforeBg:before,
.VividYellowAfterBg:after,
.VividYellowBg {
    background-color: #ffe302
}

.VividYellowColor {
    color: #ffe302
}

.VoltBeforeBg:before,
.VoltAfterBg:after,
.VoltBg {
    background-color: #ceff00
}

.VoltColor {
    color: #ceff00
}

.VoodooBeforeBg:before,
.VoodooAfterBg:after,
.VoodooBg {
    background-color: #533455
}

.VoodooColor {
    color: #533455
}

.VulcanBeforeBg:before,
.VulcanAfterBg:after,
.VulcanBg {
    background-color: #10121d
}

.VulcanColor {
    color: #10121d
}

.WaferBeforeBg:before,
.WaferAfterBg:after,
.WaferBg {
    background-color: #decbc6
}

.WaferColor {
    color: #decbc6
}

.WaikawaGrayBeforeBg:before,
.WaikawaGrayAfterBg:after,
.WaikawaGrayBg {
    background-color: #5a6e9c
}

.WaikawaGrayColor {
    color: #5a6e9c
}

.WaiouruBeforeBg:before,
.WaiouruAfterBg:after,
.WaiouruBg {
    background-color: #363c0d
}

.WaiouruColor {
    color: #363c0d
}

.WalnutBeforeBg:before,
.WalnutAfterBg:after,
.WalnutBg {
    background-color: #773f1a
}

.WalnutColor {
    color: #773f1a
}

.WarmBlackBeforeBg:before,
.WarmBlackAfterBg:after,
.WarmBlackBg {
    background-color: #004242
}

.WarmBlackColor {
    color: #004242
}

.WasabiBeforeBg:before,
.WasabiAfterBg:after,
.WasabiBg {
    background-color: #788a25
}

.WasabiColor {
    color: #788a25
}

.WaterLeafBeforeBg:before,
.WaterLeafAfterBg:after,
.WaterLeafBg {
    background-color: #a1e9de
}

.WaterLeafColor {
    color: #a1e9de
}

.WatercourseBeforeBg:before,
.WatercourseAfterBg:after,
.WatercourseBg {
    background-color: #056f57
}

.WatercourseColor {
    color: #056f57
}

.WaterlooBeforeBg:before,
.WaterlooAfterBg:after,
.WaterlooBg {
    background-color: #7b7c94
}

.WaterlooColor {
    color: #7b7c94
}

.WaterspoutBeforeBg:before,
.WaterspoutAfterBg:after,
.WaterspoutBg {
    background-color: #a4f4f9
}

.WaterspoutColor {
    color: #a4f4f9
}

.WattleBeforeBg:before,
.WattleAfterBg:after,
.WattleBg {
    background-color: #dcd747
}

.WattleColor {
    color: #dcd747
}

.WatusiBeforeBg:before,
.WatusiAfterBg:after,
.WatusiBg {
    background-color: #ffddcf
}

.WatusiColor {
    color: #ffddcf
}

.WaxFlowerBeforeBg:before,
.WaxFlowerAfterBg:after,
.WaxFlowerBg {
    background-color: #ffc0a8
}

.WaxFlowerColor {
    color: #ffc0a8
}

.WePeepBeforeBg:before,
.WePeepAfterBg:after,
.WePeepBg {
    background-color: #f7dbe6
}

.WePeepColor {
    color: #f7dbe6
}

.WebChartreuseBeforeBg:before,
.WebChartreuseAfterBg:after,
.WebChartreuseBg {
    background-color: #7fff00
}

.WebChartreuseColor {
    color: #7fff00
}

.WebOrangeBeforeBg:before,
.WebOrangeAfterBg:after,
.WebOrangeBg {
    background-color: #ffa500
}

.WebOrangeColor {
    color: #ffa500
}

.WedgewoodBeforeBg:before,
.WedgewoodAfterBg:after,
.WedgewoodBg {
    background-color: #4e7f9e
}

.WedgewoodColor {
    color: #4e7f9e
}

.WeldonBlueBeforeBg:before,
.WeldonBlueAfterBg:after,
.WeldonBlueBg {
    background-color: #7c98ab
}

.WeldonBlueColor {
    color: #7c98ab
}

.WellReadBeforeBg:before,
.WellReadAfterBg:after,
.WellReadBg {
    background-color: #b43332
}

.WellReadColor {
    color: #b43332
}

.WengeBeforeBg:before,
.WengeAfterBg:after,
.WengeBg {
    background-color: #645452
}

.WengeColor {
    color: #645452
}

.WestCoastBeforeBg:before,
.WestCoastAfterBg:after,
.WestCoastBg {
    background-color: #625119
}

.WestCoastColor {
    color: #625119
}

.WestSideBeforeBg:before,
.WestSideAfterBg:after,
.WestSideBg {
    background-color: #ff910f
}

.WestSideColor {
    color: #ff910f
}

.WestarBeforeBg:before,
.WestarAfterBg:after,
.WestarBg {
    background-color: #dcd9d2
}

.WestarColor {
    color: #dcd9d2
}

.WewakBeforeBg:before,
.WewakAfterBg:after,
.WewakBg {
    background-color: #f19bab
}

.WewakColor {
    color: #f19bab
}

.WheatBeforeBg:before,
.WheatAfterBg:after,
.WheatBg {
    background-color: #f5deb3
}

.WheatColor {
    color: #f5deb3
}

.WheatfieldBeforeBg:before,
.WheatfieldAfterBg:after,
.WheatfieldBg {
    background-color: #f3edcf
}

.WheatfieldColor {
    color: #f3edcf
}

.WhiskeyBeforeBg:before,
.WhiskeyAfterBg:after,
.WhiskeyBg {
    background-color: #d59a6f
}

.WhiskeyColor {
    color: #d59a6f
}

.WhisperBeforeBg:before,
.WhisperAfterBg:after,
.WhisperBg {
    background-color: #f7f5fa
}

.WhisperColor {
    color: #f7f5fa
}

.WhiteBeforeBg:before,
.WhiteAfterBg:after,
.WhiteBg {
    background-color: #ffffff
}

.WhiteColor {
    color: #ffffff
}

.WhiteIceBeforeBg:before,
.WhiteIceAfterBg:after,
.WhiteIceBg {
    background-color: #ddf9f1
}

.WhiteIceColor {
    color: #ddf9f1
}

.WhiteLilacBeforeBg:before,
.WhiteLilacAfterBg:after,
.WhiteLilacBg {
    background-color: #f8f7fc
}

.WhiteLilacColor {
    color: #f8f7fc
}

.WhiteLinenBeforeBg:before,
.WhiteLinenAfterBg:after,
.WhiteLinenBg {
    background-color: #f8f0e8
}

.WhiteLinenColor {
    color: #f8f0e8
}

.WhitePointerBeforeBg:before,
.WhitePointerAfterBg:after,
.WhitePointerBg {
    background-color: #fef8ff
}

.WhitePointerColor {
    color: #fef8ff
}

.WhiteRockBeforeBg:before,
.WhiteRockAfterBg:after,
.WhiteRockBg {
    background-color: #eae8d4
}

.WhiteRockColor {
    color: #eae8d4
}

.WhiteSmokeBeforeBg:before,
.WhiteSmokeAfterBg:after,
.WhiteSmokeBg {
    background-color: #f5f5f5
}

.WhiteSmokeColor {
    color: #f5f5f5
}

.WildBlueYonderBeforeBg:before,
.WildBlueYonderAfterBg:after,
.WildBlueYonderBg {
    background-color: #a2add0
}

.WildBlueYonderColor {
    color: #a2add0
}

.WildOrchidBeforeBg:before,
.WildOrchidAfterBg:after,
.WildOrchidBg {
    background-color: #d470a2
}

.WildOrchidColor {
    color: #d470a2
}

.WildRiceBeforeBg:before,
.WildRiceAfterBg:after,
.WildRiceBg {
    background-color: #ece090
}

.WildRiceColor {
    color: #ece090
}

.WildSandBeforeBg:before,
.WildSandAfterBg:after,
.WildSandBg {
    background-color: #f4f4f4
}

.WildSandColor {
    color: #f4f4f4
}

.WildStrawberryBeforeBg:before,
.WildStrawberryAfterBg:after,
.WildStrawberryBg {
    background-color: #ff43a4
}

.WildStrawberryColor {
    color: #ff43a4
}

.WildWatermelonBeforeBg:before,
.WildWatermelonAfterBg:after,
.WildWatermelonBg {
    background-color: #fc6c85
}

.WildWatermelonColor {
    color: #fc6c85
}

.WildWillowBeforeBg:before,
.WildWillowAfterBg:after,
.WildWillowBg {
    background-color: #b9c46a
}

.WildWillowColor {
    color: #b9c46a
}

.WilliamBeforeBg:before,
.WilliamAfterBg:after,
.WilliamBg {
    background-color: #3a686c
}

.WilliamColor {
    color: #3a686c
}

.WillowBrookBeforeBg:before,
.WillowBrookAfterBg:after,
.WillowBrookBg {
    background-color: #dfecda
}

.WillowBrookColor {
    color: #dfecda
}

.WillowGroveBeforeBg:before,
.WillowGroveAfterBg:after,
.WillowGroveBg {
    background-color: #65745d
}

.WillowGroveColor {
    color: #65745d
}

.WillpowerOrangeBeforeBg:before,
.WillpowerOrangeAfterBg:after,
.WillpowerOrangeBg {
    background-color: #fd5800
}

.WillpowerOrangeColor {
    color: #fd5800
}

.WindsorBeforeBg:before,
.WindsorAfterBg:after,
.WindsorBg {
    background-color: #3c0878
}

.WindsorColor {
    color: #3c0878
}

.WindsorTanBeforeBg:before,
.WindsorTanAfterBg:after,
.WindsorTanBg {
    background-color: #a75502
}

.WindsorTanColor {
    color: #a75502
}

.WineBeforeBg:before,
.WineAfterBg:after,
.WineBg {
    background-color: #722f37
}

.WineColor {
    color: #722f37
}

.WineBerryBeforeBg:before,
.WineBerryAfterBg:after,
.WineBerryBg {
    background-color: #591d35
}

.WineBerryColor {
    color: #591d35
}

.WineDregsBeforeBg:before,
.WineDregsAfterBg:after,
.WineDregsBg {
    background-color: #673147
}

.WineDregsColor {
    color: #673147
}

.WinterHazelBeforeBg:before,
.WinterHazelAfterBg:after,
.WinterHazelBg {
    background-color: #d5d195
}

.WinterHazelColor {
    color: #d5d195
}

.WinterSkyBeforeBg:before,
.WinterSkyAfterBg:after,
.WinterSkyBg {
    background-color: #ff007c
}

.WinterSkyColor {
    color: #ff007c
}

.WinterWizardBeforeBg:before,
.WinterWizardAfterBg:after,
.WinterWizardBg {
    background-color: #a0e6ff
}

.WinterWizardColor {
    color: #a0e6ff
}

.WintergreenDreamBeforeBg:before,
.WintergreenDreamAfterBg:after,
.WintergreenDreamBg {
    background-color: #56887d
}

.WintergreenDreamColor {
    color: #56887d
}

.WispPinkBeforeBg:before,
.WispPinkAfterBg:after,
.WispPinkBg {
    background-color: #fef4f8
}

.WispPinkColor {
    color: #fef4f8
}

.WisteriaBeforeBg:before,
.WisteriaAfterBg:after,
.WisteriaBg {
    background-color: #c9a0dc
}

.WisteriaColor {
    color: #c9a0dc
}

.WistfulBeforeBg:before,
.WistfulAfterBg:after,
.WistfulBg {
    background-color: #a4a6d3
}

.WistfulColor {
    color: #a4a6d3
}

.WitchHazeBeforeBg:before,
.WitchHazeAfterBg:after,
.WitchHazeBg {
    background-color: #fffc99
}

.WitchHazeColor {
    color: #fffc99
}

.WoodBarkBeforeBg:before,
.WoodBarkAfterBg:after,
.WoodBarkBg {
    background-color: #261105
}

.WoodBarkColor {
    color: #261105
}

.WoodlandBeforeBg:before,
.WoodlandAfterBg:after,
.WoodlandBg {
    background-color: #4d5328
}

.WoodlandColor {
    color: #4d5328
}

.WoodrushBeforeBg:before,
.WoodrushAfterBg:after,
.WoodrushBg {
    background-color: #302a0f
}

.WoodrushColor {
    color: #302a0f
}

.WoodsmokeBeforeBg:before,
.WoodsmokeAfterBg:after,
.WoodsmokeBg {
    background-color: #0c0d0f
}

.WoodsmokeColor {
    color: #0c0d0f
}

.WoodyBrownBeforeBg:before,
.WoodyBrownAfterBg:after,
.WoodyBrownBg {
    background-color: #483131
}

.WoodyBrownColor {
    color: #483131
}

.X11DarkGreenBeforeBg:before,
.X11DarkGreenAfterBg:after,
.X11DarkGreenBg {
    background-color: #006400
}

.X11DarkGreenColor {
    color: #006400
}

.X11GrayBeforeBg:before,
.X11GrayAfterBg:after,
.X11GrayBg {
    background-color: #bebebe
}

.X11GrayColor {
    color: #bebebe
}

.XanaduBeforeBg:before,
.XanaduAfterBg:after,
.XanaduBg {
    background-color: #738678
}

.XanaduColor {
    color: #738678
}

.YaleBlueBeforeBg:before,
.YaleBlueAfterBg:after,
.YaleBlueBg {
    background-color: #0f4d92
}

.YaleBlueColor {
    color: #0f4d92
}

.YankeesBlueBeforeBg:before,
.YankeesBlueAfterBg:after,
.YankeesBlueBg {
    background-color: #1c2841
}

.YankeesBlueColor {
    color: #1c2841
}

.YellowBeforeBg:before,
.YellowAfterBg:after,
.YellowBg {
    background-color: #ffff00
}

.YellowColor {
    color: #ffff00
}

.YellowGreenBeforeBg:before,
.YellowGreenAfterBg:after,
.YellowGreenBg {
    background-color: #9acd32
}

.YellowGreenColor {
    color: #9acd32
}

.YellowMetalBeforeBg:before,
.YellowMetalAfterBg:after,
.YellowMetalBg {
    background-color: #716338
}

.YellowMetalColor {
    color: #716338
}

.YellowOrangeBeforeBg:before,
.YellowOrangeAfterBg:after,
.YellowOrangeBg {
    background-color: #ffae42
}

.YellowOrangeColor {
    color: #ffae42
}

.YellowRoseBeforeBg:before,
.YellowRoseAfterBg:after,
.YellowRoseBg {
    background-color: #fff000
}

.YellowRoseColor {
    color: #fff000
}

.YellowSeaBeforeBg:before,
.YellowSeaAfterBg:after,
.YellowSeaBg {
    background-color: #fea904
}

.YellowSeaColor {
    color: #fea904
}

.YourPinkBeforeBg:before,
.YourPinkAfterBg:after,
.YourPinkBg {
    background-color: #ffc3c0
}

.YourPinkColor {
    color: #ffc3c0
}

.YukonGoldBeforeBg:before,
.YukonGoldAfterBg:after,
.YukonGoldBg {
    background-color: #7b6608
}

.YukonGoldColor {
    color: #7b6608
}

.YumaBeforeBg:before,
.YumaAfterBg:after,
.YumaBg {
    background-color: #cec291
}

.YumaColor {
    color: #cec291
}

.ZaffreBeforeBg:before,
.ZaffreAfterBg:after,
.ZaffreBg {
    background-color: #0014a8
}

.ZaffreColor {
    color: #0014a8
}

.ZambeziBeforeBg:before,
.ZambeziAfterBg:after,
.ZambeziBg {
    background-color: #685558
}

.ZambeziColor {
    color: #685558
}

.ZanahBeforeBg:before,
.ZanahAfterBg:after,
.ZanahBg {
    background-color: #daecd6
}

.ZanahColor {
    color: #daecd6
}

.ZestBeforeBg:before,
.ZestAfterBg:after,
.ZestBg {
    background-color: #e5841b
}

.ZestColor {
    color: #e5841b
}

.ZeusBeforeBg:before,
.ZeusAfterBg:after,
.ZeusBg {
    background-color: #292319
}

.ZeusColor {
    color: #292319
}

.ZigguratBeforeBg:before,
.ZigguratAfterBg:after,
.ZigguratBg {
    background-color: #bfdbe2
}

.ZigguratColor {
    color: #bfdbe2
}

.ZinnwalditeBeforeBg:before,
.ZinnwalditeAfterBg:after,
.ZinnwalditeBg {
    background-color: #ebc2af
}

.ZinnwalditeColor {
    color: #ebc2af
}

.ZinnwalditeBrownBeforeBg:before,
.ZinnwalditeBrownAfterBg:after,
.ZinnwalditeBrownBg {
    background-color: #2c1608
}

.ZinnwalditeBrownColor {
    color: #2c1608
}

.ZirconBeforeBg:before,
.ZirconAfterBg:after,
.ZirconBg {
    background-color: #f4f8ff
}

.ZirconColor {
    color: #f4f8ff
}

.ZombieBeforeBg:before,
.ZombieAfterBg:after,
.ZombieBg {
    background-color: #e4d69b
}

.ZombieColor {
    color: #e4d69b
}

.ZompBeforeBg:before,
.ZompAfterBg:after,
.ZompBg {
    background-color: #39a78e
}

.ZompColor {
    color: #39a78e
}

.ZorbaBeforeBg:before,
.ZorbaAfterBg:after,
.ZorbaBg {
    background-color: #a59b91
}

.ZorbaColor {
    color: #a59b91
}

.ZucciniBeforeBg:before,
.ZucciniAfterBg:after,
.ZucciniBg {
    background-color: #044022
}

.ZucciniColor {
    color: #044022
}

.ZumthorBeforeBg:before,
.ZumthorAfterBg:after,
.ZumthorBg {
    background-color: #edf6ff
}

.ZumthorColor {
    color: #edf6ff
}
